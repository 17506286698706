/****************************************************************************************
 *  This component creates a Parent Record for a Map Record or Form Record              *
 *  It consists of a popup that allows the user to select "Map" or "Form"               *
 *                                                                                      *
 *  Author: Robert Gautier / Joe J                                                      *
 *  Created on: 4/17/19                                                                 *
 *                                                                                      *
 ****************************************************************************************/
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form as Formm } from "react-form";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import Close from "@material-ui/icons/Close";
import { ParentRecord, RecordSection, RecordField } from "../models";
import Select from "../../common/Select";

class CreateNewDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            formName: null,
            flag: true,
            count: 0,
        };
    } 
    handleForm = (form) => {
        const { ormParentRecordUpdateLocalOnly, sectionid, forms, page, history, ormRecordSectionUpdateLocalOnly, authState } = this.props;
        const { formName, count } = this.state;

        var actForm = [];

        //get actual form from multiple forms
        if (formName) {
            actForm = forms.filter((e) => {
                if (e.name === formName) {
                    return e;
                } else {
                    return null;
                }
            });
        } else {
            //get single form
            actForm[0] = forms[0];
        }
        if (!formName && forms.length > 1) {
            alert("You must select a form");
            //if multiple forms make sure one is selected else dont proceed
            return;
        } else if (actForm.length === 1) {
            this.setState({
                loading: true,
                flag: false,
                count: count + 1,
            });
            if (actForm[0].groups.length > 0 && count === 0) {
                try {
                    //eslint-disable-next-line
                    const res = fetch(`page/${page}/${sectionid}/${actForm[0].id}/${form}/${authState.user.auth_token}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Token " + authState.user.auth_token,
                        },
                    }).then((e) => {
                        if (e.status === 200) {
                            e.json().then((rsp) => {
                                ormParentRecordUpdateLocalOnly({
                                    id: rsp.prid,
                                    parent_rs: rsp.rsid,
                                });

                                ormRecordSectionUpdateLocalOnly({
                                    id: rsp.rsid,
                                    has_children_loaded: true,
                                    //...record_section
                                });
                                this.setState({ loading: false });
                                rsp.form === "form" //form case
                                    ? history.push(
                                          "/page/" +
                                              rsp.page +
                                              "/parentrecord/" +
                                              rsp.prid +
                                              "/section/" +
                                              rsp.section +
                                              "/recordsection/" +
                                              rsp.rsid +
                                              "/form"
                                      )
                                    : //map case
                                      history.push("/page/" + rsp.page + "/parentrecord/" + rsp.prid + "/recordsection/" + rsp.rsid + "/map");
                            });
                        } else {
                            this.setState({ loading: false });
                        }
                    });
                } catch {
                    this.setState({ loading: false });
                }
            } else {
                alert("There are no Groups Created");
                this.setState({ loading: false });
            }
        }
    };
    //update the multiform react select form type for saving
    updateFormType = (e) => {
        const { sectionGeometry } = this.props;
        this.setState({ formName: e });
        const comp = this;

        setTimeout(function () {
            if (sectionGeometry) comp.handleForm("map");
            else comp.handleForm("form");
        }, 300);
    };

    render() {
        const { classes, returnDialog, open, forms, sectionGeometry, has_confirmation } = this.props;
        const { flag, loading, count } = this.state;

        // These two functions just make values to a mandatory label/value which is what react-forms expects
        function MAKE_OPTIONS(table) {
            return table.map((row) => ({
                label: row,
                value: row,
            }));
        }

        //this is to get the 'formNames' for the selected section so that the 'dialog dropdown' can be populated.
        if (forms)
            var formNames = forms.map((n) => {
                return n.name;
            });

        var onlyOneForm = {}; // for 1 form creation
        if (formNames) onlyOneForm.label = formNames[0];

        var title = loading ? "Creating a New Record" : has_confirmation ? "Confirm Record Creation" : "Select a Form";

        /* route to "automatically" create new record as we only have ONE form and NO geometry*/
        if (flag && !sectionGeometry && formNames.length === 1 && count === 0 && open && !has_confirmation) {
            this.handleForm("form");
        } else if (flag && sectionGeometry && formNames.length === 1 && count === 0 && open && !has_confirmation) {
            this.handleForm("map");
        }
        return (
            <>
                {!has_confirmation && !loading && (
                    <Dialog open={open}>
                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                            <Typography variant="h5" className={classes.flex}>
                                {title}
                            </Typography>
                            <IconButton onClick={returnDialog}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <DialogContent style={{ maxWidth: 500 }}>
                            <DialogContentText>
                                <Formm onSubmit={(values) => this.handleForm(values)}>
                                    {(formApi) => (
                                        <form onSubmit={formApi.submitForm}>
                                            {!loading &&
                                                formNames &&
                                                formNames.length > 1 && ( //only display if there is a prior form created
                                                    <Select
                                                        field="name"
                                                        label="Select a Form"
                                                        options={MAKE_OPTIONS(formNames)}
                                                        eventHandle={this.updateFormType}
                                                        fullWidth
                                                    />
                                                )}
                                        </form>
                                    )}
                                </Formm>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                )}
                {has_confirmation && !loading && (
                    <Dialog open={open}>
                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                            <Typography variant="h5" className={classes.flex}>
                                {title}
                            </Typography>
                            <IconButton onClick={returnDialog}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <DialogContent style={{ maxWidth: 500 }}>
                            <DialogContentText>
                                <Typography gutterBottom>You are about to create a new record. Press OK to continue or cancel to undo.</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => {
                                                if (sectionGeometry) this.handleForm("map");
                                                else this.handleForm("form");
                                            }}>
                                            OK
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" color="primary" fullWidth onClick={returnDialog}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                )}

                {loading && (
                    <Dialog open={loading}>
                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                            <Typography variant="h5" className={classes.flex}>
                                Record is being Created
                            </Typography>

                            <IconButton onClick={returnDialog}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <DialogContent style={{ minWidth: 400 }}>
                            <DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                        <CircularProgress size={90} className={classes.progress} />
                                        <Typography variant="h6" style={{ textAlign: "center" }}>
                                            Loading... Please Wait
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                )}
            </>
        );
    }
}
CreateNewDialog.displayName = "CreateNewDialog";

CreateNewDialog = connect(
    (state, ownProps) => ({
        authState: state.auth,
    }),
    {
        ...ParentRecord.actions,
        ...RecordSection.actions,
        ...RecordField.actions,
    }
)(CreateNewDialog);

export default withRouter(CreateNewDialog);
