import React from "react";

//import ApartmentIcon from "@material-ui/icons/Apartment";
import AccessibilityNew from "@material-ui/icons/AccessibilityNew";
import Alarm from "@material-ui/icons/Alarm";
import Assignment from "@material-ui/icons/Assignment";
import AddAPhoto from "@material-ui/icons/AddAPhoto";
import Build from "@material-ui/icons/Build";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Call from "@material-ui/icons/Call";
import Contacts from "@material-ui/icons/Contacts";
import EcoIcon from "@material-ui/icons/Eco";
import Equalizer from "@material-ui/icons/Equalizer";
import Description from "@material-ui/icons/Description";
import DriveEta from "@material-ui/icons/DriveEta";
import Email from "@material-ui/icons/Email";
import HomeWork from "@material-ui/icons/HomeWork";
import Home from "@material-ui/icons/Home";
import House from "@material-ui/icons/House";
import InsertPhoto from "@material-ui/icons/InsertPhoto";
import Fastfood from "@material-ui/icons/Fastfood";
import Grain from "@material-ui/icons/Grain";
import Layers from "@material-ui/icons/Layers";
import Museum from "@material-ui/icons/Museum";
import Nature from "@material-ui/icons/Nature";
import NaturePeople from "@material-ui/icons/NaturePeople";
import QueryBuilder from "@material-ui/icons/QueryBuilder";
import Reorder from "@material-ui/icons/Reorder";
import Room from "@material-ui/icons/Room";
import Store from "@material-ui/icons/Store";
import Subject from "@material-ui/icons/Subject";
import ShortText from "@material-ui/icons/ShortText";
import Subway from "@material-ui/icons/Subway";
import TextFields from "@material-ui/icons/TextFields";
import Terrain from "@material-ui/icons/Terrain";
import ViewAgenda from "@material-ui/icons/ViewAgenda";
import ViewHeadline from "@material-ui/icons/ViewHeadline";
import ViewWeek from "@material-ui/icons/ViewWeek";
import Work from "@material-ui/icons/Work";
import Warning from "@material-ui/icons/Warning";
import WbSunny from "@material-ui/icons/WbSunny";
import StoreFront from "@material-ui/icons/Storefront";

export function MAKE_OPTIONS_ICON(table) {
    return table.map(row => ({
        label: row.component,
        value: row.id
    }));
}

export const ICON_LIST = [
    { id: "Nature", component: <Nature title="nature" /> },
    { id: "NaturePeople", component: <NaturePeople /> },
    { id: "AccessibilityNew", component: <AccessibilityNew /> },
    { id: "Alarm", component: <Alarm /> },
    // { id: "Apartment", component: <ApartmentIcon /> },
    { id: "Assignment", component: <Assignment /> },
    { id: "Build", component: <Build /> },
    { id: "CalendarToday", component: <CalendarToday /> },
    { id: "EcoIcon", component: <EcoIcon /> },
    { id: "Description", component: <Description /> },
    { id: "Home", component: <Home /> },
    { id: "QueryBuilder", component: <QueryBuilder /> },
    { id: "Reorder", component: <Reorder /> },
    { id: "Room", component: <Room /> },
    { id: "Store", component: <Store /> },
    { id: "Subject", component: <Subject /> },
    { id: "ViewAgenda", component: <ViewAgenda /> },
    { id: "ViewHeadline", component: <ViewHeadline /> },
    { id: "ViewWeek", component: <ViewWeek /> },
    { id: "Work", component: <Work /> },
    { id: "Warning", component: <Warning /> },
    { id: "Equalizer", component: <Equalizer /> },
    { id: "Call", component: <Call /> },
    { id: "Contacts", component: <Contacts /> },
    { id: "Email", component: <Email /> },
    { id: "InsertPhoto", component: <InsertPhoto /> },
    { id: "ShortText", component: <ShortText /> },
    { id: "TextFields", component: <TextFields /> },
    { id: "AddAPhoto", component: <AddAPhoto /> },
    { id: "Fastfood", component: <Fastfood /> },
    { id: "Layers", component: <Layers /> },
    { id: "Museum", component: <Museum /> },
    { id: "Subway", component: <Subway /> },
    { id: "HomeWork", component: <HomeWork /> },
    { id: "DriveEta", component: <DriveEta /> },
    { id: "House", component: <House /> },
    { id: "StoreFront", component: <StoreFront /> },
    { id: "Grain", component: <Grain /> },
    { id: "Terrain", component: <Terrain /> },
    { id: "WbSunny", component: <WbSunny /> },
    { id: "Blank" }
];

// import AccessAlarm from "@material-ui/icons/AccessAlarm";
// //import AccessAlarms from "@material-ui/icons/AccessAlarms";
// import AccessibilityNew from "@material-ui/icons/AccessibilityNew";
// import Accessible from "@material-ui/icons/Accessible";
// import AccessTime from "@material-ui/icons/AccessTime";
// import AccountBalance from "@material-ui/icons/AccountBalance";
// import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
// import AccountBox from "@material-ui/icons/AccountBox";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import AcUnit from "@material-ui/icons/AcUnit";
// import Adb from "@material-ui/icons/Adb";
// import Add from "@material-ui/icons/Add";
// import AddAlarm from "@material-ui/icons/AddAlarm";
// import AddAlert from "@material-ui/icons/AddAlert";
// import AddAPhoto from "@material-ui/icons/AddAPhoto";
// import AddBox from "@material-ui/icons/AddBox";
// import AddCircle from "@material-ui/icons/AddCircle";
// import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
// import AddLocation from "@material-ui/icons/AddLocation";
// import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
// import AddToPhotos from "@material-ui/icons/AddToPhotos";
// import AddToQueue from "@material-ui/icons/AddToQueue";
// import Adjust from "@material-ui/icons/Adjust";
// import AirlineSeatFlat from "@material-ui/icons/AirlineSeatFlat";
// import AirlineSeatFlatAngled from "@material-ui/icons/AirlineSeatFlatAngled";
// import AirlineSeatIndividualSuite from "@material-ui/icons/AirlineSeatIndividualSuite";
// import AirlineSeatLegroomExtra from "@material-ui/icons/AirlineSeatLegroomExtra";
// import AirlineSeatLegroomNormal from "@material-ui/icons/AirlineSeatLegroomNormal";
// import AirlineSeatLegroomReduced from "@material-ui/icons/AirlineSeatLegroomReduced";
// import AirlineSeatReclineExtra from "@material-ui/icons/AirlineSeatReclineExtra";
// import AirlineSeatReclineNormal from "@material-ui/icons/AirlineSeatReclineNormal";
// import AirplanemodeActive from "@material-ui/icons/AirplanemodeActive";
// import AirplanemodeInactive from "@material-ui/icons/AirplanemodeInactive";
// import Airplay from "@material-ui/icons/Airplay";
// import AirportShuttle from "@material-ui/icons/AirportShuttle";
// import Alarm from "@material-ui/icons/Alarm";
// import AlarmAdd from "@material-ui/icons/AlarmAdd";
// import AlarmOff from "@material-ui/icons/AlarmOff";
// import AlarmOn from "@material-ui/icons/AlarmOn";
// import Album from "@material-ui/icons/Album";
// import AllInclusive from "@material-ui/icons/AllInclusive";
// import AllOut from "@material-ui/icons/AllOut";
// import Android from "@material-ui/icons/Android";
// import Announcement from "@material-ui/icons/Announcement";
// import Apps from "@material-ui/icons/Apps";
// import Archive from "@material-ui/icons/Archive";
// import ArrowBack from "@material-ui/icons/ArrowBack";
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
// import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
// import ArrowDropDownCircle from "@material-ui/icons/ArrowDropDownCircle";
// import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
// import ArrowForward from "@material-ui/icons/ArrowForward";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import ArtTrack from "@material-ui/icons/ArtTrack";
// import AspectRatio from "@material-ui/icons/AspectRatio";
// import Assessment from "@material-ui/icons/Assessment";
// import Assignment from "@material-ui/icons/Assignment";
// import AssignmentInd from "@material-ui/icons/AssignmentInd";
// import AssignmentLate from "@material-ui/icons/AssignmentLate";
// import AssignmentReturn from "@material-ui/icons/AssignmentReturn";
// import AssignmentReturned from "@material-ui/icons/AssignmentReturned";
// import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
// import Assistant from "@material-ui/icons/Assistant";
// import AssistantPhoto from "@material-ui/icons/AssistantPhoto";
// import AttachFile from "@material-ui/icons/AttachFile";
// import Attachment from "@material-ui/icons/Attachment";
// import AttachMoney from "@material-ui/icons/AttachMoney";
// import Audiotrack from "@material-ui/icons/Audiotrack";
// import Autorenew from "@material-ui/icons/Autorenew";
// import AvTimer from "@material-ui/icons/AvTimer";
// import Backspace from "@material-ui/icons/Backspace";
// import Backup from "@material-ui/icons/Backup";
// import Battery20 from "@material-ui/icons/Battery20";
// import Battery30 from "@material-ui/icons/Battery30";
// import Battery50 from "@material-ui/icons/Battery50";
// import Battery60 from "@material-ui/icons/Battery60";
// import Battery80 from "@material-ui/icons/Battery80";
// import Battery90 from "@material-ui/icons/Battery90";
// import BatteryAlert from "@material-ui/icons/BatteryAlert";
// import BatteryCharging20 from "@material-ui/icons/BatteryCharging20";
// import BatteryCharging30 from "@material-ui/icons/BatteryCharging30";
// import BatteryCharging50 from "@material-ui/icons/BatteryCharging50";
// import BatteryCharging60 from "@material-ui/icons/BatteryCharging60";
// import BatteryCharging80 from "@material-ui/icons/BatteryCharging80";
// import BatteryCharging90 from "@material-ui/icons/BatteryCharging90";
// import BatteryChargingFull from "@material-ui/icons/BatteryChargingFull";
// import BatteryFull from "@material-ui/icons/BatteryFull";
// import BatteryStd from "@material-ui/icons/BatteryStd";
// import BatteryUnknown from "@material-ui/icons/BatteryUnknown";
// import BeachAccess from "@material-ui/icons/BeachAccess";
// import Beenhere from "@material-ui/icons/Beenhere";
// import Block from "@material-ui/icons/Block";
// import Bluetooth from "@material-ui/icons/Bluetooth";
// import BluetoothAudio from "@material-ui/icons/BluetoothAudio";
// import BluetoothConnected from "@material-ui/icons/BluetoothConnected";
// import BluetoothDisabled from "@material-ui/icons/BluetoothDisabled";
// import BluetoothSearching from "@material-ui/icons/BluetoothSearching";
// import BlurCircular from "@material-ui/icons/BlurCircular";
// import BlurLinear from "@material-ui/icons/BlurLinear";
// import BlurOff from "@material-ui/icons/BlurOff";
// import BlurOn from "@material-ui/icons/BlurOn";
// import Book from "@material-ui/icons/Book";
// import Bookmark from "@material-ui/icons/Bookmark";
// import BookmarkBorder from "@material-ui/icons/BookmarkBorder";
// import BorderAll from "@material-ui/icons/BorderAll";
// import BorderBottom from "@material-ui/icons/BorderBottom";
// import BorderClear from "@material-ui/icons/BorderClear";
// import BorderColor from "@material-ui/icons/BorderColor";
// import BorderHorizontal from "@material-ui/icons/BorderHorizontal";
// import BorderInner from "@material-ui/icons/BorderInner";
// import BorderLeft from "@material-ui/icons/BorderLeft";
// import BorderOuter from "@material-ui/icons/BorderOuter";
// import BorderRight from "@material-ui/icons/BorderRight";
// import BorderStyle from "@material-ui/icons/BorderStyle";
// import BorderTop from "@material-ui/icons/BorderTop";
// import BorderVertical from "@material-ui/icons/BorderVertical";
// import BrandingWatermark from "@material-ui/icons/BrandingWatermark";
// import Brightness1 from "@material-ui/icons/Brightness1";
// import Brightness2 from "@material-ui/icons/Brightness2";
// import Brightness3 from "@material-ui/icons/Brightness3";
// import Brightness4 from "@material-ui/icons/Brightness4";
// import Brightness5 from "@material-ui/icons/Brightness5";
// import Brightness6 from "@material-ui/icons/Brightness6";
// import Brightness7 from "@material-ui/icons/Brightness7";
// import BrightnessAuto from "@material-ui/icons/BrightnessAuto";
// import BrightnessHigh from "@material-ui/icons/BrightnessHigh";
// import BrightnessLow from "@material-ui/icons/BrightnessLow";
// import BrightnessMedium from "@material-ui/icons/BrightnessMedium";
// import BrokenImage from "@material-ui/icons/BrokenImage";
// import Brush from "@material-ui/icons/Brush";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import BugReport from "@material-ui/icons/BugReport";
// import Build from "@material-ui/icons/Build";
// import BurstMode from "@material-ui/icons/BurstMode";
// import Business from "@material-ui/icons/Business";
// import BusinessCenter from "@material-ui/icons/BusinessCenter";
// import Cached from "@material-ui/icons/Cached";
// import Cake from "@material-ui/icons/Cake";
// import Call from "@material-ui/icons/Call";
// import CallEnd from "@material-ui/icons/CallEnd";
// import CallMade from "@material-ui/icons/CallMade";
// import CallMerge from "@material-ui/icons/CallMerge";
// import CallMissed from "@material-ui/icons/CallMissed";
// import CallMissedOutgoing from "@material-ui/icons/CallMissedOutgoing";
// import CallReceived from "@material-ui/icons/CallReceived";
// import CallSplit from "@material-ui/icons/CallSplit";
// import CallToAction from "@material-ui/icons/CallToAction";
// import Camera from "@material-ui/icons/Camera";
// import CameraAlt from "@material-ui/icons/CameraAlt";
// import CameraEnhance from "@material-ui/icons/CameraEnhance";
// import CameraFront from "@material-ui/icons/CameraFront";
// import CameraRear from "@material-ui/icons/CameraRear";
// import CameraRoll from "@material-ui/icons/CameraRoll";
// import Cancel from "@material-ui/icons/Cancel";
// import CardGiftcard from "@material-ui/icons/CardGiftcard";
// import CardMembership from "@material-ui/icons/CardMembership";
// import CardTravel from "@material-ui/icons/CardTravel";
// import Casino from "@material-ui/icons/Casino";
// import Cast from "@material-ui/icons/Cast";
// import CastConnected from "@material-ui/icons/CastConnected";
// import CenterFocusStrong from "@material-ui/icons/CenterFocusStrong";
// import CenterFocusWeak from "@material-ui/icons/CenterFocusWeak";
// import ChangeHistory from "@material-ui/icons/ChangeHistory";
// import Chat from "@material-ui/icons/Chat";
// import ChatBubble from "@material-ui/icons/ChatBubble";
// import ChatBubbleOutline from "@material-ui/icons/ChatBubbleOutline";
// import Check from "@material-ui/icons/Check";
// import CheckBox from "@material-ui/icons/CheckBox";
// import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
// import CheckCircle from "@material-ui/icons/CheckCircle";
// import ChevronLeft from "@material-ui/icons/ChevronLeft";
// import ChevronRight from "@material-ui/icons/ChevronRight";
// import ChildCare from "@material-ui/icons/ChildCare";
// import ChildFriendly from "@material-ui/icons/ChildFriendly";
// import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode";
// import Class from "@material-ui/icons/Class";
// import Clear from "@material-ui/icons/Clear";
// import ClearAll from "@material-ui/icons/ClearAll";
// import Close from "@material-ui/icons/Close";
// import ClosedCaption from "@material-ui/icons/ClosedCaption";
// import Cloud from "@material-ui/icons/Cloud";
// import CloudCircle from "@material-ui/icons/CloudCircle";
// import CloudDone from "@material-ui/icons/CloudDone";
// import CloudDownload from "@material-ui/icons/CloudDownload";
// import CloudOff from "@material-ui/icons/CloudOff";
// import CloudQueue from "@material-ui/icons/CloudQueue";
// import CloudUpload from "@material-ui/icons/CloudUpload";
// import Code from "@material-ui/icons/Code";
// import Collections from "@material-ui/icons/Collections";
// import CollectionsBookmark from "@material-ui/icons/CollectionsBookmark";
// import Colorize from "@material-ui/icons/Colorize";
// import ColorLens from "@material-ui/icons/ColorLens";
// import Comment from "@material-ui/icons/Comment";
// import Compare from "@material-ui/icons/Compare";
// import CompareArrows from "@material-ui/icons/CompareArrows";
// import Computer from "@material-ui/icons/Computer";
// import ConfirmationNumber from "@material-ui/icons/ConfirmationNumber";
// import ContactMail from "@material-ui/icons/ContactMail";
// import ContactPhone from "@material-ui/icons/ContactPhone";
// import Contacts from "@material-ui/icons/Contacts";
//import ContentCopy from "@material-ui/icons/ContentCopy";
/*import ContentCut from "@material-ui/icons/ContentCut";
import ContentPaste from "@material-ui/icons/ContentPaste";
import ControlPoint from "@material-ui/icons/ControlPoint";
import ControlPointDuplicate from "@material-ui/icons/ControlPointDuplicate";
import Copyright from "@material-ui/icons/Copyright";
import Create from "@material-ui/icons/Create";
import CreateNewFolder from "@material-ui/icons/CreateNewFolder";
import CreditCard from "@material-ui/icons/CreditCard";
import Crop from "@material-ui/icons/Crop";
import Crop169 from "@material-ui/icons/Crop169";
import Crop32 from "@material-ui/icons/Crop32";
import Crop54 from "@material-ui/icons/Crop54";
import Crop75 from "@material-ui/icons/Crop75";
import CropDin from "@material-ui/icons/CropDin";
import CropFree from "@material-ui/icons/CropFree";
import CropLandscape from "@material-ui/icons/CropLandscape";
import CropOriginal from "@material-ui/icons/CropOriginal";
import CropPortrait from "@material-ui/icons/CropPortrait";
import CropRotate from "@material-ui/icons/CropRotate";
import CropSquare from "@material-ui/icons/CropSquare";
import Dashboard from "@material-ui/icons/Dashboard";
import DataUsage from "@material-ui/icons/DataUsage";
import DateRange from "@material-ui/icons/DateRange";
import Dehaze from "@material-ui/icons/Dehaze";
import Delete from "@material-ui/icons/Delete";
import DeleteForever from "@material-ui/icons/DeleteForever";
import DeleteSweep from "@material-ui/icons/DeleteSweep";
import Description from "@material-ui/icons/Description";
import DesktopMac from "@material-ui/icons/DesktopMac";
import DesktopWindows from "@material-ui/icons/DesktopWindows";
import Details from "@material-ui/icons/Details";
import DeveloperBoard from "@material-ui/icons/DeveloperBoard";
import DeveloperMode from "@material-ui/icons/DeveloperMode";
import DeviceHub from "@material-ui/icons/DeviceHub";
import Devices from "@material-ui/icons/Devices";
import DevicesOther from "@material-ui/icons/DevicesOther";
import DialerSip from "@material-ui/icons/DialerSip";
import Dialpad from "@material-ui/icons/Dialpad";
import Directions from "@material-ui/icons/Directions";
import DirectionsBike from "@material-ui/icons/DirectionsBike";
import DirectionsBoat from "@material-ui/icons/DirectionsBoat";
import DirectionsBus from "@material-ui/icons/DirectionsBus";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import DirectionsRailway from "@material-ui/icons/DirectionsRailway";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import DirectionsSubway from "@material-ui/icons/DirectionsSubway";
import DirectionsTransit from "@material-ui/icons/DirectionsTransit";
import DirectionsWalk from "@material-ui/icons/DirectionsWalk";
import DiscFull from "@material-ui/icons/DiscFull";
import Dns from "@material-ui/icons/Dns";
import Dock from "@material-ui/icons/Dock";
import Domain from "@material-ui/icons/Domain";
import Done from "@material-ui/icons/Done";
import DoneAll from "@material-ui/icons/DoneAll";
import DoNotDisturb from "@material-ui/icons/DoNotDisturb";
import DoNotDisturbAlt from "@material-ui/icons/DoNotDisturbAlt";
import DoNotDisturbOff from "@material-ui/icons/DoNotDisturbOff";
import DoNotDisturbOn from "@material-ui/icons/DoNotDisturbOn";
import DonutLarge from "@material-ui/icons/DonutLarge";
import DonutSmall from "@material-ui/icons/DonutSmall";
import Drafts from "@material-ui/icons/Drafts";
import DragHandle from "@material-ui/icons/DragHandle";
import DriveEta from "@material-ui/icons/DriveEta";
import Dvr from "@material-ui/icons/Dvr";
import Edit from "@material-ui/icons/Edit";
import EditLocation from "@material-ui/icons/EditLocation";
import Eject from "@material-ui/icons/Eject";
import Email from "@material-ui/icons/Email";
import EnhancedEncryption from "@material-ui/icons/EnhancedEncryption";
import Equalizer from "@material-ui/icons/Equalizer";
import Error from "@material-ui/icons/Error";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import Event from "@material-ui/icons/Event";
import EventAvailable from "@material-ui/icons/EventAvailable";
import EventBusy from "@material-ui/icons/EventBusy";
import EventNote from "@material-ui/icons/EventNote";
import EventSeat from "@material-ui/icons/EventSeat";
import EvStation from "@material-ui/icons/EvStation";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Explicit from "@material-ui/icons/Explicit";
import Explore from "@material-ui/icons/Explore";
import Exposure from "@material-ui/icons/Exposure";
import ExposureNeg1 from "@material-ui/icons/ExposureNeg1";
import ExposureNeg2 from "@material-ui/icons/ExposureNeg2";
import ExposurePlus1 from "@material-ui/icons/ExposurePlus1";
import ExposurePlus2 from "@material-ui/icons/ExposurePlus2";
import ExposureZero from "@material-ui/icons/ExposureZero";
import Extension from "@material-ui/icons/Extension";
import Face from "@material-ui/icons/Face";
import FastForward from "@material-ui/icons/FastForward";
import FastRewind from "@material-ui/icons/FastRewind";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import FeaturedPlayList from "@material-ui/icons/FeaturedPlayList";
import FeaturedVideo from "@material-ui/icons/FeaturedVideo";
import Feedback from "@material-ui/icons/Feedback";
import FiberDvr from "@material-ui/icons/FiberDvr";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FiberNew from "@material-ui/icons/FiberNew";
import FiberPin from "@material-ui/icons/FiberPin";
import FiberSmartRecord from "@material-ui/icons/FiberSmartRecord";
import FileDownload from "@material-ui/icons/FileDownload";
import FileUpload from "@material-ui/icons/FileUpload";
import Filter from "@material-ui/icons/Filter";
import Filter1 from "@material-ui/icons/Filter1";
import Filter2 from "@material-ui/icons/Filter2";
import Filter3 from "@material-ui/icons/Filter3";
import Filter4 from "@material-ui/icons/Filter4";
import Filter5 from "@material-ui/icons/Filter5";
import Filter6 from "@material-ui/icons/Filter6";
import Filter7 from "@material-ui/icons/Filter7";
import Filter8 from "@material-ui/icons/Filter8";
import Filter9 from "@material-ui/icons/Filter9";
import Filter9Plus from "@material-ui/icons/Filter9Plus";
import FilterBAndW from "@material-ui/icons/FilterBAndW";
import FilterCenterFocus from "@material-ui/icons/FilterCenterFocus";
import FilterDrama from "@material-ui/icons/FilterDrama";
import FilterFrames from "@material-ui/icons/FilterFrames";
import FilterHdr from "@material-ui/icons/FilterHdr";
import FilterList from "@material-ui/icons/FilterList";
import FilterNone from "@material-ui/icons/FilterNone";
import FilterTiltShift from "@material-ui/icons/FilterTiltShift";
import FilterVintage from "@material-ui/icons/FilterVintage";
import FindInPage from "@material-ui/icons/FindInPage";
import FindReplace from "@material-ui/icons/FindReplace";
import Fingerprint from "@material-ui/icons/Fingerprint";
import FirstPage from "@material-ui/icons/FirstPage";
import FitnessCenter from "@material-ui/icons/FitnessCenter";
import Flag from "@material-ui/icons/Flag";
import Flare from "@material-ui/icons/Flare";
import FlashAuto from "@material-ui/icons/FlashAuto";
import FlashOff from "@material-ui/icons/FlashOff";
import FlashOn from "@material-ui/icons/FlashOn";
import Flight from "@material-ui/icons/Flight";
import FlightLand from "@material-ui/icons/FlightLand";
import FlightTakeoff from "@material-ui/icons/FlightTakeoff";
import Flip from "@material-ui/icons/Flip";
import FlipToBack from "@material-ui/icons/FlipToBack";
import FlipToFront from "@material-ui/icons/FlipToFront";
import Folder from "@material-ui/icons/Folder";
import FolderOpen from "@material-ui/icons/FolderOpen";
import FolderShared from "@material-ui/icons/FolderShared";
import FolderSpecial from "@material-ui/icons/FolderSpecial";
import FontDownload from "@material-ui/icons/FontDownload";
import FormatAlignCenter from "@material-ui/icons/FormatAlignCenter";
import FormatAlignJustify from "@material-ui/icons/FormatAlignJustify";
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRight from "@material-ui/icons/FormatAlignRight";
import FormatBold from "@material-ui/icons/FormatBold";
import FormatClear from "@material-ui/icons/FormatClear";
import FormatColorFill from "@material-ui/icons/FormatColorFill";
import FormatColorReset from "@material-ui/icons/FormatColorReset";
import FormatColorText from "@material-ui/icons/FormatColorText";
import FormatIndentDecrease from "@material-ui/icons/FormatIndentDecrease";
import FormatIndentIncrease from "@material-ui/icons/FormatIndentIncrease";
import FormatItalic from "@material-ui/icons/FormatItalic";
import FormatLineSpacing from "@material-ui/icons/FormatLineSpacing";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import FormatPaint from "@material-ui/icons/FormatPaint";
import FormatQuote from "@material-ui/icons/FormatQuote";
import FormatShapes from "@material-ui/icons/FormatShapes";
import FormatSize from "@material-ui/icons/FormatSize";
import FormatStrikethrough from "@material-ui/icons/FormatStrikethrough";
import FormatTextdirectionLToR from "@material-ui/icons/FormatTextdirectionLToR";
import FormatTextdirectionRToL from "@material-ui/icons/FormatTextdirectionRToL";
import FormatUnderlined from "@material-ui/icons/FormatUnderlined";
import Forum from "@material-ui/icons/Forum";
import Forward from "@material-ui/icons/Forward";
import Forward10 from "@material-ui/icons/Forward10";
import Forward30 from "@material-ui/icons/Forward30";
import Forward5 from "@material-ui/icons/Forward5";
import FreeBreakfast from "@material-ui/icons/FreeBreakfast";
import Fullscreen from "@material-ui/icons/Fullscreen";
import FullscreenExit from "@material-ui/icons/FullscreenExit";
import Functions from "@material-ui/icons/Functions";
import Gamepad from "@material-ui/icons/Gamepad";
import Games from "@material-ui/icons/Games";
import Gavel from "@material-ui/icons/Gavel";
import Gesture from "@material-ui/icons/Gesture";
import GetApp from "@material-ui/icons/GetApp";
import Gif from "@material-ui/icons/Gif";
import GolfCourse from "@material-ui/icons/GolfCourse";
import GpsFixed from "@material-ui/icons/GpsFixed";
import GpsNotFixed from "@material-ui/icons/GpsNotFixed";
import GpsOff from "@material-ui/icons/GpsOff";
import Grade from "@material-ui/icons/Grade";
import Gradient from "@material-ui/icons/Gradient";
import Grain from "@material-ui/icons/Grain";
import GraphicEq from "@material-ui/icons/GraphicEq";
import GridOff from "@material-ui/icons/GridOff";
import GridOn from "@material-ui/icons/GridOn";
import Group from "@material-ui/icons/Group";
import GroupAdd from "@material-ui/icons/GroupAdd";
import GroupWork from "@material-ui/icons/GroupWork";
import GTranslate from "@material-ui/icons/GTranslate";
import Hd from "@material-ui/icons/Hd";
import HdrOff from "@material-ui/icons/HdrOff";
import HdrOn from "@material-ui/icons/HdrOn";
import HdrStrong from "@material-ui/icons/HdrStrong";
import HdrWeak from "@material-ui/icons/HdrWeak";
import Headset from "@material-ui/icons/Headset";
import HeadsetMic from "@material-ui/icons/HeadsetMic";
import Healing from "@material-ui/icons/Healing";
import Hearing from "@material-ui/icons/Hearing";
import Help from "@material-ui/icons/Help";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Highlight from "@material-ui/icons/Highlight";
import HighlightOff from "@material-ui/icons/HighlightOff";
import HighQuality from "@material-ui/icons/HighQuality";
import History from "@material-ui/icons/History";
import Home from "@material-ui/icons/Home";
import Hotel from "@material-ui/icons/Hotel";
import HotTub from "@material-ui/icons/HotTub";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import HourglassFull from "@material-ui/icons/HourglassFull";
import Http from "@material-ui/icons/Http";
import Https from "@material-ui/icons/Https";
import Image from "@material-ui/icons/Image";
import ImageAspectRatio from "@material-ui/icons/ImageAspectRatio";
import ImportantDevices from "@material-ui/icons/ImportantDevices";
import ImportContacts from "@material-ui/icons/ImportContacts";
import ImportExport from "@material-ui/icons/ImportExport";
import Inbox from "@material-ui/icons/Inbox";
import IndeterminateCheckBox from "@material-ui/icons/IndeterminateCheckBox";
import index from "@material-ui/icons/index";
import Info from "@material-ui/icons/Info";
import InfoOutline from "@material-ui/icons/InfoOutline";
import Input from "@material-ui/icons/Input";
import InsertChart from "@material-ui/icons/InsertChart";
import InsertComment from "@material-ui/icons/InsertComment";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import InsertEmoticon from "@material-ui/icons/InsertEmoticon";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import InsertLink from "@material-ui/icons/InsertLink";
import InsertPhoto from "@material-ui/icons/InsertPhoto";
import InvertColors from "@material-ui/icons/InvertColors";
import InvertColorsOff from "@material-ui/icons/InvertColorsOff";
import Iso from "@material-ui/icons/Iso";
import Keyboard from "@material-ui/icons/Keyboard";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import KeyboardCapslock from "@material-ui/icons/KeyboardCapslock";
import KeyboardHide from "@material-ui/icons/KeyboardHide";
import KeyboardReturn from "@material-ui/icons/KeyboardReturn";
import KeyboardTab from "@material-ui/icons/KeyboardTab";
import KeyboardVoice from "@material-ui/icons/KeyboardVoice";
import Kitchen from "@material-ui/icons/Kitchen";
import Label from "@material-ui/icons/Label";
import LabelOutline from "@material-ui/icons/LabelOutline";
import Landscape from "@material-ui/icons/Landscape";
import Language from "@material-ui/icons/Language";
import Laptop from "@material-ui/icons/Laptop";
import LaptopChromebook from "@material-ui/icons/LaptopChromebook";
import LaptopMac from "@material-ui/icons/LaptopMac";
import LaptopWindows from "@material-ui/icons/LaptopWindows";
import LastPage from "@material-ui/icons/LastPage";
import Launch from "@material-ui/icons/Launch";
import Layers from "@material-ui/icons/Layers";
import LayersClear from "@material-ui/icons/LayersClear";
import LeakAdd from "@material-ui/icons/LeakAdd";
import LeakRemove from "@material-ui/icons/LeakRemove";
import Lens from "@material-ui/icons/Lens";
import LibraryAdd from "@material-ui/icons/LibraryAdd";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import LibraryMusic from "@material-ui/icons/LibraryMusic";
import LightbulbOutline from "@material-ui/icons/LightbulbOutline";
import LinearScale from "@material-ui/icons/LinearScale";
import LineStyle from "@material-ui/icons/LineStyle";
import LineWeight from "@material-ui/icons/LineWeight";
import Link from "@material-ui/icons/Link";
import LinkedCamera from "@material-ui/icons/LinkedCamera";
import List from "@material-ui/icons/List";
import LiveHelp from "@material-ui/icons/LiveHelp";
import LiveTv from "@material-ui/icons/LiveTv";
import LocalActivity from "@material-ui/icons/LocalActivity";
import LocalAirport from "@material-ui/icons/LocalAirport";
import LocalAtm from "@material-ui/icons/LocalAtm";
import LocalBar from "@material-ui/icons/LocalBar";
import LocalCafe from "@material-ui/icons/LocalCafe";
import LocalCarWash from "@material-ui/icons/LocalCarWash";
import LocalConvenienceStore from "@material-ui/icons/LocalConvenienceStore";
import LocalDining from "@material-ui/icons/LocalDining";
import LocalDrink from "@material-ui/icons/LocalDrink";
import LocalFlorist from "@material-ui/icons/LocalFlorist";
import LocalGasStation from "@material-ui/icons/LocalGasStation";
import LocalGroceryStore from "@material-ui/icons/LocalGroceryStore";
import LocalHospital from "@material-ui/icons/LocalHospital";
import LocalHotel from "@material-ui/icons/LocalHotel";
import LocalLaundryService from "@material-ui/icons/LocalLaundryService";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import LocalMall from "@material-ui/icons/LocalMall";
import LocalMovies from "@material-ui/icons/LocalMovies";
import LocalOffer from "@material-ui/icons/LocalOffer";
import LocalParking from "@material-ui/icons/LocalParking";
import LocalPharmacy from "@material-ui/icons/LocalPharmacy";
import LocalPhone from "@material-ui/icons/LocalPhone";
import LocalPizza from "@material-ui/icons/LocalPizza";
import LocalPlay from "@material-ui/icons/LocalPlay";
import LocalPostOffice from "@material-ui/icons/LocalPostOffice";
import LocalPrintshop from "@material-ui/icons/LocalPrintshop";
import LocalSee from "@material-ui/icons/LocalSee";
import LocalShipping from "@material-ui/icons/LocalShipping";
import LocalTaxi from "@material-ui/icons/LocalTaxi";
import LocationCity from "@material-ui/icons/LocationCity";
import LocationDisabled from "@material-ui/icons/LocationDisabled";
import LocationOff from "@material-ui/icons/LocationOff";
import LocationOn from "@material-ui/icons/LocationOn";
import LocationSearching from "@material-ui/icons/LocationSearching";
import Lock from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import LockOutline from "@material-ui/icons/LockOutline";
import Looks from "@material-ui/icons/Looks";
import Looks3 from "@material-ui/icons/Looks3";
import Looks4 from "@material-ui/icons/Looks4";
import Looks5 from "@material-ui/icons/Looks5";
import Looks6 from "@material-ui/icons/Looks6";
import LooksOne from "@material-ui/icons/LooksOne";
import LooksTwo from "@material-ui/icons/LooksTwo";
import Loop from "@material-ui/icons/Loop";
import Loupe from "@material-ui/icons/Loupe";
import LowPriority from "@material-ui/icons/LowPriority";
import Loyalty from "@material-ui/icons/Loyalty";
import Mail from "@material-ui/icons/Mail";
import MailOutline from "@material-ui/icons/MailOutline";
import Map from "@material-ui/icons/Map";
import Markunread from "@material-ui/icons/Markunread";
import MarkunreadMailbox from "@material-ui/icons/MarkunreadMailbox";
import Memory from "@material-ui/icons/Memory";
import Menu from "@material-ui/icons/Menu";
import MergeType from "@material-ui/icons/MergeType";
import Message from "@material-ui/icons/Message";
import Mic from "@material-ui/icons/Mic";
import MicNone from "@material-ui/icons/MicNone";
import MicOff from "@material-ui/icons/MicOff";
import Mms from "@material-ui/icons/Mms";
import ModeComment from "@material-ui/icons/ModeComment";
import ModeEdit from "@material-ui/icons/ModeEdit";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import MoneyOff from "@material-ui/icons/MoneyOff";
import MonochromePhotos from "@material-ui/icons/MonochromePhotos";
import Mood from "@material-ui/icons/Mood";
import MoodBad from "@material-ui/icons/MoodBad";
import More from "@material-ui/icons/More";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import MoreVert from "@material-ui/icons/MoreVert";
import Motorcycle from "@material-ui/icons/Motorcycle";
import Mouse from "@material-ui/icons/Mouse";
import MoveToInbox from "@material-ui/icons/MoveToInbox";
import Movie from "@material-ui/icons/Movie";
import MovieCreation from "@material-ui/icons/MovieCreation";
import MovieFilter from "@material-ui/icons/MovieFilter";
import MultilineChart from "@material-ui/icons/MultilineChart";
import MusicNote from "@material-ui/icons/MusicNote";
import MusicVideo from "@material-ui/icons/MusicVideo";
import MyLocation from "@material-ui/icons/MyLocation";
import Nature from "@material-ui/icons/Nature";
import NaturePeople from "@material-ui/icons/NaturePeople";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import Navigation from "@material-ui/icons/Navigation";
import NearMe from "@material-ui/icons/NearMe";
import NetworkCell from "@material-ui/icons/NetworkCell";
import NetworkCheck from "@material-ui/icons/NetworkCheck";
import NetworkLocked from "@material-ui/icons/NetworkLocked";
import NetworkWifi from "@material-ui/icons/NetworkWifi";
import NewReleases from "@material-ui/icons/NewReleases";
import NextWeek from "@material-ui/icons/NextWeek";
import Nfc from "@material-ui/icons/Nfc";
import NoEncryption from "@material-ui/icons/NoEncryption";
import NoSim from "@material-ui/icons/NoSim";
import Note from "@material-ui/icons/Note";
import NoteAdd from "@material-ui/icons/NoteAdd";
import Notifications from "@material-ui/icons/Notifications";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import NotificationsNone from "@material-ui/icons/NotificationsNone";
import NotificationsOff from "@material-ui/icons/NotificationsOff";
import NotificationsPaused from "@material-ui/icons/NotificationsPaused";
import NotInterested from "@material-ui/icons/NotInterested";
import OfflinePin from "@material-ui/icons/OfflinePin";
import OndemandVideo from "@material-ui/icons/OndemandVideo";
import Opacity from "@material-ui/icons/Opacity";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import OpenInNew from "@material-ui/icons/OpenInNew";
import OpenWith from "@material-ui/icons/OpenWith";
import Pages from "@material-ui/icons/Pages";
import Pageview from "@material-ui/icons/Pageview";
import Palette from "@material-ui/icons/Palette";
import Panorama from "@material-ui/icons/Panorama";
import PanoramaFishEye from "@material-ui/icons/PanoramaFishEye";
import PanoramaHorizontal from "@material-ui/icons/PanoramaHorizontal";
import PanoramaVertical from "@material-ui/icons/PanoramaVertical";
import PanoramaWideAngle from "@material-ui/icons/PanoramaWideAngle";
import PanTool from "@material-ui/icons/PanTool";
import PartyMode from "@material-ui/icons/PartyMode";
import Pause from "@material-ui/icons/Pause";
import PauseCircleFilled from "@material-ui/icons/PauseCircleFilled";
import PauseCircleOutline from "@material-ui/icons/PauseCircleOutline";
import Payment from "@material-ui/icons/Payment";
import People from "@material-ui/icons/People";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import PermCameraMic from "@material-ui/icons/PermCameraMic";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import PermDataSetting from "@material-ui/icons/PermDataSetting";
import PermDeviceInformation from "@material-ui/icons/PermDeviceInformation";
import PermIdentity from "@material-ui/icons/PermIdentity";
import PermMedia from "@material-ui/icons/PermMedia";
import PermPhoneMsg from "@material-ui/icons/PermPhoneMsg";
import PermScanWifi from "@material-ui/icons/PermScanWifi";
import Person from "@material-ui/icons/Person";
import PersonAdd from "@material-ui/icons/PersonAdd";
import PersonalVideo from "@material-ui/icons/PersonalVideo";
import PersonOutline from "@material-ui/icons/PersonOutline";
import PersonPin from "@material-ui/icons/PersonPin";
import PersonPinCircle from "@material-ui/icons/PersonPinCircle";
import Pets from "@material-ui/icons/Pets";
import Phone from "@material-ui/icons/Phone";
import PhoneAndroid from "@material-ui/icons/PhoneAndroid";
import PhoneBluetoothSpeaker from "@material-ui/icons/PhoneBluetoothSpeaker";
import PhoneForwarded from "@material-ui/icons/PhoneForwarded";
import PhoneInTalk from "@material-ui/icons/PhoneInTalk";
import PhoneIphone from "@material-ui/icons/PhoneIphone";
import Phonelink from "@material-ui/icons/Phonelink";
import PhonelinkErase from "@material-ui/icons/PhonelinkErase";
import PhonelinkLock from "@material-ui/icons/PhonelinkLock";
import PhonelinkOff from "@material-ui/icons/PhonelinkOff";
import PhonelinkRing from "@material-ui/icons/PhonelinkRing";
import PhonelinkSetup from "@material-ui/icons/PhonelinkSetup";
import PhoneLocked from "@material-ui/icons/PhoneLocked";
import PhoneMissed from "@material-ui/icons/PhoneMissed";
import PhonePaused from "@material-ui/icons/PhonePaused";
import Photo from "@material-ui/icons/Photo";
import PhotoAlbum from "@material-ui/icons/PhotoAlbum";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import PhotoFilter from "@material-ui/icons/PhotoFilter";
import PhotoLibrary from "@material-ui/icons/PhotoLibrary";
import PhotoSizeSelectActual from "@material-ui/icons/PhotoSizeSelectActual";
import PhotoSizeSelectLarge from "@material-ui/icons/PhotoSizeSelectLarge";
import PhotoSizeSelectSmall from "@material-ui/icons/PhotoSizeSelectSmall";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import PictureInPicture from "@material-ui/icons/PictureInPicture";
import PictureInPictureAlt from "@material-ui/icons/PictureInPictureAlt";
import PieChart from "@material-ui/icons/PieChart";
import PieChartOutlined from "@material-ui/icons/PieChartOutlined";
import PinDrop from "@material-ui/icons/PinDrop";
import Place from "@material-ui/icons/Place";
import PlayArrow from "@material-ui/icons/PlayArrow";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";
import PlayForWork from "@material-ui/icons/PlayForWork";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import PlaylistAddCheck from "@material-ui/icons/PlaylistAddCheck";
import PlaylistPlay from "@material-ui/icons/PlaylistPlay";
import PlusOne from "@material-ui/icons/PlusOne";
import Poll from "@material-ui/icons/Poll";
import Polymer from "@material-ui/icons/Polymer";
import Pool from "@material-ui/icons/Pool";
import PortableWifiOff from "@material-ui/icons/PortableWifiOff";
import Portrait from "@material-ui/icons/Portrait";
import Power from "@material-ui/icons/Power";
import PowerInput from "@material-ui/icons/PowerInput";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import PregnantWoman from "@material-ui/icons/PregnantWoman";
import PresentToAll from "@material-ui/icons/PresentToAll";
import Print from "@material-ui/icons/Print";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import Public from "@material-ui/icons/Public";
import Publish from "@material-ui/icons/Publish";
import QueryBuilder from "@material-ui/icons/QueryBuilder";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import Queue from "@material-ui/icons/Queue";
import QueueMusic from "@material-ui/icons/QueueMusic";
import QueuePlayNext from "@material-ui/icons/QueuePlayNext";
import Radio from "@material-ui/icons/Radio";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RateReview from "@material-ui/icons/RateReview";
import Receipt from "@material-ui/icons/Receipt";
import RecentActors from "@material-ui/icons/RecentActors";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Redeem from "@material-ui/icons/Redeem";
import Redo from "@material-ui/icons/Redo";
import Refresh from "@material-ui/icons/Refresh";
import Remove from "@material-ui/icons/Remove";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import RemoveFromQueue from "@material-ui/icons/RemoveFromQueue";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import RemoveShoppingCart from "@material-ui/icons/RemoveShoppingCart";
import Reorder from "@material-ui/icons/Reorder";
import Repeat from "@material-ui/icons/Repeat";
import RepeatOne from "@material-ui/icons/RepeatOne";
import Replay from "@material-ui/icons/Replay";
import Replay10 from "@material-ui/icons/Replay10";
import Replay30 from "@material-ui/icons/Replay30";
import Replay5 from "@material-ui/icons/Replay5";
import Reply from "@material-ui/icons/Reply";
import ReplyAll from "@material-ui/icons/ReplyAll";
import Report from "@material-ui/icons/Report";
import ReportProblem from "@material-ui/icons/ReportProblem";
import Restaurant from "@material-ui/icons/Restaurant";
import RestaurantMenu from "@material-ui/icons/RestaurantMenu";
import Restore from "@material-ui/icons/Restore";
import RestorePage from "@material-ui/icons/RestorePage";
import RingVolume from "@material-ui/icons/RingVolume";
import Room from "@material-ui/icons/Room";
import RoomService from "@material-ui/icons/RoomService";
import Rotate90DegreesCcw from "@material-ui/icons/Rotate90DegreesCcw";
import RotateLeft from "@material-ui/icons/RotateLeft";
import RotateRight from "@material-ui/icons/RotateRight";
import RoundedCorner from "@material-ui/icons/RoundedCorner";
import Router from "@material-ui/icons/Router";
import Rowing from "@material-ui/icons/Rowing";
import RssFeed from "@material-ui/icons/RssFeed";
import RvHookup from "@material-ui/icons/RvHookup";
import Satellite from "@material-ui/icons/Satellite";
import Save from "@material-ui/icons/Save";
import Scanner from "@material-ui/icons/Scanner";
import Schedule from "@material-ui/icons/Schedule";
import School from "@material-ui/icons/School";
import ScreenLockLandscape from "@material-ui/icons/ScreenLockLandscape";
import ScreenLockPortrait from "@material-ui/icons/ScreenLockPortrait";
import ScreenLockRotation from "@material-ui/icons/ScreenLockRotation";
import ScreenRotation from "@material-ui/icons/ScreenRotation";
import ScreenShare from "@material-ui/icons/ScreenShare";
import SdCard from "@material-ui/icons/SdCard";
import SdStorage from "@material-ui/icons/SdStorage";
import Search from "@material-ui/icons/Search";
import Security from "@material-ui/icons/Security";
import SelectAll from "@material-ui/icons/SelectAll";
import Send from "@material-ui/icons/Send";
import SentimentDissatisfied from "@material-ui/icons/SentimentDissatisfied";
import SentimentNeutral from "@material-ui/icons/SentimentNeutral";
import SentimentSatisfied from "@material-ui/icons/SentimentSatisfied";
import SentimentVeryDissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentVerySatisfied from "@material-ui/icons/SentimentVerySatisfied";
import Settings from "@material-ui/icons/Settings";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import SettingsBluetooth from "@material-ui/icons/SettingsBluetooth";
import SettingsBrightness from "@material-ui/icons/SettingsBrightness";
import SettingsCell from "@material-ui/icons/SettingsCell";
import SettingsEthernet from "@material-ui/icons/SettingsEthernet";
import SettingsInputAntenna from "@material-ui/icons/SettingsInputAntenna";
import SettingsInputComponent from "@material-ui/icons/SettingsInputComponent";
import SettingsInputComposite from "@material-ui/icons/SettingsInputComposite";
import SettingsInputHdmi from "@material-ui/icons/SettingsInputHdmi";
import SettingsInputSvideo from "@material-ui/icons/SettingsInputSvideo";
import SettingsOverscan from "@material-ui/icons/SettingsOverscan";
import SettingsPhone from "@material-ui/icons/SettingsPhone";
import SettingsPower from "@material-ui/icons/SettingsPower";
import SettingsRemote from "@material-ui/icons/SettingsRemote";
import SettingsSystemDaydream from "@material-ui/icons/SettingsSystemDaydream";
import SettingsVoice from "@material-ui/icons/SettingsVoice";
import Share from "@material-ui/icons/Share";
import Shop from "@material-ui/icons/Shop";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ShopTwo from "@material-ui/icons/ShopTwo";
import ShortText from "@material-ui/icons/ShortText";
import ShowChart from "@material-ui/icons/ShowChart";
import Shuffle from "@material-ui/icons/Shuffle";
import SignalCellular0Bar from "@material-ui/icons/SignalCellular0Bar";
import SignalCellular1Bar from "@material-ui/icons/SignalCellular1Bar";
import SignalCellular2Bar from "@material-ui/icons/SignalCellular2Bar";
import SignalCellular3Bar from "@material-ui/icons/SignalCellular3Bar";
import SignalCellular4Bar from "@material-ui/icons/SignalCellular4Bar";
import SignalCellularConnectedNoInternet0Bar from "@material-ui/icons/SignalCellularConnectedNoInternet0Bar";
import SignalCellularConnectedNoInternet1Bar from "@material-ui/icons/SignalCellularConnectedNoInternet1Bar";
import SignalCellularConnectedNoInternet2Bar from "@material-ui/icons/SignalCellularConnectedNoInternet2Bar";
import SignalCellularConnectedNoInternet3Bar from "@material-ui/icons/SignalCellularConnectedNoInternet3Bar";
import SignalCellularConnectedNoInternet4Bar from "@material-ui/icons/SignalCellularConnectedNoInternet4Bar";
import SignalCellularNoSim from "@material-ui/icons/SignalCellularNoSim";
import SignalCellularNull from "@material-ui/icons/SignalCellularNull";
import SignalCellularOff from "@material-ui/icons/SignalCellularOff";
import SignalWifi0Bar from "@material-ui/icons/SignalWifi0Bar";
import SignalWifi1Bar from "@material-ui/icons/SignalWifi1Bar";
import SignalWifi1BarLock from "@material-ui/icons/SignalWifi1BarLock";
import SignalWifi2Bar from "@material-ui/icons/SignalWifi2Bar";
import SignalWifi2BarLock from "@material-ui/icons/SignalWifi2BarLock";
import SignalWifi3Bar from "@material-ui/icons/SignalWifi3Bar";
import SignalWifi3BarLock from "@material-ui/icons/SignalWifi3BarLock";
import SignalWifi4Bar from "@material-ui/icons/SignalWifi4Bar";
import SignalWifi4BarLock from "@material-ui/icons/SignalWifi4BarLock";
import SignalWifiOff from "@material-ui/icons/SignalWifiOff";
import SimCard from "@material-ui/icons/SimCard";
import SimCardAlert from "@material-ui/icons/SimCardAlert";
import SkipNext from "@material-ui/icons/SkipNext";
import SkipPrevious from "@material-ui/icons/SkipPrevious";
import Slideshow from "@material-ui/icons/Slideshow";
import SlowMotionVideo from "@material-ui/icons/SlowMotionVideo";
import Smartphone from "@material-ui/icons/Smartphone";
import SmokeFree from "@material-ui/icons/SmokeFree";
import SmokingRooms from "@material-ui/icons/SmokingRooms";
import Sms from "@material-ui/icons/Sms";
import SmsFailed from "@material-ui/icons/SmsFailed";
import Snooze from "@material-ui/icons/Snooze";
import Sort from "@material-ui/icons/Sort";
import SortByAlpha from "@material-ui/icons/SortByAlpha";
import Spa from "@material-ui/icons/Spa";
import SpaceBar from "@material-ui/icons/SpaceBar";
import Speaker from "@material-ui/icons/Speaker";
import SpeakerGroup from "@material-ui/icons/SpeakerGroup";
import SpeakerNotes from "@material-ui/icons/SpeakerNotes";
import SpeakerNotesOff from "@material-ui/icons/SpeakerNotesOff";
import SpeakerPhone from "@material-ui/icons/SpeakerPhone";
import Spellcheck from "@material-ui/icons/Spellcheck";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import StarHalf from "@material-ui/icons/StarHalf";
import Stars from "@material-ui/icons/Stars";
import StayCurrentLandscape from "@material-ui/icons/StayCurrentLandscape";
import StayCurrentPortrait from "@material-ui/icons/StayCurrentPortrait";
import StayPrimaryLandscape from "@material-ui/icons/StayPrimaryLandscape";
import StayPrimaryPortrait from "@material-ui/icons/StayPrimaryPortrait";
import Stop from "@material-ui/icons/Stop";
import StopScreenShare from "@material-ui/icons/StopScreenShare";
import Storage from "@material-ui/icons/Storage";
import Store from "@material-ui/icons/Store";
import StoreMallDirectory from "@material-ui/icons/StoreMallDirectory";
import Straighten from "@material-ui/icons/Straighten";
import Streetview from "@material-ui/icons/Streetview";
import StrikethroughS from "@material-ui/icons/StrikethroughS";
import Style from "@material-ui/icons/Style";
import SubdirectoryArrowLeft from "@material-ui/icons/SubdirectoryArrowLeft";
import SubdirectoryArrowRight from "@material-ui/icons/SubdirectoryArrowRight";
import Subject from "@material-ui/icons/Subject";
import Subscriptions from "@material-ui/icons/Subscriptions";
import Subtitles from "@material-ui/icons/Subtitles";
import Subway from "@material-ui/icons/Subway";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import SurroundSound from "@material-ui/icons/SurroundSound";
import SwapCalls from "@material-ui/icons/SwapCalls";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import SwapVert from "@material-ui/icons/SwapVert";
import SwapVerticalCircle from "@material-ui/icons/SwapVerticalCircle";
import SwitchCamera from "@material-ui/icons/SwitchCamera";
import SwitchVideo from "@material-ui/icons/SwitchVideo";
import Sync from "@material-ui/icons/Sync";
import SyncDisabled from "@material-ui/icons/SyncDisabled";
import SyncProblem from "@material-ui/icons/SyncProblem";
import SystemUpdate from "@material-ui/icons/SystemUpdate";
import SystemUpdateAlt from "@material-ui/icons/SystemUpdateAlt";
import Tab from "@material-ui/icons/Tab";
import Tablet from "@material-ui/icons/Tablet";
import TabletAndroid from "@material-ui/icons/TabletAndroid";
import TabletMac from "@material-ui/icons/TabletMac";
import TabUnselected from "@material-ui/icons/TabUnselected";
import TagFaces from "@material-ui/icons/TagFaces";
import TapAndPlay from "@material-ui/icons/TapAndPlay";
import Terrain from "@material-ui/icons/Terrain";
import TextFields from "@material-ui/icons/TextFields";
import TextFormat from "@material-ui/icons/TextFormat";
import Textsms from "@material-ui/icons/Textsms";
import Texture from "@material-ui/icons/Texture";
import Theaters from "@material-ui/icons/Theaters";
import ThreeDRotation from "@material-ui/icons/ThreeDRotation";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbsUpDown from "@material-ui/icons/ThumbsUpDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Timelapse from "@material-ui/icons/Timelapse";
import Timeline from "@material-ui/icons/Timeline";
import Timer from "@material-ui/icons/Timer";
import Timer10 from "@material-ui/icons/Timer10";
import Timer3 from "@material-ui/icons/Timer3";
import TimerOff from "@material-ui/icons/TimerOff";
import TimeToLeave from "@material-ui/icons/TimeToLeave";
import Title from "@material-ui/icons/Title";
import Toc from "@material-ui/icons/Toc";
import Today from "@material-ui/icons/Today";
import Toll from "@material-ui/icons/Toll";
import Tonality from "@material-ui/icons/Tonality";
import TouchApp from "@material-ui/icons/TouchApp";
import Toys from "@material-ui/icons/Toys";
import TrackChanges from "@material-ui/icons/TrackChanges";
import Traffic from "@material-ui/icons/Traffic";
import Train from "@material-ui/icons/Train";
import Tram from "@material-ui/icons/Tram";
import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import Transform from "@material-ui/icons/Transform";
import Translate from "@material-ui/icons/Translate";
import TrendingDown from "@material-ui/icons/TrendingDown";
import TrendingFlat from "@material-ui/icons/TrendingFlat";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Tune from "@material-ui/icons/Tune";
import TurnedIn from "@material-ui/icons/TurnedIn";
import TurnedInNot from "@material-ui/icons/TurnedInNot";
import Tv from "@material-ui/icons/Tv";
import Unarchive from "@material-ui/icons/Unarchive";
import Undo from "@material-ui/icons/Undo";
import UnfoldLess from "@material-ui/icons/UnfoldLess";
import UnfoldMore from "@material-ui/icons/UnfoldMore";
import Update from "@material-ui/icons/Update";
import Usb from "@material-ui/icons/Usb";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import VerticalAlignBottom from "@material-ui/icons/VerticalAlignBottom";
import VerticalAlignCenter from "@material-ui/icons/VerticalAlignCenter";
import VerticalAlignTop from "@material-ui/icons/VerticalAlignTop";
import Vibration from "@material-ui/icons/Vibration";
import VideoCall from "@material-ui/icons/VideoCall";
import Videocam from "@material-ui/icons/Videocam";
import VideocamOff from "@material-ui/icons/VideocamOff";
import VideogameAsset from "@material-ui/icons/VideogameAsset";
import VideoLabel from "@material-ui/icons/VideoLabel";
import VideoLibrary from "@material-ui/icons/VideoLibrary";
import ViewAgenda from "@material-ui/icons/ViewAgenda";
import ViewArray from "@material-ui/icons/ViewArray";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ViewComfy from "@material-ui/icons/ViewComfy";
import ViewCompact from "@material-ui/icons/ViewCompact";
import ViewDay from "@material-ui/icons/ViewDay";
import ViewHeadline from "@material-ui/icons/ViewHeadline";
import ViewList from "@material-ui/icons/ViewList";
import ViewModule from "@material-ui/icons/ViewModule";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import ViewStream from "@material-ui/icons/ViewStream";
import ViewWeek from "@material-ui/icons/ViewWeek";
import Vignette from "@material-ui/icons/Vignette";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VoiceChat from "@material-ui/icons/VoiceChat";
import Voicemail from "@material-ui/icons/Voicemail";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeMute";
import VolumeOff from "@material-ui/icons/VolumeOff";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VpnKey from "@material-ui/icons/VpnKey";
import VpnLock from "@material-ui/icons/VpnLock";
import Wallpaper from "@material-ui/icons/Wallpaper";
import Warning from "@material-ui/icons/Warning";
import Watch from "@material-ui/icons/Watch";
import WatchLater from "@material-ui/icons/WatchLater";
import WbAuto from "@material-ui/icons/WbAuto";
import WbCloudy from "@material-ui/icons/WbCloudy";
import WbIncandescent from "@material-ui/icons/WbIncandescent";
import WbIridescent from "@material-ui/icons/WbIridescent";
import WbSunny from "@material-ui/icons/WbSunny";
import Wc from "@material-ui/icons/Wc";
import Web from "@material-ui/icons/Web";
import WebAsset from "@material-ui/icons/WebAsset";
import Weekend from "@material-ui/icons/Weekend";
import Whatshot from "@material-ui/icons/Whatshot";
import Widgets from "@material-ui/icons/Widgets";
import Wifi from "@material-ui/icons/Wifi";
import WifiLock from "@material-ui/icons/WifiLock";
import WifiTethering from "@material-ui/icons/WifiTethering";
import Work from "@material-ui/icons/Work";
import WrapText from "@material-ui/icons/WrapText";
import YoutubeSearchedFor from "@material-ui/icons/YoutubeSearchedFor";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import ZoomOutMap from "@material-ui/icons/ZoomOutMap";*/

/*
    { id: "AccessAlarm", component: <AccessAlarm /> },
    //{id: "AccessAlarms", component: <AccessAlarms />},
    { id: "AccessibilityNew", component: <AccessibilityNew /> },
    { id: "Accessible", component: <Accessible /> },
    { id: "AccessTime", component: <AccessTime /> },
    { id: "AccountBalance", component: <AccountBalance /> },
    { id: "AccountBalanceWallet", component: <AccountBalanceWallet /> },
    { id: "AccountBox", component: <AccountBox /> },
    { id: "AccountCircle", component: <AccountCircle /> },
    { id: "AcUnit", component: <AcUnit /> },
    { id: "Adb", component: <Adb /> },
    { id: "Add", component: <Add /> },
    { id: "AddAlarm", component: <AddAlarm /> },
    { id: "AddAlert", component: <AddAlert /> },
    { id: "AddAPhoto", component: <AddAPhoto /> },
    { id: "AddBox", component: <AddBox /> },
    { id: "AddCircle", component: <AddCircle /> },
    { id: "AddCircleOutline", component: <AddCircleOutline /> },
    { id: "AddLocation", component: <AddLocation /> },
    { id: "AddShoppingCart", component: <AddShoppingCart /> },
    { id: "AddToPhotos", component: <AddToPhotos /> },
    { id: "AddToQueue", component: <AddToQueue /> },
    { id: "Adjust", component: <Adjust /> },
    { id: "AirlineSeatFlat", component: <AirlineSeatFlat /> },
    { id: "AirlineSeatFlatAngled", component: <AirlineSeatFlatAngled /> },
    { id: "AirlineSeatIndividualSuite", component: <AirlineSeatIndividualSuite /> },
    { id: "AirlineSeatLegroomExtra", component: <AirlineSeatLegroomExtra /> },
    { id: "AirlineSeatLegroomNormal", component: <AirlineSeatLegroomNormal /> },
    { id: "AirlineSeatLegroomReduced", component: <AirlineSeatLegroomReduced /> },
    { id: "AirlineSeatReclineExtra", component: <AirlineSeatReclineExtra /> },
    { id: "AirlineSeatReclineNormal", component: <AirlineSeatReclineNormal /> },
    { id: "AirplanemodeActive", component: <AirplanemodeActive /> },
    { id: "AirplanemodeInactive", component: <AirplanemodeInactive /> },
    { id: "Airplay", component: <Airplay /> },
    { id: "AirportShuttle", component: <AirportShuttle /> },
    { id: "Alarm", component: <Alarm /> },
    { id: "AlarmAdd", component: <AlarmAdd /> },
    { id: "AlarmOff", component: <AlarmOff /> },
    { id: "AlarmOn", component: <AlarmOn /> },
    { id: "Album", component: <Album /> },
    { id: "AllInclusive", component: <AllInclusive /> },
    { id: "AllOut", component: <AllOut /> },
    { id: "Android", component: <Android /> },
    { id: "Announcement", component: <Announcement /> },
    { id: "Apps", component: <Apps /> },
    { id: "Archive", component: <Archive /> },
    { id: "ArrowBack", component: <ArrowBack /> },
    { id: "ArrowDownward", component: <ArrowDownward /> },
    { id: "ArrowDropDown", component: <ArrowDropDown /> },
    { id: "ArrowDropDownCircle", component: <ArrowDropDownCircle /> },
    { id: "ArrowDropUp", component: <ArrowDropUp /> },
    { id: "ArrowForward", component: <ArrowForward /> },
    { id: "ArrowUpward", component: <ArrowUpward /> },
    { id: "ArtTrack", component: <ArtTrack /> },
    { id: "AspectRatio", component: <AspectRatio /> },
    { id: "Assessment", component: <Assessment /> },
    { id: "Assignment", component: <Assignment /> },
    { id: "AssignmentInd", component: <AssignmentInd /> },
    { id: "AssignmentLate", component: <AssignmentLate /> },
    { id: "AssignmentReturn", component: <AssignmentReturn /> },
    { id: "AssignmentReturned", component: <AssignmentReturned /> },
    { id: "AssignmentTurnedIn", component: <AssignmentTurnedIn /> },
    { id: "Assistant", component: <Assistant /> },
    { id: "AssistantPhoto", component: <AssistantPhoto /> },
    { id: "AttachFile", component: <AttachFile /> },
    { id: "Attachment", component: <Attachment /> },
    { id: "AttachMoney", component: <AttachMoney /> },
    { id: "Audiotrack", component: <Audiotrack /> },
    { id: "Autorenew", component: <Autorenew /> },
    { id: "AvTimer", component: <AvTimer /> },
    { id: "Backspace", component: <Backspace /> },
    { id: "Backup", component: <Backup /> },
    { id: "Battery20", component: <Battery20 /> },
    { id: "Battery30", component: <Battery30 /> },
    { id: "Battery50", component: <Battery50 /> },
    { id: "Battery60", component: <Battery60 /> },
    { id: "Battery80", component: <Battery80 /> },
    { id: "Battery90", component: <Battery90 /> },
    { id: "BatteryAlert", component: <BatteryAlert /> },
    { id: "BatteryCharging20", component: <BatteryCharging20 /> },
    { id: "BatteryCharging30", component: <BatteryCharging30 /> },
    { id: "BatteryCharging50", component: <BatteryCharging50 /> },
    { id: "BatteryCharging60", component: <BatteryCharging60 /> },
    { id: "BatteryCharging80", component: <BatteryCharging80 /> },
    { id: "BatteryCharging90", component: <BatteryCharging90 /> },
    { id: "BatteryChargingFull", component: <BatteryChargingFull /> },
    { id: "BatteryFull", component: <BatteryFull /> },
    { id: "BatteryStd", component: <BatteryStd /> },
    { id: "BatteryUnknown", component: <BatteryUnknown /> },
    { id: "BeachAccess", component: <BeachAccess /> },
    { id: "Beenhere", component: <Beenhere /> },
    { id: "Block", component: <Block /> },
    { id: "Bluetooth", component: <Bluetooth /> },
    { id: "BluetoothAudio", component: <BluetoothAudio /> },
    { id: "BluetoothConnected", component: <BluetoothConnected /> },
    { id: "BluetoothDisabled", component: <BluetoothDisabled /> },
    { id: "BluetoothSearching", component: <BluetoothSearching /> },
    { id: "BlurCircular", component: <BlurCircular /> },
    { id: "BlurLinear", component: <BlurLinear /> },
    { id: "BlurOff", component: <BlurOff /> },
    { id: "BlurOn", component: <BlurOn /> },
    { id: "Book", component: <Book /> },
    { id: "Bookmark", component: <Bookmark /> },
    { id: "BookmarkBorder", component: <BookmarkBorder /> },
    { id: "BorderAll", component: <BorderAll /> },
    { id: "BorderBottom", component: <BorderBottom /> },
    { id: "BorderClear", component: <BorderClear /> },
    { id: "BorderColor", component: <BorderColor /> },
    { id: "BorderHorizontal", component: <BorderHorizontal /> },
    { id: "BorderInner", component: <BorderInner /> },
    { id: "BorderLeft", component: <BorderLeft /> },
    { id: "BorderOuter", component: <BorderOuter /> },
    { id: "BorderRight", component: <BorderRight /> },
    { id: "BorderStyle", component: <BorderStyle /> },
    { id: "BorderTop", component: <BorderTop /> },
    { id: "BorderVertical", component: <BorderVertical /> },
    { id: "BrandingWatermark", component: <BrandingWatermark /> },
    { id: "Brightness1", component: <Brightness1 /> },
    { id: "Brightness2", component: <Brightness2 /> },
    { id: "Brightness3", component: <Brightness3 /> },
    { id: "Brightness4", component: <Brightness4 /> },
    { id: "Brightness5", component: <Brightness5 /> },
    { id: "Brightness6", component: <Brightness6 /> },
    { id: "Brightness7", component: <Brightness7 /> },
    { id: "BrightnessAuto", component: <BrightnessAuto /> },
    { id: "BrightnessHigh", component: <BrightnessHigh /> },
    { id: "BrightnessLow", component: <BrightnessLow /> },
    { id: "BrightnessMedium", component: <BrightnessMedium /> },
    { id: "BrokenImage", component: <BrokenImage /> },
    { id: "Brush", component: <Brush /> },
    { id: "BubbleChart", component: <BubbleChart /> },
    { id: "BugReport", component: <BugReport /> },
    { id: "Build", component: <Build /> },
    { id: "BurstMode", component: <BurstMode /> },
    { id: "Business", component: <Business /> },
    { id: "BusinessCenter", component: <BusinessCenter /> },
    { id: "Cached", component: <Cached /> },
    { id: "Cake", component: <Cake /> },
    { id: "Call", component: <Call /> },
    { id: "CallEnd", component: <CallEnd /> },
    { id: "CallMade", component: <CallMade /> },
    { id: "CallMerge", component: <CallMerge /> },
    { id: "CallMissed", component: <CallMissed /> },
    { id: "CallMissedOutgoing", component: <CallMissedOutgoing /> },
    { id: "CallReceived", component: <CallReceived /> },
    { id: "CallSplit", component: <CallSplit /> },
    { id: "CallToAction", component: <CallToAction /> },
    { id: "Camera", component: <Camera /> },
    { id: "CameraAlt", component: <CameraAlt /> },
    { id: "CameraEnhance", component: <CameraEnhance /> },
    { id: "CameraFront", component: <CameraFront /> },
    { id: "CameraRear", component: <CameraRear /> },
    { id: "CameraRoll", component: <CameraRoll /> },
    { id: "Cancel", component: <Cancel /> },
    { id: "CardGiftcard", component: <CardGiftcard /> },
    { id: "CardMembership", component: <CardMembership /> },
    { id: "CardTravel", component: <CardTravel /> },
    { id: "Casino", component: <Casino /> },
    { id: "Cast", component: <Cast /> },
    { id: "CastConnected", component: <CastConnected /> },
    { id: "CenterFocusStrong", component: <CenterFocusStrong /> },
    { id: "CenterFocusWeak", component: <CenterFocusWeak /> },
    { id: "ChangeHistory", component: <ChangeHistory /> },
    { id: "Chat", component: <Chat /> },
    { id: "ChatBubble", component: <ChatBubble /> },
    { id: "ChatBubbleOutline", component: <ChatBubbleOutline /> },
    { id: "Check", component: <Check /> },
    { id: "CheckBox", component: <CheckBox /> },
    { id: "CheckBoxOutlineBlank", component: <CheckBoxOutlineBlank /> },
    { id: "CheckCircle", component: <CheckCircle /> },
    { id: "ChevronLeft", component: <ChevronLeft /> },
    { id: "ChevronRight", component: <ChevronRight /> },
    { id: "ChildCare", component: <ChildCare /> },
    { id: "ChildFriendly", component: <ChildFriendly /> },
    { id: "ChromeReaderMode", component: <ChromeReaderMode /> },
    { id: "Class", component: <Class /> },
    { id: "Clear", component: <Clear /> },
    { id: "ClearAll", component: <ClearAll /> },
    { id: "Close", component: <Close /> },
    { id: "ClosedCaption", component: <ClosedCaption /> },
    { id: "Cloud", component: <Cloud /> },
    { id: "CloudCircle", component: <CloudCircle /> },
    { id: "CloudDone", component: <CloudDone /> },
    { id: "CloudDownload", component: <CloudDownload /> },
    { id: "CloudOff", component: <CloudOff /> },
    { id: "CloudQueue", component: <CloudQueue /> },
    { id: "CloudUpload", component: <CloudUpload /> },
    { id: "Code", component: <Code /> },
    { id: "Collections", component: <Collections /> },
    { id: "CollectionsBookmark", component: <CollectionsBookmark /> },
    { id: "Colorize", component: <Colorize /> },
    { id: "ColorLens", component: <ColorLens /> },
    { id: "Comment", component: <Comment /> },
    { id: "Compare", component: <Compare /> },
    { id: "CompareArrows", component: <CompareArrows /> },
    { id: "Computer", component: <Computer /> },
    { id: "ConfirmationNumber", component: <ConfirmationNumber /> },
    { id: "ContactMail", component: <ContactMail /> },
    { id: "ContactPhone", component: <ContactPhone /> },
    { id: "Contacts", component: <Contacts /> },

    { id: "ContentCut", component: <ContentCut /> },
    { id: "ContentPaste", component: <ContentPaste /> },
    { id: "ControlPoint", component: <ControlPoint /> },
    { id: "ControlPointDuplicate", component: <ControlPointDuplicate /> },
    { id: "Copyright", component: <Copyright /> },
    { id: "Create", component: <Create /> },
    { id: "CreateNewFolder", component: <CreateNewFolder /> },
    { id: "CreditCard", component: <CreditCard /> },
    { id: "Crop", component: <Crop /> },
    { id: "Crop169", component: <Crop169 /> },
    { id: "Crop32", component: <Crop32 /> },
    { id: "Crop54", component: <Crop54 /> },
    { id: "Crop75", component: <Crop75 /> },
    { id: "CropDin", component: <CropDin /> },
    { id: "CropFree", component: <CropFree /> },
    { id: "CropLandscape", component: <CropLandscape /> },
    { id: "CropOriginal", component: <CropOriginal /> },
    { id: "CropPortrait", component: <CropPortrait /> },
    { id: "CropRotate", component: <CropRotate /> },
    { id: "CropSquare", component: <CropSquare /> },
    { id: "Dashboard", component: <Dashboard /> },
    { id: "DataUsage", component: <DataUsage /> },
    { id: "DateRange", component: <DateRange /> },
    { id: "Dehaze", component: <Dehaze /> },
    { id: "Delete", component: <Delete /> },
    { id: "DeleteForever", component: <DeleteForever /> },
    { id: "DeleteSweep", component: <DeleteSweep /> },
    { id: "Description", component: <Description /> },
    { id: "DesktopMac", component: <DesktopMac /> },
    { id: "DesktopWindows", component: <DesktopWindows /> },
    { id: "Details", component: <Details /> },
    { id: "DeveloperBoard", component: <DeveloperBoard /> },
    { id: "DeveloperMode", component: <DeveloperMode /> },
    { id: "DeviceHub", component: <DeviceHub /> },
    { id: "Devices", component: <Devices /> },
    { id: "DevicesOther", component: <DevicesOther /> },
    { id: "DialerSip", component: <DialerSip /> },
    { id: "Dialpad", component: <Dialpad /> },
    { id: "Directions", component: <Directions /> },
    { id: "DirectionsBike", component: <DirectionsBike /> },
    { id: "DirectionsBoat", component: <DirectionsBoat /> },
    { id: "DirectionsBus", component: <DirectionsBus /> },
    { id: "DirectionsCar", component: <DirectionsCar /> },
    { id: "DirectionsRailway", component: <DirectionsRailway /> },
    { id: "DirectionsRun", component: <DirectionsRun /> },
    { id: "DirectionsSubway", component: <DirectionsSubway /> },
    { id: "DirectionsTransit", component: <DirectionsTransit /> },
    { id: "DirectionsWalk", component: <DirectionsWalk /> },
    { id: "DiscFull", component: <DiscFull /> },
    { id: "Dns", component: <Dns /> },
    { id: "Dock", component: <Dock /> },
    { id: "Domain", component: <Domain /> },
    { id: "Done", component: <Done /> },
    { id: "DoneAll", component: <DoneAll /> },
    { id: "DoNotDisturb", component: <DoNotDisturb /> },
    { id: "DoNotDisturbAlt", component: <DoNotDisturbAlt /> },
    { id: "DoNotDisturbOff", component: <DoNotDisturbOff /> },
    { id: "DoNotDisturbOn", component: <DoNotDisturbOn /> },
    { id: "DonutLarge", component: <DonutLarge /> },
    { id: "DonutSmall", component: <DonutSmall /> },
    { id: "Drafts", component: <Drafts /> },
    { id: "DragHandle", component: <DragHandle /> },
    { id: "DriveEta", component: <DriveEta /> },
    { id: "Dvr", component: <Dvr /> },
    { id: "Edit", component: <Edit /> },
    { id: "EditLocation", component: <EditLocation /> },
    { id: "Eject", component: <Eject /> },
    { id: "Email", component: <Email /> },
    { id: "EnhancedEncryption", component: <EnhancedEncryption /> },
    { id: "Equalizer", component: <Equalizer /> },
    { id: "Error", component: <Error /> },
    { id: "ErrorOutline", component: <ErrorOutline /> },
    { id: "EuroSymbol", component: <EuroSymbol /> },
    { id: "Event", component: <Event /> },
    { id: "EventAvailable", component: <EventAvailable /> },
    { id: "EventBusy", component: <EventBusy /> },
    { id: "EventNote", component: <EventNote /> },
    { id: "EventSeat", component: <EventSeat /> },
    { id: "EvStation", component: <EvStation /> },
    { id: "ExitToApp", component: <ExitToApp /> },
    { id: "ExpandLess", component: <ExpandLess /> },
    { id: "ExpandMore", component: <ExpandMore /> },
    { id: "Explicit", component: <Explicit /> },
    { id: "Explore", component: <Explore /> },
    { id: "Exposure", component: <Exposure /> },
    { id: "ExposureNeg1", component: <ExposureNeg1 /> },
    { id: "ExposureNeg2", component: <ExposureNeg2 /> },
    { id: "ExposurePlus1", component: <ExposurePlus1 /> },
    { id: "ExposurePlus2", component: <ExposurePlus2 /> },
    { id: "ExposureZero", component: <ExposureZero /> },
    { id: "Extension", component: <Extension /> },
    { id: "Face", component: <Face /> },
    { id: "FastForward", component: <FastForward /> },
    { id: "FastRewind", component: <FastRewind /> },
    { id: "Favorite", component: <Favorite /> },
    { id: "FavoriteBorder", component: <FavoriteBorder /> },
    { id: "FeaturedPlayList", component: <FeaturedPlayList /> },
    { id: "FeaturedVideo", component: <FeaturedVideo /> },
    { id: "Feedback", component: <Feedback /> },
    { id: "FiberDvr", component: <FiberDvr /> },
    { id: "FiberManualRecord", component: <FiberManualRecord /> },
    { id: "FiberNew", component: <FiberNew /> },
    { id: "FiberPin", component: <FiberPin /> },
    { id: "FiberSmartRecord", component: <FiberSmartRecord /> },
    { id: "FileDownload", component: <FileDownload /> },
    { id: "FileUpload", component: <FileUpload /> },
    { id: "Filter", component: <Filter /> },
    { id: "Filter1", component: <Filter1 /> },
    { id: "Filter2", component: <Filter2 /> },
    { id: "Filter3", component: <Filter3 /> },
    { id: "Filter4", component: <Filter4 /> },
    { id: "Filter5", component: <Filter5 /> },
    { id: "Filter6", component: <Filter6 /> },
    { id: "Filter7", component: <Filter7 /> },
    { id: "Filter8", component: <Filter8 /> },
    { id: "Filter9", component: <Filter9 /> },
    { id: "Filter9Plus", component: <Filter9Plus /> },
    { id: "FilterBAndW", component: <FilterBAndW /> },
    { id: "FilterCenterFocus", component: <FilterCenterFocus /> },
    { id: "FilterDrama", component: <FilterDrama /> },
    { id: "FilterFrames", component: <FilterFrames /> },
    { id: "FilterHdr", component: <FilterHdr /> },
    { id: "FilterList", component: <FilterList /> },
    { id: "FilterNone", component: <FilterNone /> },
    { id: "FilterTiltShift", component: <FilterTiltShift /> },
    { id: "FilterVintage", component: <FilterVintage /> },
    { id: "FindInPage", component: <FindInPage /> },
    { id: "FindReplace", component: <FindReplace /> },
    { id: "Fingerprint", component: <Fingerprint /> },
    { id: "FirstPage", component: <FirstPage /> },
    { id: "FitnessCenter", component: <FitnessCenter /> },
    { id: "Flag", component: <Flag /> },
    { id: "Flare", component: <Flare /> },
    { id: "FlashAuto", component: <FlashAuto /> },
    { id: "FlashOff", component: <FlashOff /> },
    { id: "FlashOn", component: <FlashOn /> },
    { id: "Flight", component: <Flight /> },
    { id: "FlightLand", component: <FlightLand /> },
    { id: "FlightTakeoff", component: <FlightTakeoff /> },
    { id: "Flip", component: <Flip /> },
    { id: "FlipToBack", component: <FlipToBack /> },
    { id: "FlipToFront", component: <FlipToFront /> },
    { id: "Folder", component: <Folder /> },
    { id: "FolderOpen", component: <FolderOpen /> },
    { id: "FolderShared", component: <FolderShared /> },
    { id: "FolderSpecial", component: <FolderSpecial /> },
    { id: "FontDownload", component: <FontDownload /> },
    { id: "FormatAlignCenter", component: <FormatAlignCenter /> },
    { id: "FormatAlignJustify", component: <FormatAlignJustify /> },
    { id: "FormatAlignLeft", component: <FormatAlignLeft /> },
    { id: "FormatAlignRight", component: <FormatAlignRight /> },
    { id: "FormatBold", component: <FormatBold /> },
    { id: "FormatClear", component: <FormatClear /> },
    { id: "FormatColorFill", component: <FormatColorFill /> },
    { id: "FormatColorReset", component: <FormatColorReset /> },
    { id: "FormatColorText", component: <FormatColorText /> },
    { id: "FormatIndentDecrease", component: <FormatIndentDecrease /> },
    { id: "FormatIndentIncrease", component: <FormatIndentIncrease /> },
    { id: "FormatItalic", component: <FormatItalic /> },
    { id: "FormatLineSpacing", component: <FormatLineSpacing /> },
    { id: "FormatListBulleted", component: <FormatListBulleted /> },
    { id: "FormatListNumbered", component: <FormatListNumbered /> },
    { id: "FormatPaint", component: <FormatPaint /> },
    { id: "FormatQuote", component: <FormatQuote /> },
    { id: "FormatShapes", component: <FormatShapes /> },
    { id: "FormatSize", component: <FormatSize /> },
    { id: "FormatStrikethrough", component: <FormatStrikethrough /> },
    { id: "FormatTextdirectionLToR", component: <FormatTextdirectionLToR /> },
    { id: "FormatTextdirectionRToL", component: <FormatTextdirectionRToL /> },
    { id: "FormatUnderlined", component: <FormatUnderlined /> },
    { id: "Forum", component: <Forum /> },
    { id: "Forward", component: <Forward /> },
    { id: "Forward10", component: <Forward10 /> },
    { id: "Forward30", component: <Forward30 /> },
    { id: "Forward5", component: <Forward5 /> },
    { id: "FreeBreakfast", component: <FreeBreakfast /> },
    { id: "Fullscreen", component: <Fullscreen /> },
    { id: "FullscreenExit", component: <FullscreenExit /> },
    { id: "Functions", component: <Functions /> },
    { id: "Gamepad", component: <Gamepad /> },
    { id: "Games", component: <Games /> },
    { id: "Gavel", component: <Gavel /> },
    { id: "Gesture", component: <Gesture /> },
    { id: "GetApp", component: <GetApp /> },
    { id: "Gif", component: <Gif /> },
    { id: "GolfCourse", component: <GolfCourse /> },
    { id: "GpsFixed", component: <GpsFixed /> },
    { id: "GpsNotFixed", component: <GpsNotFixed /> },
    { id: "GpsOff", component: <GpsOff /> },
    { id: "Grade", component: <Grade /> },
    { id: "Gradient", component: <Gradient /> },
    { id: "Grain", component: <Grain /> },
    { id: "GraphicEq", component: <GraphicEq /> },
    { id: "GridOff", component: <GridOff /> },
    { id: "GridOn", component: <GridOn /> },
    { id: "Group", component: <Group /> },
    { id: "GroupAdd", component: <GroupAdd /> },
    { id: "GroupWork", component: <GroupWork /> },
    {
        id: "GTranslate",
        component: <GTranslate />
    } ,
    {id: "Hd", component: <Hd />},
    {id: "HdrOff", component: <HdrOff />},
    {id: "HdrOn", component: <HdrOn />},
    {id: "HdrStrong", component: <HdrStrong />},
    {id: "HdrWeak", component: <HdrWeak />},
    {id: "Headset", component: <Headset />},
    {id: "HeadsetMic", component: <HeadsetMic />},
    {id: "Healing", component: <Healing />},
    {id: "Hearing", component: <Hearing />},
    {id: "Help", component: <Help />},
    {id: "HelpOutline", component: <HelpOutline />},
    {id: "Highlight", component: <Highlight />},
    {id: "HighlightOff", component: <HighlightOff />},
    {id: "HighQuality", component: <HighQuality />},
    {id: "History", component: <History />},
    {id: "Home", component: <Home />},
    {id: "Hotel", component: <Hotel />},
    {id: "HotTub", component: <HotTub />},
    {id: "HourglassEmpty", component: <HourglassEmpty />},
    {id: "HourglassFull", component: <HourglassFull />},
    {id: "Http", component: <Http />},
    {id: "Https", component: <Https />},
    {id: "Image", component: <Image />},
    {id: "ImageAspectRatio", component: <ImageAspectRatio />},
    {id: "ImportantDevices", component: <ImportantDevices />},
    {id: "ImportContacts", component: <ImportContacts />},
    {id: "ImportExport", component: <ImportExport />},
    {id: "Inbox", component: <Inbox />},
    {id: "IndeterminateCheckBox", component: <IndeterminateCheckBox />},
    {id: "index", component: <index />},
    {id: "Info", component: <Info />},
    {id: "InfoOutline", component: <InfoOutline />},
    {id: "Input", component: <Input />},
    {id: "InsertChart", component: <InsertChart />},
    {id: "InsertComment", component: <InsertComment />},
    {id: "InsertDriveFile", component: <InsertDriveFile />},
    {id: "InsertEmoticon", component: <InsertEmoticon />},
    {id: "InsertInvitation", component: <InsertInvitation />},
    {id: "InsertLink", component: <InsertLink />},
    {id: "InsertPhoto", component: <InsertPhoto />},
    {id: "InvertColors", component: <InvertColors />},
    {id: "InvertColorsOff", component: <InvertColorsOff />},
    {id: "Iso", component: <Iso />},
    {id: "Keyboard", component: <Keyboard />},
    {id: "KeyboardArrowDown", component: <KeyboardArrowDown />},
    {id: "KeyboardArrowLeft", component: <KeyboardArrowLeft />},
    {id: "KeyboardArrowRight", component: <KeyboardArrowRight />},
    {id: "KeyboardArrowUp", component: <KeyboardArrowUp />},
    {id: "KeyboardBackspace", component: <KeyboardBackspace />},
    {id: "KeyboardCapslock", component: <KeyboardCapslock />},
    {id: "KeyboardHide", component: <KeyboardHide />},
    {id: "KeyboardReturn", component: <KeyboardReturn />},
    {id: "KeyboardTab", component: <KeyboardTab />},
    {id: "KeyboardVoice", component: <KeyboardVoice />},
    {id: "Kitchen", component: <Kitchen />},
    {id: "Label", component: <Label />},
    {id: "LabelOutline", component: <LabelOutline />},
    {id: "Landscape", component: <Landscape />},
    {id: "Language", component: <Language />},
    {id: "Laptop", component: <Laptop />},
    {id: "LaptopChromebook", component: <LaptopChromebook />},
    {id: "LaptopMac", component: <LaptopMac />},
    {id: "LaptopWindows", component: <LaptopWindows />},
    {id: "LastPage", component: <LastPage />},
    {id: "Launch", component: <Launch />},
    {id: "Layers", component: <Layers />},
    {id: "LayersClear", component: <LayersClear />},
    {id: "LeakAdd", component: <LeakAdd />},
    {id: "LeakRemove", component: <LeakRemove />},
    {id: "Lens", component: <Lens />},
    {id: "LibraryAdd", component: <LibraryAdd />},
    {id: "LibraryBooks", component: <LibraryBooks />},
    {id: "LibraryMusic", component: <LibraryMusic />},
    {id: "LightbulbOutline", component: <LightbulbOutline />},
    {id: "LinearScale", component: <LinearScale />},
    {id: "LineStyle", component: <LineStyle />},
    {id: "LineWeight", component: <LineWeight />},
    {id: "Link", component: <Link />},
    {id: "LinkedCamera", component: <LinkedCamera />},
    {id: "List", component: <List />},
    {id: "LiveHelp", component: <LiveHelp />},
    {id: "LiveTv", component: <LiveTv />},
    {id: "LocalActivity", component: <LocalActivity />},
    {id: "LocalAirport", component: <LocalAirport />},
    {id: "LocalAtm", component: <LocalAtm />},
    {id: "LocalBar", component: <LocalBar />},
    {id: "LocalCafe", component: <LocalCafe />},
    {id: "LocalCarWash", component: <LocalCarWash />},
    {id: "LocalConvenienceStore", component: <LocalConvenienceStore />},
    {id: "LocalDining", component: <LocalDining />},
    {id: "LocalDrink", component: <LocalDrink />},
    {id: "LocalFlorist", component: <LocalFlorist />},
    {id: "LocalGasStation", component: <LocalGasStation />},
    {id: "LocalGroceryStore", component: <LocalGroceryStore />},
    {id: "LocalHospital", component: <LocalHospital />},
    {id: "LocalHotel", component: <LocalHotel />},
    {id: "LocalLaundryService", component: <LocalLaundryService />},
    {id: "LocalLibrary", component: <LocalLibrary />},
    {id: "LocalMall", component: <LocalMall />},
    {id: "LocalMovies", component: <LocalMovies />},
    {id: "LocalOffer", component: <LocalOffer />},
    {id: "LocalParking", component: <LocalParking />},
    {id: "LocalPharmacy", component: <LocalPharmacy />},
    {id: "LocalPhone", component: <LocalPhone />},
    {id: "LocalPizza", component: <LocalPizza />},
    {id: "LocalPlay", component: <LocalPlay />},
    {id: "LocalPostOffice", component: <LocalPostOffice />},
    {id: "LocalPrintshop", component: <LocalPrintshop />},
    {id: "LocalSee", component: <LocalSee />},
    {id: "LocalShipping", component: <LocalShipping />},
    {id: "LocalTaxi", component: <LocalTaxi />},
    {id: "LocationCity", component: <LocationCity />},
    {id: "LocationDisabled", component: <LocationDisabled />},
    {id: "LocationOff", component: <LocationOff />},
    {id: "LocationOn", component: <LocationOn />},
    {id: "LocationSearching", component: <LocationSearching />},
    {id: "Lock", component: <Lock />},
    {id: "LockOpen", component: <LockOpen />},
    {id: "LockOutline", component: <LockOutline />},
    {id: "Looks", component: <Looks />},
    {id: "Looks3", component: <Looks3 />},
    {id: "Looks4", component: <Looks4 />},
    {id: "Looks5", component: <Looks5 />},
    {id: "Looks6", component: <Looks6 />},
    {id: "LooksOne", component: <LooksOne />},
    {id: "LooksTwo", component: <LooksTwo />},
    {id: "Loop", component: <Loop />},
    {id: "Loupe", component: <Loupe />},
    {id: "LowPriority", component: <LowPriority />},
    {id: "Loyalty", component: <Loyalty />},
    {id: "Mail", component: <Mail />},
    {id: "MailOutline", component: <MailOutline />},
    {id: "Map", component: <Map />},
    {id: "Markunread", component: <Markunread />},
    {id: "MarkunreadMailbox", component: <MarkunreadMailbox />},
    {id: "Memory", component: <Memory />},
    {id: "Menu", component: <Menu />},
    {id: "MergeType", component: <MergeType />},
    {id: "Message", component: <Message />},
    {id: "Mic", component: <Mic />},
    {id: "MicNone", component: <MicNone />},
    {id: "MicOff", component: <MicOff />},
    {id: "Mms", component: <Mms />},
    {id: "ModeComment", component: <ModeComment />},
    {id: "ModeEdit", component: <ModeEdit />},
    {id: "MonetizationOn", component: <MonetizationOn />},
    {id: "MoneyOff", component: <MoneyOff />},
    {id: "MonochromePhotos", component: <MonochromePhotos />},
    {id: "Mood", component: <Mood />},
    {id: "MoodBad", component: <MoodBad />},
    {id: "More", component: <More />},
    {id: "MoreHoriz", component: <MoreHoriz />},
    {id: "MoreVert", component: <MoreVert />},
    {id: "Motorcycle", component: <Motorcycle />},
    {id: "Mouse", component: <Mouse />},
    {id: "MoveToInbox", component: <MoveToInbox />},
    {id: "Movie", component: <Movie />},
    {id: "MovieCreation", component: <MovieCreation />},
    {id: "MovieFilter", component: <MovieFilter />},
    {id: "MultilineChart", component: <MultilineChart />},
    {id: "MusicNote", component: <MusicNote />},
    {id: "MusicVideo", component: <MusicVideo />},
    {id: "MyLocation", component: <MyLocation />},
    {id: "Nature", component: <Nature />},
    {id: "NaturePeople", component: <NaturePeople />},
    {id: "NavigateBefore", component: <NavigateBefore />},
    {id: "NavigateNext", component: <NavigateNext />},
    {id: "Navigation", component: <Navigation />},
    {id: "NearMe", component: <NearMe />},
    {id: "NetworkCell", component: <NetworkCell />},
    {id: "NetworkCheck", component: <NetworkCheck />},
    {id: "NetworkLocked", component: <NetworkLocked />},
    {id: "NetworkWifi", component: <NetworkWifi />},
    {id: "NewReleases", component: <NewReleases />},
    {id: "NextWeek", component: <NextWeek />},
    {id: "Nfc", component: <Nfc />},
    {id: "NoEncryption", component: <NoEncryption />},
    {id: "NoSim", component: <NoSim />},
    {id: "Note", component: <Note />},
    {id: "NoteAdd", component: <NoteAdd />},
    {id: "Notifications", component: <Notifications />},
    {id: "NotificationsActive", component: <NotificationsActive />},
    {id: "NotificationsNone", component: <NotificationsNone />},
    {id: "NotificationsOff", component: <NotificationsOff />},
    {id: "NotificationsPaused", component: <NotificationsPaused />},
    {id: "NotInterested", component: <NotInterested />},
    {id: "OfflinePin", component: <OfflinePin />},
    {id: "OndemandVideo", component: <OndemandVideo />},
    {id: "Opacity", component: <Opacity />},
    {id: "OpenInBrowser", component: <OpenInBrowser />},
    {id: "OpenInNew", component: <OpenInNew />},
    {id: "OpenWith", component: <OpenWith />},
    {id: "Pages", component: <Pages />},
    {id: "Pageview", component: <Pageview />},
    {id: "Palette", component: <Palette />},
    {id: "Panorama", component: <Panorama />},
    {id: "PanoramaFishEye", component: <PanoramaFishEye />},
    {id: "PanoramaHorizontal", component: <PanoramaHorizontal />},
    {id: "PanoramaVertical", component: <PanoramaVertical />},
    {id: "PanoramaWideAngle", component: <PanoramaWideAngle />},
    {id: "PanTool", component: <PanTool />},
    {id: "PartyMode", component: <PartyMode />},
    {id: "Pause", component: <Pause />},
    {id: "PauseCircleFilled", component: <PauseCircleFilled />},
    {id: "PauseCircleOutline", component: <PauseCircleOutline />},
    {id: "Payment", component: <Payment />},
    {id: "People", component: <People />},
    {id: "PeopleOutline", component: <PeopleOutline />},
    {id: "PermCameraMic", component: <PermCameraMic />},
    {id: "PermContactCalendar", component: <PermContactCalendar />},
    {id: "PermDataSetting", component: <PermDataSetting />},
    {id: "PermDeviceInformation", component: <PermDeviceInformation />},
    {id: "PermIdentity", component: <PermIdentity />},
    {id: "PermMedia", component: <PermMedia />},
    {id: "PermPhoneMsg", component: <PermPhoneMsg />},
    {id: "PermScanWifi", component: <PermScanWifi />},
    {id: "Person", component: <Person />},
    {id: "PersonAdd", component: <PersonAdd />},
    {id: "PersonalVideo", component: <PersonalVideo />},
    {id: "PersonOutline", component: <PersonOutline />},
    {id: "PersonPin", component: <PersonPin />},
    {id: "PersonPinCircle", component: <PersonPinCircle />},
    {id: "Pets", component: <Pets />},
    {id: "Phone", component: <Phone />},
    {id: "PhoneAndroid", component: <PhoneAndroid />},
    {id: "PhoneBluetoothSpeaker", component: <PhoneBluetoothSpeaker />},
    {id: "PhoneForwarded", component: <PhoneForwarded />},
    {id: "PhoneInTalk", component: <PhoneInTalk />},
    {id: "PhoneIphone", component: <PhoneIphone />},
    {id: "Phonelink", component: <Phonelink />},
    {id: "PhonelinkErase", component: <PhonelinkErase />},
    {id: "PhonelinkLock", component: <PhonelinkLock />},
    {id: "PhonelinkOff", component: <PhonelinkOff />},
    {id: "PhonelinkRing", component: <PhonelinkRing />},
    {id: "PhonelinkSetup", component: <PhonelinkSetup />},
    {id: "PhoneLocked", component: <PhoneLocked />},
    {id: "PhoneMissed", component: <PhoneMissed />},
    {id: "PhonePaused", component: <PhonePaused />},
    {id: "Photo", component: <Photo />},
    {id: "PhotoAlbum", component: <PhotoAlbum />},
    {id: "PhotoCamera", component: <PhotoCamera />},
    {id: "PhotoFilter", component: <PhotoFilter />},
    {id: "PhotoLibrary", component: <PhotoLibrary />},
    {id: "PhotoSizeSelectActual", component: <PhotoSizeSelectActual />},
    {id: "PhotoSizeSelectLarge", component: <PhotoSizeSelectLarge />},
    {id: "PhotoSizeSelectSmall", component: <PhotoSizeSelectSmall />},
    {id: "PictureAsPdf", component: <PictureAsPdf />},
    {id: "PictureInPicture", component: <PictureInPicture />},
    {id: "PictureInPictureAlt", component: <PictureInPictureAlt />},
    {id: "PieChart", component: <PieChart />},
    {id: "PieChartOutlined", component: <PieChartOutlined />},
    {id: "PinDrop", component: <PinDrop />},
    {id: "Place", component: <Place />},
    {id: "PlayArrow", component: <PlayArrow />},
    {id: "PlayCircleFilled", component: <PlayCircleFilled />},
    {id: "PlayCircleOutline", component: <PlayCircleOutline />},
    {id: "PlayForWork", component: <PlayForWork />},
    {id: "PlaylistAdd", component: <PlaylistAdd />},
    {id: "PlaylistAddCheck", component: <PlaylistAddCheck />},
    {id: "PlaylistPlay", component: <PlaylistPlay />},
    {id: "PlusOne", component: <PlusOne />},
    {id: "Poll", component: <Poll />},
    {id: "Polymer", component: <Polymer />},
    {id: "Pool", component: <Pool />},
    {id: "PortableWifiOff", component: <PortableWifiOff />},
    {id: "Portrait", component: <Portrait />},
    {id: "Power", component: <Power />},
    {id: "PowerInput", component: <PowerInput />},
    {id: "PowerSettingsNew", component: <PowerSettingsNew />},
    {id: "PregnantWoman", component: <PregnantWoman />},
    {id: "PresentToAll", component: <PresentToAll />},
    {id: "Print", component: <Print />},
    {id: "PriorityHigh", component: <PriorityHigh />},
    {id: "Public", component: <Public />},
    {id: "Publish", component: <Publish />},
    {id: "QueryBuilder", component: <QueryBuilder />},
    {id: "QuestionAnswer", component: <QuestionAnswer />},
    {id: "Queue", component: <Queue />},
    {id: "QueueMusic", component: <QueueMusic />},
    {id: "QueuePlayNext", component: <QueuePlayNext />},
    {id: "Radio", component: <Radio />},
    {id: "RadioButtonChecked", component: <RadioButtonChecked />},
    {id: "RadioButtonUnchecked", component: <RadioButtonUnchecked />},
    {id: "RateReview", component: <RateReview />},
    {id: "Receipt", component: <Receipt />},
    {id: "RecentActors", component: <RecentActors />},
    {id: "RecordVoiceOver", component: <RecordVoiceOver />},
    {id: "Redeem", component: <Redeem />},
    {id: "Redo", component: <Redo />},
    {id: "Refresh", component: <Refresh />},
    {id: "Remove", component: <Remove />},
    {id: "RemoveCircle", component: <RemoveCircle />},
    {id: "RemoveCircleOutline", component: <RemoveCircleOutline />},
    {id: "RemoveFromQueue", component: <RemoveFromQueue />},
    {id: "RemoveRedEye", component: <RemoveRedEye />},
    {id: "RemoveShoppingCart", component: <RemoveShoppingCart />},
    {id: "Reorder", component: <Reorder />},
    {id: "Repeat", component: <Repeat />},
    {id: "RepeatOne", component: <RepeatOne />},
    {id: "Replay", component: <Replay />},
    {id: "Replay10", component: <Replay10 />},
    {id: "Replay30", component: <Replay30 />},
    {id: "Replay5", component: <Replay5 />},
    {id: "Reply", component: <Reply />},
    {id: "ReplyAll", component: <ReplyAll />},
    {id: "Report", component: <Report />},
    {id: "ReportProblem", component: <ReportProblem />},
    {id: "Restaurant", component: <Restaurant />},
    {id: "RestaurantMenu", component: <RestaurantMenu />},
    {id: "Restore", component: <Restore />},
    {id: "RestorePage", component: <RestorePage />},
    {id: "RingVolume", component: <RingVolume />},
    {id: "Room", component: <Room />},
    {id: "RoomService", component: <RoomService />},
    {id: "Rotate90DegreesCcw", component: <Rotate90DegreesCcw />},
    {id: "RotateLeft", component: <RotateLeft />},
    {id: "RotateRight", component: <RotateRight />},
    {id: "RoundedCorner", component: <RoundedCorner />},
    {id: "Router", component: <Router />},
    {id: "Rowing", component: <Rowing />},
    {id: "RssFeed", component: <RssFeed />},
    {id: "RvHookup", component: <RvHookup />},
    {id: "Satellite", component: <Satellite />},
    {id: "Save", component: <Save />},
    {id: "Scanner", component: <Scanner />},
    {id: "Schedule", component: <Schedule />},
    {id: "School", component: <School />},
    {id: "ScreenLockLandscape", component: <ScreenLockLandscape />},
    {id: "ScreenLockPortrait", component: <ScreenLockPortrait />},
    {id: "ScreenLockRotation", component: <ScreenLockRotation />},
    {id: "ScreenRotation", component: <ScreenRotation />},
    {id: "ScreenShare", component: <ScreenShare />},
    {id: "SdCard", component: <SdCard />},
    {id: "SdStorage", component: <SdStorage />},
    {id: "Search", component: <Search />},
    {id: "Security", component: <Security />},
    {id: "SelectAll", component: <SelectAll />},
    {id: "Send", component: <Send />},
    {id: "SentimentDissatisfied", component: <SentimentDissatisfied />},
    {id: "SentimentNeutral", component: <SentimentNeutral />},
    {id: "SentimentSatisfied", component: <SentimentSatisfied />},
    {id: "SentimentVeryDissatisfied", component: <SentimentVeryDissatisfied />},
    {id: "SentimentVerySatisfied", component: <SentimentVerySatisfied />},
    {id: "Settings", component: <Settings />},
    {id: "SettingsApplications", component: <SettingsApplications />},
    {id: "SettingsBackupRestore", component: <SettingsBackupRestore />},
    {id: "SettingsBluetooth", component: <SettingsBluetooth />},
    {id: "SettingsBrightness", component: <SettingsBrightness />},
    {id: "SettingsCell", component: <SettingsCell />},
    {id: "SettingsEthernet", component: <SettingsEthernet />},
    {id: "SettingsInputAntenna", component: <SettingsInputAntenna />},
    {id: "SettingsInputComponent", component: <SettingsInputComponent />},
    {id: "SettingsInputComposite", component: <SettingsInputComposite />},
    {id: "SettingsInputHdmi", component: <SettingsInputHdmi />},
    {id: "SettingsInputSvideo", component: <SettingsInputSvideo />},
    {id: "SettingsOverscan", component: <SettingsOverscan />},
    {id: "SettingsPhone", component: <SettingsPhone />},
    {id: "SettingsPower", component: <SettingsPower />},
    {id: "SettingsRemote", component: <SettingsRemote />},
    {id: "SettingsSystemDaydream", component: <SettingsSystemDaydream />},
    {id: "SettingsVoice", component: <SettingsVoice />},
    {id: "Share", component: <Share />},
    {id: "Shop", component: <Shop />},
    {id: "ShoppingBasket", component: <ShoppingBasket />},
    {id: "ShoppingCart", component: <ShoppingCart />},
    {id: "ShopTwo", component: <ShopTwo />},
    {id: "ShortText", component: <ShortText />},
    {id: "ShowChart", component: <ShowChart />},
    {id: "Shuffle", component: <Shuffle />},
    {id: "SignalCellular0Bar", component: <SignalCellular0Bar />},
    {id: "SignalCellular1Bar", component: <SignalCellular1Bar />},
    {id: "SignalCellular2Bar", component: <SignalCellular2Bar />},
    {id: "SignalCellular3Bar", component: <SignalCellular3Bar />},
    {id: "SignalCellular4Bar", component: <SignalCellular4Bar />},
    {id: "SignalCellularConnectedNoInternet0Bar", component: <SignalCellularConnectedNoInternet0Bar />},
    {id: "SignalCellularConnectedNoInternet1Bar", component: <SignalCellularConnectedNoInternet1Bar />},
    {id: "SignalCellularConnectedNoInternet2Bar", component: <SignalCellularConnectedNoInternet2Bar />},
    {id: "SignalCellularConnectedNoInternet3Bar", component: <SignalCellularConnectedNoInternet3Bar />},
    {id: "SignalCellularConnectedNoInternet4Bar", component: <SignalCellularConnectedNoInternet4Bar />},
    {id: "SignalCellularNoSim", component: <SignalCellularNoSim />},
    {id: "SignalCellularNull", component: <SignalCellularNull />},
    {id: "SignalCellularOff", component: <SignalCellularOff />},
    {id: "SignalWifi0Bar", component: <SignalWifi0Bar />},
    {id: "SignalWifi1Bar", component: <SignalWifi1Bar />},
    {id: "SignalWifi1BarLock", component: <SignalWifi1BarLock />},
    {id: "SignalWifi2Bar", component: <SignalWifi2Bar />},
    {id: "SignalWifi2BarLock", component: <SignalWifi2BarLock />},
    {id: "SignalWifi3Bar", component: <SignalWifi3Bar />},
    {id: "SignalWifi3BarLock", component: <SignalWifi3BarLock />},
    {id: "SignalWifi4Bar", component: <SignalWifi4Bar />},
    {id: "SignalWifi4BarLock", component: <SignalWifi4BarLock />},
    {id: "SignalWifiOff", component: <SignalWifiOff />},
    {id: "SimCard", component: <SimCard />},
    {id: "SimCardAlert", component: <SimCardAlert />},
    {id: "SkipNext", component: <SkipNext />},
    {id: "SkipPrevious", component: <SkipPrevious />},
    {id: "Slideshow", component: <Slideshow />},
    {id: "SlowMotionVideo", component: <SlowMotionVideo />},
    {id: "Smartphone", component: <Smartphone />},
    {id: "SmokeFree", component: <SmokeFree />},
    {id: "SmokingRooms", component: <SmokingRooms />},
    {id: "Sms", component: <Sms />},
    {id: "SmsFailed", component: <SmsFailed />},
    {id: "Snooze", component: <Snooze />},
    {id: "Sort", component: <Sort />},
    {id: "SortByAlpha", component: <SortByAlpha />},
    {id: "Spa", component: <Spa />},
    {id: "SpaceBar", component: <SpaceBar />},
    {id: "Speaker", component: <Speaker />},
    {id: "SpeakerGroup", component: <SpeakerGroup />},
    {id: "SpeakerNotes", component: <SpeakerNotes />},
    {id: "SpeakerNotesOff", component: <SpeakerNotesOff />},
    {id: "SpeakerPhone", component: <SpeakerPhone />},
    {id: "Spellcheck", component: <Spellcheck />},
    {id: "Star", component: <Star />},
    {id: "StarBorder", component: <StarBorder />},
    {id: "StarHalf", component: <StarHalf />},
    {id: "Stars", component: <Stars />},
    {id: "StayCurrentLandscape", component: <StayCurrentLandscape />},
    {id: "StayCurrentPortrait", component: <StayCurrentPortrait />},
    {id: "StayPrimaryLandscape", component: <StayPrimaryLandscape />},
    {id: "StayPrimaryPortrait", component: <StayPrimaryPortrait />},
    {id: "Stop", component: <Stop />},
    {id: "StopScreenShare", component: <StopScreenShare />},
    {id: "Storage", component: <Storage />},
    {id: "Store", component: <Store />},
    {id: "StoreMallDirectory", component: <StoreMallDirectory />},
    {id: "Straighten", component: <Straighten />},
    {id: "Streetview", component: <Streetview />},
    {id: "StrikethroughS", component: <StrikethroughS />},
    {id: "Style", component: <Style />},
    {id: "SubdirectoryArrowLeft", component: <SubdirectoryArrowLeft />},
    {id: "SubdirectoryArrowRight", component: <SubdirectoryArrowRight />},
    {id: "Subject", component: <Subject />},
    {id: "Subscriptions", component: <Subscriptions />},
    {id: "Subtitles", component: <Subtitles />},
    {id: "Subway", component: <Subway />},
    {id: "SupervisorAccount", component: <SupervisorAccount />},
    {id: "SurroundSound", component: <SurroundSound />},
    {id: "SwapCalls", component: <SwapCalls />},
    {id: "SwapHoriz", component: <SwapHoriz />},
    {id: "SwapVert", component: <SwapVert />},
    {id: "SwapVerticalCircle", component: <SwapVerticalCircle />},
    {id: "SwitchCamera", component: <SwitchCamera />},
    {id: "SwitchVideo", component: <SwitchVideo />},
    {id: "Sync", component: <Sync />},
    {id: "SyncDisabled", component: <SyncDisabled />},
    {id: "SyncProblem", component: <SyncProblem />},
    {id: "SystemUpdate", component: <SystemUpdate />},
    {id: "SystemUpdateAlt", component: <SystemUpdateAlt />},
    {id: "Tab", component: <Tab />},
    {id: "Tablet", component: <Tablet />},
    {id: "TabletAndroid", component: <TabletAndroid />},
    {id: "TabletMac", component: <TabletMac />},
    {id: "TabUnselected", component: <TabUnselected />},
    {id: "TagFaces", component: <TagFaces />},
    {id: "TapAndPlay", component: <TapAndPlay />},
    {id: "Terrain", component: <Terrain />},
    {id: "TextFields", component: <TextFields />},
    {id: "TextFormat", component: <TextFormat />},
    {id: "Textsms", component: <Textsms />},
    {id: "Texture", component: <Texture />},
    {id: "Theaters", component: <Theaters />},
    {id: "ThreeDRotation", component: <ThreeDRotation />},
    {id: "ThumbDown", component: <ThumbDown />},
    {id: "ThumbsUpDown", component: <ThumbsUpDown />},
    {id: "ThumbUp", component: <ThumbUp />},
    {id: "Timelapse", component: <Timelapse />},
    {id: "Timeline", component: <Timeline />},
    {id: "Timer", component: <Timer />},
    {id: "Timer10", component: <Timer10 />},
    {id: "Timer3", component: <Timer3 />},
    {id: "TimerOff", component: <TimerOff />},
    {id: "TimeToLeave", component: <TimeToLeave />},
    {id: "Title", component: <Title />},
    {id: "Toc", component: <Toc />},
    {id: "Today", component: <Today />},
    {id: "Toll", component: <Toll />},
    {id: "Tonality", component: <Tonality />},
    {id: "TouchApp", component: <TouchApp />},
    {id: "Toys", component: <Toys />},
    {id: "TrackChanges", component: <TrackChanges />},
    {id: "Traffic", component: <Traffic />},
    {id: "Train", component: <Train />},
    {id: "Tram", component: <Tram />},
    {id: "TransferWithinAStation", component: <TransferWithinAStation />},
    {id: "Transform", component: <Transform />},
    {id: "Translate", component: <Translate />},
    {id: "TrendingDown", component: <TrendingDown />},
    {id: "TrendingFlat", component: <TrendingFlat />},
    {id: "TrendingUp", component: <TrendingUp />},
    {id: "Tune", component: <Tune />},
    {id: "TurnedIn", component: <TurnedIn />},
    {id: "TurnedInNot", component: <TurnedInNot />},
    {id: "Tv", component: <Tv />},
    {id: "Unarchive", component: <Unarchive />},
    {id: "Undo", component: <Undo />},
    {id: "UnfoldLess", component: <UnfoldLess />},
    {id: "UnfoldMore", component: <UnfoldMore />},
    {id: "Update", component: <Update />},
    {id: "Usb", component: <Usb />},
    {id: "VerifiedUser", component: <VerifiedUser />},
    {id: "VerticalAlignBottom", component: <VerticalAlignBottom />},
    {id: "VerticalAlignCenter", component: <VerticalAlignCenter />},
    {id: "VerticalAlignTop", component: <VerticalAlignTop />},
    {id: "Vibration", component: <Vibration />},
    {id: "VideoCall", component: <VideoCall />},
    {id: "Videocam", component: <Videocam />},
    {id: "VideocamOff", component: <VideocamOff />},
    {id: "VideogameAsset", component: <VideogameAsset />},
    {id: "VideoLabel", component: <VideoLabel />},
    {id: "VideoLibrary", component: <VideoLibrary />},
    {id: "ViewAgenda", component: <ViewAgenda />},
    {id: "ViewArray", component: <ViewArray />},
    {id: "ViewCarousel", component: <ViewCarousel />},
    {id: "ViewColumn", component: <ViewColumn />},
    {id: "ViewComfy", component: <ViewComfy />},
    {id: "ViewCompact", component: <ViewCompact />},
    {id: "ViewDay", component: <ViewDay />},
    {id: "ViewHeadline", component: <ViewHeadline />},
    {id: "ViewList", component: <ViewList />},
    {id: "ViewModule", component: <ViewModule />},
    {id: "ViewQuilt", component: <ViewQuilt />},
    {id: "ViewStream", component: <ViewStream />},
    {id: "ViewWeek", component: <ViewWeek />},
    {id: "Vignette", component: <Vignette />},
    {id: "Visibility", component: <Visibility />},
    {id: "VisibilityOff", component: <VisibilityOff />},
    {id: "VoiceChat", component: <VoiceChat />},
    {id: "Voicemail", component: <Voicemail />},
    {id: "VolumeDown", component: <VolumeDown />},
    {id: "VolumeMute", component: <VolumeMute />},
    {id: "VolumeOff", component: <VolumeOff />},
    {id: "VolumeUp", component: <VolumeUp />},
    {id: "VpnKey", component: <VpnKey />},
    {id: "VpnLock", component: <VpnLock />},
    {id: "Wallpaper", component: <Wallpaper />},
    {id: "Warning", component: <Warning />},
    {id: "Watch", component: <Watch />},
    {id: "WatchLater", component: <WatchLater />},
    {id: "WbAuto", component: <WbAuto />},
    {id: "WbCloudy", component: <WbCloudy />},
    {id: "WbIncandescent", component: <WbIncandescent />},
    {id: "WbIridescent", component: <WbIridescent />},
    {id: "WbSunny", component: <WbSunny />},
    {id: "Wc", component: <Wc />},
    {id: "Web", component: <Web />},
    {id: "WebAsset", component: <WebAsset />},
    {id: "Weekend", component: <Weekend />},
    {id: "Whatshot", component: <Whatshot />},
    {id: "Widgets", component: <Widgets />},
    {id: "Wifi", component: <Wifi />},
    {id: "WifiLock", component: <WifiLock />},
    {id: "WifiTethering", component: <WifiTethering />},
    {id: "Work", component: <Work />},
    {id: "WrapText", component: <WrapText />},
    {id: "YoutubeSearchedFor", component: <YoutubeSearchedFor />},
    {id: "ZoomIn", component: <ZoomIn />},
    {id: "ZoomOut", component: <ZoomOut />},
    {id: "ZoomOutMap", component: <ZoomOutMap />}  */
