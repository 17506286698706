import React from "react";

import MenuItem from "@material-ui/core/MenuItem";

import TextField from "./TextField";

// FIXME: If a menu item with a value of 0 is selected the item doesn't appear to be selected
// https://github.com/mui-org/material-ui/issues/10531
// https://github.com/mui-org/material-ui/issues/8990

//SelectProps={{...rest}}
const Select = ({ options, native, ...rest }) => (
    <TextField
        select //select prop renders a select element
        disabled={rest.disabled}
        isRequired={rest.isRequired}
        {...rest}>
        {native && //native implys optgroup selects
            options.map(({ label, value, optgroup, items }) => {
                if (optgroup) {
                    return (
                        <optgroup label={label}>
                            {items.map(({ iLabel, iValue }) => (
                                <option value={iValue}>{iLabel}</option>
                            ))}
                        </optgroup>
                    );
                } else {
                    return <option value={value}>{label}</option>;
                }
            })}
        {!native &&
            options.map(({ label, value }) => (
                <MenuItem key={value} disabled={rest.disabled} value={value}>
                    {label}
                </MenuItem>
            ))}
    </TextField>
);

export default Select;
