import React, { Component } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Gauge from "react-svg-gauge";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import MapIcon from "@material-ui/icons/Map";
import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ViewHeadline } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import { LineChart, Line, Bar, CartesianGrid, XAxis, YAxis, Tooltip as Tt, Legend, ResponsiveContainer, ComposedChart } from "recharts";

const styles = (theme) => ({
    card: {
        margin: "5px 15px",
        backgroundColor: "#ececec59",
        borderRadius: 9,
        width: 621,
        boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        [theme.breakpoints.up(1400)]: {
            width: "95%",
        },
        [theme.breakpoints.down(1400)]: {
            width: "95%",
        },
    },
    refresh: {
        maxWidth: "77%",
        flexBasis: "76%",
    },

    topBar: {
        width: "137%",
        marginTop: -17,
        marginLeft: -17,
        paddingTop: 9,
        paddingLeft: "7%",
        // backgroundColor: "#d6d6d6bf",

        "& p": {
            width: "95%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },

    },

    topGuageLabel: {
        marginTop: 8,
        textAlign: "center",
        maxWidth: "100%",
        flexBasis: "100%",
    },

    list: {
        "& ul": {
            padding: 0,
        },
    },
});

class SimpleCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingRecords: false,
            data: [],
            seriesdata: [],
            analMenuAnchor: null,
        };
    }
    analyticsSent = [];
    handleMenu = (event) => {
        this.setState({ analMenuAnchor: event.currentTarget });
    };
    handleMenuClose = () => {
        this.setState({ analMenuAnchor: null });
    };
    //called on componentdidmount and everytime a refresh is clicked on
    refresh = (data) => {
        const { ormPageAnalyticsUpdate, pageanalytics } = this.props;

        if ((data && data.length > 0) || typeof data === "string") {
            var sum = 0,
                count = 0;
            var c = [];
            var temp;
            var decimals = null;
            if (typeof data === "string") {
                this.setState({ loadingRecords: false });
            } else if (pageanalytics.chart_type === "Gauge" || pageanalytics.chart_type === "None") {
                let has_geometry = false;
                data.forEach((d) => {
                    let dontSkip = true
                    d.recordfields.forEach((rf) => {
                        if (rf.field_id === parseInt(pageanalytics.field) && rf.userinput && dontSkip) {
                            //verify not empty string, if include a comma get rid of for calculation
                            if (rf.userinput.includes(",")) {
                                c.push(rf.userinput.replace(",", ""));
                            } else {
                                c.push(rf.userinput);
                            }
                            if (rf && rf.decimals && !decimals) decimals = rf.decimals;
                            dontSkip = false
                        }
                    });
                    if (!has_geometry && d.geometry) has_geometry = true; //set into model if we find one result on this search with a geometry
                });

                if (pageanalytics.stat_type !== "Min" || pageanalytics.stat_type !== "Max")
                    c.forEach((c) => {
                        sum += parseFloat(c);
                        count += 1;
                    });

                if (pageanalytics.stat_type === "Sum") temp = sum.toFixed(decimals);
                else if (pageanalytics.stat_type === "Count") temp = count;
                else if (pageanalytics.stat_type === "Average") temp = (sum / count).toFixed(decimals);
                else if (pageanalytics.stat_type === "Min") temp = Math.min.apply(null, c);
                else if (pageanalytics.stat_type === "Max") temp = Math.max.apply(null, c);

                let d = new Date();
                ormPageAnalyticsUpdate({ id: pageanalytics.id, field_value: temp, lastupdated: d.toString(), geo_in_results: has_geometry });
                this.setState({ loadingRecords: false });
            } else {
                //time series case
                data.forEach((d) => {
                    c.push(d.recordfields);
                });
                this.timeSeries(c, data);
            }
        } else {
            if (pageanalytics /*&& !pageanalytics.chart */&& data && data.length === 0) {
                //data came back as empty array so 0 records we set
                let d = new Date();
                ormPageAnalyticsUpdate({ id: pageanalytics.id, field_value: 0, lastupdated: d.toString() });
            }
            this.setState({ loadingRecords: false });
        }
    };

    componentWillUnmount() {
        if (this.props.history.location.pathname.includes("dashboard") === true) {
            //only stop requests if moving to another module
            this.analyticsSent.forEach(function (a) {
                a.abort();
            });
        }
    }

    componentDidMount() {
        const { pageanalytics, page, authState } = this.props;

        this.setState({ loadingRecords: true, thisPage: page[0].id });

        let today = new Date();
        let hasLastUpdate = new Date(pageanalytics.lastupdated);
        let isItToday = today.toLocaleDateString() === hasLastUpdate.toLocaleDateString() ? true : false;

        if (/*pageanalytics.chart ||*/ !isItToday) {
            const controller = new AbortController(); //to keep track of requests and cancel IF they move to another module, table etc dont cancel
            const signal = controller.signal;
            this.analyticsSent.push(controller);
            try {
                const res = fetch(`page/${page[0].id}/pageanalytics/${pageanalytics.advancedsearch}/${pageanalytics.field}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + authState.user.auth_token,
                    },
                    signal: signal, //assign signal to contoller
                }).then((e) => {
                    if (e.status === 200) {
                        e.json().then((reply) => {
                            this.refresh(reply.message);
                        });
                    } else {
                        this.setState({ loadingRecords: false });
                    }
                });
                console.log(res);
            } catch {
                this.setState({ loadingRecords: false });
            }
        } else if (pageanalytics.field2_value) {
            this.setState({ loadingRecords: false, seriesdata: pageanalytics.field2_value });
        } else {
            this.refresh(pageanalytics.field_value);
        }
    }

    editHeaderFormat = (hasSectionGeometry) => {
        const { advsearch, pageanalytics } = this.props;
        const { loadingRecords } = this.state;
        return (
            <>
                <Grid item xs={1} style={{ marginLeft: "-4%"}}>
                    {loadingRecords && <CircularProgress size={19} style={{ marginLeft: -25, position: "fixed", marginTop: 2, color: "#fff" }} />}
                    <Tooltip title={advsearch.name + " Menu"} onClick={this.handleMenu}>
                        <MoreHorizIcon style={{color: pageanalytics.card_header_txtcolor }}/>
                    </Tooltip>
                </Grid>
            </>
        );
    };

    //here we handle the calculation for yr, month, week etc
    // props (incrementBy, currentMthYrWk, prevMthYrWk) = Month, Week, Year, Daily, Quarter etc.
    timeSeriesCalculate = (key, v, valueIndex, incrementBy, currentMthYrWk, prevMthYrWk, counter, lastRecord) => {
        const { pageanalytics } = this.props;

        switch (pageanalytics.stat_type) {
            case "Sum":
            case "Average":
                // this is the final step to do the calculation on the yr, mth, etc
                if (prevMthYrWk && prevMthYrWk !== currentMthYrWk) {
                    if (prevMthYrWk === true)
                        incrementBy.push({ date: currentMthYrWk, value: v[valueIndex].userinput === "" ? [0] : [v[valueIndex].userinput] }); // add this new object entry for last case senerio so we can find in array
                    let i = incrementBy.find((key) => {
                        if (!lastRecord) {
                            return key.date === prevMthYrWk ? key : null;
                        } else {
                            return key.date === currentMthYrWk ? key : null;
                        }
                    });
                    //check for average or sum
                    return pageanalytics.stat_type === "Average" ? (i.value = parseFloat(i.value / counter)) : (i.value = parseFloat(i.value));
                }
                //push first (month, week, yr) case and every new (month, week,yr) addition
                if (incrementBy) {
                    return incrementBy.push({ date: currentMthYrWk, value: v[valueIndex].userinput === "" ? 0 : v[valueIndex].userinput });
                } //append to previously stored month, week, yr
                return (key.value = (parseFloat(key.value) + parseFloat(v[valueIndex].userinput)).toFixed(2));

            case "Count":
                //push to array for incrementBy type (mth, yr, week)
                if (incrementBy) {
                    return incrementBy.push({ date: currentMthYrWk, value: 1 });
                } else {
                    return (key.value = key.value + 1);
                }
            case "Min":
            case "Max":
                if (prevMthYrWk && prevMthYrWk !== currentMthYrWk) {
                    if (prevMthYrWk === true)
                        incrementBy.push({ date: currentMthYrWk, value: v[valueIndex].userinput === "" ? [0] : [v[valueIndex].userinput] }); // add first for last case so we can find in array
                    let i = incrementBy.find((key) => {
                        if (!lastRecord) {
                            return key.date === prevMthYrWk ? key : null;
                        } else {
                            return key.date === currentMthYrWk ? key : null;
                        }
                    });

                    if (i.value.length > 1) {
                        //verify array
                        return pageanalytics.stat_type === "Min"
                            ? (i.value = Math.min.apply(null, i.value))
                            : (i.value = Math.max.apply(null, i.value));
                    } else {
                        //only one value(non-array)
                        return (i.value = parseFloat(i.value));
                    }
                }
                //push first (month, week, yr) case and every new (month, week,yr) addition
                if (incrementBy) {
                    return incrementBy.push({ date: currentMthYrWk, value: v[valueIndex].userinput === "" ? [0] : [v[valueIndex].userinput] });
                }
                //append to previously stored month, week, yr
                return key.value.push(v[valueIndex].userinput);

            default:
                break;
        }
    };
    timeSeries = (c, data) => {
        const { ormPageAnalyticsUpdate, pageanalytics } = this.props;
        var temp = [];
        var dateIndex = null;
        var valueIndex = null;
        var statType = pageanalytics.stat_type;

        //we are going to remove any arrays instances without data in them
        //not the best but will keep the sort function from crashing when we dont have a date being returned
        c = c.filter((d) => {
            return d.length === 2 ? d : null;
        });

        //sort the dates arrays by lowest first
        c.sort((a, b) => {
            if (a[0].type === "Date") {
                let aInput = Date.parse(a[0].userinput);
                let bInput = Date.parse(b[0].userinput);
                dateIndex = 0;
                valueIndex = 1;
                return bInput < aInput ? 1 : -1;
            } else {
                let aInput = Date.parse(a[1].userinput);
                let bInput = Date.parse(b[1].userinput);
                dateIndex = 1;
                valueIndex = 0;
                return bInput < aInput ? 1 : -1;
            }
        });

        var months = [],
            years = [],
            quarters = [],
            weeks = [],
            days = [];
        var prevDay,
            prevMth,
            prevYr,
            prevWk,
            prevQtr,
            currDay = null;
        var dummy = null;
        var counter = 0;
        //now groupby the time_increment
        c.forEach((v, i) => {
            if (pageanalytics.time_increment === "Daily") {
                if (
                    v.length === 2 &&
                    (v[valueIndex].type === "Numeric" || v[valueIndex].type === "Currency" || v[valueIndex].type === "Calculated")
                ) {
                    //case for date and numeric value & prior month matches this month
                    currDay = parseInt(v[dateIndex].userinput.slice(3, 5));
                    if (currDay === prevDay) {
                        days.find((key) => {
                            return key.date === currDay
                                ? this.timeSeriesCalculate(key, v, valueIndex) //calculate based on sum, count, avg, min/max
                                : null;
                        });
                    } else {
                        //chk if we are on another month if so calculate prev mth
                        if ((statType === "Average" || statType === "Min" || statType === "Max") && currDay !== prevDay && i > 0) {
                            this.timeSeriesCalculate(dummy, v, valueIndex, days, currDay, prevDay, counter, false);
                            counter = 0;
                        }
                        //this is to check for the lastrecord at the end of the array & send a dummy prop to trigger the calculation
                        let lastRecord = c.length - 1 === i ? (true, (dummy = true)) : false;
                        //add first month or a new month not in array or calculatae the lastrecord
                        this.timeSeriesCalculate(dummy, v, valueIndex, days, currDay, dummy, dummy, lastRecord);
                    }

                    prevDay = currDay;
                    counter += 1;
                } else {
                    // date only count for adding daily only to a bar or chart graph
                    let dateIndex = v.findIndex((f) => f.type === "Date");
                    //incase  a user selected a field in the timeseries section in advancedsearchsettings.js we will chk to get proper index
                    currDay = parseInt(v[dateIndex].userinput.slice(3, 5));
                    if (days.length > 0) {
                        let hasKey = Object.values(days).find((key) => {
                            return key.date === currDay;
                        });
                        if (hasKey) {
                            days.find((key) => {
                                return key.date === hasKey.date ? (key.value = key.value + 1) : null;
                            });
                        } else {
                            days.push({ date: currDay, value: 1 });
                        }
                    } else {
                        days.push({ date: currDay, value: 1 });
                    }
                }
            } else if (pageanalytics.time_increment === "Weekly") {
                //function to get week
                // eslint-disable-next-line
                Date.prototype.getWeek = function () {
                    var date = new Date(this.getTime());
                    date.setHours(0, 0, 0, 0);
                    // Thursday in current week decides the year.
                    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
                    // January 4 is always in week 1.
                    var week1 = new Date(date.getFullYear(), 0, 4);
                    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
                    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
                };

                if (
                    v.length === 2 &&
                    (v[valueIndex].type === "Numeric" || v[valueIndex].type === "Currency" || v[valueIndex].type === "Calculated")
                ) {
                    //case for date and numeric value & prior month matches this month
                    let d = new Date(v[dateIndex].userinput);
                    let currWk = d.getWeek();
                    if (currWk === prevWk) {
                        weeks.find((key) => {
                            return key.date === currWk
                                ? this.timeSeriesCalculate(key, v, valueIndex) //calculate based on sum, count, avg, min/max
                                : null;
                        });
                    } else {
                        //chk if we are on another week if so calculate prev mth
                        if ((statType === "Average" || statType === "Min" || statType === "Max") && currWk !== prevWk && i > 0) {
                            this.timeSeriesCalculate(dummy, v, valueIndex, weeks, currWk, prevWk, counter, false);
                            counter = 0;
                        }
                        //this is to check for the lastrecord at the end of the array & send a dummy prop to trigger the calculation
                        let lastRecord = c.length - 1 === i ? (true, (dummy = true)) : false;
                        //add first week or a new week not in array or calculatae the lastrecord
                        this.timeSeriesCalculate(dummy, v, valueIndex, weeks, currWk, dummy, dummy, lastRecord);
                    }

                    prevWk = currWk;
                    counter += 1;
                } else {
                    // date only count for adding wk only to a bar or chart graph
                    //incase  a user selected a field in the timeseries section in advancedsearchsettings.js we will chk to get proper index
                    let dateIndex = v.findIndex((f) => f.type === "Date");
                    let d = new Date(v[dateIndex].userinput);
                    let currWk = d.getWeek();

                    if (weeks.length > 0) {
                        let hasKey = Object.values(weeks).find((key) => {
                            return key.date === currWk;
                        });
                        if (hasKey) {
                            weeks.find((key) => {
                                return key.date === hasKey.date ? (key.value = key.value + 1) : null;
                            });
                        } else {
                            weeks.push({ date: currWk, value: 1 });
                        }
                    } else {
                        weeks.push({ date: currWk, value: 1 });
                    }
                }
            } else if (pageanalytics.time_increment === "Monthly") {
                if (
                    v.length === 2 &&
                    (v[valueIndex].type === "Numeric" || v[valueIndex].type === "Currency" || v[valueIndex].type === "Calculated")
                ) {
                    //case for date and numeric value & prior month matches this month
                    let currMth = parseInt(v[dateIndex].userinput.slice(0, 2));
                    if (currMth === prevMth) {
                        months.find((key) => {
                            return key.date === currMth
                                ? this.timeSeriesCalculate(key, v, valueIndex) //calculate based on sum, count, avg, min/max
                                : null;
                        });
                    } else {
                        //chk if we are on another month if so calculate prev mth
                        if ((statType === "Average" || statType === "Min" || statType === "Max") && currMth !== prevMth && i > 0) {
                            this.timeSeriesCalculate(dummy, v, valueIndex, months, currMth, prevMth, counter, false);
                            counter = 0;
                        }
                        //this is to check for the lastrecord at the end of the array & send a dummy prop to trigger the calculation
                        let lastRecord = c.length - 1 === i ? (true, (dummy = true)) : false;
                        //add first month or a new month not in array or calculatae the lastrecord
                        this.timeSeriesCalculate(dummy, v, valueIndex, months, currMth, dummy, dummy, lastRecord);
                    }

                    prevMth = currMth;
                    counter += 1;
                } else {
                    // date only count for adding month only to a bar or chart graph
                    let currMth;
                    let dateIndex = v.findIndex((f) => f.type === "Date");
                    //incase  a user selected a field in the timeseries section in advancedsearchsettings.js we will chk to get proper index
                    currMth = parseInt(v[dateIndex].userinput.slice(0, 2));
                    if (months.length > 0) {
                        let hasKey = Object.values(months).find((key) => {
                            return key.date === currMth;
                        });
                        if (hasKey) {
                            months.find((key) => {
                                return key.date === hasKey.date ? (key.value = key.value + 1) : null;
                            });
                        } else {
                            months.push({ date: currMth, value: 1 });
                        }
                    } else {
                        months.push({ date: currMth, value: 1 });
                    }
                }
            } else if (pageanalytics.time_increment === "Quarterly") {
                if (
                    v.length === 2 &&
                    (v[valueIndex].type === "Numeric" || v[valueIndex].type === "Currency" || v[valueIndex].type === "Calculated")
                ) {
                    let currQtr;
                    //case for date and numeric value & prior yr matches this yr
                    let currMth = parseInt(v[dateIndex].userinput.slice(0, 2));
                    if (currMth < 4) currQtr = 1;
                    else if (currMth < 7) currQtr = 2;
                    else if (currMth < 10) currQtr = 3;
                    else if (currMth < 13) currQtr = 4;

                    if (currQtr === prevQtr) {
                        quarters.find((key) => {
                            return key.date === currQtr
                                ? this.timeSeriesCalculate(key, v, valueIndex) //calculate based on sum, count, avg, min/max
                                : null;
                        });
                    } else {
                        //chk if we are on another qtr if so calculate prev qtr
                        if ((statType === "Average" || statType === "Min" || statType === "Max") && currQtr !== prevQtr && i > 0) {
                            this.timeSeriesCalculate(dummy, v, valueIndex, quarters, currQtr, prevQtr, counter, false);
                            counter = 0;
                        }
                        //this is to check for the lastrecord at the end of the array & send a dummy prop to trigger the calculation
                        let lastRecord = c.length - 1 === i ? (true, (dummy = true)) : false;
                        //add first yr or a new yr not in array or calculatae the lastrecord
                        this.timeSeriesCalculate(dummy, v, valueIndex, quarters, currQtr, dummy, dummy, lastRecord);
                    }
                    prevQtr = currQtr;
                    counter += 1;
                } else {
                    // date only count for adding qtr only to a bar or chart graph
                    let currQtr;
                    let dateIndex = v.findIndex((f) => f.type === "Date");
                    //incase  a user selected a field in the timeseries section in advancedsearchsettings.js we will chk to get proper index

                    let currMth = parseInt(v[dateIndex].userinput.slice(0, 2));
                    if (currMth < 4) currQtr = 1;
                    else if (currMth < 7) currQtr = 2;
                    else if (currMth < 10) currQtr = 3;
                    else if (currMth < 13) currQtr = 4;

                    if (quarters.length > 0) {
                        let hasKey = Object.values(quarters).find((key) => {
                            return key.date === currQtr;
                        });
                        if (hasKey) {
                            quarters.find((key) => {
                                return key.date === hasKey.date ? (key.value = key.value + 1) : null;
                            });
                        } else {
                            quarters.push({ date: currQtr, value: 1 });
                        }
                    } else {
                        quarters.push({ date: currQtr, value: 1 });
                    }
                }
            } else if (pageanalytics.time_increment === "Annually") {
                if (
                    v.length === 2 &&
                    (v[valueIndex].type === "Numeric" || v[valueIndex].type === "Currency" || v[valueIndex].type === "Calculated")
                ) {
                    //case for date and numeric value & prior yr matches this yr
                    let currYr = parseInt(v[dateIndex].userinput.slice(6, 11));
                    if (currYr === prevYr) {
                        months.find((key) => {
                            return key.date === currYr
                                ? this.timeSeriesCalculate(key, v, valueIndex) //calculate based on sum, count, avg, min/max
                                : null;
                        });
                    } else {
                        //chk if we are on another yr if so calculate prev yr
                        if ((statType === "Average" || statType === "Min" || statType === "Max") && currYr !== prevYr && i > 0) {
                            this.timeSeriesCalculate(dummy, v, valueIndex, months, currYr, prevYr, counter, false);
                            counter = 0;
                        }
                        //this is to check for the lastrecord at the end of the array & send a dummy prop to trigger the calculation
                        let lastRecord = c.length - 1 === i ? (true, (dummy = true)) : false;
                        //add first yr or a new yr not in array or calculatae the lastrecord
                        this.timeSeriesCalculate(dummy, v, valueIndex, months, currYr, dummy, dummy, lastRecord);
                    }

                    prevYr = currYr;
                    counter += 1;
                } else {
                    // date only count for adding month only to a bar or chart graph
                    let currYr;
                    let dateIndex = v.findIndex((f) => f.type === "Date");
                    //incase  a user selected a field in the timeseries section in advancedsearchsettings.js we will chk to get proper index
                    currYr = parseInt(v[dateIndex].userinput.slice(6, 11));
                    if (years.length > 0) {
                        let hasKey = Object.values(years).find((key) => {
                            return key.date === currYr;
                        });
                        if (hasKey) {
                            years.find((key) => {
                                return key.date === hasKey.date ? (key.value = key.value + 1) : null;
                            });
                        } else {
                            years.push({ date: currYr, value: 1 });
                        }
                    } else {
                        years.push({ date: currYr, value: 1 });
                    }
                }
            }
        });
        if (months.length > 0) temp = months;
        else if (years.length > 0) temp = years;
        else if (quarters.length > 0) temp = quarters;
        else if (weeks.length > 0) temp = weeks;
        else if (days.length > 0) temp = days;

        //here we will add series inbetween missing weeks, months, days, years, etc for the line to be a timeline. ex. 2018, 2020 values were in list we will add 2019 with 0
        //we will also check for 'goal_value' and add it if its set > c0
        var prevDate = "";
        var newTemp = [];
        temp.forEach((v, i) => {
            if (pageanalytics.goal_value !== 0) v.goal = pageanalytics.goal_value;
            if (i === 0) {
                prevDate = v.date + 1;
                newTemp.push(v);
            } else {
                //reset prevDate when we go over months, weeks, quarters,this occurs when between years
                if (prevDate > v.date && (weeks.length > 0 || quarters.length > 0 || months.length > 0 || days.length > 0)) {
                    //we have to add more weeks up to 52 as next weekdate is lower (next yr)
                    if (weeks.length > 0) {
                        while (prevDate <= 52) {
                            if (pageanalytics.goal_value !== 0) {
                                //set goal value
                                newTemp.push({ date: prevDate, value: 0, goal: pageanalytics.goal_value });
                            } else newTemp.push({ date: prevDate, value: 0 }); //not a goalvalue set
                            prevDate += 1;
                        }
                    } else if (months.length > 0) {
                        //add additional months to 12 before going to next yr
                        while (prevDate <= 12) {
                            if (pageanalytics.goal_value !== 0) {
                                //set goal value
                                newTemp.push({ date: prevDate, value: 0, goal: pageanalytics.goal_value });
                            } else newTemp.push({ date: prevDate, value: 0 }); //not a goalvalue set

                            prevDate += 1;
                        }
                    }
                    prevDate = 1;
                }

                //add inbetween date values or actual value for all that are upto the actual date value we are on (ex week 1,2,3 up to week 4)
                while (prevDate <= v.date) {
                    if (prevDate === v.date) {
                        newTemp.push(v);
                    } else {
                        if (pageanalytics.goal_value !== 0) {
                            //set goal value
                            newTemp.push({ date: prevDate, value: 0, goal: pageanalytics.goal_value });
                        } else newTemp.push({ date: prevDate, value: 0 }); //not a goalvalue set
                    }
                    prevDate += 1;
                }
            }
        });
        //if cumulative we will add prior date value to next date value
        if (pageanalytics.cumulative) {
            var prevVal = 0;
            newTemp.forEach((v, i) => {
                if (i === 0) {
                    prevVal = parseFloat(v.value);
                } else if (i < newTemp.length) {
                    v.value = prevVal += parseFloat(v.value); //neeed to assign to prevVal for next iteration
                    v.value = parseFloat(v.value);
                }
            });
        }
        let has_geometry = false;
        data.forEach((d) => {
            if (!has_geometry && d.geometry) has_geometry = true;
        });
        let d = new Date();
        ormPageAnalyticsUpdate({ id: pageanalytics.id, field2_value: newTemp, lastupdated: d.toString(), geo_in_results: has_geometry });
        this.setState({ loadingRecords: false, seriesdata: newTemp });
    }; 

    render() {
        const { pageanalytics, advsearch, classes, sections, history, page, authState } = this.props;
        const { loadingRecords, seriesdata, analMenuAnchor } = this.state;
        const menuOpen = Boolean(analMenuAnchor);

        //convert iso8601 to a new date to display properly, mth is 0-11 so need to add one
        let d = new Date(pageanalytics.lastupdated);
        var convertedDateTime = d.getUTCMonth() + 1 + "-" + d.getUTCDate() + "-" + d.getUTCFullYear() + " at " + d.toLocaleTimeString();
        var date = d.toLocaleTimeString();

        var hasSectionGeometry = false;
        sections.forEach((s) => {
            if (s.has_geometry) {
                if (s.parent || s.id === advsearch.section) {
                    hasSectionGeometry = true;
                } else return null;
            } else return null;
        });

        return (
            <Card className={classes.card} variant="outlined">
                <CardContent>
                    <Grid container className={classes.topBar} style={{backgroundColor: pageanalytics.card_header_bgcolor}}>
                        <Grid item xs={12} className={classes.refresh} >
                            <Tooltip title={pageanalytics.card_label + ". Updated " + convertedDateTime}>
                                <Typography style={{color: pageanalytics.card_header_txtcolor}} gutterBottom>Search: {advsearch.name}</Typography>
                            </Tooltip>
                        </Grid>
                        {this.editHeaderFormat(hasSectionGeometry)}
                    </Grid>
                    {pageanalytics.chart_type === "None" && pageanalytics.chart && (
                        <Grid container style={{ marginBottom: 54 }}>
                            <Grid item xs={hasSectionGeometry ? 9 : 10}>
                                <Tooltip title={pageanalytics.card_label}>
                                    <Typography gutterBottom style={{ marginLeft: 9, color: "red", fontSize: "medium", flexBasis: "78%" }}>
                                        A 'chart type' in 'Module Analytic'settings needs to be selected to display properly. Otherwise deselect
                                        'Include Chart'.
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )}
                    {/* GUAGE with label */}
                    {pageanalytics.chart_type === "Gauge" && parseInt(pageanalytics.field_value) >= 0 && (
                        <Grid container>
                            {/* Show label ontop of gauage when the screen is smaller */}
                            <Grid item xs={12} className={classes.topGuageLabel}>
                                <Typography variant="h5" component="h2">
                                    {pageanalytics.card_label}
                                </Typography>
                            </Grid>
                            {/* Center gauage when the screen is smaller */}
                            <Grid item xs={12} style={{marginTop:-60, textAlign:"center"}}>
                                <Gauge
                                    valueLabelStyle={{
                                        fill: pageanalytics.stat_color ? pageanalytics.stat_color : "#000",
                                        fontSize: pageanalytics.field_value.length > 6 ? 18 : 25,
                                    }}
                                    color={pageanalytics.progress_color ? pageanalytics.progress_color : "#000"}
                                    backgroundColor={"#d6d6d6bf"}
                                    value={pageanalytics.field_value ?  parseInt(pageanalytics.field_value) : 0}
                                    width={175}
                                    height={175}
                                    max={pageanalytics.goal_value}//pageanalytics.field_type === "Currency"? "$" + pageanalytics.goal_value: pageanalytics.goal_value}
                                    label=''
                                    valueFormatter={()=>pageanalytics.field_type === "Currency"? `$${parseInt(pageanalytics.field_value) }`:parseInt(pageanalytics.field_value)}
                                />
                          
                            </Grid>   
                      
                            <Grid item xs={12} style={{ width: "100%", textAlign: "right", marginBottom: -150 }}>
                                <Tooltip title={convertedDateTime}>
                                    <Typography variant="body2" component="p">
                                        {!loadingRecords && date ? `Updated ${date}` : "Loading..."}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )}
                    {pageanalytics.chart_type === "Time Series" && (
                        <Grid container style={{ marginBottom: 6, maxHeight: 256, paddingBottom: 0 }}>
                            {pageanalytics.time_series_type === "Line" && (
                                <Grid item xs={12} lg={12} style={{ marginTop: "2%", overflow: "hidden", maxWidth: 350 }}>
                                    <ResponsiveContainer width="100%" height={140}>
                                        <LineChart width={"100%"} height={140} data={seriesdata} margin={{ top: 5, right: 20, bottom: 5, left: -10 }}>
                                            <Line
                                                type="monotone"
                                                dataKey="value"
                                                stroke={pageanalytics.progress_color ? pageanalytics.progress_color : "#000"}
                                            />
                                            {pageanalytics.goal_value !== 0 && <Line type="monotone" dataKey="goal" stroke={"#FFA500"} />}
                                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                            <XAxis
                                                dataKey="date"
                                                label={{
                                                    value: pageanalytics.time_increment,
                                                    position: "insideBottomRight",
                                                    offset: -16,
                                                }}
                                            />
                                            {pageanalytics.goal_value !== 0 && <XAxis dataKey="goal" />}
                                            <Legend />
                                            <YAxis />
                                            <Tt />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Grid>
                            )}
                            {pageanalytics.time_series_type === "Bar" && (
                                <Grid item xs={12} lg={12} style={{ marginTop: "2%", overflow: "hidden", maxWidth: 350 }}>
                                    <ResponsiveContainer width="100%" height={140}>
                                        <ComposedChart
                                            width={"100%"}
                                            height={140}
                                            data={seriesdata}
                                            margin={{
                                                top: 5,
                                                right: 20,
                                                bottom: 5,
                                                left: -10,
                                            }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Bar dataKey="value" fill={pageanalytics.progress_color ? pageanalytics.progress_color : "#000"} />
                                            {pageanalytics.goal_value !== 0 && <Line type="monotone" dataKey="goal" stroke={"#FFA500"} />}
                                            <XAxis
                                                dataKey="date"
                                                label={{
                                                    value: pageanalytics.time_increment,
                                                    position: "insideBottomRight",
                                                    offset: -16,
                                                }}
                                            />
                                            {pageanalytics.goal_value !== 0 && <XAxis dataKey="goal" />}
                                            <YAxis />
                                            <Tt />
                                            <Legend />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {/* value and label on each line by self as lenght of value is larger */}
                    {/* updated section only for 'non-guages' as the styling is different for the dynamic snap*/}
                    {!pageanalytics.chart && (
                        <Grid container style={{ height: 120 }}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    style={{ textAlign: "center", paddingTop: 8 }}>
                                    {pageanalytics.card_label}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip title="Click on value to view table">
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        style={{
                                            color: pageanalytics.stat_color ? pageanalytics.stat_color : "#000",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            display: "table",
                                            marginRight: "auto",
                                            marginLeft: "auto"
                                        }}
                                        onClick={() => {
                                            history.push(`/page/${page[0].id}/advancedsearch/${advsearch.id}&=load`);
                                        }}>
                                        {pageanalytics.field_value ? pageanalytics.field_type === "Currency"? "$" + parseInt(pageanalytics.field_value).toLocaleString(): parseFloat(pageanalytics.field_value).toLocaleString() : 0}
                                    </Typography>
                                </Tooltip>
                            </Grid>

                            <Grid item xs={12} style={{ width: "100%", textAlign: "right", marginBottom: -150 }}>
                                <Tooltip title={convertedDateTime}>
                                    <Typography variant="body2" component="p">
                                        {!loadingRecords && date ? `Updated ${date}` : "Loading..."}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )}{" "}
                    <Menu
                        id="analytic"
                        anchorEl={analMenuAnchor}
                        classes={{
                            paper: classes.list,
                        }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={menuOpen}
                        onClose={this.handleMenuClose}>
                        <ListItem
                            button
                            onClick={() => {
                                this.setState({ loadingRecords: true });
                                this.handleMenuClose();
                                const controller = new AbortController();
                                const signal = controller.signal;
                                this.analyticsSent.push(controller);
                                try {
                                    const res = fetch(`page/${page[0].id}/pageanalytics/${pageanalytics.advancedsearch}/${pageanalytics.field}`, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            Authorization: "Token " + authState.user.auth_token,
                                        },
                                        signal: signal,
                                    }).then((e) => {
                                        if (e.status === 200) {
                                            e.json().then((reply) => {
                                                this.refresh(reply.message);
                                            });
                                        } else {
                                            this.setState({ loadingRecords: false });
                                        }
                                    });
                                    console.log(res);
                                } catch {
                                    this.setState({ loadingRecords: false });
                                }
                            }}
                            style={{ borderBottom: "1px solid black" }}>
                            <ListItemIcon>
                                <RefreshIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Refresh Analytic"} />
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => history.push(`/page/${page[0].id}/advancedsearch/${advsearch.id}${"&=load"}`)}
                            style={{ borderBottom: "1px solid black" }}>
                            <ListItemIcon>
                                <ViewHeadline />
                            </ListItemIcon>
                            <ListItemText primary={"View in Table"} />
                        </ListItem>
                        {advsearch.geometriesonmap && (
                            <ListItem
                                button
                                onClick={() =>
                                    history.push("/map/" + page[0].id + "/section/" + advsearch.section + "/advancedsearch/" + advsearch.id)
                                }>
                                <ListItemIcon>
                                    <MapIcon />
                                </ListItemIcon>
                                <ListItemText primary={"View on Map"} />
                            </ListItem>
                        )}
                    </Menu>
                </CardContent>
            </Card>
        );
    }
}

SimpleCard.displayName = "SimpleCard";
export default withStyles(styles)(withRouter(SimpleCard));
