import orm, { Model } from "../common/orm";
import { fk } from "redux-orm";

// This file contains the database models
// They tie directly with the django models and redux-orm
// Feel free to split out the models into however many models.js you would like
// This "page" folder was originally supposed to be for anything related to the actual pages

export class Page extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Page";
    }
}
orm.register(Page);

export class ModuleNotes extends Model {
    static get fields() {
        return {
            page: fk("Page", "modulenotes"), //first parameter is FK table ie. page, 2nd param is used
        }; //left side to tie in the name of the queryset ex: page.sections
    }
    static get modelName() {
        return "ModuleNotes";
    }
}
orm.register(ModuleNotes);

export class QuickSearchField extends Model {
    static get fields() {
        return {
            page: fk("Page", "quicksearchfield"), //first parameter is FK table ie. page, 2nd param is used
        }; //left side to tie in the name of the queryset ex: page.quicksearchfield
    }
    static get modelName() {
        return "QuickSearchField";
    }
}
orm.register(QuickSearchField);

export class Section extends Model {
    static get fields() {
        return {
            page: fk("Page", "sections"), //first parameter is FK table ie. page, 2nd param is used
        }; //left side to tie in the name of the queryset ex: page.sections
    }
    static get modelName() {
        return "Section";
    }
}
orm.register(Section);

export class Form extends Model {
    //renamed to FormModel to remove same name issue from react-form
    static get fields() {
        return {
            section: fk("Section", "forms"), //section id as FK
        }; // calling section.forms in ORM links them ex: in map(section=> forms:section.forms etc..)
    }
    static get modelName() {
        return "Form";
    }
}
orm.register(Form);

// filtering of records model
export class Filter extends Model {
    static get fields() {
        return {
            section: fk("Section", "fields"), //section id as FK
        }; // calling section.fields in ORM links them ex: in map(section=> fields:section.fields etc..)
    }
    static get modelName() {
        return "Filter";
    }
}
orm.register(Filter);

export class Group extends Model {
    static get fields() {
        return {
            form: fk("Form", "groups"),
        };
    }
    static get modelName() {
        return "Group";
    }
}
orm.register(Group);

export class Field extends Model {
    static get fields() {
        return {
            group: fk("Group", "fields"), //group is what is called for in the ORM selector. Can call fields
        };
    }
    static get modelName() {
        return "Field";
    }
}
orm.register(Field);

export class SectionMapGrouping extends Model {
    static get fields() {
        return {
            section: fk("Section", "groupings"),
            field: fk("Field", "groupings"),
        };
    }
    static get modelName() {
        return "SectionMapGrouping";
    }
}
orm.register(SectionMapGrouping);

export class GroupingValue extends Model {
    static get fields() {
        return {
            sectionmapgrouping: fk("SectionMapGrouping", "values"),
        };
    }
    static get modelName() {
        return "GroupingValue";
    }
}
orm.register(GroupingValue);

export class Permission extends Model {
    static get fields() {
        return {
            page: fk("Page", "permissions"),
            user: fk("User", "permissions"),
        };
    }
    static get modelName() {
        return "Permission";
    }
}
orm.register(Permission);

export class ParentRecord extends Model {
    //static fields getter this allows for all fields in the page table to be available when an ORM selector is used.
    //Page is the model the FK is pointing too, "parentRecord" (optional) is property name that will access a querySet that the FK is defined from.
    static get fields() {
        return {
            page: fk("Page", "parentRecord"),
            section: fk("Section", "parentRecord"),
        };
    }
    //the modelname "ParentRecord" will be used to resolve all related fields
    static get modelName() {
        return "ParentRecord";
    }
    static get loadByDefault() {
        return false;
    }
}
orm.register(ParentRecord);

export class RecordSection extends Model {
    //static fields 'get' this allows for all fields in the page table to be available when an ORM selector is used with this model
    static get fields() {
        return {
            // Defines a foreign key on a model, which points to a single entity on another model.
            //Can pass arguments as either a 'single object', or two arguments.
            //If you pass two arguments, the first one is the "name" of the Model the foreign key is pointing to,
            //and the second one is an 'optional' related name, which will be used to access the Model the foreign key is being defined from, from the target Model.
            //If the related name is not passed, it will be set as ${toModelName}Set.

            //ParentRecord is name of Model FK is pointing to, "recordSection" is an optional property name and is used to access a querySet
            parentrecord: fk("ParentRecord", "recordSection"),
            section: fk("Section", "recordSection"),
            form: fk("Form", "recordSection"),
        };
    }
    //the modelname "ParentRecord" will be used to resolve all related fields, use name of model always
    static get modelName() {
        return "RecordSection";
    }

    //will use the dynamic loading feature to get the recordsections and wont load all database records when page is refreshed
    static get loadByDefault() {
        return false;
    }
}
orm.register(RecordSection);

export class RecordField extends Model {
    //static fields getter this allows for all fields in the recordField table to be available when an ORM selector is used.
    //RecordField is the model the FK is pointing too, "recordField" (optional) is property name that will access a querySet that the FK is defined from.
    static get fields() {
        return {
            recordsection: fk("RecordSection", "recordField"),
            field: fk("Field", "recordField"),
            group: fk("Group", "recordField"),
        };
    }
    //the modelname "ParentRecord" will be used to resolve all related fields
    static get modelName() {
        return "RecordField";
    }

    static get loadByDefault() {
        return false;
    }
}
orm.register(RecordField);

export class RepeatableTable extends Model {
    static get fields() {
        return {
            recordsection: fk("RecordSection", "repeatableTable"),
            group: fk("Group", "repeatableTable"),
        };
    }
    //the modelname that will be used to resolve all related fields
    static get modelName() {
        return "RepeatableTable";
    }

    static get loadByDefault() {
        return false;
    }
}
orm.register(RepeatableTable);

export class RepeatableField extends Model {
    static get fields() {
        return {
            repeatabletable: fk("RepeatableTable", "repeatableField"),
            field: fk("Field", "repeatableField"),
        };
    }
    //the modelname that will be used to resolve all related fields
    static get modelName() {
        return "RepeatableField";
    }

    static get loadByDefault() {
        return false;
    }
}
orm.register(RepeatableField);

export class AttachmentSection extends Model {
    static get fields() {
        return {
            recordsection: fk("RecordSection", "attachmentsection"),
        };
    }
    static get modelName() {
        return "AttachmentSection";
    }

    static get loadByDefault() {
        return false;
    }
}
orm.register(AttachmentSection);

export class MapSetting extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "MapSetting";
    }
}
orm.register(MapSetting);

export class MapLayer extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "MapLayer";
    }
}
orm.register(MapLayer);

export class LetterTemplate extends Model {
    static get modelName() {
        return "LetterTemplate";
    }
    static get fields() {
        return {
            section: fk("Section", "lettertemplates"),
        };
    }
}
orm.register(LetterTemplate);

export class Shortcut extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Shortcut";
    }
}
orm.register(Shortcut);

export class ExternalDashboard extends Model {
    static get fields() {
        return {
            page: fk("Page", "externaldashboard"), //Page id as FK
        };
    }
    static get modelName() {
        return "ExternalDashboard";
    }
}
orm.register(ExternalDashboard);

export class AdvancedGroup extends Model {
    static get fields() {
        return {
            page: fk("Page", "advancedgroup"), //Page id as FK
        };
    }
    static get modelName() {
        return "AdvancedGroup";
    }
}
orm.register(AdvancedGroup);

export class AdvancedSearch extends Model {
    static get fields() {
        return {
            advancedgroup: fk("AdvancedGroup", "advancedsearch"), //Page id as FK
            section: fk("Section", "advancedsearch"), //Page id as FK
        }; // calling page.advancedsearch in ORM links them ex: in map(page=> advancedsearch:page.advancedsearch etc..)
    }
    static get modelName() {
        return "AdvancedSearch";
    }
}
orm.register(AdvancedSearch);

export class PageAnalytics extends Model {
    static get fields() {
        return {
            advancedsearch: fk("AdvancedSearch", "pageanalytics"), //advancedsearch_id as FK
        };
    }
    static get modelName() {
        return "PageAnalytics";
    }
}
orm.register(PageAnalytics);

export class AdvancedFilter extends Model {
    static get fields() {
        return {
            advancedsearch: fk("AdvancedSearch", "advancedfilter"), //advancedsearch_id as FK
            field: fk("Field", "advancedfilter"),
        };
    }
    static get modelName() {
        return "AdvancedFilter";
    }
}
orm.register(AdvancedFilter);

export class AdvancedTableFields extends Model {
    static get fields() {
        return {
            advancedsearch: fk("AdvancedSearch", "advancedtablefields"), //advancedsearch_id as FK
            field: fk("Field", "advancedtablefields"),
            contact_field: fk("ContactField", "advancedtablefields2"),
        };
    }
    static get modelName() {
        return "AdvancedTableFields";
    }
}
orm.register(AdvancedTableFields);

export class HelpDocument extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "HelpDocument";
    }
}
orm.register(HelpDocument);

export class ReleaseNote extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "ReleaseNote";
    }
}
orm.register(ReleaseNote);

export class ContactField extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "ContactField";
    }
}
orm.register(ContactField);

export class ContactRecord extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "ContactRecord";
    }

    static get loadByDefault() {
        return false;
    }
}
orm.register(ContactRecord);

export class ContactRecordField extends Model {
    static get fields() {
        return {
            contactfield: fk("ContactField", "contactrecordfields"),
            contactrecord: fk("ContactRecord", "contactrecordfields"),
        };
    }
    static get modelName() {
        return "ContactRecordField";
    }

    static get loadByDefault() {
        return false;
    }
}
orm.register(ContactRecordField);

export class ContactNonGlobalField extends Model {
    static get fields() {
        return {
            contactfield: fk("ContactField", "contactnonglobalfields"),
            contactrecord: fk("ContactRecord", "contactnonglobalfields"),
            parentrecord: fk("ParentRecord", "contactnonglobalfields"),
        };
    }
    static get modelName() {
        return "ContactNonGlobalField";
    }

    static get loadByDefault() {
        return false;
    }
}
orm.register(ContactNonGlobalField);

export class CustomReportRecord extends Model {
    static get fields() {
        return {
            // city: fk("City", "customreports")
        };
    }
    static get modelName() {
        return "CustomReportRecord";
    }
}
orm.register(CustomReportRecord);

export class CustomReportPage extends Model {
    static get fields() {
        return {
            customreportrecord: fk("CustomReportRecord", "customreportpage"),
            page: fk("Page", "customreportpage"),
        };
    }
    static get modelName() {
        return "CustomReportPage";
    }
}
orm.register(CustomReportPage);

export class CustomReportQueryField extends Model {
    static get fields() {
        return {
            customreportpage: fk("CustomReportPage", "customreportqueryfields"),
        };
    }
    static get modelName() {
        return "CustomReportQueryField";
    }
}
orm.register(CustomReportQueryField);

export class CustomReportTableField extends Model {
    static get fields() {
        return {
            customreportpage: fk("CustomReportPage", "customreporttablefields"),
        };
    }
    static get modelName() {
        return "CustomReportTableField";
    }
}
orm.register(CustomReportTableField);

export class Application extends Model {
    static get fields() {
        return {
            page: fk("Page", "applications"),
        };
    }
    static get modelName() {
        return "Application";
    }
}
orm.register(Application);

export class ApplicationRecord extends Model {
    static get fields() {
        return {
            application: fk("Application", "applicationrecords"),
        };
    }
    static get modelName() {
        return "ApplicationRecord";
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(ApplicationRecord);

export class ApplicationRecordStatus extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "applicationrecordstatuss"),
        };
    }
    static get modelName() {
        return "ApplicationRecordStatus";
    }

    static get loadByDefault() {//weather or not to load models to internal users or not, we dont want to load for applications
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(ApplicationRecordStatus);

//ninemile app
export class NineMilePermit extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "ninemilepermit"),
        };
    }
    static get modelName() {
        return "NineMilePermit";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(NineMilePermit);
 
export class NineMilePermitAttachment extends Model {
    static get fields() {
        return {
            permit: fk("NineMilePermit", "ninemilepermitattachments"),
        };
    }
    static get modelName() {
        return "NineMilePermitAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(NineMilePermitAttachment);

export class NineMileGrant extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "ninemilegrant"),
        };
    }
    static get modelName() {
        return "NineMileGrant";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(NineMileGrant);

export class NineMileGrantExpense extends Model {
    static get fields() {
        return {
            grant: fk("NineMileGrant", "ninemilegrantexpenses"),
        };
    }
    static get modelName() {
        return "NineMileGrantExpense";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(NineMileGrantExpense);


export class NineMileGrantAttachment extends Model {
    static get fields() {
        return {
            grant: fk("NineMileGrant", "ninemilegrantattachments"),
        };
    }
    static get modelName() {
        return "NineMileGrantAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(NineMileGrantAttachment);

export class NineMileGrantInprogressReporting extends Model {
    static get fields() {
        return {
            ninemilegrant: fk("NineMileGrant", "ninemilegrantinprogressreporting"),
        };
    }
    static get modelName() {
        return "NineMileGrantInprogressReporting";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(NineMileGrantInprogressReporting);

export class NineMileGrantInprogressExpense extends Model {
    static get fields() {
        return {
            ninemilegrantinprogressreporting: fk("NineMileGrantInprogressReporting", "ninemilegrantinprogressexpense"),
        };
    }
    static get modelName() {
        return "NineMileGrantInprogressExpense";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(NineMileGrantInprogressExpense);

export class NineMileGrantInprogressAttachment extends Model {
    static get fields() {
        return {
            ninemilegrantinprogressreporting: fk("NineMileGrantInprogressReporting", "ninemilegrantinprogressattachment"),
        };
    }
    static get modelName() {
        return "NineMileGrantInprogressAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(NineMileGrantInprogressAttachment);

export class NineMileGrantAnnualReporting extends Model {
    static get fields() {
        return {
            ninemilegrant: fk("NineMileGrant", "ninemilegrantannualreporting"),
        };
    }
    static get modelName() {
        return "NineMileGrantAnnualReporting";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(NineMileGrantAnnualReporting);

export class NineMileGrantAnnualAttachment extends Model {
    static get fields() {
        return {
            ninemilegrantannualreporting: fk("NineMileGrantAnnualReporting", "ninemilegrantannualattachment"),
        };
    }
    static get modelName() {
        return "NineMileGrantAnnualAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(NineMileGrantAnnualAttachment);



//BRRWD app
export class BRRWDPermit extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "brrwdpermit"),
        };
    }
    static get modelName() {
        return "BRRWDPermit";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(BRRWDPermit);

export class BRRWDCulvert extends Model {
    static get fields() {
        return {
            brrwdpermit: fk("BRRWDPermit", "brrwdculvert"),
        };
    }
    static get modelName() {
        return "BRRWDCulvert";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(BRRWDCulvert);

export class BRRWDAttachment extends Model {
    static get fields() {
        return {
            brrwdpermit: fk("BRRWDPermit", "brrwdattachment"),
        };
    }
    static get modelName() {
        return "BRRWDAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(BRRWDAttachment);

export class RoseauPermit extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "roseaupermit"),
        };
    }
    static get modelName() {
        return "RoseauPermit";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(RoseauPermit);

export class RoseauCulvert extends Model {
    static get fields() {
        return {
            roseaupermit: fk("RoseauPermit", "roseauculvert"),
        };
    }
    static get modelName() {
        return "RoseauCulvert";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(RoseauCulvert);

export class RoseauAttachment extends Model {
    static get fields() {
        return {
            roseaupermit: fk("RoseauPermit", "roseauattachment"),
        };
    }
    static get modelName() {
        return "RoseauAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(RoseauAttachment);

//Sandhill app
export class SandhillPermit extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "sandhillpermit"),
        };
    }
    static get modelName() {
        return "SandhillPermit";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(SandhillPermit);

export class SandhillCulvert extends Model {
    static get fields() {
        return {
            sandhillpermit: fk("SandhillPermit", "sandhillculvert"),
        };
    }
    static get modelName() {
        return "SandhillCulvert";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(SandhillCulvert);

export class SandhillAttachment extends Model {
    static get fields() {
        return {
            sandhillpermit: fk("SandhillPermit", "sandhillattachment"),
        };
    }
    static get modelName() {
        return "SandhillAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(SandhillAttachment);

//sauk river app
export class SrwdPermit extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "srwdpermit"),
        };
    }
    static get modelName() {
        return "SrwdPermit";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(SrwdPermit);

export class SrwdPermitContact extends Model {
    static get fields() {
        return {
            srwdpermit: fk("SrwdPermit", "srwdpermitcontacts"),
        };
    }
    static get modelName() {
        return "SrwdPermitContact";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(SrwdPermitContact);

export class SrwdPermitAttachment extends Model {
    static get fields() {
        return {
            grantpermit: fk("SrwdPermit", "srwdpermitattachments"),
        };
    }
    static get modelName() {
        return "SrwdPermitAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(SrwdPermitAttachment);

export class RpbcwdPermit extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "rppbcwdpermits"),
        };
    }
    static get modelName() {
        return "RpbcwdPermit";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(RpbcwdPermit);

export class RpbcwdPermitContact extends Model {
    static get fields() {
        return {
            rpbcwdpermit: fk("RpbcwdPermit", "rpbcwdpermitcontacts"),
        };
    }
    static get modelName() {
        return "RpbcwdPermitContact";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(RpbcwdPermitContact);

export class RpbcwdPermitAttachment extends Model {
    static get fields() {
        return {
            rpbcwdpermit: fk("RpbcwdPermit", "rpbcwdpermitattachments"),
        };
    }
    static get modelName() {
        return "RpbcwdPermitAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(RpbcwdPermitAttachment);

export class CcwdPermit extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "ccwdpermits"),
        };
    }
    static get modelName() {
        return "CcwdPermit";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(CcwdPermit);

export class CcwdPermitContact extends Model {
    static get fields() {
        return {
            rpbcwdpermit: fk("CcwdPermit", "ccwdpermitcontacts"),
        };
    }
    static get modelName() {
        return "CcwdPermitContact";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(CcwdPermitContact);

export class CcwdPermitAttachment extends Model {
    static get fields() {
        return {
            rpbcwdpermit: fk("CcwdPermit", "ccwdpermitattachments"),
        };
    }
    static get modelName() {
        return "CcwdPermitAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(CcwdPermitAttachment);

export class ColumbusApplication extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "columbusapplications"),
        };
    }
    static get modelName() {
        return "ColumbusApplication";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(ColumbusApplication);

export class ColumbusApplicationContact extends Model {
    static get fields() {
        return {
            rpbcwdpermit: fk("ColumbusApplication", "columbusapplicationcontacts"),
        };
    }
    static get modelName() {
        return "ColumbusApplicationContact";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(ColumbusApplicationContact);

export class ColumbusApplicationAttachment extends Model {
    static get fields() {
        return {
            rpbcwdpermit: fk("ColumbusApplication", "columbusapplicationattachments"),
        };
    }
    static get modelName() {
        return "ColumbusApplicationAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(ColumbusApplicationAttachment);

export class CrwdStewardship extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "crwdstewardships"),
        };
    }
    static get modelName() {
        return "CrwdStewardship";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(CrwdStewardship);

export class CrwdStewardshipAttachment extends Model {
    static get fields() {
        return {
            rpbcwdpermit: fk("CrwdStewardship", "crwdstewardshipattachments"),
        };
    }
    static get modelName() {
        return "CrwdStewardshipAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(CrwdStewardshipAttachment);

export class CrwdStewardshipGrant extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "crwdstewardshipgrants"),
        };
    }
    static get modelName() {
        return "CrwdStewardshipGrant";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        //determines if ORM loading will occur with link
        return true;
    }
}
orm.register(CrwdStewardshipGrant);

export class CrwdStewardshipGrantAttachment extends Model {
    static get fields() {
        return {
            grantpermit: fk("CrwdStewardshipGrant", "crwdstewardshipgrantattachments"),
        };
    }
    static get modelName() {
        return "CrwdStewardshipGrantAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(CrwdStewardshipGrantAttachment);

export class CrwdPermit extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "crwdpermits"),
        };
    }
    static get modelName() {
        return "CrwdPermit";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(CrwdPermit);

export class CrwdPermitAttachment extends Model {
    static get fields() {
        return {
            rpbcwdpermit: fk("CrwdPermit", "crwdpermitattachments"),
        };
    }
    static get modelName() {
        return "CrwdPermitAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(CrwdPermitAttachment);

export class CrwdPartner extends Model {
    static get fields() {
        return {
            applicationrecord: fk("ApplicationRecord", "crwdpartners"),
        };
    }
    static get modelName() {
        return "CrwdPartner";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(CrwdPartner);

export class CrwdPartnerAttachment extends Model {
    static get fields() {
        return {
            rpbcwdpermit: fk("CrwdPartner", "crwdpartnerattachments"),
        };
    }
    static get modelName() {
        return "CrwdPartnerAttachment";
    }

    static get loadByDefault() {
        return false;
    }

    static get allowPublicAccess() {
        return true;
    }
}
orm.register(CrwdPartnerAttachment);
