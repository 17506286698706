import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PeopleIcon from "@material-ui/icons/People";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import AssigmentIcon from "@material-ui/icons/Assignment";
import Dashboard from "@material-ui/icons/Dashboard";
import Description from "@material-ui/icons/Description";

import { ICON_LIST } from "./icons";
import * as navActions from "./actions";
import { createSelector } from "./orm";

const allPages = createSelector((schema) => {
    return schema.Page.all()
        .orderBy("order")
        .toModelArray()
        .map((section) => ({
            sections: section.sections
                .filter((section) => section.parent === true)
                .orderBy("id")
                .toRefArray(),
            ...section.ref,
        }));
});

const allPermissions = createSelector((schema) => {
    return schema.Permission.all().orderBy("user").toRefArray();
});

const getPageID = createSelector(
    (state, ownProps) => ownProps.match,
    (session, match) => {
        if (match.params["pageId"])
            return parseInt(match.params["pageId"])
        if (match.params["id"])
            return parseInt(match.params["id"])
        return null;
    }
);

const styles = (theme) => ({
    drawerPaper: {
        height: "calc(100% - 64px)", // 64 = height of Header
        marginTop: 64,

        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
        width: 52,
        // whiteSpace: "nowrap",
        backgroundColor: "#ddd",
        [theme.breakpoints.down("960")]: {
            display: "none",
        },
    },
    drawerOpen: {
        [theme.breakpoints.up("lg")]: {
            width: 325,
        },
        [theme.breakpoints.down("md")]: {
            width: 275,
        },
        "& $listItemIndent": {
            paddingLeft: theme.spacing(3),
        },
    },

    noFlex: {
        padding: 0,
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px",
        minWidth: "0px",
    },
    blackText: {
        color: "#000",
        fontSize: "16px",
    },
    listItemTop: {
        borderBottom: "1px solid #000",
        borderTop: "1px solid #000",
    },
    listItem: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    listItemHighlight: {
        borderRight: "3px solid #262626",
        backgroundColor: "rgba(60, 138, 199, 0.08)",
    },
    listItemIndent: {
        transition: theme.transitions.create("padding-left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    svg: {
        "& svg": {
            marginTop: 5,
            marginBottom: 5,
        },
    },
});

class Navigation extends React.Component {
    continueDashboard = () => {
        this.props.history.push("/dashboard");
    };

    //occurs when closing and opening the collapse button
    navigationClick = () => {
        const { navState } = this.props;
        this.props.navToggleNavigation();

        //this is to update the sectiontab header indicator/underline as it doesnt center in sectiontabheader component
        //when collapsing/opening side menu .we get reference of component from redux 'navState' to programmically trigger its methods to recenter the line
        if (navState.tabref)
            setTimeout(function () {
                // Animation duration
                navState.tabref.updateIndicator();
            }, 300);
    };

    pagesClick = () => {
        this.props.navTogglePages();
    };

    reportsClick = () => {
        this.props.navToggleReports();
    };

    configClick = () => {
        this.props.navToggleConfig();
    };

    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#3c8ac7",
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    render() {
        const { classes, syncState, navState, pages, authState, permissions, page_id } = this.props;

        const syncProgress = Math.round((syncState.progress / syncState.total) * 100);

        if (!navState.navigationOpen && navState.navigationOpen !== false) this.props.navToggleNavigation();

        //filter pages based on users permissions
        if (authState.user) {
            var userpermissions = permissions.filter((k) => {
                if (k.user === authState.user.id) {
                    return k;
                }
                return null;
            });

            var userallowpages = [];
            pages.forEach((o) => {
                var u = userpermissions.find((i) => {
                    if (i.page === o.id) {
                        return i;
                    }
                    return null;
                });
                if (u) {
                    //remove undefined
                    if (u.readonly || u.fullaccess || u.readonlyexternal) {
                        userallowpages.push(o);
                    } else if (!u.readonly && !u.fullaccess && !u.noaccess && !u.readonlyexternal) {
                        userallowpages.push(o); //nothing set as far a access rights this is for heitest admin troubleshooting
                    }
                } else {
                    //nothing set as far a access rights this is for heitest admin troubleshooting
                    userallowpages.push(o);
                }
            });
        }
        return (
            <Drawer
                id="drawContainer"
                variant="permanent"
                open={true}
                classes={{
                    paper: classNames(classes.drawerPaper, navState.navigationOpen && classes.drawerOpen),
                }}>
                <Scrollbars renderThumbVertical={this.renderThumb} autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <div>
                        {!syncState.ready && <LinearProgress variant="determinate" value={syncProgress} />}

                        {/* PAGES SECTION */}
                        <List className={classes.noFlex}>
                            <ListItem title="Module" button onClick={this.pagesClick} className={classNames(classes.listItemTop, classes.listItem)}>
                                <ListItemIcon>
                                    <Dashboard className={classes.iconSize} />
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary="Modules"
                                    classes={{
                                        primary: classes.blackText,
                                    }}
                                    style={{ paddingLeft: "0px" }}
                                />
                                {navState.pagesOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={navState.pagesOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {userallowpages &&
                                        userallowpages.map((page) => {
                                            if (page.icon !== undefined) {
                                                const icon_array = ICON_LIST.find(function (itm) {
                                                    return itm.id === page.icon;
                                                });
                                                var icon_component;
                                                if (icon_array) {
                                                    icon_component = icon_array["component"];
                                                }
                                            }
                                            if (page) {
                                                return (
                                                    <ListItem
                                                        key={page.id}
                                                        button
                                                        className={classNames(classes.listItem, classes.listItemIndent, page.id === page_id && classes.listItemHighlight)}
                                                        component={Link}
                                                        to={page.id === 249 ? "/page/249/798/table" : "/dashboard/" + page.id}
                                                        // custom route directly to table for LA above
                                                    >
                                                        {/* ALLOW FOR DISPLAYING ICON WITH PAGE WITH CUSTOM PADDING */}
                                                        {icon_component !== undefined && (
                                                            <>
                                                                <ListItemIcon className={classes.svg}>{icon_component}</ListItemIcon>
                                                                <div>
                                                                    <ListItemText
                                                                        // inset
                                                                        primary={page.name}
                                                                        classes={{
                                                                            primary: classNames(classes.textOverflowField, classes.blackText),
                                                                        }}
                                                                        // style={{ paddingLeft: "30px" }}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {/* PRINT PAGES WITHOUT ICON WITH DIFFERENT PADDING */}
                                                        {icon_component === undefined && (
                                                            <ListItemText
                                                                inset
                                                                primary={page.name}
                                                                classes={{
                                                                    primary: classNames(classes.textOverflowField, classes.blackText),
                                                                }}
                                                                style={{ paddingLeft: "55px" }}
                                                            />
                                                        )}
                                                    </ListItem>
                                                );
                                            } else return null;
                                        })}
                                </List>
                            </Collapse>
                            {authState.user && authState.user.is_city_admin === true && (
                                <ListItem
                                    title="Config Settings"
                                    button
                                    onClick={this.configClick}
                                    className={classNames(classes.listItemTop, classes.listItem)}
                                    style={{ paddingLeft: "0px" }}>
                                    <ListItemIcon>
                                        <SettingsIcon className={classes.iconSize} style={{ paddingLeft: "16px" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary="Config Settings"
                                        classes={{
                                            primary: classes.blackText,
                                        }}
                                        style={{ paddingLeft: "17px" }}
                                    />
                                    {navState.configOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                            )}
                            {authState.user && authState.user.is_city_admin === true && (
                                <Collapse in={navState.configOpen && authState.user.is_city_admin === true} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className={classes.listItem} component={Link} to={"/config/page"}>
                                            <ListItemText
                                                inset
                                                primary="Module Configuration"
                                                classes={{
                                                    primary: classes.blackText,
                                                }}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} component={Link} to={"/config/map"}>
                                            <ListItemText
                                                inset
                                                primary="Map Settings"
                                                classes={{
                                                    primary: classes.blackText,
                                                }}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} component={Link} to={"/config/letter"}>
                                            <ListItemText
                                                inset
                                                primary="Letter & Report Templates"
                                                classes={{
                                                    primary: classes.blackText,
                                                }}
                                            />
                                        </ListItem>
                                        <ListItem button className={classes.listItem} component={Link} to={"/adm/manage"}>
                                            <ListItemText
                                                inset
                                                primary="Manage Users"
                                                classes={{
                                                    primary: classes.blackText,
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            )}
                            <ListItem
                                title="Master Contacts List"
                                button
                                component={Link}
                                to={"/contacts"}
                                style={{ borderBottom: navState.navigationOpen ? 0 : "1px solid" }}
                                className={classNames(classes.listItemTop, classes.listItem)}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary="Master Contacts List"
                                    classes={{
                                        primary: classes.blackText,
                                    }}
                                    style={{ paddingLeft: "0px" }}
                                />
                            </ListItem>
                            {authState.user && authState.user.show_application === true && (
                                <ListItem
                                    title="Public Applications"
                                    button
                                    component={Link}
                                    to={"/applications"}
                                    style={{ borderBottom: navState.navigationOpen ? 0 : "1px solid" }}
                                    className={classNames(classes.listItemTop, classes.listItem)}>
                                    <ListItemIcon>
                                        <AssigmentIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary="Public Applications"
                                        classes={{
                                            primary: classes.blackText,
                                        }}
                                        style={{ paddingLeft: "0px" }}
                                    />
                                </ListItem>
                            )}

                            {authState.user && authState.user.show_custom_report === true && (
                                //they have access so go to report screen
                                <ListItem
                                    title="Summary Reports"
                                    button
                                    component={Link}
                                    to={"/customreports"}
                                    style={{ borderBottom: navState.navigationOpen ? 0 : "1px solid" }}
                                    className={classNames(classes.listItemTop, classes.listItem)}>
                                    <ListItemIcon>
                                        <Description />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary="Summary Reports"
                                        classes={{
                                            primary: classes.blackText,
                                        }}
                                        style={{ paddingLeft: "0px" }}
                                    />
                                </ListItem>
                            )}
                            {authState.user && authState.user.show_custom_report === false && (
                                //they dont have access
                                <ListItem
                                    title="Summary Reports"
                                    button
                                    onClick={() => this.props.warningDialog()}
                                    style={{ borderBottom: navState.navigationOpen ? 0 : "1px solid" }}
                                    className={classNames(classes.listItemTop, classes.listItem)}>
                                    <ListItemIcon>
                                        <Description />
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary="Summary Reports"
                                        classes={{
                                            primary: classes.blackText,
                                        }}
                                        style={{ paddingLeft: "0px" }}
                                    />
                                </ListItem>
                            )}
                            <ListItem
                                title="Collapse/Expand Navigation"
                                button
                                onClick={this.navigationClick}
                                className={classNames(classes.listItemTop, classes.listItem)}>
                                <ListItemIcon>{navState.navigationOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}</ListItemIcon>
                                <ListItemText
                                    inset
                                    primary="Collapse Panel"
                                    classes={{
                                        primary: classes.blackText,
                                    }}
                                    style={{ paddingLeft: "0px" }}
                                />
                            </ListItem>
                        </List>
                    </div>
                </Scrollbars>
            </Drawer>
        );
    }
}
Navigation.displayName = "Navigation";
Navigation = connect(
    (state, ownProps) => ({
        pages: allPages(state),
        page_id: getPageID(state, ownProps),
        permissions: allPermissions(state),
        navState: state.nav,
        authState: state.auth,
        syncState: state.sync,
    }),
    {
        ...navActions,
    }
)(Navigation);

export default withStyles(styles)(withRouter(Navigation));
