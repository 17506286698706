import React, { Component } from "react";
import { connect } from "react-redux";
import { Form as Formm } from "react-form";
import { withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Button from "@material-ui/core/Button";
import { loadModules } from "esri-loader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Close from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CloudUpload from "@material-ui/icons/CloudUpload";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Edit from "@material-ui/icons/Edit";
import Smartphone from "@material-ui/icons/Smartphone";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table"; 
import EnhancedTableHead from "../common/EnhancedTableHead";
// import Paper from "@material-ui/core/Paper";
import CustomTableCell from "../common/TableCell";

import * as authActions from "../auth/actions";
import Subquestion from "../common/Subquestion";
import drag from "../common/icons/dnd.png";
import BreadCrumbs from "../common/BreadCrumbs";
import AppContainer from "../common/AppContainer";
import { createSelector } from "../common/orm";
import WarningDialog from "../common/WarningDialog";
import ReactSelect from "../common/ReactSelect";
import TextField from "../common/TextField";
import Checkbox from "../common/Checkbox";
import Select from "../common/Select";
import HelpLabel from "../common/HelpLabel";
import Switch from "../common/Switch";
import FileInput from "../common/FileInput";
import RadioButtonsV4 from "../common/RadioButtonsV4";

import { Group, Field, Form, ParentRecord } from "../page/models";

const allPages = createSelector((schema) => {
    return schema.Page.all()
        .orderBy("id")
        .toModelArray()
        .map((page) => ({
            _sections: page.sections.all().orderBy("id").toRefArray(),
            ...page.ref,
        }));
});

const getSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["section"]), //changed for test!!!!!!!!!!!!!
    (session, sectionID) => {
        return session.Section.filter((s) => s.id === sectionID)
            .all()
            .orderBy("id")
            .toRefArray();
    }
);

const getAllSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["section"]),
    (session, sectionID) => {
        const section = session.Section.filter((s) => s.id === sectionID).toRefArray();

        return session.Section.filter((s) => s.page === section[0].page)
            .orderBy("id")
            .toModelArray()
            .map((section) => ({
                forms: section.forms
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((form) => ({
                        groups: form.groups
                            .all()
                            .orderBy("order")
                            .toModelArray()
                            .map((group) => ({
                                fields: group.fields.all().orderBy("order").toRefArray(),
                                ...group.ref,
                            })),
                        ...form.ref,
                    })),
                ...section.ref,
            }));
    }
);
const getAllPageSections = createSelector((session) => {
    return session.Page.all()
        .orderBy("id")
        .toModelArray()
        .map((page) => ({
            section: page.sections
                .filter((s) => s.parent === true)
                .orderBy("id")
                .toModelArray()
                .map((section) => ({
                    forms: section.forms
                        .all()
                        .orderBy("id")
                        .toModelArray()
                        .map((form) => ({
                            groups: form.groups
                                .all()
                                .orderBy("order")
                                .toModelArray()
                                .map((group) => ({
                                    fields: group.fields.all().orderBy("order").toRefArray(),
                                    ...group.ref,
                                })),
                            ...form.ref,
                        })),
                    ...section.ref,
                })),
            ...page.ref,
        }));
});

const getGroups = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["formId"]), //changed for test!!!!!!!!!!!!!
    (session, formid) => {
        return session.Group.filter((group) => group.form === formid)
            .orderBy("order")
            .toModelArray()
            .map((group) => ({
                _fields: group.fields.all().orderBy("order").toRefArray(),
                ...group.ref,
            }));
    }
);

const getFields = createSelector((session) => {
    return session.Field.all().orderBy("group").orderBy("order").toRefArray();
});

// Get all sections that are present for the current page
const getForm = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["formId"]),
    (session, id) => {
        return session.Form.withId(id);
    }
);
const getForms = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["section"]),
    (session, id) => {
        return session.Form.filter((f) => f.section === id).toRefArray();
    }
);

const styles = (theme) => ({
    italics: {
        fontStyle: "italic",
    },
    floatLeft: {
        float: "left",
    },
    floatRight: {
        float: "right",
    },
    fieldButton: {
        display: "block",
        backgroundColor: "#74A94C",
        color: "white",
        borderRadius: 10,
        minWidth: 200,
        margin: 8,
    },
    buttonMargin: {
        margin: 4,
    },
    minWidth: {
        minWidth: 712,
    },
    minWidth2: {
        width: 600,
    },
    flex: {
        flex: 1,
    },
    formButton: {
        float: "right",
        marginBottom: 30,
    },
    fieldsetOptions: {
        borderRadius: 10,
        marginBottom: 16,
        backgroundColor: "#eeeeee",
    },
    root: {
        width: "100%",
        marginTop: "12px",
    },
    expandedMargin: {
        paddingRight: "60px",
    },
    titlePanel: {
        minHeight: "auto !important",
    },
    redText: {
        color: "#f00f0f",
    },
    overridetooltip: {
        fontWeight: 80,
        fontSize: 12,
    },
    progress: {
        display: "table",
        marginLeft: "auto",
        marginRight: "auto",
    },
    overridepaper:{ 
        float:"right",
        width: 600
    }
});

const grid = 8;

//full group section which encapsulates all draggable content
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    //border: "1.5px dotted",
    borderRadius: 3,
    boxShadow: "0 2px 18px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.25)",
    // change background colour if dragging
    // background: isDragging ? "lightgrey" : "lightgrey",

    // styles we need to apply on draggables
    ...draggableStyle,
});

//fields styling section for groups
const getItemStyle2 = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid,
    margin: `0 0 ${grid}px 0`,
    color: "primary",
    fontSize: "18px",
    borderRadius: 5,
    textAlign: "left",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 2px, rgba(0, 0, 0, 0.25) 0px 2px 2px",

    // change background of fields when dragging
    background: isDragging ? "lightgrey" : "lightgrey",

    // styles we need to apply on draggables
    ...draggableStyle,
});
// background color on main card group fields when dragging
const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#4e4e4e" : "",
    padding: grid,
    width: "99%",
    borderRadius: 5,
});
// background color on "fields child card" section when dragging
const getListStyle2 = (isDraggingOver) => ({
    background: isDraggingOver ? "#4e4e4e" : "",
    padding: 5,
    borderRadius: 5,
    marginBottom: isDraggingOver ? 16 : 0,
});

class GroupContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteDialogOpen: false,
            deleteDialogConfirmAction: null,
            timeout: null,
            disableDrag: false,
            count: [],
            groupDialogopen: false,
            activeIndex: null,
            loading: false,
            fieldToOpen: {},
            moving:false
        };

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    createField = (groupid, order) => {
        const { ormFieldCreate } = this.props;
        this.setState({ loading: true });

        ormFieldCreate({
            group: groupid,
            order: order,
        }).then((foo) => {
            let fieldToOpen = {};
            fieldToOpen["field"] = foo;
            fieldToOpen["open"] = true;
            return this.setState({ loading: false, fieldToOpen });
        });
    };

    deleteGroup = (group) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: group,
        });
    };

    disableDragFunction = (val) => {
        this.setState({
            disableDrag: val,
        });
    };

    setfieldToOpen = () => {
        this.setState({ fieldToOpen: {} });
    };

    saveGroup = (values) => {
        const { ormGroupUpdate } = this.props;
        const { timeout } = this.state;

        clearTimeout(timeout);

        var t = setTimeout(function () {
            ormGroupUpdate({
                id: values.id,
                ...values,
            });
        }, 500);

        this.setState({ timeout: t });
    };

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        console.log(result); 

        const { ormFieldLoadDetailChild, ormGroupLoadDetailChild, form, authState} = this.props;
        this.setState({moving:true})
        var _this = this;    
        // Order Groups
        if (result.destination.droppableId === "group_list") { 
            // Set the order of groups moved between starting and ending position
            //eslint-disable-next-line
            const res = fetch(`dndreorder/${form.id}/${result.destination.index}/${result.source.index}/${result.draggableId}/${"group"}/${authState.user.auth_token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token,
                },
            }).then((e) => {
                if (e.status === 200) {
                    e.json().then((rsp) => {   
                         ormGroupLoadDetailChild(result.draggableId, function () { 
                            _this.setState({ moving: false });
                         }); 
                    });
                }else { 
                    _this.setState({moving:false}) 
                }
            }); 
        }
        // Order fields
        else { 
            const groupid = result.destination.droppableId.split("-")[1]; 
            // Set the order of searches moved between starting and ending position
            //eslint-disable-next-line
            const res = fetch(`dndreorder/${groupid}/${result.destination.index}/${result.source.index}/${result.draggableId}/${"field"}/${authState.user.auth_token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token,
                },
            }).then((e) => {
                if (e.status === 200) {
                    e.json().then((rsp) => {   
                         ormFieldLoadDetailChild(result.draggableId, function () { 
                            _this.setState({ moving: false });
                         }); 
                    });
                }else { 
                    _this.setState({moving:false}) 
                }
            });  
        }
    }

    render() {
        const {
            classes,
            ormGroupDelete,
            ormGroupUpdate,
            groups,
            ormFieldUpdate,
            ormFieldReload,
            ormFieldDelete,
            ormParentRecordReload,
            section,
            allSections,
            allPageSections,
            fields2,
        } = this.props;
        const { deleteDialogOpen, deleteDialogConfirmAction, disableDrag, groupDialogopen, activeIndex, loading, fieldToOpen,moving } = this.state;

        var repeatableStyle = {
            whiteSpace: "nowrap",
            display: "flex",
            marginTop: "-13px",
            marginBottom: "-9px",
        };

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="group_list" type="group_list" key="group_list">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                            {groups.map((item, index) => (
                                <>
                                    <Draggable key={item.id} draggableId={item.id} index={item.order} isDragDisabled={disableDrag}>
                                        {(provided, snapshot) => (
                                            <div
                                                key={item.id}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                <Accordion key={item.id} style={{ backgroundColor: "#fff", margin: "12px 0" }} defaultExpanded>
                                                    <AccordionSummary
                                                        key={item.id}
                                                        className={classes.titlePanel}
                                                        classes={{ root: classes.expandedMargin }}
                                                        expandIcon={<ExpandMoreIcon />}
                                                        style={{
                                                            backgroundColor: "#ddd",
                                                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 0px, rgba(0, 0, 0, 0.2) 0px 1px 1px",
                                                        }}>
                                                        <Formm
                                                            key={index}
                                                            getApi={(el) => (this.formApi = el)}
                                                            dontValidateOnMount="true"
                                                            validateOnSubmit="true"
                                                            defaultValues={item}
                                                            onSubmit={(values) => this.saveGroup(values)}>
                                                            {(formApi) => (
                                                                <form onSubmit={formApi.submitForm} style={{ width: "100%" }}>
                                                                    <table style={{ width: "100%", marginBottom: -10, marginTop: -8 }}>
                                                                        <tr>
                                                                            <td style={{ width: "100%" }}>
                                                                                <table>
                                                                                    <tr>
                                                                                        <Tooltip title="Drag and Drop Groups for Form Ordering">
                                                                                            <td>
                                                                                                <img
                                                                                                    style={{
                                                                                                        width: "2.2em",
                                                                                                        height: "2.2em",
                                                                                                        verticalAlign: "middle",
                                                                                                        marginRight: 11,
                                                                                                        marginLeft: 6,
                                                                                                    }}
                                                                                                    src={drag}
                                                                                                    alt="fireSpot"
                                                                                                />
                                                                                            </td>
                                                                                        </Tooltip>
                                                                                        <td style={{ width: "100%" }}>
                                                                                            <Typography
                                                                                                variant="h5"
                                                                                                style={{ fontSize: 18 }}
                                                                                                className={!item.name && classes.redText}>
                                                                                                {item.name
                                                                                                    ? item.name
                                                                                                    : "Click on the Edit Button to the right to assign a Group Name ~>"}
                                                                                            </Typography>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>

                                                                            <td style={repeatableStyle}>
                                                                                {/* {section[0].repeatable_subform && ( */}
                                                                                <>
                                                                                    <Typography style={{ marginTop: "22px", marginRight: "16px" }}>
                                                                                        Repeatable Group?
                                                                                    </Typography>
                                                                                    <Switch
                                                                                        field="repeatable"
                                                                                        name="repeatable"
                                                                                        // disabled={true}
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            ormGroupUpdate({
                                                                                                id: item.id,
                                                                                                repeatable: !item.repeatable,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                                {/* )} */}
                                                                                <Tooltip title="Edit Group Name">
                                                                                    <IconButton
                                                                                        style={{ marginLeft: "10px" }}
                                                                                        color="primary"
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            this.setState({
                                                                                                groupDialogopen: true,
                                                                                                activeIndex: index,
                                                                                            });
                                                                                        }}>
                                                                                        <Edit />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Delete Group">
                                                                                    <IconButton
                                                                                        onClick={(e) => {
                                                                                            this.deleteGroup(item);
                                                                                            e.stopPropagation();
                                                                                        }}>
                                                                                        <Delete />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </form>
                                                            )}
                                                        </Formm>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ padding: "0px" }}>
                                                        <Grid container>
                                                            <Grid
                                                                style={{
                                                                    marginLeft: 40,
                                                                    marginTop: 12,
                                                                    marginBottom: 16,
                                                                    marginRight: 16,
                                                                }}
                                                                item
                                                                xs={12}>
                                                                {item._fields < 1 && !item.mirror_repeatabletable && (
                                                                    <Typography>No Fields Defined</Typography>
                                                                )}
                                                                {item._fields < 1 && item.mirror_repeatabletable && (
                                                                    <Typography>
                                                                        This Groups configuration will pull in other section/groups to fill in this
                                                                        repeatable table
                                                                    </Typography>
                                                                )}

                                                                {/* Fieldbuttons class maps through and prints out each field */}
                                                                <FieldButtons
                                                                    //geoCounter={this.state.geoCounter}
                                                                    ormFieldReload={ormFieldReload}
                                                                    ormFieldDelete={ormFieldDelete}
                                                                    ormFieldUpdate={ormFieldUpdate}
                                                                    ormParentRecordReload={ormParentRecordReload}
                                                                    disableDrag={disableDrag}
                                                                    disableDragFunction={this.disableDragFunction}
                                                                    group_id={item.id}
                                                                    classes={classes}
                                                                    section={section}
                                                                    // fields={item._fields}
                                                                    fields2={fields2}
                                                                    groups={this.props.groups}
                                                                    allSections={allSections}
                                                                    allPageSections={allPageSections}
                                                                    fieldToOpen={fieldToOpen}
                                                                    setfieldToOpen={this.setfieldToOpen}
                                                                />
                                                                {!item.mirror_repeatabletable && (
                                                                    <div style={{ paddingRight: 5, paddingLeft: 5 }}>
                                                                        <Button
                                                                            onClick={() => this.createField(item.id, item._fields.length)}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            fullWidth>
                                                                            <AddCircleOutlineIcon />
                                                                            &nbsp;&nbsp;Add New Field
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        )}
                                    </Draggable> 
                                    {moving && index === 0 && (
                                        <Dialog open={moving}>
                                            <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                                <Typography variant="h5" className={classes.flex}>
                                                    Resaving Ordering
                                                </Typography>
                    
                                                {/* <IconButton onClick={returnDialog}>
                                                    <Close />
                                                </IconButton> */}
                                            </Toolbar>
                                            <DialogContent style={{ minWidth: 400 }}>
                                                <DialogContentText>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                                            <CircularProgress size={90} className={classes.progress} />
                                                            <Typography variant="h6" style={{ textAlign: "center" }}>
                                                                Please Wait...
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </DialogContentText>
                                            </DialogContent>
                                        </Dialog>
                                    )}
                                    {/* DIALOG FOR NAMING THE GROUPS ON THE FORM  */}
                                    {activeIndex === index && (
                                        <Dialog open={groupDialogopen} classes={{ paper: classes.minWidth }}>
                                            <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                                <Typography variant="h5" className={classes.flex}>
                                                    Edit Group
                                                </Typography>
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({ groupDialogopen: false });
                                                    }}>
                                                    <Close />
                                                </IconButton>
                                            </Toolbar>
                                            <DialogContent className={classes.dialogMin}>
                                                <DialogContentText>
                                                    <Formm
                                                        key={index}
                                                        getApi={(el) => (this.formApi = el)}
                                                        dontValidateOnMount="true"
                                                        validateOnSubmit="true"
                                                        defaultValues={item}
                                                        onSubmit={(values) => this.saveGroup(values)}>
                                                        {(formApi) => (
                                                            <form onSubmit={formApi.submitForm}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <TextField field="name" label="Group Name" fullWidth />
                                                                        <Grid item xs={6}>
                                                                            <Switch
                                                                                field="default_expanded"
                                                                                label={
                                                                                    <HelpLabel
                                                                                        inputLabel="Have Group Expanded by Default?"
                                                                                        helpText="Upon opening a record this group will be expanded or not"
                                                                                    />
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Switch
                                                                                field="mirror_repeatabletable"
                                                                                label="Mirror another 'Repeatable Table' into this group?"
                                                                                // eventHandle={(e) => {

                                                                                // }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                field="mirror_repeatablesection"
                                                                                fullWidth
                                                                                label={
                                                                                    <HelpLabel
                                                                                        inputLabel="Mirror Section ID"
                                                                                        helpText="Type in the Section Identification field (ie: 500) which has the repeatable group that you want to mirror."
                                                                                    />
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                style={{ marginTop: 10 }}
                                                                                field="mirror_repeatablegroup_ids"
                                                                                fullWidth
                                                                                label={
                                                                                    <HelpLabel
                                                                                        inputLabel="Mirror Repeatable Group ID's"
                                                                                        helpText="Type in the 'group identification' field from each form from the above section. Seperate multiple forms within the same section with a comma ex: 500,501."
                                                                                    />
                                                                                }
                                                                            />
                                                                        </Grid>

                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size="large"
                                                                            style={{ float: "right", marginRight: 7, marginTop: 11 }}
                                                                            className={classes.button}
                                                                            onClick={() => {
                                                                                this.setState({ groupDialogopen: false });
                                                                            }}>
                                                                            Save
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </form>
                                                        )}
                                                    </Formm>
                                                </DialogContentText>
                                            </DialogContent>
                                        </Dialog>
                                    )}
                                    {index === 0 && section[0].append_fields && (
                                        <Dialog open={loading} classes={{ paper: classes.minWidth2 }}>
                                            <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                                <Typography variant="h5" className={classes.flex}>
                                                    Adding New Field to Prior Records
                                                </Typography>
                                            </Toolbar>
                                            <DialogContent className={classes.dialogMin}>
                                                <DialogContentText>
                                                    <Typography variant="h6" className={classes.flex}>
                                                        Please Wait ...
                                                    </Typography>
                                                    <CircularProgress size={90} className={classes.progress} />
                                                </DialogContentText>
                                            </DialogContent>
                                        </Dialog>
                                    )}
                                </>
                            ))}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <WarningDialog
                    confirmAction={() => {
                        ormGroupDelete(deleteDialogConfirmAction.id);
                        // Reduce the order for every group above this group
                        groups.forEach(function (g) {
                            if (g.order > deleteDialogConfirmAction.order) {
                                ormGroupUpdate({
                                    id: g.id,
                                    order: g.order - 1,
                                });
                            }
                        });
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    confirmText="Delete Group"
                    title={`Delete ${deleteDialogConfirmAction ? deleteDialogConfirmAction.name : " "} Group?`}
                    text="Are you sure you wish to permanently delete this group?"
                />
            </DragDropContext>
        );
    }
}

//buttons of each group
class FieldButtons extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            fieldDialog: false,
            fieldState: null,
            typez: "",
            autopop: false, //date field auto populating
            deleteDialogOpen: false,
            field: null,
            populateFromAnotherSection: null,
        };
        this.returnDialog = this.returnDialog.bind(this);
    }

    deleteField = (field) => {
        this.setState({ field, deleteDialogOpen: true });
    };

    returnDialog() {
        const { disableDragFunction } = this.props;
        this.setState({ fieldDialog: false, fieldState: null });
        disableDragFunction(false);
    }

    openDialog(f) {
        const { disableDragFunction } = this.props;

        this.setState({ fieldDialog: true, fieldState: f, autopop: f.auto_populate_another_date });
        if (!f.name) {
            this.setState({ typez: "" }); //reset typez back for non filled in edit form cases
        } else {
            this.setState({ typez: f.type }); //update state for clicking on a different field
        }
        disableDragFunction(true);
        this.props.setfieldToOpen(); //is called when we first create or open a field and we want to clear state in above component (groupcontainer) so rerenders dont double open
    }

    //set 'typez-->datatype' this is parent state which is passed down to 'addField' component
    setType = (type) => {
        this.setState({ typez: type });
    };

    //for autopopulating another date field
    setautopop = (v) => {
        this.setState({ autopop: v });
    };

    setPopulateFromAnotherSection = (v) => {
        this.setState({ populateFromAnotherSection: v });
    };

    render() {
        const {
            classes,
            // fields,
            group_id,
            ormFieldUpdate,
            ormFieldReload,
            ormFieldDelete,
            ormParentRecordReload,
            disableDrag,
            section,
            allSections,
            allPageSections,
            fields2,
            fieldToOpen,
        } = this.props;
        const { fieldDialog, fieldState, field, deleteDialogOpen } = this.state;
        let fields = fields2.filter((f) => f.group === group_id);
        if (fieldToOpen && fieldToOpen["open"] && !fieldDialog) this.openDialog(fieldToOpen["field"]); //creating a new field route to opening editing to setup as we want users to fill out right away
        return (
            <Droppable droppableId={"field_list-" + group_id} type={"field_list-" + group_id} key={"field_list-" + group_id}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} style={getListStyle2(snapshot.isDraggingOver)}>
                        {fields.map((f, index) => (
                            <Draggable key={f.id} draggableId={f.id} index={f.order} isDragDisabled={disableDrag}>
                                {(provided, snapshot) => (
                                    //group general field information (each button)
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle2(snapshot.isDragging, provided.draggableProps.style)}
                                        variant="contained"
                                        className={classes.fieldButton}>
                                        <table style={{ width: "100%", marginBottom: -10, marginTop: -8 }}>
                                            <tr>
                                                <td style={{ width: "100%" }}>
                                                    {!f.type && (
                                                        <Typography style={{ textAlign: "center" }} className={classes.redText}>
                                                            Drag to Rearrange or Click Edit to Modify Field
                                                        </Typography>
                                                    )}
                                                    {f.type && (
                                                        <table>
                                                            <tr>
                                                                <Tooltip title="Drag and Drop Fields for Form Ordering">
                                                                    <td>
                                                                        <img
                                                                            style={{
                                                                                width: "1.5em",
                                                                                height: "1.6em",
                                                                                verticalAlign: "middle",
                                                                                marginRight: 11,
                                                                                marginLeft: 6,
                                                                            }}
                                                                            src={drag}
                                                                            alt="fireSpot"
                                                                        />
                                                                    </td>
                                                                </Tooltip>
                                                                <td style={{ whiteSpace: "nowrap" }}>
                                                                    <Typography
                                                                        style={{
                                                                            width: 77,
                                                                            paddingRight: 4,
                                                                            textAlign: "center",
                                                                            color:
                                                                                f.name && f.name.includes("New Field Configure Me")
                                                                                    ? "red"
                                                                                    : "#262626",
                                                                        }}>
                                                                        {f.type}
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <div
                                                                        style={{
                                                                            margin: "-5px 8px",
                                                                            backgroundColor: "#262626",
                                                                            width: 2,
                                                                            height: 52,
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td style={{ width: "100%" }}>
                                                                    <Typography
                                                                        style={{
                                                                            color:
                                                                                f.name && f.name.includes("New Field Configure Me")
                                                                                    ? "red"
                                                                                    : "#262626",
                                                                        }}>
                                                                        {f.name} <span style={{float:"right"}}>(ID: {f.id})</span>
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    )}
                                                </td>
                                                <td style={{ whiteSpace: "nowrap" }}>
                                                    <Tooltip title="Edit Field">
                                                        <IconButton
                                                            color="primary"
                                                            onClick={(e) => {
                                                                this.openDialog(f);
                                                            }}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete Field">
                                                        <IconButton
                                                            onClick={() => {
                                                                this.deleteField(f); //delete field from this component
                                                            }}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        <WarningDialog
                            confirmAction={() => {
                                ormFieldDelete(field.id);
                                // Reduce the order for every field above this field
                                fields.forEach(function (f) {
                                    if (f.order > field.order) {
                                        ormFieldUpdate({
                                            id: f.id,
                                            order: f.order - 1,
                                        });
                                    }
                                });
                                this.setState({ deleteDialogOpen: false });
                            }}
                            cancelAction={() => this.setState({ deleteDialogOpen: false })}
                            open={deleteDialogOpen}
                            confirmText="Delete Field"
                            title={`Delete ${field ? field.name : " "} Field?`}
                            text="Are you sure you wish to permanently delete this Field?"
                        />
                        {fieldState && fieldState.group === group_id && (
                            <AddField
                                actualType={this.state.typez} //get state of type to pass down
                                typeChange={this.setType} //pass upstream into this component
                                classes={classes}
                                open={fieldDialog}
                                field={fieldState}
                                section={section}
                                groupid={group_id}
                                groups={this.props.groups}
                                returnDialog={this.returnDialog}
                                ormFieldUpdate={ormFieldUpdate}
                                ormFieldDelete={ormFieldDelete}
                                ormFieldReload={ormFieldReload}
                                ormParentRecordReload={ormParentRecordReload}
                                deletePassedFromFields={this.state.deleteStatus}
                                autopop={this.state.autopop} //for autopopulating another date field
                                setautopop={this.setautopop}
                                setPopulateFromAnotherSection={this.setPopulateFromAnotherSection} //for autopopulating another date field
                                populateFromAnotherSection={this.state.populateFromAnotherSection}
                                allSections={allSections}
                                allPageSections={allPageSections}
                            />
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    }
}

//Popup up for editing or adding a field to the drag and drop section
class AddField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            snackbarOpen: false,
            descriptionButtons: false,
            descriptionFlag: false,
            serviceAttribute: false,
            serviceFlag: false,
            selectedSection: null,
            agol_fields: [],
            showids:false
        };

        const field = props.field;
        if (field && field.default_value_service) {
            this.serviceLookup(field.default_value_service);
        }
    }

    updateField(values) {
        const { ormFieldUpdate, field, returnDialog, setautopop, ormParentRecordReload } = this.props;

        //clean up spaces in values.csv dropdown list
        if (values.values_csv) {
            var t = values.values_csv.split(",");
            if (t.length > 0) {
                var trimArr = t.map((e) => {
                    return e.trim();
                });
                var rebasedArr = "";
                trimArr.forEach((j, index) => {
                    rebasedArr += j;
                    if (index + 1 !== trimArr.length) return (rebasedArr += ",");
                });
                values.values_csv = rebasedArr; //reassign back all spaces will have been removed.
            }
        }
        if (field.type === "Calculated" && !values.values_csv && values.default_value) {
            values.values_csv = ""; //remove any prior datatype saving/use of this field for export
        }
        if (!values.decimals){
            values.decimals = 0
        }
        ormFieldUpdate({
            id: field.id,
            ...values,
        });

        returnDialog();

        setautopop(false); //pass state upwards for autopopulating date

        this.setState({ descriptionFlag: false, descriptionButtons: false, serviceAttribute: false, serviceFlag: false });
        if (values.append_to_header) {
            //doing a blanket 10sec after incase they have alot of records--def isnt fail safe worse case they have to reload
            setTimeout(() => {
                ormParentRecordReload();
            }, 10000);
        }
    }

    deleteField = () => {
        const { ormFieldDelete, field, returnDialog } = this.props;

        ormFieldDelete(field.id);

        returnDialog();
    };

    //error validator for saving of options for each field-checks option cases with logic to make sure
    //specific one are adheared to properly
    errorValidator = (values) => {
        const { groups } = this.props;
        let id = 0;
        let append = 0;
        let otherField = null;
        var valObj = {};
        var calcFields = [];
        //check for indentification, or append_to_header being used more than once
        groups.forEach((g) => {
            return g._fields.forEach((f) => {
                //eslint-disable-next-line
                return (
                    f.identification && f.id !== values.id ? id++ : null,
                    f.append_to_header && f.id !== values.id ? (append === 1 ? append++ : ((otherField = f.name), append++)) : null,
                    f.type === "Numeric" || f.type === "Currency" || f.type === "Calculated" ? calcFields.push(f.name) : null //
                );
            });
        });

        //text output for checking of columns
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        //check for default value matching one in the dropdown list
        const doesntMatch = (val) => {
            return !val ? "Default Value needs to be a value in dropdown list" : null;
        };
        //check that if "is_read_only " is selected that a default value is presented
        const isReadAndDefault = (val) => {
            return !val ? "Requires a default value for read only" : null;
        };

        const checkJSON = (val) =>{
            try {
                JSON.parse(values.calc_jsonfield)
                return null
            } catch (error) {
                return `An error was found with the JSON conditional formua. For exact postion location of the error copy the forumla and validate at https://www.jsonlint.com ${error}`
            }
        }
        //dropdown and multicase checking "read only", "default value" & "csv list"
        //making sure the 'read only' case checks that a 'default value' was provided
        //& if a 'default value' is used to make sure it matches the 'values_csv' list
        if (values.type === "Multi Select" || values.type === "Drop Down") {
            let status;
            var checkDefaultAndValuesCsv = false; //flag
            var valCheck;
   

            //values_csv field was NOT filled out
            if (values.values_csv == null || values.values_csv === "" || values.values_csv === " ") {
                if (values.user_dropdown || values.populate_from_another_section) {
                    //dont require 'dropdown' field as 'user_dropdown' was selected
                    valObj = {
                        name: isRequired(values.name),
                        type: isRequired(values.type),
                        values_csv: null, //clear buffer
                        default_value: null, //clear buffer
                    };
                } else {
                    //error as values_csv field wasnt filled out
                    if(!values.calc_jsonfield){
                        valCheck = "At least two values are required to save the form with this field type";
                        valObj = {
                            name: isRequired(values.name),
                            type: isRequired(values.type),
                            values_csv: valCheck,
                            default_value: null, //clear buffer
                        };
                    }
                }

                return valObj;
            }

            //check logic for values_csv, default_values, and is_read_only for logic
            if (values.values_csv) {
                if (values.user_dropdown) {
                    //error as values_csv was filled out and 'user dropdown' was selected
                    valObj = {
                        name: isRequired(values.name),
                        type: isRequired(values.type),
                        values_csv: "User dropdown 'option' can not have additional values remove values to proceed",
                        default_value: null, //clear buffer
                    };
                    return valObj;
                }

                //chk logic for more than values list being properly set
                let valuesArray = values.values_csv.trim().split(",");
                if (valuesArray.length > 1) {
                    valuesArray.find((f) => {
                        f === "" ? (valCheck = "One value is missing, this datatype requires 2 or more values") : (valCheck = null);
                        return null;
                    });
                }
                if (valuesArray.length === 1) {
                    valCheck = "One value is missing, this datatype requires 2 or more values";
                }

                //OPTION CHECKS
                if (!values.default_value && values.is_read_only) {
                    status = false;
                } else if (values.default_value !== " " && values.default_value !== "") {
                    //check to make sure values match with default value and values_csv, this also checks 'is_read_only' case
                    status = true;
                    checkDefaultAndValuesCsv = true;
                } else {
                    //neither 'default_value or values_csv' are checked so allow to proceed
                    status = true;
                }
            }

            //check for 'default value' matching 'values_csv' list & clean up list
            if (checkDefaultAndValuesCsv) {
                var tempArr = [];
                var str = values.values_csv;
                let wtf = "";
                let shouldPass = false;

                for (let i = 0; i < str.length; i++) {
                    if (str[i].includes("\\")) {
                        shouldPass = true;
                    } else if (shouldPass) {
                        //record this comma as \ before it
                        wtf = wtf + str[i];
                        shouldPass = false;
                    } else if (str[i] === ",") {
                        //not \ before it so record selection
                        tempArr.push(wtf);
                        wtf = "";
                    } else {
                        wtf = wtf + str[i];
                        if (i === str.length - 1) {
                            //last selection save
                            tempArr.push(wtf);
                        }
                    }
                }
                var match = tempArr.find((val) => {
                    if (val === values.default_value.replace("\\", "")) {
                        return val;
                    }
                    return null;
                });
                valObj = {
                    //check for default value to match a value in the dropdown list--total 4 fields
                    name: isRequired(values.name),
                    type: isRequired(values.type),
                    values_csv: valCheck,
                    default_value: doesntMatch(match),
                    calc_jsonfield: values.calc_jsonfield ? checkJSON(values.calc_jsonfield): null
                };
                return valObj;
            } else {
                valObj = {
                    //check for 'default value' to check the "read only" checkbox being checked
                    name: isRequired(values.name),
                    type: isRequired(values.type),
                    values_csv: valCheck,
                    default_value: isReadAndDefault(status),
                    calc_jsonfield: values.calc_jsonfield ? checkJSON(values.calc_jsonfield): null
                };
                return valObj;
            }

           

        } else if (values.type === "Radio") {
            let defaultCheck, valCheck;

            if (!values.values_csv) return (valObj = { values_csv: "At least two values are required to save the form with this field type" }); //chk if not filled out at all
            var valuesArray = values.values_csv !== "" ? values.values_csv.trim().split(",") : null;

            //chk values for blanks in csv list
            if (values.values_csv) {
                if (valuesArray.length > 1) {
                    valuesArray.find((f) => {
                        f === "" ? (valCheck = "One value is missing, this datatype requires 2 or more values") : (valCheck = null);
                        return null;
                    });
                }
                if (valuesArray.length === 1) {
                    valCheck = "One value is missing, this datatype requires 2 or more values";
                }
            } else if (values.values_csv === "") {
                valCheck = "At least two values are required to save the form with this field type";
            }

            //chk default value matches values for radio
            if (values.default_value && values.values_csv) {
                if (valuesArray.length > 1) {
                    valuesArray.find((f) => {
                        if (values.default_value === f) defaultCheck = null;
                        return null;
                    });
                    if (defaultCheck === undefined) defaultCheck = "Default value does not match one of the values in the list";
                }
            }
            if (defaultCheck === undefined && values.is_read_only) defaultCheck = "Default value must be filled out for read only selection";

            valObj = {
                name: isRequired(values.name),
                type: isRequired(values.type),
                values_csv: valCheck,
                default_value: defaultCheck,
            };
            return valObj;
        } else if (values.type === "Related Field") {
            valObj = {
                populate_field: isRequired(values.populate_field),
            };
        } else if (values.type === "Calculated") {
            let temp = values.default_value.split(",");
            valObj["default_value"] = null;
       
            var t;
            temp.forEach((f, ix) => {
                if (f.includes("[") || f.includes("]")) {
                    t = temp[ix].replace(/[^-+*/\w\s]/gi, "");
                    if (t === "Internal_ID") valObj["default_value"] = null;
                    else if (!calcFields.find((c) => (c === t ? c : null)))
                        // return console.log("pass")
                        //check if field is numeric/currency
                        valObj["default_value"] = `'${t}' is not a valid 'numeric', 'currency' or 'calculated' field.`;
                } else if (f.match(/[.0-9]/g) && f.match(/[.0-9]/g).length === f.length) {
                    //verify just numeric digits and pass
                } else if (f !== "-" && f !== "+" && f !== "*" && f !== "/" && f !== "sqrt" && f !== "^" && f !== "(" && f !== ")") {
                    //check for valid operators
                    if (f === "") {
                        valObj[
                            "default_value"
                        ] = `The calculation formula must include '+ , - , * , / , sqrt, ^' operators between fields. Refer to the help bubble for more information.`;
                    } else {
                        // return console.log("pass")
                        valObj[
                            "default_value"
                        ] = `'${f}' is not a valid operator. Calculation fields only accept '+ , - , * , / , sqrt, ^' operators. Refer to the help bubble for more information.`;
                    }
                }
            });
        }
        //NON-dropdown/multicase, however, checks for "readonly check box" and "default value" field association
        else if (values.type !== "Multi Select" || values.type !== "Drop Down") {
            if ((values.default_value || values.is_read_only) && !values.values_csv) {
                var status;
                if (values.default_value && values.is_read_only) status = true;
                else if (values.type === "Date" && values.is_read_only) status = true;
                else if (!values.default_value && values.is_read_only) status = false;
                else if (values.default_value && !values.is_read_only) status = true;

                valObj = {
                    //check for 'default value' to check the "read only" checkbox being checked
                    name: isRequired(values.name),
                    type: isRequired(values.type),
                    default_value: isReadAndDefault(status),
                };
            } else {
                //neither "default value" or "read only" field is checked, this is to clear the error buffer on 'default value'
                //incase it was set previously & then removed
                valObj = {
                    name: isRequired(values.name),
                    type: isRequired(values.type),
                    default_value: null, //clear buffer
                };
            }
        }
        if (id >= 1 && values.identification) valObj["identification"] = "Only 1 identification field per 'primary' form. Uncheck to proceed";
        else valObj["identification"] = null;
        if (append >= 1 && values.append_to_header)
            valObj[
                "append_to_header"
            ] = `Only 1 append to header can be selected per primary form. The other field is set on ${otherField}. Uncheck one of these to proceed`;
        else valObj["append_to_header"] = null;

        return valObj;
    };

    //prints out the datatype options based on case of datatype
    getDataTypeOptions = (type) => {
        const {
            classes,
            section,
            groups,
            groupid,
            ormFieldUpdate,
            field,
            autopop,
            setautopop,
            allSections,

            populateFromAnotherSection,
            setPopulateFromAnotherSection,
        } = this.props;

        const { selectedSection, serviceAttribute, agol_fields, agol_loading, agol_error } = this.state;
        var isParent = section[0].parent; //check for parentSection else dont show id field

        var isRepeatable = groups.find((g) => {
            //chk if section is repeatable for options to become available
            return g.id === groupid ? g : null;
        });

        //get sections names for dropdown/multi
        var sections = [];
        allSections.forEach((s) => sections.push(s));

        function MAKE_OPTIONS(select) {
            return select.map((s) => ({
                label: s.name,
                value: s.id,
            }));
        } //for autopopulate another date, uses just value(name) for database
        function MAKE_OPTIONS2(select) {
            return select.map((s) => ({
                label: s.name,
                value: s.name,
            }));
        }
        function MAKE_OPTIONS3(select) {
            return select.map((s) => ({
                label: s,
                value: s,
            }));
        }

        //add other date fields in an array that are 'not' repeatable and not this field, used for autopopulating off another field in the "Date" case below
        //also add hidefield logic to the 'Yes/No' option
        var dateFields = [],
            hideFields = [],
            relatedfield = [];
        groups.forEach((g) =>
            !g.repeatable
                ? g._fields.forEach(
                      (f) => (
                          //eslint-disable-next-line
                          f.type === "Date" && field && f.name !== field.name ? (dateFields.push(f), hideFields.push(f)) : hideFields.push(f),
                          f.type ? relatedfield.push(f) : null
                      )
                  )
                : null
        );

        var fields = [];
        if (selectedSection) {
            let thisSection = allSections.filter((s) => s.id === parseInt(selectedSection));
            thisSection.forEach((s) => {
                s.forms.forEach((fo) => {
                    fo.groups.forEach((g) => {
                        g.fields.forEach((f) => {
                            fields.push(f);
                        });
                    });
                });
            });
        }

        switch (type) {
            case "Text":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Checkbox field="is_required">
                                    <HelpLabel
                                        inputLabel="Is Required"
                                        helpText="User interface will force this field to have a value entered before the record can be saved."
                                    />
                                </Checkbox>
                                <Checkbox field="is_read_only">
                                    <HelpLabel inputLabel="Is Read Only" helpText="User interface will have this field as 'read only'." />
                                </Checkbox>
                                {isParent && !isRepeatable.repeatable && (
                                    <Checkbox field="identification">
                                        <HelpLabel
                                            inputLabel="Identification? (one per module)"
                                            helpText="This field will be used to store the relationship between primary and related records. Only one field in each module can be the identification field. The field must also be within the primary section."
                                        />
                                    </Checkbox>
                                )}
                                {!isParent && !isRepeatable.repeatable && section[0].has_geometry && (
                                    <Checkbox field="child_identification">
                                        <HelpLabel
                                            inputLabel="Map Label (one field per section)"
                                            helpText={
                                                <div>
                                                    This field will be displayed on the map as a label if configured. Only one field per section can
                                                    be shown as a label.
                                                    <br />
                                                    <br />
                                                    The labeling option must also be turned on in the Section settings.
                                                </div>
                                            }
                                        />
                                    </Checkbox>
                                )}
                                {!isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                {!isParent && (
                                    <Checkbox field="last_record_parent_table">
                                        <HelpLabel
                                            inputLabel="Show most recent in primary table?"
                                            helpText="Most recent record will show in the primary table"
                                        />
                                    </Checkbox>
                                )}
                                {isParent && !isRepeatable.repeatable && (
                                    <Checkbox field="append_to_header">
                                        <HelpLabel
                                            inputLabel="Append to Header?"
                                            helpText="Fields value will be appended to the 'Identification' fields header and should not be set on an 'Identification' field. Note: only one 'Append to Header' field can be used per form."
                                        />
                                    </Checkbox>
                                )}
                                <TextField
                                    style={{ marginTop: 10, marginBottom: 7 }}
                                    field="default_value"
                                    fullWidth
                                    label={<HelpLabel inputLabel="Default Value" helpText="Field will be pre-populated with this value" />}
                                />
                                {section[0].has_geometry && (
                                    <>
                                        <TextField
                                            style={{ marginTop: 10, marginBottom: 7 }}
                                            fullWidth
                                            field="default_value_service"
                                            eventHandle={(e) => {
                                                if (e && e.trim()) { 
                                                    this.setState({ serviceAttribute: true });
                                                    this.serviceLookup(e.trim());
                                                }
                                                else this.setState({ serviceAttribute: false });
                                            }}
                                            label={
                                                <HelpLabel
                                                    inputLabel="Populate Value from ArcGIS Service"
                                                    helpText={
                                                        <div>
                                                            This configuration setting will use a GIS service to pre-populate a text or drop down
                                                            fields within the same MS4Front module section as the geometry. When the geometry on the
                                                            record is updated (created or edited) MS4Front will perform a spatial query on the service
                                                            and attempt to grab the value from the attribute to populate this field with. The service
                                                            must be shared with the public. Ex:
                                                            https://maps.co.ramsey.mn.us/arcgis/rest/services/MapRamsey/MapRamseyOperationalLayersAll/MapServer/84
                                                            <br />
                                                            <br />
                                                            This configuration option is only available on text and drop down field types. It is also
                                                            only supported with ESRI Feature and Map services. The services may be published in any
                                                            coordinate system. If the attribute value doesn't match the list of values in a drop down
                                                            configuration then it won't populate the field.
                                                        </div>
                                                    }
                                                />
                                            }
                                        />
                                        {serviceAttribute && (
                                            <Subquestion
                                                component={
                                                    agol_loading ? (
                                                        <CircularProgress size={24} />
                                                    ) : agol_error ? (
                                                        <Typography style={{color: "darkred"}}>Service Unreachable</Typography>
                                                    ) : (
                                                        <Select
                                                            style={{ marginTop: 10, marginBottom: 7 }}
                                                            fullWidth
                                                            field="default_value_attribute"
                                                            options={MAKE_OPTIONS3(agol_fields)}
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Service Attribute Name"
                                                                    helpText="The attribute name to pull from the service."
                                                                />
                                                            }
                                                        />
                                                    )
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </fieldset>
                );
            case "Currency":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={2}>
                            <Grid item xs={!isParent ? 7 : 6}>
                                <Checkbox field="is_required">
                                    <HelpLabel
                                        inputLabel="Is Required?"
                                        helpText="User interface will force this field to have a value entered before the record can be saved."
                                    />
                                </Checkbox>
                                <Checkbox field="is_read_only">
                                    <HelpLabel inputLabel="Is Read Only" helpText="User interface will have this field as 'read only'." />
                                </Checkbox>
                                {!isParent && (
                                    <Checkbox field="last_record_parent_table">
                                        <HelpLabel
                                            inputLabel="Show most recent in primary table?"
                                            helpText="Most recent record will show in the primary table"
                                        />
                                    </Checkbox>
                                )}
                            </Grid>
                            <Grid item xs={!isParent ? 5 : 6}>
                                {isParent && !isRepeatable.repeatable && (
                                    <Checkbox field="identification">
                                        <HelpLabel
                                            inputLabel="Identification? (one per module)"
                                            helpText="This field will be used to store the relationship between primary and related records. Only one field in each module can be the identification field. The field must also be within the primary section."
                                        />
                                    </Checkbox>
                                )}
                                {!isParent && !isRepeatable.repeatable && section[0].has_geometry && (
                                    <Checkbox field="child_identification">
                                        <HelpLabel
                                            inputLabel="Map Label (one field per section)"
                                            helpText={
                                                <div>
                                                    This field will be displayed on the map as a label if configured. Only one field per section can
                                                    be shown as a label.
                                                    <br />
                                                    <br />
                                                    The labeling option must also be turned on in the Section settings.
                                                </div>
                                            }
                                        />
                                    </Checkbox>
                                )}
                                {!isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}
                                <TextField
                                    style={{ marginTop: 10 }}
                                    field="default_value"
                                    label={<HelpLabel inputLabel="Default Value" helpText="Field will be pre-populated with this value" />}
                                />
                                {isRepeatable.repeatable && (
                                    <TextField
                                        style={{ marginTop: 10 }}
                                        field="values_csv"
                                        label={
                                            <HelpLabel
                                                inputLabel="Value Summation?" //"Enter the non-repeatable field name to take the Sum of 'All' records with the above named field?"
                                                helpText="Enter the name of a field in a non-repeatable group in this Section to automatically show the sum of values stored in this repeatable field. This option is only available for Numeric or Currency field types"
                                            />
                                        }
                                        fullWidth
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </fieldset>
                );
            case "Yes/No":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Checkbox field="is_required">
                                    <HelpLabel
                                        inputLabel="Is Required?"
                                        helpText="User interface will force this field to have a value entered before the record can be saved."
                                    />
                                </Checkbox>
                                <Checkbox field="is_read_only">
                                    <HelpLabel inputLabel="Is Read Only" helpText="User interface will have this field as 'read only'." />
                                </Checkbox>
                                {!isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}{" "}
                                {!isParent && (
                                    <Checkbox field="last_record_parent_table">
                                        <HelpLabel
                                            inputLabel="Show most recent in primary table?"
                                            helpText="Most recent record will show in the primary table"
                                        />
                                    </Checkbox>
                                )}
                                {isParent && !isRepeatable.repeatable && (
                                    <Checkbox field="identification">
                                        <HelpLabel
                                            inputLabel="Identification? (one per module)"
                                            helpText="This field will be used to store the relationship between primary and related records. Only one field in each module can be the identification field. The field must also be within the primary section."
                                        />
                                    </Checkbox>
                                )}
                                {!isParent && !isRepeatable.repeatable && section[0].has_geometry && (
                                    <Checkbox field="child_identification">
                                        <HelpLabel
                                            inputLabel="Map Label (one field per section)"
                                            helpText={
                                                <div>
                                                    This field will be displayed on the map as a label if configured. Only one field per section can
                                                    be shown as a label.
                                                    <br />
                                                    <br />
                                                    The labeling option must also be turned on in the Section settings.
                                                </div>
                                            }
                                        />
                                    </Checkbox>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                {!isRepeatable.repeatable && (
                                    <>
                                        <Checkbox field="hide_field">
                                            <HelpLabel inputLabel="Hide Fields?" helpText="Selecting will enable 'Fields to Hide' to be active" />
                                        </Checkbox>
                                        <Subquestion
                                            component={
                                                <ReactSelect
                                                    label={
                                                        <HelpLabel
                                                            inputLabel={"Fields to Hide"}
                                                            helpText={`Choose the fields that will be 'shown' or 'hidden' based upon the 'Yes'/'No' value of this field. 
                                                            
                                                            Field to hide logic is as follows:
                                                             1.) The selected fields will be hidden when 'No' is selected. 
                                                             2.) If the 'Default Value' option is set to 'No' the selected fields will not be seen until 'Yes' is selected in the record. 
                                                             3.) If a 'Default Value' isnt choosen the hidden fields will default to be seen.
                                                             4.) Only fields previously created will show up in the selectable fields list.
                                                             5.) Repeatable group fields are excluded from the field list.
                                                             6.) All field datatypes are available to use, even within other groups.
                                                             7.) Selected fields should not be set to 'Required'
                                                             8.) Selected fields are only interfaceable with fields on the same form.
                                                             9.) Numeric/Currency fields that are selected within this list will retain there database value if they were previously filled-in/saved even though they wont be seen if 'No' is selected .
                                                             `}
                                                        />
                                                    }
                                                    style={{ marginTop: 3 }}
                                                    field={"fields_to_hide"}
                                                    options={MAKE_OPTIONS2(hideFields)}
                                                    // disabled={isReadOnly}
                                                    isMulti={true}
                                                    // required={groupfield.is_required}
                                                    height="auto"
                                                    fullWidth
                                                />
                                            }
                                        />
                                    </>
                                )}
                                {/* {!isRepeatable.repeatable && (
                                    <div style={{ marginLeft: 18, marginTop: 3 }}>
                                        <RadioButtonsV4
                                            field="logic_to_hide"
                                            label={
                                                <HelpLabel
                                                    inputLabel="Logic to Hide?"
                                                    helpText="Select the trigger logic that will hide the above fields"
                                                />
                                            }
                                        />
                                    </div>
                                )} */}
                                <div style={{ marginLeft: 18, marginTop: 3 }}>
                                    <RadioButtonsV4
                                        field="default_value"
                                        label={<HelpLabel inputLabel="Default Value" helpText="Field will be pre-populated with this value" />}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </fieldset>
                );
            case "Date":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container>
                            <Grid item xs={6}>
                                <Checkbox field="auto_populate">
                                    <HelpLabel
                                        inputLabel="Auto Populate with Current Date when Created?"
                                        helpText="Todays 'date' will be populated for creation of 'new' record"
                                        overrideStyling={true} //allows 2 rows to be aligned
                                    />
                                </Checkbox>
                                <Checkbox field="is_required">
                                    <HelpLabel
                                        inputLabel="Is Required?"
                                        helpText="User interface will force this field to have a value entered before the record can be saved."
                                    />
                                </Checkbox>
                                <Checkbox field="is_read_only">
                                    <HelpLabel
                                        inputLabel="Is Read Only"
                                        helpText="User interface will have this field as 'read only'. Auto-Populate should be checked when this option is set"
                                    />
                                </Checkbox>
                                {!isRepeatable.repeatable && autopop && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}
                                {isParent && !isRepeatable.repeatable && (
                                    <Checkbox field="identification">
                                        <HelpLabel
                                            inputLabel="Identification? (one per module)"
                                            helpText="This field will be used to store the relationship between primary and related records. Only one field in each module can be the identification field. The field must also be within the primary section."
                                        />
                                    </Checkbox>
                                )}
                                {!isParent && !isRepeatable.repeatable && section[0].has_geometry && (
                                    <Checkbox field="child_identification">
                                        <HelpLabel
                                            inputLabel="Map Label (one field per section)"
                                            helpText={
                                                <div>
                                                    This field will be displayed on the map as a label if configured. Only one field per section can
                                                    be shown as a label.
                                                    <br />
                                                    <br />
                                                    The labeling option must also be turned on in the Section settings.
                                                </div>
                                            }
                                        />
                                    </Checkbox>
                                )}
                                {!isParent && !autopop && (
                                    <Checkbox field="last_record_parent_table">
                                        <HelpLabel
                                            inputLabel="Show most recent in primary table?"
                                            helpText="Most recent record will show in the primary table"
                                        />
                                    </Checkbox>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                {!isParent && autopop && (
                                    <Checkbox field="last_record_parent_table">
                                        <HelpLabel
                                            inputLabel="Show most recent in primary table?"
                                            helpText="Most recent record will show in the primary table"
                                        />
                                    </Checkbox>
                                )}

                                {!isRepeatable.repeatable && (
                                    <Checkbox
                                        field="auto_populate_another_date"
                                        eventHandle={(v, i) => {
                                            //pass state upwards
                                            return setautopop(v) && ormFieldUpdate({ id: field.id, auto_populate_another_date: v });
                                        }}>
                                        <HelpLabel
                                            inputLabel="Auto Populate from another Date Field?"
                                            helpText="When enabled, the 'field to populate from' will be used for auto generation of each record. NOTE: the other date field must be conatined in the same Section and Form but can be in a different 'group' in the Form"
                                            overrideStyling={true}
                                        />
                                    </Checkbox>
                                )}
                                {!autopop && !isRepeatable.repeatable && ( // called out again to reformat view based on auto_populate field being true/false
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}
                                {autopop && !isRepeatable.repeatable && (
                                    <Subquestion
                                        component={
                                            <Select
                                                field="default_value"
                                                style={{ marginTop: 15 }}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Field to Populate From?"
                                                        helpText="Select the name of the Date field to populate from. Note: allowed date fields are fields that are non-repeatable and are within the same section."
                                                    />
                                                }
                                                options={MAKE_OPTIONS2(dateFields)}
                                                fullWidth
                                            />
                                        }
                                    />
                                )}
                                {autopop && !isRepeatable.repeatable && (
                                    <Subquestion
                                        component={
                                            <TextField
                                                style={{ marginTop: 15 }}
                                                field="values_csv"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Increment/Decrement Date by?"
                                                        helpText="Enter in the number of days to increment/decrement this date field by (ex. 2, 30 etc.). Note: this value can be negative or positve, but must be whole numbers"
                                                    />
                                                }
                                                fullWidth
                                            />
                                        }
                                    />
                                )}
                                {autopop && !isRepeatable.repeatable && (
                                    <>
                                        <div style={{ marginLeft: 10 }}>
                                            <Checkbox field="skip_weekends">
                                                <HelpLabel
                                                    inputLabel="Exclude Weekends?"
                                                    helpText="Auto Populating of this fields record will only use Monday-Friday when calculating the next date."
                                                />
                                            </Checkbox>
                                        </div>

                                        <div style={{ marginLeft: 10 }}>
                                            <Checkbox field="skip_holidays">
                                                <HelpLabel
                                                    inputLabel="Exclude Holidays?"
                                                    helpText={`Auto Populating of this fields record will not count observed holidays. 
                                                
                                                Observed holidays are as follows:
                                                 1.) New Years Day
                                                 2.) Martin Luther King, Jr. Day
                                                 3.) President's Day
                                                 4.) Memorial Day
                                                 5.) Juneteenth
                                                 6.) Independence Day
                                                 7.) Labor Day
                                                 8.) Veterans Day
                                                 9.) Thanksgiving Day
                                                 10.) Black Friday
                                                 11.) Christmas Day`}
                                                />
                                            </Checkbox>
                                        </div>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </fieldset>
                );
            case "Time":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={2}>
                            <Grid item xs={7}>
                                {!isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}
                                {!isParent && (
                                    <Checkbox field="last_record_parent_table">
                                        <HelpLabel
                                            inputLabel="Show most recent in primary table?"
                                            helpText="Most recent record will show in the primary table"
                                        />
                                    </Checkbox>
                                )}
                            </Grid>
                            <Grid item xs={5}>
                                <Checkbox field="is_required">
                                    <HelpLabel
                                        inputLabel="Is Required?"
                                        helpText="User interface will force this field to have a value entered before the record can be saved."
                                    />
                                </Checkbox>
                                <Checkbox field="auto_populate">
                                    <HelpLabel inputLabel="Auto Populate Time?" helpText="Field will be populated with time of record creation" />
                                </Checkbox>
                            </Grid>
                        </Grid>
                    </fieldset>
                );
            case "Drop Down":
            case "Multi Select":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={1}>
                            <Grid item xs={isParent ? 6 : 5}>
                                {isParent && !isRepeatable.repeatable && (
                                    <Checkbox field="identification">
                                        <HelpLabel
                                            inputLabel="Identification? (1 per module)"
                                            helpText="This field will be used to store the relationship between primary and related records. Only one field in each module can be the identification field. The field must also be within the primary section."
                                        />
                                    </Checkbox>
                                )}
                                {!isParent && !isRepeatable.repeatable && section[0].has_geometry && (
                                    <Checkbox field="child_identification">
                                        <HelpLabel
                                            inputLabel="Map Label (one field per section)"
                                            helpText={
                                                <div>
                                                    This field will be displayed on the map as a label if configured. Only one field per section can
                                                    be shown as a label.
                                                    <br />
                                                    <br />
                                                    The labeling option must also be turned on in the Section settings.
                                                </div>
                                            }
                                        />
                                    </Checkbox>
                                )}
                                <Checkbox field="is_required">
                                    <HelpLabel
                                        inputLabel="Is Required?"
                                        helpText="User interface will force this field to have a value entered before the record can be saved."
                                    />
                                </Checkbox>
                                <Checkbox field="is_read_only">
                                    <HelpLabel inputLabel="Is Read Only" helpText="User interface will have this field as 'read only'." />
                                </Checkbox>
                                {/* <Checkbox field="responsible_field" label="Responsible Staff Member?"  /> */}
                                <Checkbox field="user_dropdown">
                                    <HelpLabel
                                        inputLabel="User Dropdown? "
                                        helpText="Account active 'users' will be placed in the dropdown window, dropdown values from 'above' will be disregarded"
                                    />
                                </Checkbox>
                                <Checkbox field="auto_populate">
                                    <HelpLabel
                                        inputLabel="Auto Populate Current User?"
                                        helpText="Current user will be populated in field upon 'new' record creation"
                                    />
                                </Checkbox>
                            </Grid>

                            <Grid item xs={isParent ? 6 : 7}>
                                {!isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}

                                {!isParent && (
                                    <Checkbox field="last_record_parent_table">
                                        <HelpLabel
                                            inputLabel="Show most recent in primary table?"
                                            helpText="Most recent record will show in the primary table"
                                        />
                                    </Checkbox>
                                )}
                                {!isParent && (
                                    <Checkbox
                                        field="populate_from_another_section"
                                        eventHandle={(e) => {
                                            setPopulateFromAnotherSection(e); //for setting state to allow below options to popup
                                            if (e === true) {
                                                this.formApi.setValue("populate_field", field.populate_field);
                                            }
                                        }}>
                                        <HelpLabel
                                            inputLabel="Populate Dropdown from Another Section?"
                                            helpText="If this option is checked, the values in the drop down field will be populated by the selected Section Name and Field Name. Make sure values are not in the 'Enter Drop Down Values' above."
                                        />
                                    </Checkbox>
                                )}
                                {!isParent && populateFromAnotherSection && (
                                    <Subquestion
                                        component={
                                            <Select
                                                style={{ marginTop: 10 }}
                                                field="populate_section"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Section Name:"
                                                        helpText="Select the Section to generate the list of fields to select"
                                                    />
                                                }
                                                options={MAKE_OPTIONS(sections)}
                                                onClick={(e) => this.setState({ selectedSection: e.target.value })}
                                                fullWidth
                                                eventHandle={(e) => {}}
                                            />
                                        }
                                    />
                                )}
                                {!isParent && populateFromAnotherSection && (
                                    <Subquestion
                                        component={
                                            <Select
                                                style={{ marginTop: 10 }}
                                                field="populate_field"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Field Name:"
                                                        helpText="Select the Field, this will populate the dropdown list for each record created"
                                                    />
                                                }
                                                options={MAKE_OPTIONS(fields)}
                                                // onClick={(e) => this.setType(e)}
                                                fullWidth
                                                eventHandle={(e) => {}}
                                            />
                                        }
                                    />
                                )}

                                <TextField
                                    style={{ marginTop: 10 }}
                                    field="default_value"
                                    fullWidth
                                    eventHandle={(e) => {
                                        //need to delete space as is needed in form to trigger a save but not here with the error checker
                                        if (e === " ") {
                                            this.formApi.setValue("default_value", "");
                                        }
                                    }}
                                    label={<HelpLabel inputLabel="Default Value" helpText="Field will be pre-populated with this value" />}
                                />
                                {section[0].has_geometry && (
                                    <>
                                        <TextField
                                            style={{ marginTop: 10, marginBottom: 7 }}
                                            fullWidth
                                            field="default_value_service"
                                            eventHandle={(e) => {
                                                if (e && e.trim()) { 
                                                    this.setState({ serviceAttribute: true });
                                                    this.serviceLookup(e.trim());
                                                }
                                                else this.setState({ serviceAttribute: false });
                                            }}
                                            label={
                                                <HelpLabel
                                                    inputLabel="Populate Value from ArcGIS Service"
                                                    helpText={
                                                        <div>
                                                            This configuration setting will use a GIS service to pre-populate a text or drop down
                                                            fields within the same MS4Front module section as the geometry. When the geometry on the
                                                            record is updated (created or edited) MS4Front will perform a spatial query on the service
                                                            and attempt to grab the value from the attribute to populate this field with. The service
                                                            must be shared with the public. Ex:
                                                            https://maps.co.ramsey.mn.us/arcgis/rest/services/MapRamsey/MapRamseyOperationalLayersAll/MapServer/84
                                                            <br />
                                                            <br />
                                                            This configuration option is only available on text and drop down field types. It is also
                                                            only supported with ESRI Feature and Map services. The services may be published in any
                                                            coordinate system. If the attribute value doesn't match the list of values in a drop down
                                                            configuration then it won't populate the field.
                                                        </div>
                                                    }
                                                />
                                            }
                                        />
                                        {serviceAttribute && (
                                            <Subquestion
                                                component={
                                                    agol_loading ? (
                                                        <CircularProgress size={24} />
                                                    ) : agol_error ? (
                                                        <Typography style={{color: "darkred"}}>Service Unreachable</Typography>
                                                    ) : (
                                                        <Select
                                                            style={{ marginTop: 10, marginBottom: 7 }}
                                                            fullWidth
                                                            field="default_value_attribute"
                                                            options={MAKE_OPTIONS3(agol_fields)}
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Service Attribute Name"
                                                                    helpText="The attribute name to pull from the service."
                                                                />
                                                            }
                                                        />
                                                    )
                                                }
                                            />
                                        )}
                                    </>
                                )}
                                {!isRepeatable.repeatable && (
                                    <>
                                        <Checkbox field="hide_field">
                                            <HelpLabel inputLabel="Hide Fields?" helpText="Selecting will enable 'Fields to Hide' to be active" />
                                        </Checkbox>
                                        <Subquestion
                                            component={
                                                <ReactSelect
                                                    label={
                                                        <HelpLabel
                                                            inputLabel={"Fields to Hide"}
                                                            helpText={`Choose the fields that will be 'shown' or 'hidden' based upon the 'Drop Down' value of this field. 
                                                            
                                                            Field to hide logic is as follows:
                                                             1.) The selected fields will be hidden until the selected field_id in the json formula is selected. 
                                                             2.) If the 'Default Value' option is set to an 'unhide' field then the unhidden field will initally be seen until the value is changed 
                                                             3.) Only fields previously created will show up in the selectable fields list.
                                                             4.) Repeatable group fields are excluded from the field list.
                                                             5.) All field datatypes are available to use, even within other groups.
                                                             6.) Selected fields should not be set to 'Required'
                                                             7.) Selected fields are only interfaceable with fields on the same form.
                                                             8.) If a value was set into the hidden field and then the dropdown value was changed to hide the field the value will be set to 0 in the hidden field. Note: the value wont be saved into the database though until the record is saved.
                                                             `}
                                                        />
                                                    }
                                                    style={{ marginTop: 3 }}
                                                    field={"fields_to_hide"}
                                                    options={MAKE_OPTIONS2(hideFields)}
                                                    // disabled={isReadOnly}
                                                    isMulti={true}
                                                    // required={groupfield.is_required}
                                                    height="auto"
                                                    fullWidth
                                                />
                                            }
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </fieldset>
                );

            case "Numeric":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={2}>
                            <Grid item xs={7}>
                                {isParent && !isRepeatable.repeatable && (
                                    <Checkbox field="identification">
                                        <HelpLabel
                                            inputLabel="Identification? (one per module)"
                                            helpText="This field will be used to store the relationship between primary and related records. Only one field in each module can be the identification field. The field must also be within the primary section."
                                        />
                                    </Checkbox>
                                )}
                                {!isParent && !isRepeatable.repeatable && section[0].has_geometry && (
                                    <Checkbox field="child_identification">
                                        <HelpLabel
                                            inputLabel="Map Label (one field per section)"
                                            helpText={
                                                <div>
                                                    This field will be displayed on the map as a label if configured. Only one field per section can
                                                    be shown as a label.
                                                    <br />
                                                    <br />
                                                    The labeling option must also be turned on in the Section settings.
                                                </div>
                                            }
                                        />
                                    </Checkbox>
                                )}

                                <Checkbox field="is_required">
                                    <HelpLabel
                                        inputLabel="Is Required?"
                                        helpText="User interface will force this field to have a value entered before the record can be saved."
                                    />
                                </Checkbox>

                                <Checkbox field="is_read_only">
                                    <HelpLabel inputLabel="Is Read Only" helpText="User interface will have this field as 'read only'." />
                                </Checkbox>
                                {!isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}

                                <Checkbox field="thousands_seperator">
                                    <HelpLabel
                                        inputLabel="Show thousands separator?"
                                        helpText="Commas will appear in value when greater than a thousand. 
                                        </br><u>With the separator turned 'ON':</u>
                                        1001 would be displayed as 1,001"
                                    />
                                </Checkbox>
                                {!isParent && (
                                    <Checkbox field="last_record_parent_table">
                                        <HelpLabel
                                            inputLabel="Show most recent in primary table?"
                                            helpText="Most recent record will show in the primary table"
                                        />
                                    </Checkbox>
                                )}
                                {isRepeatable.repeatable && (
                                    <TextField
                                        style={{ marginTop: 10 }}
                                        field="values_csv"
                                        label={
                                            <HelpLabel
                                                inputLabel="Value Summation?" //"Enter the non-repeatable field name to take the Sum of 'All' records with the above named field?"
                                                helpText="Enter the name of a field in a non-repeatable group in this Section to automatically show the sum of values stored in this repeatable field. This option is only available for Numeric or Currency field types"
                                            />
                                        }
                                        fullWidth
                                    />
                                )}
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    field="measurement_unit"
                                    label={
                                        <HelpLabel
                                            inputLabel="Measurement Unit"
                                            helpText="Unit to be appended to field value. Example: ft, yd, acre, etc."
                                        />
                                    }
                                />
                                <TextField
                                    style={{ marginTop: 10 }}
                                    field="default_value"
                                    label={<HelpLabel inputLabel="Default Value" helpText="Field will be pre-populated with this value" />}
                                />
                                <TextField
                                    style={{ marginTop: 10 }}
                                    field="decimals"
                                    label={
                                        <HelpLabel
                                            inputLabel="Decimal Places"
                                            helpText="Default value is 2 decimal places. Set to control the number of decimal places to display or enter into the form."
                                        />
                                    }
                                />
                                <TextField
                                    style={{ marginTop: 10 }}
                                    field="restrict_value"
                                    label={
                                        <HelpLabel
                                            inputLabel="Restrict Value?" //"Enter the non-repeatable field name to take the Sum of 'All' records with the above named field?"
                                            helpText="Enter in numeric restriction values separated by a comma ex. 1,5. This would allow only input entries 1 thru 5.<br/>
                                            
                                            Note: restriction starting values should start with 0 or 1 for proper input entry, starting at a higher number will not allow lower number entries."
                                        />
                                    }
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                );
            case "Radio":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={2}>
                            <Grid item xs={7}>
                                {isParent && !isRepeatable.repeatable && (
                                    <Checkbox field="identification">
                                        <HelpLabel
                                            inputLabel="Identification? (one per module)"
                                            helpText="This field will be used to store the relationship between primary and related records. Only one field in each module can be the identification field. The field must also be within the primary section."
                                        />
                                    </Checkbox>
                                )}
                                {!isParent && !isRepeatable.repeatable && section[0].has_geometry && (
                                    <Checkbox field="child_identification">
                                        <HelpLabel
                                            inputLabel="Map Label (one field per section)"
                                            helpText={
                                                <div>
                                                    This field will be displayed on the map as a label if configured. Only one field per section can
                                                    be shown as a label.
                                                    <br />
                                                    <br />
                                                    The labeling option must also be turned on in the Section settings.
                                                </div>
                                            }
                                        />
                                    </Checkbox>
                                )}
                                <Checkbox field="is_required">
                                    <HelpLabel
                                        inputLabel="Is Required?"
                                        helpText="User interface will force this field to have a value entered before the record can be saved."
                                    />
                                </Checkbox>
                                <Checkbox field="is_read_only">
                                    <HelpLabel inputLabel="Is Read Only" helpText="User interface will have this field as 'read only'." />
                                </Checkbox>
                                {!isParent && (
                                    <Checkbox field="last_record_parent_table">
                                        <HelpLabel
                                            inputLabel="Show most recent in primary table?"
                                            helpText="Most recent record will show in the primary table"
                                        />
                                    </Checkbox>
                                )}
                            </Grid>
                            <Grid item xs={5}>
                                {!isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}
                                <TextField
                                    field="default_value"
                                    label={<HelpLabel inputLabel="Default Value" helpText="Field will be pre-populated with this value" />}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                );

            case "Calculated":
                return (
                    <>
                        <fieldset className={classes.fieldsetOptions}>
                            <legend>Options</legend>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    {isParent && !isRepeatable.repeatable && (
                                        <Checkbox field="identification">
                                            <HelpLabel
                                                inputLabel="Identification? (one per module)"
                                                helpText="This field will be used to store the relationship between primary and related records. Only one field in each module can be the identification field. The field must also be within the primary section."
                                            />
                                        </Checkbox>
                                    )}
                                    {!isParent && !isRepeatable.repeatable && section[0].has_geometry && (
                                        <Checkbox field="child_identification">
                                            <HelpLabel
                                                inputLabel="Map Label (one field per section)"
                                                helpText={
                                                    <div>
                                                        This field will be displayed on the map as a label if configured. Only one field per section
                                                        can be shown as a label.
                                                        <br />
                                                        <br />
                                                        The labeling option must also be turned on in the Section settings.
                                                    </div>
                                                }
                                            />
                                        </Checkbox>
                                    )}
                                    {!isParent && !isRepeatable.repeatable && (
                                        <Checkbox field="last_record_parent_table">
                                            <HelpLabel
                                                inputLabel="Show most recent in primary table?"
                                                helpText="Most recent record will show in the primary table"
                                            />
                                        </Checkbox>
                                    )}
                                    {!isRepeatable.repeatable && (
                                        <Checkbox field="is_column">
                                            <HelpLabel
                                                inputLabel="Show field in table view?"
                                                helpText="Record field will appear in the 'table' column"
                                            />
                                        </Checkbox>
                                    )}
                                    <Checkbox field="is_read_only">
                                        <HelpLabel
                                            inputLabel="Is Read Only"
                                            helpText="The user interface (UI) will have this field set as <u>read only</u> when selected. <br /><br /> 
                                          
                                            Calculated fields can be <b>overridden</b> with manual entries into their calculated field by deselecting <u>Is Read Only</u>. However, all attached upstream or downstream fields will automatically change if their value is overriden. If this isnt desired refer to the <u>Override Calculation</u> option in the field settings 
                                          
                                           
                                     "
                                        />
                                    </Checkbox>
                                    {!isRepeatable.repeatable && (
                                        <Checkbox field="manual_calculation">
                                            <HelpLabel
                                                inputLabel="Override Calculation?"
                                                helpText="Overridding this calculation field is allowed with this option being selected.<br /><br />

                                                This option should only be used when a calculation field value is needed to be overriden/changed, but not instantenously update the attached upstream or downstream fields. 
                                                <br /> <br />
                                                When this option is selected the <u>only</u> time this field will automatically update is when the <u>Calculate</u> button is pressed next to the field which will then recalculate any attached calculation fields.<br /> 
                                                <br /> <br />
                                                Note: This option takes presidence whether or not <u> Is Read Only </u> is selected"
                                            />
                                        </Checkbox>
                                    )}
                                    <Select
                                        field="measurement_unit"
                                        label={
                                            <HelpLabel
                                                inputLabel="Calculated Type"
                                                helpText="Calculation fields allow for 'Numeric', 'Currency' or 'Percent' output of its calculated value."
                                            />
                                        }
                                        options={[
                                            { value: "Numeric", label: "Numeric" },
                                            { value: "Currency", label: "Currency" },
                                            { value: "Percent", label: "Percent" },
                                        ]}
                                        onClick={(e) => {
                                            return e.target.value;
                                        }}
                                        fullWidth
                                    />
                                    {isRepeatable.repeatable && (
                                        <TextField
                                            field="values_csv"
                                            label={
                                                <HelpLabel
                                                    inputLabel="Value Summation?" //"Enter the non-repeatable field name to take the Sum of 'All' records with the above named field?"
                                                    helpText="Enter the name of a field in a non-repeatable group in this Section to automatically show the sum of values stored in this repeatable field. This option is only available for Numeric or Currency field types"
                                                />
                                            }
                                            style={{ marginTop: 10 }}
                                            fullWidth
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        field="decimals"
                                        label={
                                            <HelpLabel
                                                inputLabel="Decimal Places"
                                                helpText=" Default value is 2 decimal places. Set to control the number of decimal places to display or enter into the form. Note: value is only applicable to 'Numeric' calculation types."
                                            />
                                        }
                                    />
                                    <TextField field="default_value" multiline fullWidth style={{ marginTop: 10 }}>
                                        <HelpLabel
                                            inputLabel="Calculation Formula"
                                            helpText={`A formula can be entered to automatically calculate a value based on input values from other fields in the form. The other 'fields' must be either Numeric or Currency types.
                                            
                                            The formula must follow these rules:
                                            1.) Fields referenced in the formula must be surrounded by brackets [] and cannot include special characters such as: +, -, , /, [, ] , &, ^, (, or ). The field name is case sensitive and needs to match the field that was saved.
                                            2.) The field name must be separated by a comma from arithimitic operators. For example, [Field 1],+,[Field 2]
                                            The following operators are currently available:
                                            • Add - ‘+’
                                            • Subtract - ‘–‘
                                            • Divide - ‘/’
                                            • Multiply - ‘*’
                                            • Square Root - ‘sqrt’
                                            • Exponent - ‘^’
                                            
                                            The calculated field formula recognizes parenthesis () to establish the order of the calculation consistent with basic algebra principles. When using parenthesis, a comma is required before and after each field name.
                                            The below examples show fields named as [Field 1] & [Field 2], make note these are just examples -- the calculation that is created needs to use the exact 'field' name for proper calculation. 
                                            Examples:
                                            • Multiply two fields – [Field 1], * ,[Field 2]
                                            • Add a constant value to a field - ‘[Field 1],+100
                                            • Using parenthesis – (,[Field 1],*,[Field 2],)+,[Field 3]
                                            • Square Root – sqrt(,[Field1],)
                                            • Exponent – [Field 1], ^ 3
                                            • RecordSection Identification – [Internal_ID]
                                    
                                            `}
                                        />
                                    </TextField>
                                </Grid>
                            </Grid>
                        </fieldset>

                        <Typography style={{ color: "red", paddingLeft: "6px", paddingBottom: "13px" }}>
                            Note: calculated field 'types' require all related fields to use 'numeric', 'currency' or other 'calculated' fields for
                            proper calculation
                        </Typography>
                    </>
                );

            case "Incremental":
                return (
                    <>
                        <fieldset className={classes.fieldsetOptions}>
                            <legend>Options</legend>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    {!isRepeatable.repeatable && (
                                        <Checkbox field="is_column">
                                            <HelpLabel
                                                inputLabel="Show field in table view?"
                                                helpText="Record field will appear in the 'table' column"
                                            />
                                        </Checkbox>
                                    )}
                                    {isParent && !isRepeatable.repeatable && (
                                        <Checkbox field="identification">
                                            <HelpLabel
                                                inputLabel="Identification? (one per module)"
                                                helpText="This field will be used to store the relationship between primary and related records. Only one field in each module can be the identification field. The field must also be within the primary section."
                                            />
                                        </Checkbox>
                                    )}
                                    {!isParent && !isRepeatable.repeatable && section[0].has_geometry && (
                                        <Checkbox field="child_identification">
                                            <HelpLabel
                                                inputLabel="Map Label (one field per section)"
                                                helpText={
                                                    <div>
                                                        This field will be displayed on the map as a label if configured. Only one field per section
                                                        can be shown as a label.
                                                        <br />
                                                        <br />
                                                        The labeling option must also be turned on in the Section settings.
                                                    </div>
                                                }
                                            />
                                        </Checkbox>
                                    )}
                                    {!isRepeatable.repeatable && (
                                        <>
                                            <Checkbox field="man_reset_incremental">
                                                <HelpLabel
                                                    inputLabel="Reset to starting value?"
                                                    helpText={`Check this box at the beginning of the year for the 'first' record to reset back to the 'Increment Starting Value'.
                                                
                                                After the first record is created, uncheck this box for all other records which will allow the incrementing to properly increment.
                                                `}
                                                />
                                            </Checkbox>
                                            <Checkbox field="auto_reset_incremental_yearly">
                                                <HelpLabel
                                                    inputLabel="Auto Reset Yearly?"
                                                    helpText={`Incremental formula will default to its starting value each year.
                                               
                                               Note: the custom incremental formula must use 'YYYY' or 'YY' to properly restart each year.
                                               `}
                                                />
                                            </Checkbox>
                                        </>
                                    )}
                                    {!isParent && !isRepeatable.repeatable && (
                                        <Checkbox field="last_record_parent_table">
                                            <HelpLabel
                                                inputLabel="Last record in primary table?"
                                                helpText="Last recorded record field will show in the primary table"
                                            />
                                        </Checkbox>
                                    )}
                                    {isParent && !isRepeatable.repeatable && (
                                        <Checkbox field="append_to_header">
                                            <HelpLabel
                                                inputLabel="Append to Header?"
                                                helpText="Fields value will be appended to the 'Identification' fields header and should not be set on an 'Identification' field. Note: only one 'Append to Header' field can be used per form."
                                            />
                                        </Checkbox>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        field="default_value"
                                        style={{ width: "100%" }}
                                        label={
                                            <HelpLabel
                                                inputLabel="Increment Starting Value"
                                                helpText={
                                                    "Enter in the Increment starting value (i.e 1000). Note: the starting value must be an integer (i.e numerical values). Leaving the field blank will result in the value starting at '1'"
                                                }
                                            />
                                        }
                                    />
                                    <TextField
                                        field="values_csv"
                                        style={{ width: "100%", marginTop: 10 }}
                                        multiline
                                        label={
                                            <HelpLabel
                                                inputLabel="Custom Incremental Formula"
                                                helpText={`Enter in a custom incremental formula by creating a string from the following spec'd format. 

                                        Acceptable formats are 'YYYY' for 4-digit year, 'YY' for 2-digit year, 'MM' for 2-digit month, 'DD' for 2-digit day or 'Auto' which is used for incrementing that section of the string. 
                                        Auto uses the 'increment starting value' field to define which value the 'Auto' increment portion of the string will start from. Whereas, dates will use the date the actual record was created. 
                                        
                                        The incremental formula can contain user defined custom text and seperators as well (ex: CP,-,+,*, etc.), however, each 'needs' be seperated by a comma (,). Combining 'Auto' and static text (CP) together is allowed, however, 'Auto' must come 'first' before the static text. 
                                        
                                        Allowed Formulas:
                                        1.) Auto,CP,-,MM,-,DD,-,YY   
                                        2.) CP,-,Auto,-,MM,-,DD,-,YY

                                        Not Allowed Formulas:    
                                        1.) CP,Auto,-,MM,-,DD,-,YY 
                                        Note: CP and Auto will be grouped together ex: CP001 and is not allowed, using a special character seperator (-,*,+, etc.) will allow for this orientation as seen in the above second example.  
                                         
                                        Typical example formula:               
                                        MM,-,DD,-,YY,-,Auto,CP would create the incremental field as 12-01-20-001CP. 
                                        Note: 001 is the 'Increment Starting Value'

                                        <b>Note:</b> when configuring this field to be used for the <b>first</b> time <u>or</u> if the formula was changed make sure to follow the below steps for the first intial record:
                                        1.) Check the <u>'Reset to starting value'</u> option within this fields configuration settings. This allows the system to not look for the prior record (i.e. there wont be another record so we have to override this).
                                        2.) After the first record in created <u>Uncheck</u> the 'Reset to starting value' and resave.

                                        Following the above 2 steps will properly configure the field to use the prior record value.  
                                        `}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>

                        <Typography style={{ color: "red", paddingLeft: "6px", paddingBottom: "13px" }}>
                            Note: increment 'types' increment the above "Increment Starting Value" by 1.
                        </Typography>
                    </>
                );

            case "Email":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Checkbox field="is_required">
                                    <HelpLabel
                                        inputLabel="Is Required?"
                                        helpText="User interface will force this field to have a value entered before the record can be saved."
                                    />
                                </Checkbox>
                            </Grid>
                            <Grid item xs={6}>
                                {!isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}
                            </Grid>
                        </Grid>
                    </fieldset>
                );

            case "Primary Mirror":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Checkbox field="is_read_only">
                                    <HelpLabel inputLabel="Is Read Only" helpText="User interface will have this field as 'read only'." />
                                </Checkbox>
                                {!isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                {!isParent && (
                                    <TextField
                                        field="values_csv"
                                        label={
                                            <HelpLabel
                                                inputLabel="Mirror Primary Field?"
                                                helpText={`Enter the field name from the Primary Section Form to be mirrored into this field. 

                                                When a new Related record is created using this form, the current value from the Primary record will be inserted into this field. 
                                                
                                                Anytime the Related record is saved and the browser window refreshed, this mirrored field will be updated to the current Primary record value.`}
                                            />
                                        }
                                        fullWidth
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </fieldset>
                );
            case "Related Mirror":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    field="values_csv"
                                    label={
                                        <HelpLabel
                                            inputLabel="Mirror Related Field?"
                                            helpText={`Enter the field identification from the Related Section Form to be mirrored into this field. Note: this can be aquired from "Letter & Report Templates", however, only include the numerical portion of the field id. For Example: 123456 
    
                                                    When a new record is created using this form, the current value from the Related record will be inserted into this field. 
                                                    
                                                    Anytime the Related record is saved, this mirrored field will be updated in the Primary Record.`}
                                        />
                                    }
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                );
            case "Related Field":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={1}>
                            <Grid items xs={12}>
                                <Typography style={{ textAlign: "left" }}>
                                    <u>Select a 'Field' name that is common across modules to append to the hyperlink.</u>
                                </Typography>
                            </Grid>
                            <Grid item xs={isParent ? 6 : 5}>
                                {isParent && (
                                    <Select
                                        style={{ marginTop: 15 }}
                                        field="populate_field"
                                        label={
                                            <HelpLabel
                                                inputLabel="Appended Field Name:"
                                                helpText="Select an 'Appended Field Name' to append to the related 'identification' field. This will join the 'Identification' and 'Appended Field' together.  
                                               </br></br><b>Example Form Field Configuration:</b></br>
                                                <u>Field Name&nbsp;&nbsp;&nbsp;Field Type</u></br> 
                                                Grant #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Identification</br> 
                                                Project Name&nbsp;&nbsp;&nbsp;&nbsp;Text</br>
                                                </br><b>Example Related Field Entry:</b></br>
                                                Grant # = 21-001</br>
                                                Project Name = Willow Road</br>
                                                Adding a related record entry of 21-001 would create the following hyperlink record: 
                                                </br><u>21-001 Willow Road </u></br></br>
                                                Note: the 'Appended Field Name' <u>needs</u> to match across modules for full search queries to be returned."
                                            />
                                        }
                                        options={MAKE_OPTIONS(relatedfield)}
                                        fullWidth
                                    />
                                )}
                            </Grid>

                            <Grid item xs={isParent ? 6 : 7}></Grid>
                        </Grid>
                    </fieldset>
                );
            case "Phone Number":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Checkbox field="is_read_only">
                                    <HelpLabel inputLabel="Is Read Only" helpText="User interface will have this field as 'read only'." />
                                </Checkbox>{" "}
                                <Checkbox field="is_required">
                                    <HelpLabel
                                        inputLabel="Is Required?"
                                        helpText="User interface will force this field to have a value entered before the record can be saved."
                                    />
                                </Checkbox>
                                {!isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    field="default_value"
                                    style={{ width: "100%" }}
                                    label={
                                        <HelpLabel
                                            inputLabel=" Default Phone Number?"
                                            helpText={"Enter in the default phone number to be displayed on new records"}
                                        />
                                    }
                                    usePhoneNumberFormat={true}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                );
            case "Dynamic Label":
                return (
                    <fieldset className={classes.fieldsetOptions}>
                        <legend>Options</legend>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    field="values_csv"
                                    style={{ width: "100%" }}
                                    label={
                                        <HelpLabel
                                            inputLabel="Dynamic Formula"
                                            helpText={`Enter in the dynamic formula which includes primary field names. This formula can be a combination of URL values, substring values (more below) and parent fields. 
                                            
                                            The formula must follow these rules:
                                            1.) Primary fields referenced in the formula must be surrounded by brackets [] and cannot include special characters such as: +, -, , /, [, ] , &, ^, (, or ). NOTE: brackets ( , ) are only allowed & are required when used in conjunction with the substring function (below). 
                                            2.) The field name is case sensitive and needs to match the primary field that was saved.
                                            3.) Substring functions are allowed within the dynamic formula and can be used multiple times within the URL.
                                            4.) Substring functions slice a part of the primary fields value. For example, to take the first thru the second character of the primary field value use [substring(0,3); primary field name]. 
                                            • Note: the substring function is a zero based index meaning the first character of the string starts at value 0 and proceeds up to (not including) the second value.
                                        
                                            
                                            The dynamic formula recognizes parenthesis () and [] brackets to inject & construct primary fields into the dynamic label. NOTE: using the substring function requires a semicolon ';' between the substring function and primary field name  
                                            Example Configurations:
                                            • Primary field only – [Primary Field Name]
                                            • Substring used with a primary field - [substring(0,3);Primary Field Name]
                                            • URL with primary field – https://www.yahoo.com/[Primary Field Name]'
                                            • URL with substring and primary field – https://www.yahoo.com/[substring(2,4);Primary Field Name]'
                                            • URL with multiple substring and primary fields – https://www.yahoo.com/[substring(2,4);Primary Field Name 1]/%20[substring(2,4);Primary Field Name 2]'
                                           
                                            If the dynamic field is used in the 'primary' section and is interfacing with another 'primary' label field, make sure the 'other' field is higher in the group ordering as the fields are created from the top down.

                                            NOTE-dynamic label fields only work with non-repeatable groups fields.
                                            `}
                                        />
                                    }
                                />
                                {isParent && !isRepeatable.repeatable && (
                                    <Checkbox field="is_column">
                                        <HelpLabel inputLabel="Show field in table view?" helpText="Record field will appear in the 'table' column" />
                                    </Checkbox>
                                )}
                                <Checkbox field="hyperlink">
                                    <HelpLabel
                                        inputLabel="Show as a hyperlink"
                                        helpText="Display label name will only be shown on the form. When in a record clicking on the fields name will route the user to the constructed URL"
                                    />
                                </Checkbox>
                            </Grid>
                        </Grid>
                    </fieldset>
                );
            case "undefined":
            default:
                return null;
        }
    };

    serviceLookup = (url) => {
        const _this = this;
        this.setState({agol_loading: true, agol_error: false, agol_fields: []});
        loadModules(["esri/layers/FeatureLayer"], { css: true }).then(([FeatureLayer]) => {
            var feature = new FeatureLayer({
                url: url,
                outFields: ["*"],
                visible: false
            });

            var query = feature.createQuery();
            query.where = "1=1";
            query.returnCentroid = false;
            query.returnGeometry = false;
            query.num = 1;
            query.where = "1=1";

            return feature.queryFeatures(query)
                .then(data => {
                    var _fields = [];
                    data.fields.forEach(f => {
                        _fields.push(f.name)
                    })
                    _this.setState({agol_loading: false, agol_fields: _fields});
                }).catch(err => {
                    _this.setState({agol_error: true, agol_loading: false});
                });
        });
    }

    //set type to pass up to parent FieldButtons class, this class holds state of 'typez'
    setType = (e) => {
        if (e.target.value) this.props.typeChange(e.target.value);
    };

    defaultValues = () => {
        const { field, setPopulateFromAnotherSection, populateFromAnotherSection } = this.props;
        const { descriptionFlag, selectedSection, serviceFlag } = this.state;

        if (field && field.description_input && !descriptionFlag) this.setState({ descriptionButtons: true, descriptionFlag: true });
        if (field && field.default_value_service && !serviceFlag) {
            this.setState({ serviceAttribute: true, serviceFlag: true });
        }

        //this is for population of dropdown with another sections prior record field values (just state)
        if (field && (field.type === "Drop Down" || field.type === "Multi Select") && populateFromAnotherSection === null) {
            setPopulateFromAnotherSection(field.populate_from_another_section);
            if (field.populate_from_another_section && selectedSection === null) {
                this.setState({ selectedSection: field.populate_section });
            }
        }

        return field;
    };

    render() {
        const { classes, returnDialog, open, groups, groupid, setautopop, setPopulateFromAnotherSection, section, field } = this.props;
        const { actualType } = this.props; //get props from parent 'fieldbuttons'class
        const { /*bothChecked,*/ snackbarOpen, descriptionButtons, showids } = this.state;

        if (returnDialog === true) this.setState({ snackbarOpen: false });

        var isRepeatable = groups.find((g) => {
            //chk if section is repeatable for options to become available
            return g.id === groupid ? g : null;
        });

        function MAKE_OPTIONS(table) {
            return table.map((row) => ({
                label: row,
                value: row,
            }));
        }

        const parentTypes = [
            "Text",
            "Currency",
            "Email",
            "Phone Number",
            "Date",
            "Time",
            "Drop Down",
            "Multi Select",
            "Numeric",
            "Radio",
            "Calculated",
            "Incremental",
            "Yes/No",
            "Signature",
            "Related Mirror",
            "Dynamic Label",
        ];
        const childTypes = [
            "Text",
            "Currency",
            "Email",
            "Phone Number",
            "Date",
            "Time",
            "Drop Down",
            "Multi Select",
            "Numeric",
            "Radio",
            "Calculated",
            "Incremental",
            "Yes/No",
            "Signature",
            "Primary Mirror",
            "Dynamic Label",
        ];

        const repeatableTypes = [
            "Text",
            "Currency",
            "Phone Number",
            "Date",
            "Time",
            "Drop Down",
            "Multi Select",
            "Numeric",
            "Calculated",
            "Radio",
            "Yes/No",
            "Related Field",
        ];

        return (<>
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                    <Typography variant="h5" className={classes.flex}>
                        Edit Field
                    </Typography>
                    <IconButton
                        onClick={() => {
                            if (!snackbarOpen) {
                                return (
                                    returnDialog(),
                                    setautopop(false), //used to reset sections and field for another section
                                    setPopulateFromAnotherSection(null),
                                    this.setState({ selectedSection: null, serviceFlag: false, serviceAttribute: false })
                                );
                            }
                        }}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Formm
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            getApi={(el) => (this.formApi = el)}
                            validateError={this.errorValidator}
                            defaultValues={this.defaultValues()}
                            onSubmit={(values) => this.updateField(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography>Field ID: <b>{field.id}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="name"
                                                multiline={true}
                                                fullWidth
                                                label={
                                                    <HelpLabel inputLabel="Name" helpText="Label that appears on the Form in the user interface" />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{ marginTop: 10 }}
                                                field="description_input"
                                                fullWidth
                                                multiline={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Help Description"
                                                        helpText={`Enter the description for this data type to be used as a help guide. </br></br>The help description allows inline <b>HTML</b> within the help string. <font color="red"> Note-when using HTML commands make sure 'spaces' are not in between the command brackets. </font> 
                                                        
                                                        Typical HTML commands: 
                                                        1.) Start a new line  < /br >.
                                                        2.) Bold text < b><b>My text</b>< /b>
                                                        3.) Underline text < u><u>My text</u>< /u>
                                                        4.) Italic text < i><i>My text</i>< /i>
                                                        5.) Insert a link to another webpage < a href='https://www.google.com' target='_blank'>Google< /a>
                                                        
                                                        `}
                                                    />
                                                }
                                                eventHandle={(event) => {
                                                    if (event) {
                                                        this.setState({ descriptionButtons: true });
                                                    } else {
                                                        this.setState({ descriptionButtons: false });
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        {descriptionButtons && ( //only show if description is filled in
                                            <>
                                                <Grid item xs={6} style={{ marginTop: -17 }}>
                                                    <Switch
                                                        field="description_desktop"
                                                        name="description_desktop"
                                                        label={"Enable Description for Desktop?"}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginTop: -17 }}>
                                                    <Switch
                                                        field="description_mobile"
                                                        name="description_mobile"
                                                        label={"Enable Description for Mobile?"}
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                        <Grid item xs={12}>
                                            <Select
                                                style={{ marginTop: 10 }}
                                                field="type"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Type"
                                                        helpText="Type of input that appears on the form and value type stored in the database"
                                                    />
                                                }
                                                options={
                                                    isRepeatable.repeatable
                                                        ? MAKE_OPTIONS(repeatableTypes)
                                                        : MAKE_OPTIONS(section[0].parent ? parentTypes : childTypes)
                                                }
                                                onClick={(e) => this.setType(e)}
                                                fullWidth
                                                eventHandle={(e) => {
                                                    this.formApi.setValue("default_value", "");
                                                    this.formApi.setValue("values_csv", "");
                                                    this.formApi.setValue("is_required", false);
                                                    this.formApi.setValue("is_read_only", false);
                                                    this.formApi.setValue("is_column", false);
                                                    this.formApi.setValue("identification", false);
                                                    this.formApi.setValue("measurement_unit", "");
                                                    this.formApi.setValue("user_dropdown", false);
                                                    this.formApi.setValue("auto_populate", false);
                                                    this.formApi.setValue("count_child_records", false);
                                                    this.formApi.setValue("last_record_parent_table", false);
                                                    this.formApi.setValue("auto_populate_another_date", false);
                                                    this.formApi.setValue("reset_incremental", false);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {(actualType === "Drop Down" || actualType === "Multi Select" || actualType === "Radio") && (
                                                <TextField
                                                    style={{ marginTop: 10, marginBottom: 7 }}
                                                    field="values_csv"
                                                    multiline={true}
                                                    label={
                                                        <HelpLabel
                                                            inputLabel={
                                                                actualType === "Radio"
                                                                    ? "Enter Radio Buttons in comma seperated form (ex. Yes,No,Maybe)"
                                                                    : "Enter Drop Down Values in comma seperated form (ex. 10,20,30)"
                                                            }
                                                            helpText={`Make sure all entries values are seperated by a comma ' , '. There shouldnt be a comma in the 'beginning' or 'end' of the list"
                                                                
                                                                To allow for commas within thousands values (i.e. $10,000) escape the value with a backslash '\\'. For example: $10\\,000,$20\\,000
                                                                `}
                                                        />
                                                    }
                                                    fullWidth
                                                />
                                            )}
                                            {((actualType === "Drop Down") || (actualType === 'Multi Select')) && ( 
                                                <>
                                                <TextField
                                                    style={{ marginTop: 10, marginBottom: 7 }}
                                                    field="calc_jsonfield"
                                                    multiline={true}
                                                    label={
                                                        <HelpLabel
                                                            inputLabel={`Enter a conditional formula as JSON to 'hide' or 'auto fill-in' other Numeric/Text fields.` }
                                                            helpText={`The below constructs as if/then configuration based upon drop down selection. 
                                                            
                                                            <b><u>The JSON conditional formula needs to follow these rules:</u></b>
                                                            1.) Parenthesis and brackets need to be placed properly for proper configuration as shown below.
                                                            2.) Different group fields are allowed to be triggered as long as fields are in the same form.
                                                            3.) Field triggering is allowed with <u>Numeric</u> OR <u>Text</u> fields
                                                            4.) Repeatable fields can not be auto triggered.
                                                            5.) When using 'this' conditional field with calculated fields, assign the auto triggered field into an interm field (another field). Then set the interm field into the calculated fields formula.
                                                            6.) When using a dropdown value to <u>unhide</u> a field use the keyword <u>unhide</u> into the value formula (below).  

                                                            <b><u>Hidden field to unhide based upon dropdown selection:</u></b>
                                                            1.) Use the value keyword 'unhide' to trigger this fields dropdown value to unhide another field. 
                                                            Example: {"Dropdown_value_A":[{"other_numeric_field_id":"unhide"}]}
                                                            2.) Select the field name that you want to hide located in the '<u>Hide Fields</u>' window

                                                            <b><u>Single field value triggering based upon dropdown selection:</u></b>
                                                            {"Dropdown_value_A":[{"other_numeric_field_id":"value"}]
                                                            ,"Dropdown_value_B":[{"other_numeric_field_id":"value"}]}

                                                            <b><u>Multiple field value triggering based upon dropdown selection:</u></b>
                                                            {"Dropdown_value_A":[{"numeric_field_id_1":"value"},{"numeric_field_id_2":"value"}], 
                                                            "Dropdown_value_B":[{"numeric_field_id_1":"value"},{"numeric_field_id_2":"value"}]}

                                                            If issues arise with not updating the <b>other</b> fields copy the <u>conditional</u> formula and paste it into <a href='https://www.jsonlint.com' target='_blank'>JSON Checker</a> and click on 'Validate JSON'. This will highlight any logic issues.   
                                                                `}
                                                        />
                                                    }
                                                    fullWidth
                                                /> 
                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    onClick={()=>{
                                                        this.setState({showids:true})
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    size="large"
                                                    style={{ float: "left", marginTop: "4px" }}>
                                                    Show Field IDs
                                                </Button>
                                            </Grid>
                                            </>
                                            )}
                                            
                                            {/* Output the type of 'options' for the datatype */}
                                            {actualType !== null && this.getDataTypeOptions(actualType)}

                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                size="large"
                                                style={{ float: "right", marginTop: "4px" }}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
              
                </Dialog>  
                {showids &&( 
                    <Dialog open={open} classes={{ scrollPaper: classes.overridepaper }}>
                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                            <Typography variant="h5" className={classes.flex}>
                                Conditional Formula Field IDs
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    this.setState({showids:false})
                                }}>
                                <Close />
                            </IconButton>
                        </Toolbar>
                        <DialogContent className={classes.dialogMin}>
                            <DialogContentText> 
                                {groups.map(g => {
                                    return(<>
                                        <Typography variant="h7" className={classes.flex} style={{fontSize:'medium', fontWeight:600, 
                                            color:"#262626"}}>
                                            {`Group Name: ${g.name}`}
                                        </Typography>
                                        <Table className={classes.dialogMin}>        
                                            <EnhancedTableHead 
                                                // onRequestSort={this.handleRequestSort}
                                                columnData={[{ id: "name", numeric: false, label: "Field Name", allowSort: false, width: "auto" },
                                                { id: "id", numeric: true, label: "Field Identification", allowSort: false, width: "auto" }]}
                                            /> 
                                            <TableBody>
                                                {g._fields.map(f => {
                                                    return(
                                                        <TableRow hover key={f.id}> 
                                                            <CustomTableCell>{f.name}</CustomTableCell> 
                                                            <CustomTableCell>{f.id}</CustomTableCell>   
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>     
                                        </Table>
                                    </>
                                    )
                                })}  
                            </DialogContentText>
                        </DialogContent>  
                    </Dialog>    
                )}
                </>
        );
    }
}

//Detail for main form page, contains all text, buttons, etc.
class FormDetail extends Component {
    state = {
        deleteDialogOpen: false,
        open: false,
        openConfig: false,
        groupCreate: false,
    };

    deleteGroups = () => {
        const { ormGroupDelete, groups, form } = this.props;
        if (form) {
            groups.forEach((group) => {
                ormGroupDelete(group.id);
            });
        }
    };
    deleteForm = () => {
        const { ormFormDelete, form, history } = this.props;
        if (form) {
            this.deleteGroups();
            ormFormDelete(form.id);

            history.goBack();
        }
    };

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name),
        };
        return valObj;
    };

    defaultValues = () => {
        const { form } = this.props;

        return form;
    };

    render() {
        const {
            classes,
            groups,
            ormGroupCreate,
            ormGroupDelete,
            ormFieldCreate,
            ormFieldUpdate,
            ormFieldDelete,
            ormGroupUpdate,
            ormFormUpdate,
            ormParentRecordReload,
            ormFieldLoadDetailChild,
            ormGroupLoadDetailChild,
            form,
            forms,
            allSections,
            allPageSections,
            pages,
            section,
            ormFieldReload,
            ormGroupReload,
            authState,
            authLogout,
            fields2,
        } = this.props;
        const { deleteDialogOpen, open, openConfig, groupCreate } = this.state;

        //get pageName
        if (pages.length > 0 && section.length > 0) {
            var pageName = pages.find((page) => {
                if (page.id === section[0].page) {
                    return page;
                }
                return null;
            });
        }
        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ color: "lightgray" }}>
                        <Typography variant="h5" style={{ marginLeft: "5px" }}>
                            Form Configuration: &nbsp;
                            {form && form.name}
                        </Typography>
                        <Typography style={{ marginLeft: "5px" }} className={classes.italics}>
                            Use the tools on this module to create and edit forms.
                        </Typography>
                        {section.length > 0 && pages.length > 0 && form && (
                            <BreadCrumbs
                                pageName={pageName.name}
                                allPageConfig={"/config/page"}
                                sectionConfig={`/page/${section[0].page}/config`}
                                formName={form.name}
                            />
                        )}
                    </Grid>
                    <Grid item xs={2} style={{ paddingLeft: 20, whiteSpace: "nowrap" }}>
                        {forms.length > 1 && (
                            <Formm validateOnSubmit="true" defaultValues={this.defaultValues()}>
                                <Checkbox
                                    field="is_column"
                                    eventHandle={(e) => {
                                        ormFormUpdate({ id: form.id, is_column: e });
                                    }}>
                                    <HelpLabel inputLabel="Form Name in Column? " helpText="Form name will be included in the table view." />
                                </Checkbox>
                            </Formm>
                        )}
                    </Grid>
                    <Grid item xs={10} style={{ textAlign: "right", paddingRight: 12 }}> 
                        <Button
                            variant="raised"
                            type="button"
                            size="medium"
                            className={classes.buttonMargin}
                            onClick={() => this.setState({ openConfig: true })}>
                            Generate ArcGIS Online Files&nbsp;&nbsp;&nbsp;
                            <Smartphone />
                        </Button>  
                        <Button
                            variant="raised"
                            type="button"
                            size="medium"
                            className={classes.buttonMargin}
                            onClick={() => window.open("/excel/form/" + form.id + "/export/" + authState.user.auth_token)}>
                            Export Form to Spreadsheet&nbsp;&nbsp;&nbsp;
                            <CloudDownload />
                        </Button>
                        <Button
                            variant="raised"
                            type="button"
                            size="medium"
                            className={classes.buttonMargin}
                            onClick={() => this.setState({ open: true })}>
                            Import Form from Spreadsheet&nbsp;&nbsp;&nbsp;
                            <CloudUpload />
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            onClick={() => {
                                this.setState({ groupCreate: true });
                            }}
                            variant="contained"
                            color="primary"
                            style={{ width: "99%", marginLeft: ".5%" }}>
                            <AddCircleOutlineIcon />
                            &nbsp;&nbsp;&nbsp;Add New Group
                        </Button>
                        <GroupContainer
                            ormGroupDelete={ormGroupDelete}
                            ormGroupUpdate={ormGroupUpdate}
                            ormFieldCreate={ormFieldCreate}
                            ormFieldUpdate={ormFieldUpdate}
                            ormFieldDelete={ormFieldDelete}
                            ormFieldReload={ormFieldReload}
                            ormParentRecordReload={ormParentRecordReload}
                            ormFieldLoadDetailChild={ormFieldLoadDetailChild}
                            ormGroupLoadDetailChild={ormGroupLoadDetailChild}
                            authState={authState}
                            classes={classes}
                            groups={groups}
                            form={form}
                            fields2={fields2}
                            section={section}
                            allSections={allSections}
                            allPageSections={allPageSections}
                        />
                    </Grid>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.formButton}
                        onClick={() => this.setState({ deleteDialogOpen: true })}
                        style={{ marginTop: 10, marginLeft: "15px" }}>
                        <i class="material-icons">delete</i> &nbsp;&nbsp; Delete Form &nbsp;
                    </Button>
                    <WarningDialog
                        confirmAction={() => {
                            this.deleteForm();
                            this.setState({ deleteDialogOpen: false });
                        }}
                        cancelAction={() => this.setState({ deleteDialogOpen: false })}
                        confirmText="Delete Form"
                        open={deleteDialogOpen}
                        title="Delete Form"
                        text={
                            "Are you sure you wish to permanently delete this form? Selecting 'Delete Entry' will permanently remove the Form with 'All' groups assigned to the database."
                        }
                    />
                </Grid>

                <CreateGroup
                    ormGroupCreate={ormGroupCreate}
                    form={form}
                    groups={groups}
                    section={section}
                    classes={classes}
                    open={groupCreate}
                    returnDialog={() => {
                        this.setState({ groupCreate: false });
                    }}
                />
                <FormUpload
                    ormGroupReload={ormGroupReload}
                    ormFieldReload={ormFieldReload}
                    authState={authState}
                    authLogout={authLogout}
                    form={form}
                    classes={classes}
                    open={open}
                    returnDialog={() => {
                        this.setState({ open: false });
                    }}
                />
                <Survey123
                    section={section}
                    pages={pages}
                    ormFormUpdate={ormFormUpdate}
                    authState={authState}
                    classes={classes}
                    form={form}
                    allSections={allSections}
                    open={openConfig}
                    returnDialog={() => {
                        this.setState({ openConfig: false });
                    }}
                />
            </AppContainer>
        );
    }
}
class CreateGroup extends Component {
    formKey = window.performance.now();

    createGroup = (values) => {
        const { ormGroupCreate, groups, form, returnDialog } = this.props;
        if (form) {
            ormGroupCreate({
                form: form.id,
                order: groups.length,
                ...values,
            });
            returnDialog();
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight); //scroll to bottom of window
        }
    };
    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name),
        };

        return valObj;
    };

    closeDialog = () => {
        const { returnDialog } = this.props;

        returnDialog();
    };
    defaultValues = () => {
        let obj = {};
        obj["default_expanded"] = true;

        return obj;
    };
    render() {
        const { classes, open } = this.props;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth2 }}>
                <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                    <Typography variant="h5" className={classes.flex}>
                        Create A New Group
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={this.closeDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Formm
                            key={this.formKey}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={this.defaultValues()}
                            onSubmit={(values, e, formApi) => this.createGroup(values, formApi)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="name"
                                                fullWidth
                                                label={
                                                    <HelpLabel inputLabel="Name" helpText="Label that appears on the Form in the user interface" />
                                                }
                                            />

                                            <Switch
                                                field="repeatable"
                                                name="repeatable"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Repeatable Group?"
                                                        helpText="Repeatable Groups allow tables to be added to the fields in the group list"
                                                    />
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            />

                                            <Switch
                                                field="default_expanded"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Have Group Expanded by Default?"
                                                        helpText="Upon opening a record this group will be expanded or not"
                                                    />
                                                }
                                            />
                                            <Grid item xs={6}>
                                                <Switch
                                                    field="mirror_repeatabletable"
                                                    label="Mirror another 'Repeatable Table' into this group?"
                                                    // eventHandle={(e) => {

                                                    // }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    field="mirror_repeatablesection"
                                                    fullWidth
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Mirror Section ID"
                                                            helpText="Type in the Section Identification field (ie: 500) which has the repeatable group that you want to mirror."
                                                        />
                                                    }
                                                />
                                                <TextField
                                                    style={{ marginTop: 10 }}
                                                    field="mirror_repeatablegroup_ids"
                                                    fullWidth
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Mirror Repeatable Group ID's"
                                                            helpText="Type in the 'group identification' field from each form from the above section. Seperate multiple forms within the same section with a comma ex: 500,501."
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <div style={{ marginTop: 22 }}>
                                                <Button type="submit" variant="raised" color="primary" className={classes.floatLeft}>
                                                    Add Group
                                                </Button>
                                                <Button
                                                    onClick={this.closeDialog}
                                                    type="button"
                                                    variant="raised"
                                                    color="primary"
                                                    className={classes.floatRight}>
                                                    Close
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

class FormUpload extends Component {
    state = {
        loading: false,
        results: null,
        deleteSelected: false,
        warningOpen: false,
    };
    formKey = window.performance.now();

    uploadDocument = (values, formApi) => {
        var { authState, history, form, ormGroupReload, ormFieldReload, authLogout } = this.props;
        this.setState({ loading: true, results: null });
        fetch("/excel/form/" + form.id + "/import", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token,
            },
            body: JSON.stringify(values),
        }).then((e) => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else if (e.status === 500) {
                let msg = "<b>Upload Failed</b><br/>";
                this.setState({ loading: false, results: msg });
            } else {
                var msg;
                e.json()
                    .then((data) => {
                        msg = "<b>Upload Results:</b><br/>";
                        data.message.forEach(function (m) {
                            msg += m.name + ": " + m.message + "<br/>";
                        });
                        formApi.resetAll();
                        this.formKey = window.performance.now();

                        ormGroupReload();
                        ormFieldReload();
                    })
                    .then(() => {
                        this.setState({ loading: false, results: msg });
                    });
            }
        });
    };

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
        };

        return valObj;
    };

    closeDialog = () => {
        const { returnDialog } = this.props;

        this.setState({ results: null, loading: false });
        returnDialog();
    };

    render() {
        const { classes, open } = this.props;
        const { loading, results, warningOpen, deleteSelected } = this.state;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth2 }}>
                <Toolbar>
                    <Typography variant="h5" className={classes.flex}>
                        Import Form Configuration
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={this.closeDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Formm
                            key={this.formKey}
                            dontValidateOnMount={true}
                            getApi={(el) => (this.formApi = el)}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={(values, e, formApi) => this.uploadDocument(values, formApi)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FileInput
                                                field="file"
                                                id="file_upl"
                                                fullWidth
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Configuration File"
                                                        helpText="Upload an Excel spreadsheet (.xlsx) containing your fields on each row. The column names should match the spreadsheet generated from the 'Export Form to Spreadsheet' button."
                                                    />
                                                }
                                            />
                                            <Switch
                                                field="delete_current"
                                                name="delete_current"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Do you want to remove all existing groups and fields?"
                                                        helpText={`Select 'No' to append the field information in the Configuration File to the existing fields.

                                                        Select 'Yes' to remove any existing fields prior to uploading the new Configuration File.

                                                        WARNING: Choosing 'Yes' will remove existing fields in your form configuration AND clear data records associated with those fields.
                                                        `}
                                                    />
                                                }
                                                eventHandle={(e) => this.setState({ deleteSelected: e })}
                                            />

                                            {loading && (
                                                <CircularProgress size={24} style={{ display: "table", marginLeft: "auto", marginRight: "auto" }} />
                                            )}
                                            {results && (
                                                <Typography style={{ textAlign: "center" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: results }} />
                                                </Typography>
                                            )}
                                            {!loading && (
                                                <>
                                                    <Button
                                                        onClick={(e) =>
                                                            deleteSelected ? this.setState({ warningOpen: true }) : this.formApi.submitForm()
                                                        }
                                                        variant="raised"
                                                        color="primary"
                                                        className={classes.floatLeft}>
                                                        Upload
                                                    </Button>
                                                    <Button
                                                        onClick={this.closeDialog}
                                                        type="button"
                                                        variant="raised"
                                                        color="primary"
                                                        className={classes.floatRight}>
                                                        Close
                                                    </Button>
                                                </>
                                            )}
                                            <WarningDialog
                                                confirmAction={() => {
                                                    this.setState({ warningOpen: false });
                                                    this.formApi.submitForm();
                                                }}
                                                cancelAction={(e) => {
                                                    this.setState({ warningOpen: false, deleteSelected: false });
                                                    this.formApi.setValue(`delete_current`, false);
                                                }}
                                                confirmText="Yes"
                                                cancelText="No"
                                                open={warningOpen}
                                                title="Remove All Existing Groups/Fields?"
                                                text={
                                                    "WARNING: You are about to remove all existing field configuration settings. Doing so will also remove existing data using those field configuration settings. Are you sure you want to remove the existing fields and assocaited data?"
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

class Survey123 extends Component {
    state = {
        fields: [],
    };
    formKeyDoc = window.performance.now();
    typingTimer;

    filterFields = (section_id) => {
        const { allSections } = this.props;
        const selectedSection = allSections.find((s) => s.id === section_id);

        var fields_temp = [];
        var fields = [];
        // Loop through all fields
        selectedSection.forms.forEach((f, i) => {
            f.groups.forEach((g) => {
                g.fields.forEach((field) => {
                    if (i === 0) {
                        fields.push(field.name);
                    } else {
                        fields_temp.push(field.name);
                    }
                });
            });
            if (i !== 0) {
                fields = fields.filter(function (val) {
                    return fields_temp.indexOf(val) !== -1;
                });
                fields_temp = [];
            }
        });
        fields = fields.filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
        });

        this.setState({ fields: fields });
        this.formKeyDoc = window.performance.now();
    };

    generateZip = (values) => {
        const { form, authState } = this.props;
        if (values.section)
            window.open("/excel/form/" + form.id + "/survey123/" + authState.user.auth_token + "/" + values.section + "/" + values.field);
        else window.open("/excel/form/" + form.id + "/survey123/" + authState.user.auth_token);
    };

    updateNotes = (value) => {
        const { form, ormFormUpdate } = this.props;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function () {
            ormFormUpdate({ id: form.id, arcgis_notes: value });
        }, 1000);
    };

    render() {
        const { classes, open, returnDialog, pages, section, form } = this.props;
        const { fields } = this.state;

        var page;
        if (pages.length > 0 && section.length > 0) {
            page = pages.find((page) => {
                if (page.id === section[0].page) {
                    return page;
                }
                return null;
            });
        }

        function MAKE_OPTIONS(table) {
            return table.map((row) => ({
                label: row.name,
                value: row.id,
            }));
        }

        function MAKE_OPTIONS2(table) {
            return table.map((row) => ({
                label: row,
                value: row,
            }));
        }

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth2 }}>
                <Toolbar>
                    <Typography variant="h5" className={classes.flex}>
                        Generate Configuration Files
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={returnDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Formm
                            defaultValues={form}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            onSubmit={(values) => this.generateZip(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Select
                                                field="section"
                                                label="Which section should be used as the primary? (Leave blank to use default primary)"
                                                options={MAKE_OPTIONS(page._sections)}
                                                eventHandle={this.filterFields}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                field="field"
                                                key={this.formKeyDoc}
                                                disabled={fields.length === 0}
                                                label="Which field should be used as the identifier? (Leave blank to use default primary)"
                                                options={MAKE_OPTIONS2(fields)}
                                                fullWidth
                                            />
                                            {fields.length === 0 && (
                                                <Typography style={{ color: "darkred" }}>
                                                    The selected primary section does not have any fields that are found across every form.
                                                </Typography>
                                            )}
                                            <TextField
                                                style={{ marginBottom: 12 }}
                                                field="arcgis_notes"
                                                label="Saved Notes"
                                                multiline
                                                rows={6}
                                                eventHandle={this.updateNotes}
                                                fullWidth
                                            />
                                            <Button type="submit" variant="raised" color="primary" className={classes.floatLeft}>
                                                Download
                                            </Button>
                                            <Button
                                                onClick={returnDialog}
                                                type="button"
                                                variant="raised"
                                                color="primary"
                                                className={classes.floatRight}>
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
FormDetail.displayName = "FormDetail";

FormDetail = connect(
    (state, ownProps) => ({
        pages: allPages(state, ownProps),
        section: getSections(state, ownProps),
        allSections: getAllSections(state, ownProps),
        allPageSections: getAllPageSections(state, ownProps),
        form: getForm(state, ownProps),
        forms: getForms(state, ownProps),
        groups: getGroups(state, ownProps),
        fields2: getFields(state, ownProps),
        authState: state.auth,
    }),
    {
        ...Form.actions, //AKA forms
        ...Group.actions,
        ...Field.actions,
        ...authActions,
        ...ParentRecord.actions,
    }
)(FormDetail);

export default withStyles(styles)(withRouter(FormDetail));
