import React, { Component } from "react";
import { connect } from "react-redux";
import { Form as Formm } from "react-form";
import { withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MaterialTable, { MTableToolbar, MTableAction } from "material-table";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Checkbox from "@material-ui/core/Checkbox";
import Close from "@material-ui/icons/Close";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import drag from "../common/icons/dnd.png";
import CloudDownload from "@material-ui/icons/CloudDownload";

import Subquestion from "../common/Subquestion";
import HelpLabel from "../common/HelpLabel";
import AppContainer from "../common/AppContainer";
import Select from "../common/Select";
import TextField from "../common/TextField";
import Switch from "../common/Switch";
import ColorPicker from "../common/ColorPicker";
import ParentWarningDialog from "./ParentWarningDialog";
import WarningDialog from "../common/WarningDialog";
import { createSelector } from "../common/orm";
import { ICON_LIST, MAKE_OPTIONS_ICON } from "../common/icons";
import { Page, Section, Form, SectionMapGrouping, GroupingValue, Field } from "../page/models";

// Get all form objects and order by name
const allForms = createSelector((schema) => {
    return schema.Form.all().orderBy("name").toRefArray();
});

// Get the specific page object
// Ex: the URL is http://localhost:3000/#/page/49 we would get page object #49
const getPage = Page.selectByUrlId();

// Get all sections that are present for the current page
const getSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageId) => {
        return session.Section.filter((sect) => sect.page === pageId)
            .orderBy("order")
            .toModelArray()
            .map((section) => ({
                _forms: section.forms
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((form) => ({
                        groups: form.groups
                            .all()
                            .orderBy("order")
                            .toModelArray()
                            .map((group) => ({
                                fields: group.fields.all().orderBy("order").toRefArray(),
                                ...group.ref,
                            })),
                        ...form.ref,
                    })),
                _groupings: section.groupings.all().orderBy("id").toRefArray(),
                ...section.ref,
            }));
    }
);

const getGroupings = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageId) => {
        return session.SectionMapGrouping.all().orderBy("id").toRefArray();
    }
);

const getValueGroupings = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageId) => {
        return session.GroupingValue.all().orderBy("id").toRefArray();
    }
);

// CSS in JS
const styles = (theme) => ({
    materialTableToolbar: {
        "& > div": {
            padding: 0,
            marginLeft: -45,
        },
        "& > div div": {
            width: "100%",
        },
    },
    materialTableActions: {
        "& button": {
            width: "100%",
        },
        "& > button:hover": {
            backgroundColor: "rgba(0, 0, 0, 0)",
        },
    },
    italics: {
        fontStyle: "italic",
    },
    table: {
        minWidth: 500,
    },
    dialogMin: {
        minWidth: 400, 
    },
    flex: {
        flex: 1,
    },
    button: {
        float: "right",
    },

    minWidth: {
        minWidth: 800,
    },
    gutterBottom: {
        marginBottom: ".6em",
    },
    root: {
        width: "100%",
        overflowX: "auto",
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    materialtable: {
        fontSize: "large",
    },
    buttonMargin: {
        margin: 4,
    },
    iconPush: {
        "& svg": {
            marginBottom: -8,
        },
    },
});

//fields styling section for groups
const getItemStyle2 = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 1,
    margin: `6px 14px 6px 14px`,
    color: "primary",
    fontSize: "18px",
    borderRadius: 5,
    textAlign: "left",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 2px, rgba(0, 0, 0, 0.25) 0px 2px 2px",

    // change background of fields when dragging
    background: isDragging ? "lightgrey" : "lightgrey",

    // styles we need to apply on draggables
    ...draggableStyle,
});

// background color on "fields child card" section when dragging
const getListStyle2 = (isDraggingOver) => ({
    background: isDraggingOver ? "#4e4e4e" : "",
    borderRadius: 5,
    marginBottom: isDraggingOver ? 80 : 30,
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
    }));
}

// The table in the page details component, shows all page sections
// http://localhost:3000/#/page/49
class SectionTable extends Component {
    constructor(props, context) {
        super(props, context);

        // Set the default states of the component
        this.state = {
            openEditDialog: false,
            editDialog: null,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            title: true,
            newFormDialogOpen: false,
            clickedOnSection: "",
            showGeoColor: false,
            geoPoint: false,
            geoHitFlag: true,
            showAttachmentTypes: false,
            showAutoSectionCreate: false,
            showAGOLQuestions: false,
            attachmentHitFlag: true,
            contactHitFlag: true,
            autoSectionHitFlag:true,
            AGOLHitFlag: true,
            moving:false
        };
        this.returnEditDialog = this.returnEditDialog.bind(this);
    }

    returnNewFormDialog = () => {
        this.setState({ newFormDialogOpen: false });
    };

    returnEditDialog() {
        this.setState({
            openEditDialog: false,
            showGeoColor: false,
            geoPoint: false,
            geoHitFlag: true,
            attachmentHitFlag: true,
            contactHitFlag: true,
            autoSectionHitFlag: true,
            AGOLHitFlag: true,
            showAttachmentTypes: false,
            showAutoSectionCreate: false,
            showAGOLQuestions: false,
            disabledRepeatable: false

        });
    }
    handleMasterContacts = (e) => {
        if (e) this.setState({ disabledRepeatable: true, contactHitFlag: false });
        if (!e) this.setState({ disabledRepeatable: false });
    };
    handleGeoColor = (e) => {
        if (e) this.setState({ showGeoColor: true, geoHitFlag: false });
        if (!e) this.setState({ showGeoColor: false });
    };
    handleGeoType = (e) => {
        if (e === "Point" || e === "MultiPoint") this.setState({ geoPoint: true });
        else this.setState({ geoPoint: false });
    };

    handleAttachmentTypes = (e) => {
        if (e) this.setState({ showAttachmentTypes: true, attachmentHitFlag: false });
        if (!e) this.setState({ showAttachmentTypes: false });
    };
    handleAutoSection = (e) => {
        if (e) this.setState({ showAutoSectionCreate: true, autoSectionHitFlag: false });
        if (!e) this.setState({ showAutoSectionCreate: false });
    };

    handleAGOLQuestions = (e) => {
        if (e) this.setState({ showAGOLQuestions: true, AGOLHitFlag: false });
        if (!e) this.setState({ showAGOLQuestions: false });
    };

    // When the delete button is pressed--check for parent/child relationships. If there is a child do not allow
    // parent to be deleted.
    deleteSection = (section, name, parent) => {
        const { sections } = this.props;
        // Set the state which controls the popup dialog
        // If deleteDialogOpen = true then the popup will appear during the render event
        if (!parent) {
            this.setState({
                deleteDialogOpen: true,
                deleteDialogConfirmAction: section,
                deleteDialogText:
                    "WARNING: This will remove section for all current records. Are you sure you wish to permanently delete " + name + "?",
                buttons: false,
            });
        } else {
            let childCounter = 0;
            //add up the total children
            sections.forEach((n) => {
                if (n.parent === false) {
                    return (childCounter = childCounter + 1);
                }
            });
            //dont allow deletion as there is children
            if (childCounter > 0) {
                this.setState({
                    deleteDialogOpen: true,
                    deleteDialogConfirmAction: null,
                    deleteDialogText:
                        "WARNING: This section can not be deleted as it is the primary section and has related sections. Delete the 'non-primary' sections first.",
                    title: false,
                    buttons: true,
                });
                //no children so allow last section which is parent to be deleted
            } else if (childCounter === 0) {
                this.setState({
                    deleteDialogOpen: true,
                    deleteDialogConfirmAction: section,
                    deleteDialogText:
                        "WARNING: This will remove section for all current records. Are you sure you wish to permanently delete " + name + "?",
                    buttons: false,
                });
            }
        }
    };
    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        } 
        console.log(result);
 
        const { ormSectionLoadDetailChild, page, authState} = this.props;
        this.setState({moving:true})
        
        // Order Sections
        if (result.destination.droppableId === "section_list") { 
            var _this = this;
            //eslint-disable-next-line
            const res = fetch(`dndreorder/${page}/${result.destination.index}/${result.source.index}/${result.draggableId}/${"section"}/${authState.auth.user.auth_token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.auth.user.auth_token,
                },
            }).then((e) => {
                if (e.status === 200) {
                    e.json().then((rsp) => {   
                         ormSectionLoadDetailChild(result.draggableId, function () { 
                            _this.setState({ moving: false });
                         }); 
                    });
                }else { 
                    _this.setState({moving:false}) 
                }
            }); 
        }
    };

    render() {
        const {
            classes,
            ormSectionDelete,
            ormSectionCreate,
            ormSectionUpdate,
            ormFormUpdate,
            ormFormCreate,
            newDialogOpen,
            returnDialog,
            page,
            sections,
            forms,
            history,
            ormSectionMapGroupingCreate,
            ormSectionMapGroupingUpdate,
            ormSectionMapGroupingDelete, 
            ormGroupingValueCreate,
            ormGroupingValueUpdate,
            ormGroupingValueDelete,
            mapGroups,
            groupValues,
            authState,
        } = this.props;

        const { deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction, openEditDialog, editDialog, newFormDialogOpen, moving } = this.state;

        return (
            <>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="section_list" type="section_list" key="section_list">
                        {(provided, snapshot) => (
                            <Accordion
                                style={{
                                    backgroundColor: snapshot.isDraggingOver ? "#4e4e4e" : "",
                                }}
                                defaultExpanded
                                expanded={true}>
                                <AccordionSummary
                                    className={classes.titlePanel}
                                    classes={{ root: classes.expandedMargin }}
                                    style={{
                                        backgroundColor: "#333",
                                        borderRadius: 3,
                                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 0px, rgba(0, 0, 0, 0.2) 0px 1px 1px",
                                    }}>
                                    <table style={{ width: "100%" }}>
                                        {/* Table Header */}
                                        <tr>
                                            <td>
                                                <Typography variant="h5" style={{ fontSize: 18, marginLeft: "46%", color: "#fff" }}>
                                                    Section Name
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="h5" style={{ fontSize: 18, marginLeft: "0%", color: "#fff" }}>
                                                    Forms
                                                </Typography>{" "}
                                            </td>
                                            <td>
                                                <Typography variant="h5" style={{ fontSize: 18, marginLeft: "0%", color: "#fff" }}>
                                                    Primary
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="h5" style={{ fontSize: 18, marginLeft: "10%", color: "#fff" }}>
                                                    Geometry
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="h5" style={{ fontSize: 18, marginLeft: "-18%", color: "#fff" }}>
                                                    Icon
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="h5" style={{ fontSize: 18, marginLeft: "19%", color: "#fff" }}>
                                                    Attachments
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography variant="h5" style={{ fontSize: 18, marginLeft: "4%", color: "#fff" }}>
                                                    Contacts
                                                </Typography>
                                            </td>
                                        </tr>
                                    </table>
                                </AccordionSummary>
                                <div ref={provided.innerRef} style={getListStyle2(snapshot.isDraggingOver)}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <table
                                                style={{
                                                    width: "100%",
                                                    marginTop: -3,
                                                    marginBottom: -3,
                                                    fontSize: "initial",
                                                }}>
                                                {sections.map((n) => {
                                                    const icon_array = ICON_LIST.find(function (itm) {
                                                        return itm.id === n.icon;
                                                    });
                                                    var icon_component;
                                                    if (icon_array) icon_component = icon_array["component"];

                                                    return (
                                                        <Draggable key={n.id} draggableId={n.id} index={n.order} /*isDragDisabled={disableDrag}*/>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    key={n.id}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle2(snapshot.isDragging, provided.draggableProps.style)}
                                                                    className={classes.fieldButton}>
                                                                    <tr hover key={n.id} style={{ width: "100%", display: "inline-table" }}>
                                                                        <td className={classes.nowrap} style={{ width: "10%" }}>
                                                                            <Tooltip title="Drag and Drop Sections to Rearrange how they will look on the module">
                                                                                <img
                                                                                    style={{
                                                                                        width: "1.5em",
                                                                                        height: "1.6em",
                                                                                        verticalAlign: "middle",
                                                                                        marginRight: 11,
                                                                                        marginLeft: 6,
                                                                                    }}
                                                                                    src={drag}
                                                                                    alt="fireSpot"
                                                                                />
                                                                            </Tooltip>
                                                                            <Tooltip title="Edit">
                                                                                <Button
                                                                                    onClick={() =>
                                                                                        this.setState({
                                                                                            openEditDialog: true,
                                                                                            editDialog: n,
                                                                                            showGeoColor: false,
                                                                                            disabledRepeatable: false,
                                                                                            geoPoint: false,
                                                                                            geoHitFlag: true,
                                                                                        })
                                                                                    }
                                                                                    className={classes.deleteWidth}>
                                                                                    <EditIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                            <Tooltip title="Delete">
                                                                                <Button
                                                                                    onClick={() => this.deleteSection(n.id, n.name, n.parent)}
                                                                                    className={classes.deleteWidth}>
                                                                                    <DeleteIcon />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </td>
                                                                        <td style={{ width: "10%" }}>
                                                                            <Typography>{n.name}</Typography>
                                                                        </td>
                                                                        <td className={classes.nowrap} style={{ paddingLeft: "2%", width: "12%" }}>
                                                                            <Tooltip title="Forms">
                                                                                <Button
                                                                                    className={classes.deleteWidth}
                                                                                    disabled={n.is_master_contact_form}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            newFormDialogOpen: true,
                                                                                            clickedOnSection: n.id,
                                                                                        });
                                                                                    }}>
                                                                                    <EditIcon color="inherit" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </td>
                                                                        {/* Primary CHECKBOX */}
                                                                        <td style={{ width: "13%" }}>
                                                                            <Checkbox
                                                                                checked={n.parent}
                                                                                disabled={true}
                                                                                label=""
                                                                                className={classes.deleteWidth}
                                                                            />
                                                                        </td>
                                                                        <td style={{ width: "13%" }}>
                                                                            <Checkbox
                                                                                checked={n.has_geometry}
                                                                                disabled={true}
                                                                                label=""
                                                                                className={classes.deleteWidth}
                                                                            />
                                                                        </td>
                                                                        <td style={{ width: "13%" }} className={classes.iconPush}>
                                                                            {icon_component ? icon_component : <div />}
                                                                        </td>

                                                                        <td style={{ marginLeft: icon_component ? "7.5%" : "8.7%" }}>
                                                                            <Checkbox
                                                                                checked={n.has_attachments}
                                                                                disabled={true}
                                                                                label=""
                                                                                className={classes.deleteWidth}
                                                                            />
                                                                        </td>
                                                                        <td style={{ width: "13%" }}>
                                                                            <Checkbox
                                                                                checked={n.is_contact_form || n.is_master_contact_form}
                                                                                disabled={true}
                                                                                label=""
                                                                                className={classes.deleteWidth}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {sections.length < 1 && (
                                                    <tr colSpan={7} className={classes.centerAlign}>
                                                        <td><Typography style={{marginTop: 16, marginBottom:-16}}>No Sections Defined</Typography></td>
                                                    </tr>
                                                )}
                                            </table>
                                        </Grid>
                                    </Grid>
                                </div>
                                {moving && (
                                <Dialog open={moving}>
                                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                        <Typography variant="h5" className={classes.flex}>
                                            Resaving Ordering
                                        </Typography>
            
                                        {/* <IconButton onClick={returnDialog}>
                                            <Close />
                                        </IconButton> */}
                                    </Toolbar>
                                    <DialogContent style={{ minWidth: 400 }}>
                                        <DialogContentText>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <CircularProgress size={90} className={classes.progress} />
                                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                                        Please Wait...
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                            )}
                            </Accordion>
                        )}
                    </Droppable>
                </DragDropContext>

                {newFormDialogOpen && (
                    <AddNewForm //add new form component for adding to section
                        classes={classes}
                        open={newFormDialogOpen}
                        returnNewFormDialog={this.returnNewFormDialog}
                        ormFormCreate={ormFormCreate}
                        ormFormUpdate={ormFormUpdate}
                        page={page}
                        sections={sections}
                        forms={forms}
                        clickedOnSection={this.state.clickedOnSection}
                        history={history}
                    />
                )}
                <AddSection
                    classes={classes}
                    open={newDialogOpen}
                    returnDialog={returnDialog}
                    ormSectionCreate={ormSectionCreate}
                    page={page}
                    otherSections={sections}
                    geoPoint={this.state.geoPoint}
                    showGeoColor={this.state.showGeoColor}
                    disabledRepeatable={this.state.disabledRepeatable}
                    handleGeoColor={this.handleGeoColor}
                    handleMasterContacts={this.handleMasterContacts}
                    handleGeoType={this.handleGeoType}
                    showAttachmentTypes={this.state.showAttachmentTypes}
                    showAutoSectionCreate={this.state.showAutoSectionCreate}
                    showAGOLQuestions={this.state.showAGOLQuestions}
                    handleAttachmentTypes={this.handleAttachmentTypes}
                    handleAGOLQuestions={this.handleAGOLQuestions}
                    handleAutoSection={this.handleAutoSection}
                />
                <EditSection
                    classes={classes}
                    open={openEditDialog}
                    ormSectionUpdate={ormSectionUpdate}
                    ormSectionMapGroupingCreate={ormSectionMapGroupingCreate}
                    ormSectionMapGroupingUpdate={ormSectionMapGroupingUpdate}
                    ormSectionMapGroupingDelete={ormSectionMapGroupingDelete}
                    ormGroupingValueCreate={ormGroupingValueCreate}
                    ormGroupingValueUpdate={ormGroupingValueUpdate}
                    ormGroupingValueDelete={ormGroupingValueDelete}
                    section={editDialog}
                    otherSections={sections}
                    authState={authState}
                    returnEditDialog={this.returnEditDialog}
                    geoHitFlag={this.state.geoHitFlag}
                    geoPoint={this.state.geoPoint}
                    showGeoColor={this.state.showGeoColor}
                    disabledRepeatable={this.state.disabledRepeatable}
                    handleGeoColor={this.handleGeoColor}
                    handleMasterContacts={this.handleMasterContacts}
                    handleGeoType={this.handleGeoType}
                    attachmentHitFlag={this.state.attachmentHitFlag}
                    contactHitFlag={this.state.contactHitFlag}
                    AGOLHitFlag={this.state.AGOLHitFlag}
                    autoSectionHitFlag={this.state.autoSectionHitFlag}
                    showAttachmentTypes={this.state.showAttachmentTypes}
                    showAutoSectionCreate={this.state.showAutoSectionCreate}
                    showAGOLQuestions={this.state.showAGOLQuestions}
                    handleAttachmentTypes={this.handleAttachmentTypes}
                    handleAutoSection={this.handleAutoSection}
                    handleAGOLQuestions={this.handleAGOLQuestions}
                    mapGroups={mapGroups}
                    groupValues={groupValues}
                />
                <ParentWarningDialog
                    confirmAction={() => {
                        ormSectionDelete(deleteDialogConfirmAction);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title={this.state.title ? "Delete Section" : ""} //dont display delete section if its a parent and there is children
                    text={deleteDialogText}
                    buttons={this.state.buttons} //pass whether or not to show buttons. True = show, false = dont show
                />
            </>
        );
    }
}
// // The add new form popup
// // http://localhost:3000/#/page/49
class AddNewForm extends Component {
    constructor() {
        super();
        this.state = {
            error: "",
        };
    }
    // When the add button is pressed
    createForm(values) {
        const { ormFormCreate, returnNewFormDialog, clickedOnSection, history } = this.props;

        var temp = {};
        temp.name = values.name;
        ormFormCreate({
            section: clickedOnSection,
            ...temp,
        }).then((formm) => {
            return history.push("/form/" + clickedOnSection + "/" + formm.id);
        });
        //**** "...values" is the same as typing out column name with value below   *******
        // name: values.name, etc...

        returnNewFormDialog();
    }

    //edit the form that was previously created and selected
    editForm = (e, oldValue) => {
        const { clickedOnSection, history, sections, ormFormUpdate } = this.props;

        //get form that was clicked on to route to that form for editing below
        if (e) {
            //just route to form
            if (!oldValue) {
                let actForm = sections
                    .find((sec) => {
                        if (sec.id === clickedOnSection) {
                            return sec;
                        }
                        return null;
                    })
                    ._forms.find((n) => {
                        if (n.name === e.name) {
                            return n.id;
                        }
                        return null;
                    });

                history.push("/form/" + clickedOnSection + "/" + actForm.id);
            } else {
                //here we want to just rename it
                let actForm = sections
                    .find((sec) => {
                        if (sec.id === clickedOnSection) {
                            return sec;
                        }
                        return null;
                    })
                    ._forms.find((n) => {
                        if (n.name === oldValue) {
                            return n.id;
                        }
                        return null;
                    });

                ormFormUpdate({ id: actForm.id, name: e });
            }
        }
    };

    // Make sure there are values entered for the Name field and check for prior 'form names' that have already been added
    errorValidator = (values) => {
        const { sections, clickedOnSection } = this.props;
        var value = {};
        if (values) value = values.name;

        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        const previouslyAdded = (val) => {
            return val ? "There is already a Form with that Name. Rename to continue..." : null;
        };

        //get all form names for that section
        var formNames = sections
            .find((sec) => {
                if (sec.id === clickedOnSection) {
                    return sec;
                }
                return null;
            })
            ._forms.map((n) => {
                return n.name;
            });

        //check for previous name, trim out spaces in front/end of new value to check against saved values
        const previousName = formNames.filter((e) => {
            if (value) {
                if (e === value.trim()) {
                    return e;
                }
            }
            return null;
        });

        //check for if name wasnt added, or if a formname is the same as the list that was already created
        var valObj = {};
        var name = isRequired(value);
        var prevName = previouslyAdded(previousName[0]);

        if (name !== null) {
            valObj.name = name;
        } else if (prevName !== null) {
            valObj.name = prevName;
        } else {
            valObj.name = name;
        }

        return valObj;
    };
    setError = (error) => {
        //set error for same form name
        this.setState({ error });
    };
    render() {
        const { classes, returnNewFormDialog, open, sections, clickedOnSection } = this.props;
        const { error } = this.state;

        var columns = [{ title: "Form Name", field: "name" }];

        var formNames = sections
            .find((sec) => {
                if (sec.id === clickedOnSection) {
                    return sec;
                }
                return null;
            })
            ._forms.map((n) => {
                let t = {};
                t.name = n.name;
                return t;
            });

        //default object for checking if default is already set
        var defaultValues = {};
        if (sections.length === 0) {
            defaultValues.parent = true;
        }

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                    {formNames.length === 0 && (
                        <Typography variant="h5" className={classes.flex} style={{ marginLeft: "25%" }}>
                            Create a New Form
                        </Typography>
                    )}
                    {formNames.length > 0 && (
                        <Typography variant="h5" className={classes.flex} style={{ marginLeft: "22%" }}>
                            Create or Edit a Form
                        </Typography>
                    )}
                    <IconButton onClick={returnNewFormDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Formm
                            getApi={(el) => (this.formApi = el)}
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            onSubmit={(values) => this.createForm(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <div>
                                        <MaterialTable
                                            style={{ marginLeft: -14, marginRight: -14, marginBottom: -8 }}
                                            title=""
                                            columns={columns}
                                            data={formNames}
                                            options={{
                                                search: false,
                                                paging: false,
                                            }}
                                            components={{
                                                Toolbar: (
                                                    props //allows overriding
                                                ) => (
                                                    <div className={classes.materialTableToolbar}>
                                                        <MTableToolbar {...props} />
                                                    </div>
                                                ),
                                                Action: (
                                                    props //allows overriding
                                                ) => (
                                                    <div className={classes.materialTableActions}>
                                                        <MTableAction {...props} />
                                                    </div>
                                                ),
                                            }}
                                            editable={{
                                                onRowAdd: (newData) =>
                                                    new Promise((resolve, reject) => {
                                                        let i = this.errorValidator(newData);
                                                        if (i.name === null) {
                                                            this.createForm(newData);
                                                        } else {
                                                            this.setError(i.name);
                                                            reject(); //dont allow window to close
                                                        }
                                                    }),
                                            }}
                                            cellEditable={{
                                                //only for renaming form
                                                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                                    return new Promise((resolve, reject) => {
                                                        this.editForm(newValue, oldValue);
                                                        setTimeout(resolve, 1000);
                                                    });
                                                },
                                            }}
                                            icons={{
                                                Add: (props) => (
                                                    <Button variant="contained" color="primary">
                                                        Create New Form
                                                    </Button>
                                                ),
                                            }}
                                            actions={[
                                                {
                                                    icon: "edit",
                                                    iconProps: { color: "primary" },
                                                    tooltip: "Edit Form",
                                                    onClick: (event, rowData) => {
                                                        // edit operation
                                                        this.editForm(rowData);
                                                    },
                                                },
                                                // {
                                                //     icon: "delete",
                                                //     tooltip: "Delete Form",
                                                //     iconProps: { color: "primary" },
                                                //     onClick: (event, rowData) => {
                                                //         // delete operation
                                                //         //this.editForm(rowData);
                                                //     }
                                                // }
                                            ]}
                                        />
                                        <div style={{ color: "red" }}>{error}</div>
                                    </div>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

// The add new section popup
// http://localhost:3000/#/page/49
class AddSection extends Component {
    // When the add button is pressed
    createSection(values) {
        const { ormSectionCreate, returnDialog, page, otherSections } = this.props;

        ormSectionCreate({
            page: page,
            order: otherSections.length,
            ...values,
        });
        //**** "...values" is the same as typing out column name with value below   *******
        // name: values.name, etc...

        returnDialog();
    }

    // Make sure there are values entered for the Name and check for other parents in the Parent checkbox
    // Error validation runs FIRST from "FORM" when a submit action event occurs
    errorValidator = (values) => {
        const { otherSections } = this.props;

        const isRequired = (val) => {
            return !val ? "Required" : null;
        };

        //setup a error flag if parent count is 2 or greater else allow "errorValidator" method to pass
        const checkCount = (c) => {
            return c < 2 ? null : (
                <div>
                    <div>{"ERROR: Primary is in another Section, each Module can only have 'One' Primary."} </div>
                    <div>{"Deselect this 'Primary Section' to allow Saving of this Section"} </div>
                    <div>{"NOTE: to allow this Section to be the 'Primary' deselect the other Section first before Enabling this Section"}</div>
                    {/* {this.setState({ checkBoxStatus: !this.state.checkBoxStatus })} */}
                </div>
            );
        };

        //check and count how many parents are in "otherSections" of page, also check for the dialogbox contents that is currently opened
        let count = 0;
        otherSections.forEach((p) => {
            //take in account for the dialog parentCheckbox being clicked which is scoped in the dialog box with section.id, set to current status
            if (p.parent === true) {
                count++;
            }

            //loop through the sections, add all parent checkboxes that are true, this will be the whole section list for the page
        });

        if (values.parent) {
            count++;
        }

        const specialchars = (name) => {
            //eslint-disable-next-line
            const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            return specialChars.test(name); //regex testing of characters
        };

        //check the 3 columns in the dialog for compliance. isRequired() makes sure fields are present
        //checkCount() makes sure count is true (i.e. less than 2) before validation returns successful
        var valObj = {
            name: specialchars(values.name)
                ? "Special characters '/[`!@#$%^&*()+-=[]{};':|,.<>/?~]' are not allowed in name"
                : isRequired(values.name),
            //type: isRequired(values.type),
            parent: checkCount(count),
            attachment_types: values.has_attachments ? isRequired(values.attachment_types) : null,
        };
        if(valObj.attachment_types === 'Required')window.scrollTo({top:0, behavior: 'smooth'})

        return valObj;
    };

    render() {
        const {
            classes,
            returnDialog,
            open,
            otherSections,
            showGeoColor,
            disabledRepeatable,
            geoPoint,
            handleGeoColor,
            handleMasterContacts,
            handleGeoType,
            showAttachmentTypes,
            showAutoSectionCreate,
            handleAutoSection,
            handleAttachmentTypes,
            showAGOLQuestions,
            handleAGOLQuestions,
        } = this.props;

        //default object for checking if default is already set
        var defaultValues = {repeatable_subform: true, append_fields: true, tableview_rowsperpage:25, repeatable_rowsperpage:10};
        if (otherSections.length === 0) {
            defaultValues.parent = true;
        }

        var autoCreateSections = []
        otherSections.forEach(s=>!s.parent && !s.is_master_contact_form? autoCreateSections.push(s.name):null)

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                    <Typography variant="h5" className={classes.flex}>
                        Add New Section
                    </Typography>
                    <IconButton onClick={returnDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin} >
                    <DialogContentText>
                        <Formm
                            getApi={(el) => (this.formApi = el)}
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            defaultValues={defaultValues}
                            onSubmit={(values) => this.createSection(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="name"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Name"
                                                        helpText="Name that appears in the user interface on the tabs within a module"
                                                    />
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                field="icon"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Icon"
                                                        helpText="Icon that appears in the user interface on the tabs within a module"
                                                    />
                                                }
                                                options={MAKE_OPTIONS_ICON(ICON_LIST)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="iframe_url_section"
                                                label="(Optional) Configure section as an iframe - enter URL"
                                                fullWidth
                                            />
                                            <Switch
                                                field="has_attachments"
                                                name="has_attachments"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Has Attachments?"
                                                        helpText="Attachments can be added to the record (i.e. PDF, .doc, jpg, etc.)"
                                                    />
                                                }
                                                eventHandle={(e) => {
                                                    handleAttachmentTypes(e);
                                                }}
                                            />
                                            {showAttachmentTypes && (
                                                <Subquestion
                                                    component={
                                                        <TextField
                                                            field="attachment_types"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Attachment Types"
                                                                    helpText="Enter a comma separated list of attachment types that will be used in a drop down for adding attachments (i.e. Drawing,Map,Letter,Photo)"
                                                                />
                                                            }
                                                            multiline
                                                            fullWidth
                                                        />
                                                    }
                                                />
                                            )}
                                            <Switch
                                                field="append_fields"
                                                name="append_fields"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Append New Fields to Prior Records?"
                                                        helpText="Attach new form field to all records that were previously created "
                                                    />
                                                }
                                            /> 
                                            {!defaultValues.parent && (
                                                <Switch
                                                    field="repeatable_subform"
                                                    name="repeatable_subform"
                                                    disabled={disabledRepeatable}
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Only Allow One Record to be Created in the Section"
                                                            helpText={<div>
                                                                Toggling this setting to 'No' will allow the creation of many sub-records in the section.
                                                                <br/><br/>
                                                                Toggling this to 'No' is most useful when the intention of the section is to store many sub-records to the parent record. Some typical examples are:
                                                                <ul>
                                                                    <li>creating many inspections for a permit</li>
                                                                    <li>creating many maintenance records for a facility</li>
                                                                    <li>creating a correspondence log</li>
                                                                </ul>
                                                            If your unsure how to use this setting, please contact MS4Front support.</div>}
                                                        />
                                                    }
                                                />        
                                            )}
                                            <Grid container spacing={2}> 
                                                <Grid item xs={6}>
                                                    <Select
                                                        field="tableview_rowsperpage"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Default Table View Rows Per Page"
                                                                helpText="Total records to be seen before pagination. Note: for one recordsection sections this will not be applicable"
                                                            />
                                                        }
                                                        options={MAKE_OPTIONS(["10","25","50","100"])}
                                                        fullWidth
                                                    />
                                                </Grid>     
                                                <Grid item xs={6}>
                                                    <Select
                                                        field="repeatable_rowsperpage"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Default Repeatable Rows Per Page"
                                                                helpText="Total records to be seen before pagination"
                                                            />
                                                        }
                                                        options={MAKE_OPTIONS(["5","10","15","20","25","50"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid> 
                                            {defaultValues.parent && (<>
                                                <Switch
                                                    field="autocreate_another_section_record"
                                                    name="autocreate_another_section_record"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Auto Create Related Record for every Primary Created Record"
                                                            helpText="Related record will be created for every new primary/parent record creation. This should only be used when the related section is setup to allow for one record OR the related section has only one form."
                                                        />
                                                    }
                                                    eventHandle={(e) => {
                                                        handleAutoSection(e);
                                                    }}
                                                    
                                                />   
                                                {showAutoSectionCreate && (
                                                    <Subquestion
                                                        component={ 
                                                            <Select
                                                            field="autocreate_section"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Choose the section to auto create"
                                                                    helpText="This choosen section will be auto created for every primary record creation and is only appliable for desktop primary record creation"
                                                                />
                                                            }
                                                            options={MAKE_OPTIONS(autoCreateSections)}
                                                            fullWidth
                                                        />
                                                        }
                                                    />
                                                )}
                                            </>)}
                                            <Switch
                                                field="is_master_contact_form"
                                                name="is_master_contact_form"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Use Master Contacts?"
                                                        helpText="Selecting this will prevent you from adding custom forms and use the configuration from the master contacts list"
                                                    />

                                                }
                                                eventHandle={(e) => {
                                                    if(e===true)
                                                    this.formApi.setValue('repeatable_subform',false);
                                                    handleMasterContacts(e)
                                                }}
                                            />
                                            {defaultValues.parent && (
                                                <Switch
                                                    field="has_confirmation"
                                                    name="has_confirmation"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Show Confirmation During Record Creation"
                                                            helpText="Shows a confirmation dialog when creating a new record to avoid accidental creation. This setting is ignored if there are multiple forms configured for the section."
                                                        />
                                                    }
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h4">Map Location Settings for this Section</Typography>
                                            <Switch
                                                field="has_geometry"
                                                name="has_geometry"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Include Map Location?"
                                                        helpText="Store a map geometry to represent a location of the record."
                                                    />
                                                }
                                                eventHandle={(e) => {
                                                    handleGeoColor(e);
                                                }}
                                            />
                                            {showGeoColor && (
                                                <>
                                                    <Subquestion
                                                        component={
                                                            <Select
                                                                field="geometry_type"
                                                                options={MAKE_OPTIONS(["Point", "MultiPoint", "Line", "Polygon"])}
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Geometry Type"
                                                                        helpText="Digitizing locations on the map will be stricted to this geometry type."
                                                                    />
                                                                }
                                                                fullWidth
                                                                eventHandle={(e) => {
                                                                    handleGeoType(e);
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={11}>
                                                            <Switch
                                                                field="display_list"
                                                                name="display_list"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Include Section on Map Legend?"
                                                                        helpText="Switch to No if you don't need to view all of the geometries at once on the map."
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={11}>
                                                            <Switch
                                                                field="display_module"
                                                                name="display_module"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Include Section Within Other Modules?"
                                                                        helpText="Switch to Yes if you would like to view this section inside the map of a different module."
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={11}>
                                                            <Switch
                                                                field="on_by_default"
                                                                name="on_by_default"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Layer on by Default?"
                                                                        helpText="Whether the map is checked on by default. It's recommended that this be set to No if Map Groupings are configured."
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={11}>
                                                            <Switch
                                                                field="show_label_on_map"
                                                                name="show_label_on_map"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Show Identification Label on Map"
                                                                        helpText="If there is an identification field set it will display that value with the geometry while viewing the map."
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={11}>
                                                            <ColorPicker field="geo_color" label="Map Layer Color" />
                                                        </Grid>
                                                    </Grid>
                                                    {geoPoint && (
                                                        <>
                                                            <Grid container>
                                                                <Grid item xs={1}></Grid>
                                                                <Grid item xs={11}>
                                                                    <Select
                                                                        field="point_symbology"
                                                                        options={MAKE_OPTIONS([
                                                                            "Circle",
                                                                            "Triangle",
                                                                            "Square",
                                                                            "Cross",
                                                                            "Diamond",
                                                                            "X",
                                                                        ])}
                                                                        label={
                                                                            <HelpLabel
                                                                                inputLabel="Point Symbology"
                                                                                helpText="What type of point should this layer be displayed on the map as."
                                                                            />
                                                                        }
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={1}></Grid>
                                                                <Grid item xs={11}>
                                                                    <TextField
                                                                        field="point_size"
                                                                        style={{ marginTop: 16 }}
                                                                        label={
                                                                            <HelpLabel
                                                                                inputLabel="Point Size"
                                                                                helpText="(Integer) How large the point is displayed on the map. The default size depends on the point symbology. Square/Cross/X defaults to 8. Circle/Triangle/Diamond defaults to 12."
                                                                            />
                                                                        }
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h4">Mobile Forms Setting for this Section</Typography>
                                            <Switch
                                                field="is_contact_form"
                                                name="is_contact_form"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Include Contact Emails in Mobile Form?"
                                                        helpText="When selected a list of email addresses will be added to the mobile form to email inspection reports to."
                                                    />
                                                }
                                            />
                                        </Grid>
                                        {showGeoColor && (
                                            <Grid container style={{ padding: "8px 0" }}>
                                                <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                <Grid item xs={11}>
                                                    <TextField
                                                        field="agol_service"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Sync Data with ArcGIS Online Service"
                                                                helpText="When you add, edit, or delete data within MS4Front have it sync directly with your ArcGIS Online feature service. Ex: https://services2.arcgis.com/rwqARsO7kmPlpMQS/ArcGIS/rest/services/service_e765080ef4ca4aa0937cf8122551a902/FeatureServer/0/"
                                                            />
                                                        }
                                                        eventHandle={(e) => {
                                                            handleAGOLQuestions(e);
                                                        }}
                                                        multiline
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {showAGOLQuestions && (
                                            <>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                    <Grid item xs={11}>
                                                        <TextField field="agol_username" label="ArcGIS Username" multiline fullWidth />
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                    <Grid item xs={11}>
                                                        <TextField field="agol_password" label="ArcGIS Password" multiline fullWidth />
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                    <Grid item xs={11}>
                                                        <TextField field="agol_referer" label="ArcGIS Referrer" multiline fullWidth />
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                    <Grid item xs={11}>
                                                        <TextField
                                                            field="token_url"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Custom Token URL"
                                                                    helpText="If your organization has a self hosted ArcGIS rest service that uses token authentication paste the URL of where tokens can be generated."
                                                                />
                                                            }
                                                            multiline
                                                            fullWidth
                                                        />
                                                        <Switch
                                                            field="use_latlon"
                                                            name="use_latlon"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Transform Geometry to WGS84 (Lat/Lon)"
                                                                    helpText="If you published your rest service using WGS84 check this. (rare use case)"
                                                                />
                                                            }
                                                        />
                                                        <Switch
                                                            field="use_timestamp"
                                                            name="use_timestamp"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Sync date fields as datetime"
                                                                    helpText="Prior to August 2023 by default date fields were synced to ArcGIS Online as text. If you have set up the feature service after that date or are using esriFieldTypeDate for date fields this should be set to Yes."
                                                                />
                                                            }
                                                        />
                                                        <Switch
                                                            field="is_enterprise"
                                                            name="is_enterprise"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Is ArcGIS Enterprise"
                                                                    helpText="If your organization is using ArcGIS Enterprise version provide the enterprise ID and enterprise secret below."
                                                                />
                                                            }
                                                        />
                                                        <TextField field="enterprise_id" label="Enterprise ID" multiline fullWidth />
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                    <Grid item xs={11}>
                                                        <TextField field="enterprise_secret" label="Enterprise Secret" multiline fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

// The edit popup when you click the edit button in the table
// This should be almost the same as AddPage
// In fact perhaps AddPage + EditSection could be refactored into one component since the only difference should be ormSectionCreate vs ormSectionUpdate
// http://localhost:3000/#/page/49
class EditSection extends Component {
    state = {
        syncLoading: false,
        repeatableChange: false,
    };
    typingTimer;
    typingTimer2;
    typingTimer3;
    typingTimer4;

    updateSection(values) {
        const { ormSectionUpdate, returnEditDialog, section } = this.props;

        var newValues = values;
        if (!values.has_geometry && values.geo_color) newValues.geo_color = ""; //remove geo_color if geometry turned off

        
        ormSectionUpdate({
            id: section.id,
            ...newValues,
        });
        //**** "...values" destructuring is the same as typing out column name with value below   *******
        // name: values.name, etc...

        returnEditDialog(); //close the dialog component
    }

    // Error validation runs FIRST from "FORM" when a submit action event occurs
    errorValidator = (values) => {
        const { otherSections, section } = this.props;

        const isRequired = (val) => {
            return !val ? "Required" : null;
        };

        //setup a error flag if parent count is 2 or greater else allow "errorValidator" method to pass
        const checkCount = (c) => {
            return c < 2 ? null : (
                <div>
                    <div>{"ERROR: Primary is in another Section, each Module can only have 'One' Primary."} </div>
                    <div>{"Deselect this 'Primary Section' to allow Saving of this Section"} </div>
                    <div>{"NOTE: to allow this Section to be the 'Primary' deselect the other Section first before Enabling this Section"}</div>
                </div>
            );
        };

        //check and count how many parents are in "otherSections" of page, also check for the dialogbox contents that is currently opened
        let count = 0;
        otherSections.forEach((p) => {
            //take in account for the dialog parentCheckbox being clicked which is scoped in the dialog box with section.id, set to current status
            if (p.id !== section.id && p.parent === true) {
                count++;
            }

            //loop through the sections, add all parent checkboxes that are true, this will be the whole section list for the page
        });

        //add to count if parent is checked
        if (values.parent) {
            count++;
        }

        const specialchars = (name) => {
            //eslint-disable-next-line
            const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            return specialChars.test(name); //regex testing of characters
        };
        //check the 2 columns in the dialog for compliance. isRequired() makes sure fields are present
        //checkCount() makes sure count is true (i.e. less than 2) before validation returns successful
        var valObj = {
            name: specialchars(values.name)
                ? "Special characters '/[`!@#$%^&*()+-=[]{};':|,.<>/?~]' are not allowed in name"
                : isRequired(values.name),
            geo_color: values.has_geometry ? isRequired(values.geo_color) : null,
            // type: isRequired(values.type),
            parent: checkCount(count),
            attachment_types: values.has_attachments ? isRequired(values.attachment_types) : null,
        };
        if(valObj.attachment_types === 'Required')window.scrollTo(0,0)
        return valObj;
    };

    createGrouping = () => {
        const { section, ormSectionMapGroupingCreate } = this.props;
        ormSectionMapGroupingCreate({
            section: section.id,
        });
    };

    createGroupValue = (group_id) => {
        const { ormGroupingValueCreate } = this.props;
        ormGroupingValueCreate({
            sectionmapgrouping: group_id,
        });
    };

    updateTextValue = (id, value) => {
        const { ormGroupingValueUpdate } = this.props;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function () {
            //one sec delay for saving, used for keypressing delays
            ormGroupingValueUpdate({ id: id, label: value });
        }, 1000);
    };

    updateTextValue2 = (id, value) => {
        const { ormGroupingValueUpdate } = this.props;
        clearTimeout(this.typingTimer2);
        this.typingTimer2 = setTimeout(function () {
            //one sec delay for saving, used for keypressing delays
            ormGroupingValueUpdate({ id: id, value: value });
        }, 1000);
    };

    updateTextValue3 = (id, value) => {
        const { ormSectionMapGroupingUpdate } = this.props;
        clearTimeout(this.typingTimer3);
        this.typingTimer3 = setTimeout(function () {
            //one sec delay for saving, used for keypressing delays
            ormSectionMapGroupingUpdate({ id: id, group_name: value });
        }, 1000);
    };

    updateTextValue4 = (id, value) => {
        const { ormGroupingValueUpdate } = this.props;
        clearTimeout(this.typingTimer4);
        this.typingTimer4 = setTimeout(function () {
            //one sec delay for saving, used for keypressing delays
            ormGroupingValueUpdate({ id: id, point_size: value });
        }, 1000);
    };

    render() {
        const {
            classes,
            returnEditDialog,
            open,
            section,
            geoHitFlag,
            showGeoColor,
            disabledRepeatable,
            geoPoint,
            handleGeoColor,
            handleMasterContacts,
            handleGeoType,
            handleAttachmentTypes,
            handleAutoSection,
            handleAGOLQuestions,
            showAttachmentTypes,
            showAutoSectionCreate,
            showAGOLQuestions,
            attachmentHitFlag,
            contactHitFlag,
            AGOLHitFlag,
            ormSectionMapGroupingDelete,
            ormSectionMapGroupingUpdate,
            mapGroups,
            groupValues,
            ormGroupingValueDelete,
            ormGroupingValueUpdate,
            otherSections,
            authState,
            autoSectionHitFlag
        } = this.props;
        const { syncLoading, repeatableChange } = this.state;
        var sectionForm = Object.assign({}, section);
        delete sectionForm._forms;
        delete sectionForm._groupings;

        if (open && sectionForm.has_geometry && geoHitFlag) {
            handleGeoColor(true); //only one time to set on initial opening
            if (sectionForm.geometry_type === "Point" || sectionForm.geometry_type === "MultiPoint") handleGeoType(sectionForm.geometry_type);
        }
        if (open && sectionForm.is_master_contact_form && contactHitFlag) handleMasterContacts(true); //only one time to set on initial opening
        if (open && sectionForm.has_attachments && attachmentHitFlag) handleAttachmentTypes(true); //only one time to set on initial opening
        if (open && sectionForm.agol_service && AGOLHitFlag) handleAGOLQuestions(true); //only one time to set on initial opening
        if (open && sectionForm.autocreate_another_section_record && autoSectionHitFlag) handleAutoSection(true); //only one time to set on initial opening

        var _fields = [];
        if (open && section && section._forms) {
            otherSections.forEach(function (oS) {
                oS._forms.forEach(function (_f) {
                    _f.groups.forEach(function (_g) {
                        _g.fields.forEach(function (fl) {
                            _fields.push({ label: fl.name + " (" + oS.name + ")", value: fl.id.toString() });
                        });
                    });
                });
            });
        }
        var autoCreateSections = []
        otherSections.forEach(s=>!s.parent && !s.is_master_contact_form? autoCreateSections.push(s.name):null)

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                    <Typography variant="h5" className={classes.flex}>
                        Edit Section
                    </Typography>
                    <IconButton onClick={returnEditDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Formm
                            getApi={(el) => (this.formApi = el)}
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            defaultValues={sectionForm}
                            validateError={this.errorValidator}
                            onSubmit={(values) => this.updateSection(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="name"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Name"
                                                        helpText="Name that appears in the user interface on the tabs within a module"
                                                    />
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                field="icon"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Icon"
                                                        helpText="Icon that appears in the user interface on the tabs within a module"
                                                    />
                                                }
                                                options={MAKE_OPTIONS_ICON(ICON_LIST)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="iframe_url_section"
                                                label="(Optional) Configure section as an iframe - enter URL"
                                                fullWidth
                                            />
                                            <Switch
                                                field="has_attachments"
                                                name="has_attachments"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Has Attachments?"
                                                        helpText="Attachments can be added to the record (i.e. PDF, .doc, jpg, etc.)"
                                                    />
                                                }
                                                eventHandle={(e) => {
                                                    handleAttachmentTypes(e);
                                                }}
                                            />
                                            {showAttachmentTypes && (
                                                <Subquestion
                                                    component={
                                                        <TextField
                                                            field="attachment_types"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Attachment Types"
                                                                    helpText="Enter a comma separated list of attachment types that will be used in a drop down for adding attachments (i.e. Drawing,Map,Letter,Photo)"
                                                                />
                                                            }
                                                            multiline
                                                            fullWidth
                                                        />
                                                    }
                                                />
                                            )}
                                            <Switch
                                                field="append_fields"
                                                name="append_fields"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Append New Fields to Prior Records?"
                                                        helpText="Attach new form field to all records that were previously created "
                                                    />
                                                }
                                            /> 
                                            {section && !section.parent && (          
                                                <Switch
                                                    field="repeatable_subform"
                                                    name="repeatable_subform"
                                                    disabled={disabledRepeatable}
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Only Allow One Record to be Created in the Section"
                                                            helpText={<div>
                                                                Toggling this setting to 'No' will allow the creation of many sub-records in the section.
                                                                <br/><br/>
                                                                Toggling this to 'No' is most useful when the intention of the section is to store many sub-records to the parent record. Some typical examples are:
                                                                <ul>
                                                                    <li>creating many inspections for a permit</li>
                                                                    <li>creating many maintenance records for a facility</li>
                                                                    <li>creating a correspondence log</li>
                                                                </ul>
                                                            If your unsure how to use this setting, please contact MS4Front support.</div>}
                                                        />
                                                    }
                                                    eventHandle={(e) => this.setState({ repeatableChange: e })}
                                                />        
                                            )}
                                            <Grid container spacing={2}> 
                                                <Grid item xs={6}>
                                                    <Select
                                                        field="tableview_rowsperpage"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Default Table View Rows Per Page"
                                                                helpText="Total records to be seen before pagination. Note: for one recordsection sections this will not be applicable"
                                                            />
                                                        }
                                                        options={MAKE_OPTIONS(["10","25","50","100"])}
                                                        fullWidth
                                                    />
                                                </Grid> 
                                                <Grid item xs={6}>
                                                    <Select
                                                        field="repeatable_rowsperpage"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Default Repeatable Rows Per Page"
                                                                helpText="Total records to be seen before pagination"
                                                            />
                                                        }
                                                        options={MAKE_OPTIONS(["5","10","15","20","25","50"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid> 
                                            {section && section.parent &&(
                                                <> 
                                                <Switch
                                                    field="autocreate_another_section_record"
                                                    name="autocreate_another_section_record"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Auto Create Related Record for every Primary Created Record"
                                                            helpText="Related record will be created for every new primary/parent record creation. This should only be used when the related section is setup to allow for one record OR the related section has only one form."
                                                        />
                                                    }
                                                    eventHandle={(e) => {
                                                        handleAutoSection(e);
                                                    }}
                                                />   
                                                {showAutoSectionCreate && (
                                                    <Subquestion
                                                        component={ 
                                                            <Select
                                                            field="autocreate_section"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Choose the section to auto create"
                                                                    helpText="This choosen section will be auto created for every primary record creation and is only appliable for desktop primary record creation"
                                                                />
                                                            }
                                                            options={MAKE_OPTIONS(autoCreateSections)}
                                                            fullWidth
                                                        />
                                                        }
                                                    />
                                                )}  </>
                                            )}
                                            <Switch
                                                field="is_master_contact_form"
                                                name="is_master_contact_form"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Use Master Contacts?"
                                                        helpText="Selecting this will prevent you from adding custom forms and use the configuration from the master contacts list"
                                                    />
                                                }
                                                eventHandle={(e) => {
                                                    if(e===true)
                                                    this.formApi.setValue('repeatable_subform',false);
                                                    handleMasterContacts(e);
                                                }}
                                            />
                                            {sectionForm.parent && (
                                                <Switch
                                                    field="has_confirmation"
                                                    name="has_confirmation"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Show Confirmation During Record Creation"
                                                            helpText="Shows a confirmation dialog when creating a new record to avoid accidental creation. This setting is ignored if there are multiple forms configured for the section."
                                                        />
                                                    }
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h4">Map Location Settings for this Section</Typography>
                                            <Switch
                                                field="has_geometry"
                                                name="has_geometry"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Include Map Location?"
                                                        helpText="Store a map geometry to represent a location of the record."
                                                    />
                                                }
                                                eventHandle={(e) => {
                                                    handleGeoColor(e);
                                                }}
                                            />
                                            {showGeoColor && (
                                                <>
                                                    <Subquestion
                                                        component={
                                                            <Select
                                                                field="geometry_type"
                                                                disabled={sectionForm.is_locked}
                                                                options={MAKE_OPTIONS(["Point", "MultiPoint", "Line", "Polygon"])}
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Geometry Type"
                                                                        helpText="Digitizing locations on the map will be stricted to this geometry type."
                                                                    />
                                                                }
                                                                fullWidth
                                                                eventHandle={(e) => {
                                                                    handleGeoType(e);
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={11}>
                                                            <Switch
                                                                field="display_list"
                                                                name="display_list"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Include Section on Map Legend?"
                                                                        helpText="Switch to No if you don't need to view all of the geometries at once on the map."
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={11}>
                                                            <Switch
                                                                field="display_module"
                                                                name="display_module"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Include Section Within Other Modules?"
                                                                        helpText="Switch to Yes if you would like to view this section inside the map of a different module."
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={11}>
                                                            <Switch
                                                                field="on_by_default"
                                                                name="on_by_default"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Layer on by Default?"
                                                                        helpText="Whether the map is checked on by default. It's recommended that this be set to No if Map Groupings are configured."
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={11}>
                                                            <Switch
                                                                field="show_label_on_map"
                                                                name="show_label_on_map"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Show Identification Label on Map"
                                                                        helpText="If there is an identification field set it will display that value with the geometry while viewing the map."
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={11}>
                                                            <ColorPicker field="geo_color" label="Map Layer Color" />
                                                        </Grid>
                                                    </Grid>
                                                    {geoPoint && (
                                                        <>
                                                            <Grid container>
                                                                <Grid item xs={1}></Grid>
                                                                <Grid item xs={11}>
                                                                    <Select
                                                                        field="point_symbology"
                                                                        options={MAKE_OPTIONS([
                                                                            "Circle",
                                                                            "Triangle",
                                                                            "Square",
                                                                            "Cross",
                                                                            "Diamond",
                                                                            "X",
                                                                        ])}
                                                                        label={
                                                                            <HelpLabel
                                                                                inputLabel="Point Symbology"
                                                                                helpText="What type of point should this layer be displayed on the map as."
                                                                            />
                                                                        }
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={1}></Grid>
                                                                <Grid item xs={11}>
                                                                    <TextField
                                                                        field="point_size"
                                                                        style={{ marginTop: 16 }}
                                                                        label={
                                                                            <HelpLabel
                                                                                inputLabel="Point Size"
                                                                                helpText="(Integer) How large the point is displayed on the map. The default size depends on the point symbology. Square/Cross/X defaults to 8. Circle/Triangle/Diamond defaults to 12."
                                                                            />
                                                                        }
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h4">Mobile Forms Setting for this Section</Typography>
                                            <Switch
                                                field="is_contact_form"
                                                name="is_contact_form"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Include Contact Emails in Mobile Form?"
                                                        helpText="When selected a list of email addresses will be added to the mobile form to email inspection reports to."
                                                    />
                                                }
                                            />
                                        </Grid>
                                        {showGeoColor && (
                                            <Grid container style={{ padding: "8px 0" }}>
                                                <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                <Grid item xs={11}>
                                                    <TextField
                                                        field="agol_service"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Sync Data with ArcGIS Online Service"
                                                                helpText="When you add, edit, or delete data within MS4Front have it sync directly with your ArcGIS Online feature service. Ex: https://services2.arcgis.com/rwqARsO7kmPlpMQS/ArcGIS/rest/services/service_e765080ef4ca4aa0937cf8122551a902/FeatureServer/0/"
                                                            />
                                                        }
                                                        eventHandle={(e) => {
                                                            handleAGOLQuestions(e);
                                                        }}
                                                        multiline
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {showAGOLQuestions && (
                                            <>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                    <Grid item xs={11}>
                                                        <TextField field="agol_username" label="ArcGIS Username" multiline fullWidth />
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                    <Grid item xs={11}>
                                                        <TextField field="agol_password" label="ArcGIS Password" multiline fullWidth />
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                    <Grid item xs={11}>
                                                        <TextField field="agol_referer" label="ArcGIS Referrer" multiline fullWidth />
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                    <Grid item xs={11}>
                                                        <TextField
                                                            field="token_url"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Custom Token URL"
                                                                    helpText="If your organization has a self hosted ArcGIS rest service that uses token authentication paste the URL of where tokens can be generated."
                                                                />
                                                            }
                                                            multiline
                                                            fullWidth
                                                        />
                                                        <Switch
                                                            field="use_latlon"
                                                            name="use_latlon"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Transform Geometry to WGS84 (Lat/Lon)"
                                                                    helpText="If you published your rest service using WGS84 check this. (rare use case)"
                                                                />
                                                            }
                                                        />
                                                        <Switch
                                                            field="use_timestamp"
                                                            name="use_timestamp"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Sync date fields as datetime"
                                                                    helpText="Prior to August 2023 by default date fields were synced to ArcGIS Online as text. If you have set up the feature service after that date or are using esriFieldTypeDate for date fields this should be set to Yes."
                                                                />
                                                            }
                                                        />
                                                        <Switch
                                                            field="is_enterprise"
                                                            name="is_enterprise"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Is ArcGIS Enterprise"
                                                                    helpText="If your organization is using ArcGIS Enterprise version provide the enterprise ID and enterprise secret below."
                                                                />
                                                            }
                                                        />
                                                        <TextField field="enterprise_id" label="Enterprise ID" multiline fullWidth />
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={1} style={{ maxWidth: "5%" }}></Grid>
                                                    <Grid item xs={11}>
                                                        <TextField field="enterprise_secret" label="Enterprise Secret" multiline fullWidth />
                                                        <Grid container style={{ marginTop: 16 }} spacing={2}>
                                                            <Grid item xs={6}>
                                                                <Button
                                                                    fullWidth
                                                                    onClick={() =>
                                                                        window.open(
                                                                            "/excel/form/" +
                                                                                section._forms[0].id +
                                                                                "/json/" +
                                                                                section.id + "/" +
                                                                                authState.auth.user.auth_token +
                                                                                "/True"
                                                                        )
                                                                    }>
                                                                    Download Feature Service JSON File
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                {syncLoading ? (
                                                                    <>
                                                                        <CircularProgress
                                                                            size={24}
                                                                            style={{ display: "table", marginLeft: "auto", marginRight: "auto" }}
                                                                        />
                                                                        Loading Please Wait... This may take several minutes up to several hours
                                                                        depending on how many records are being synced. During this time records that
                                                                        are being edited will not be synced.
                                                                    </>
                                                                ) : (
                                                                    <Button
                                                                        fullWidth
                                                                        onClick={() => {
                                                                            this.setState({ syncLoading: true });
                                                                            fetch("runsync/" + section.id, {
                                                                                method: "POST",
                                                                                headers: {
                                                                                    "Content-Type": "application/json",
                                                                                    Authorization: "Token " + authState.auth.user.auth_token,
                                                                                },
                                                                            })
                                                                                .then((response) => response.json())
                                                                                .then((data) => {
                                                                                    if (data.error) alert(data.error);
                                                                                    this.setState({ syncLoading: false });
                                                                                });
                                                                        }}>
                                                                        Sync MS4Front features to ArcGIS Online
                                                                    </Button>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        {showGeoColor && (
                                            <>
                                                <Grid container style={{ padding: "8px 0" }}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4" gutterBottom>
                                                            Map Groupings
                                                        </Typography>
                                                        <Button onClick={() => this.createGrouping()} variant="contained" color="primary" fullWidth>
                                                            <AddCircleOutlineIcon />
                                                            &nbsp;&nbsp;Add New Map Grouping
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                {mapGroups
                                                    .filter((mg) => mg.section === section.id)
                                                    .map((group) => {
                                                        return (
                                                            <Grid container style={{ border: "1px dotted", padding: "8px", marginBottom: 16 }}>
                                                                <Grid item xs={12}>
                                                                    <Formm defaultValues={group}>
                                                                        {(formApiChild) => (
                                                                            <form onSubmit={formApiChild.submitForm}>
                                                                                <Grid container>
                                                                                    <Grid item xs={5}>
                                                                                        <Select
                                                                                            field="field_id"
                                                                                            label={
                                                                                                <HelpLabel
                                                                                                    inputLabel="Field to Filter By"
                                                                                                    helpText="Field to filter Layer records on. Only one field can be used as the filter for the group."
                                                                                                />
                                                                                            }
                                                                                            eventHandle={(e) => {
                                                                                                ormSectionMapGroupingUpdate({
                                                                                                    id: group.id,
                                                                                                    field_id: e,
                                                                                                });
                                                                                            }}
                                                                                            options={_fields}
                                                                                            fullWidth
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={6}>
                                                                                        <TextField
                                                                                            field="group_name"
                                                                                            eventHandle={(e) => {
                                                                                                this.updateTextValue3(group.id, e);
                                                                                            }}
                                                                                            label={
                                                                                                <HelpLabel
                                                                                                    inputLabel="Grouping Name"
                                                                                                    helpText="The name of the layer group that appears in the legend."
                                                                                                />
                                                                                            }
                                                                                            fullWidth
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={1}>
                                                                                        <Tooltip title="Delete Grouping">
                                                                                            <Button
                                                                                                onClick={() => ormSectionMapGroupingDelete(group.id)}
                                                                                                style={{ float: "right", marginTop: 12 }}
                                                                                                className={classes.deleteWidth}>
                                                                                                <DeleteIcon />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} style={{ marginTop: 8 }}>
                                                                                        <Button
                                                                                            onClick={() => this.createGroupValue(group.id)}
                                                                                            variant="contained"
                                                                                            color="primary">
                                                                                            Add New Legend Item
                                                                                            <AddCircleOutlineIcon />
                                                                                        </Button>
                                                                                        <Switch
                                                                                            field="collapsed_by_default"
                                                                                            name="collapsed_by_default"
                                                                                            style={{ marginTop: -5, width: "auto", marginLeft: 8 }}
                                                                                            eventHandle={(e) => {
                                                                                                ormSectionMapGroupingUpdate({
                                                                                                    id: group.id,
                                                                                                    collapsed_by_default: e,
                                                                                                });
                                                                                            }}
                                                                                            label={
                                                                                                <HelpLabel
                                                                                                    inputLabel="Grouping Collapsed By Default?"
                                                                                                    helpText="If this parameter is switched to true this map grouping will be collapsed when initally entering the map."
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    {groupValues
                                                                                        .filter((gv) => gv.sectionmapgrouping === group.id)
                                                                                        .map((gValue) => {
                                                                                            return (
                                                                                                <Grid item xs={12} style={{ marginTop: 8 }}>
                                                                                                    <Formm defaultValues={gValue}>
                                                                                                        {(formApiChild2) => (
                                                                                                            <form onSubmit={formApiChild2.submitForm}>
                                                                                                                <Grid container>
                                                                                                                    <Grid item xs={7}>
                                                                                                                        <TextField
                                                                                                                            field="label"
                                                                                                                            eventHandle={(e) => {
                                                                                                                                this.updateTextValue(
                                                                                                                                    gValue.id,
                                                                                                                                    e
                                                                                                                                );
                                                                                                                            }}
                                                                                                                            label={
                                                                                                                                <HelpLabel
                                                                                                                                    inputLabel="Map Legend Label"
                                                                                                                                    helpText="The name of the layer that appears in the map legend."
                                                                                                                                />
                                                                                                                            }
                                                                                                                            fullWidth
                                                                                                                        />
                                                                                                                        <TextField
                                                                                                                            field="value"
                                                                                                                            style={{ marginTop: 16 }}
                                                                                                                            eventHandle={(e) => {
                                                                                                                                this.updateTextValue2(
                                                                                                                                    gValue.id,
                                                                                                                                    e
                                                                                                                                );
                                                                                                                            }}
                                                                                                                            label={
                                                                                                                                <HelpLabel
                                                                                                                                    inputLabel="Filter Value"
                                                                                                                                    helpText="List of unique values to filter records on. The list of values creates an 'or' search and filters the records to display on that list. Values in the list need to be separated with these characters |||. For example a range of years would be: 2018|||2019|||2020|||2021"
                                                                                                                                />
                                                                                                                            }
                                                                                                                            fullWidth
                                                                                                                        />
                                                                                                                    </Grid>
                                                                                                                    <Grid item xs={4}>
                                                                                                                        <Grid container>
                                                                                                                            <Grid item xs={6}>
                                                                                                                                <ColorPicker
                                                                                                                                    field="geo_color"
                                                                                                                                    eventHandle={(
                                                                                                                                        e
                                                                                                                                    ) => {
                                                                                                                                        ormGroupingValueUpdate(
                                                                                                                                            {
                                                                                                                                                id: gValue.id,
                                                                                                                                                geo_color:
                                                                                                                                                    e,
                                                                                                                                            }
                                                                                                                                        );
                                                                                                                                    }}
                                                                                                                                    label="Map Layer Color"
                                                                                                                                />
                                                                                                                            </Grid>
                                                                                                                            <Grid item xs={6}>
                                                                                                                                <Switch
                                                                                                                                    field="on_by_default"
                                                                                                                                    name="on_by_default"
                                                                                                                                    style={{
                                                                                                                                        marginTop: 0,
                                                                                                                                        marginBottom: 3,
                                                                                                                                        width: "auto",
                                                                                                                                    }}
                                                                                                                                    eventHandle={(
                                                                                                                                        e
                                                                                                                                    ) => {
                                                                                                                                        ormGroupingValueUpdate(
                                                                                                                                            {
                                                                                                                                                id: gValue.id,
                                                                                                                                                on_by_default:
                                                                                                                                                    e,
                                                                                                                                            }
                                                                                                                                        );
                                                                                                                                    }}
                                                                                                                                    label="Layer on by Default"
                                                                                                                                />
                                                                                                                            </Grid>
                                                                                                                            {geoPoint && (
                                                                                                                                <>
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                        xs={12}>
                                                                                                                                        <Select
                                                                                                                                            field="point_symbology"
                                                                                                                                            options={MAKE_OPTIONS(
                                                                                                                                                [
                                                                                                                                                    "Circle",
                                                                                                                                                    "Triangle",
                                                                                                                                                    "Square",
                                                                                                                                                    "Cross",
                                                                                                                                                    "Diamond",
                                                                                                                                                    "X",
                                                                                                                                                ]
                                                                                                                                            )}
                                                                                                                                            label={
                                                                                                                                                <HelpLabel
                                                                                                                                                    inputLabel="Point Symbology"
                                                                                                                                                    helpText="What type of point should this layer be displayed on the map as."
                                                                                                                                                />
                                                                                                                                            }
                                                                                                                                            fullWidth
                                                                                                                                            eventHandle={(
                                                                                                                                                e
                                                                                                                                            ) => {
                                                                                                                                                ormGroupingValueUpdate(
                                                                                                                                                    {
                                                                                                                                                        id: gValue.id,
                                                                                                                                                        point_symbology:
                                                                                                                                                            e,
                                                                                                                                                    }
                                                                                                                                                );
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                    </Grid>
                                                                                                                                    <Grid
                                                                                                                                        item
                                                                                                                                        xs={12}>
                                                                                                                                        <TextField
                                                                                                                                            field="point_size"
                                                                                                                                            style={{
                                                                                                                                                marginTop: 16,
                                                                                                                                            }}
                                                                                                                                            label={
                                                                                                                                                <HelpLabel
                                                                                                                                                    inputLabel="Point Size"
                                                                                                                                                    helpText="(Integer) How large the point is displayed on the map. The default size depends on the point symbology. Square/Cross/X defaults to 8. Circle/Triangle/Diamond defaults to 12."
                                                                                                                                                />
                                                                                                                                            }
                                                                                                                                            fullWidth
                                                                                                                                            eventHandle={(
                                                                                                                                                e
                                                                                                                                            ) => {
                                                                                                                                                this.updateTextValue4(
                                                                                                                                                    gValue.id,
                                                                                                                                                    e
                                                                                                                                                );
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                    </Grid>
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                    <Grid item xs={1}>
                                                                                                                        <Tooltip title="Delete Value">
                                                                                                                            <Button
                                                                                                                                style={{
                                                                                                                                    float: "right",
                                                                                                                                    marginTop: 12,
                                                                                                                                }}
                                                                                                                                onClick={() =>
                                                                                                                                    ormGroupingValueDelete(
                                                                                                                                        gValue.id
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                className={
                                                                                                                                    classes.deleteWidth
                                                                                                                                }>
                                                                                                                                <DeleteIcon />
                                                                                                                            </Button>
                                                                                                                        </Tooltip>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </form>
                                                                                                        )}
                                                                                                    </Formm>
                                                                                                </Grid>
                                                                                            );
                                                                                        })}
                                                                                </Grid>
                                                                            </form>
                                                                        )}
                                                                    </Formm>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                            </>
                                        )}
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                        <WarningDialog
                            confirmAction={() => {
                                this.setState({ repeatableChange: false });
                            }}
                            cancelAction={() => this.setState({ repeatableChange: !repeatableChange })}
                            open={repeatableChange}
                            confirmText="Ok"
                            title={`Warning`}
                            contactLoading={false}
                            noActions={false}
                            noDeleteButtons={true}
                            text="Are you sure you wish to to change the creation of 'Repeatable' recordsections?  Note: prior data that was saved as 'multiple' repeatable recordsections per primary record will not be seen when entering into those previously saved recordsections, unless this switch is revered to its prior configuration."
                        />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

// The root component of the page
// http://localhost:3000/#/page/49
class PageDetail extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            newDialogOpen: false,
        };
        this.returnDialog = this.returnDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
    }

    returnDialog() {
        this.setState({ newDialogOpen: false });
    }

    openDialog() {
        this.setState({ newDialogOpen: true });
    }

    // When the save button is pressed update the name/icon of the page
    // This is the form on top of the page
    updatePage(values) {
        const { id } = this.props.page;
        const { ormPageUpdate } = this.props;

        ormPageUpdate({
            id: id,
            ...values,
        });

        this.props.history.push("/config/page");
    }
    // componentDidMount() {
    //     const { ormFieldReload } = this.props;
    //     ormFieldReload();
    // }
    render() {
        const {
            classes,
            page,
            sections,
            forms,
            ormSectionCreate,
            ormSectionDelete,
            ormSectionUpdate,
            ormFormCreate,
            ormFormUpdate,
            history,
            authState,
            ormSectionMapGroupingCreate,
            ormSectionMapGroupingUpdate,
            ormSectionMapGroupingDelete,
            ormSectionLoadDetailChild,
            ormGroupingValueCreate,
            ormGroupingValueUpdate,
            ormGroupingValueDelete,
            mapGroups,
            groupValues,
            // ormFieldReload,
        } = this.props;
        const { newDialogOpen } = this.state;
        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h5">Module Configuration: {page.name}</Typography>
                        <Typography className={classes.italics}>Use this module to create, edit, or delete forms used on {page.name}.</Typography>
                    </Grid>
                    <Grid item style={{ textAlign: "end", marginTop: 10 }} xs={12} md={4}>
                        <Tooltip
                            title={
                                <div>
                                    Export module, sections and form configurations.
                                    <br />
                                    This does not export module records.
                                </div>
                            }>
                            <Button
                                variant="raised"
                                type="button"
                                size="medium"
                                onClick={() => window.open("/excel/exportpage/" + page.id + "/" + authState.auth.user.auth_token)}>
                                Export Module to Spreadsheet&nbsp;&nbsp;&nbsp;
                                <CloudDownload />
                            </Button>
                        </Tooltip>
                    </Grid>
                    {/* <Grid container>
                        <Grid xs={7}>
                            <BreadCrumbs allPageConfig={"/config/page"} sectionConfig={`/page/${page.id}/config`} />
                        </Grid>
                    </Grid> */}
                    <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                        <Formm
                            getApi={(el) => (this.formApi = el)}
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            defaultValues={page}
                            onSubmit={(values) => this.updatePage(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="name"
                                                label="Name-Enter the module name that will appear on the app and reports"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                field="icon"
                                                label="Icon-Choose an icon that will appear on the app and reports"
                                                options={MAKE_OPTIONS_ICON(ICON_LIST)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="iframe_url" label="(Optional) Configure module as an iframe - enter URL" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Section Configuration</Typography>
                        <Typography className={classes.italics}>
                            Define the details of what types of data a Module stores. For each Section you add, identify the data fields and choose
                            from several different options.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={this.openDialog} fullWidth className={classes.gutterBottom} variant="contained" color="primary">
                            <AddCircleOutlineIcon />
                            &nbsp;&nbsp;&nbsp;Add New Section
                        </Button>
                        <SectionTable
                            page={page.id} 
                            sections={sections}
                            forms={forms}
                            returnDialog={this.returnDialog}
                            newDialogOpen={newDialogOpen}
                            classes={classes}
                            ormSectionCreate={ormSectionCreate}
                            ormSectionDelete={ormSectionDelete}
                            ormSectionUpdate={ormSectionUpdate}
                            ormSectionMapGroupingCreate={ormSectionMapGroupingCreate}
                            ormSectionMapGroupingUpdate={ormSectionMapGroupingUpdate}
                            ormSectionMapGroupingDelete={ormSectionMapGroupingDelete}
                            ormSectionLoadDetailChild={ormSectionLoadDetailChild}
                            ormGroupingValueCreate={ormGroupingValueCreate}
                            ormGroupingValueUpdate={ormGroupingValueUpdate}
                            ormGroupingValueDelete={ormGroupingValueDelete}
                            ormFormCreate={ormFormCreate}
                            ormFormUpdate={ormFormUpdate}
                            history={history}
                            mapGroups={mapGroups}
                            groupValues={groupValues}
                            authState={authState}
                        />
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}
PageDetail.displayName = "PageDetail";

// This connects the redux-orm functions getPage, getSections,  allows the PageDetail to have the props "page", "sections", and "allForms"
// The second part loads all orm actions into the PageConfig props for both Page and Section: ormPageCreate, ormSectionCreate, orPageUpdate, etc.
PageDetail = connect(
    (state, ownProps) => ({
        page: getPage(state, ownProps),
        sections: getSections(state, ownProps),
        forms: allForms(state, ownProps),
        mapGroups: getGroupings(state, ownProps),
        groupValues: getValueGroupings(state, ownProps),
        authState: state,
    }),
    {
        ...Page.actions,
        ...Section.actions,
        ...Form.actions,
        ...Field.actions,
        ...GroupingValue.actions,
        ...SectionMapGrouping.actions,
    }
)(PageDetail);

export default withStyles(styles)(withRouter(PageDetail));
