import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { Form } from "react-form";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import SettingsIcon from "@material-ui/icons/Settings";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import WarningDialog from "../common/WarningDialog";

import CreateNewDialog from "../page/landing/CreateNewDialog";
import AddIcon from "@material-ui/icons/Add";
import ReactSelectAsync from "../common/ReactSelectAsync";
import ReactSelect from "../common/ReactSelect";
import { createSelector } from "../common/orm";
import { PageAnalytics, QuickSearchField } from "../page/models";
import HelpLabel from "../common/HelpLabel";

const styles = (theme) => ({
    expandedMargin: {
        marginTop: "12px",
    },
    titlePanel: {
        minHeight: "auto !important",
    },
    hideIfSmall: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    root: {
        flexBasis: "28%",
        maxWidth: "25%",
        marginBottom: -28,
        marginLeft: 17,
        [theme.breakpoints.down(1700)]: {
            flexBasis: "35%",
            maxWidth: "31%",
        },
        [theme.breakpoints.down("1000")]: {
            flexBasis: "35%",
            maxWidth: "35%",
            marginLeft: 0,
        },
        [theme.breakpoints.up(2300)]: {
            flexBasis: "20%",
        },
    },
    iconAdd: {
        backgroundColor: "#34aa20",
        color: "white",
        height: "24px",
        width: "24px",
        // paddingTop: 9,
        borderRadius: 12,
    },
    progress: {
        display: "table",
        marginLeft: "auto",
        marginRight: "auto",
    },
    minWidth: {
        maxWidth: 507,
        minWidth: 489,
    },
    flex: {
        flex: 1,
    },
    minWidth2: {
        minWidth: 605,
        minheight: 685,
    },
    maxHeight: {
        minWidth: 678,
        minHeight: 760,
    },
    dialogMin: {
        minWidth: 600,
        minHeight: 650,
        padding: 0,
    },
});

//get permissions
const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.Permission.filter((p) => p.page === id).toRefArray();
    }
);
const getContactFields = createSelector((schema) => {
    return schema.ContactField.all().orderBy("order").toModelArray();
});

const getQuickSearchFields = createSelector(
    //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageId) => {
        return session.QuickSearchField.filter((p) => p.page === pageId)
            .orderBy("id")
            .toRefArray();
    }
);

const getAllSections = createSelector(
    //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageId) => {
        if (isNaN(pageId)) {
            return session.Section.filter((sect) => sect.page === pageId)
                .orderBy("id")
                .toRefArray();
        } else {
            return session.Section.filter((sect) => sect.page === pageId)
                .orderBy("id")
                .toRefArray();
        }
    }
);

const getPageFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, actPage) => {
        return session.Section.filter((sect) => sect.page === actPage && sect.parent === true)
            .orderBy("id")
            .toModelArray()
            .map((section) => ({
                form: section.forms //form.groups is FK linkage
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((form) => ({
                        groups: form.groups //form.groups is FK linkage
                            .all()
                            .orderBy("id")
                            .toModelArray()
                            .map((group) => ({
                                fields: group.fields.all().orderBy("order").toRefArray(),
                                ...group.ref, // take all groups as a reference and tie in each field
                            })),
                        ...form.ref, // take all forms as a reference and tie in each field
                    })),
                ...section.ref,
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

class RecordSearch extends Component {
    constructor(props) {
        super(props);
        const { pagefields } = this.props;

        var childFields = [];
        //get all child form fields
        var child = pagefields.filter((p) => {
            if (!p.parent) {
                return p;
            }
            return null;
        });
        child.forEach((c) => {
            c.form.forEach((f) => {
                f.groups.forEach((g) => {
                    g.fields.forEach((k) => {
                        childFields.push(k.name);
                    });
                });
            });
        });

        //get all parent form fields
        var parentFields = [];
        const parent = pagefields.filter((p) => {
            if (p.parent) {
                return p;
            }
            return null;
        });
        parent.forEach((c) => {
            c.form.forEach((f) => {
                f.groups.forEach((g) => {
                    g.fields.forEach((k) => {
                        parentFields.push(k.name);
                    });
                });
            });
        });

        // Remove the duplicates from tempFields
        this.state = {
            formCleanUp: [...new Set(childFields)],
            shownFilters: [],
            dialogStatus: false,
            loading: false,
            advancedSearchLandingPageTable: false,
            deleteDialogOpen: false,
            searchToDelete: null,
            groupDelete: false,
            totalSectionCounts: 0,
            fieldToSearch: null,
            formDialogStatus: false,
            openFieldSettings: false,
            noFormOpen: false,
        };

        // I don't even...
        this.formKey = window.performance.now();
    }
    typingTimer;

    componentDidUpdate(prevProps) {
        const { page } = this.props;

        if (page && prevProps && prevProps.page[0].id !== page[0].id) {
            //reset field ID on page changes
            this.setState({ fieldToSearch: null });
        }
    }
    returnDialog = () => {
        this.setState({ loading: false });
    };
    saveFields = (field) => {
        const { ormQuickSearchFieldCreate, ormQuickSearchFieldDelete, quicksearchfields, page } = this.props;

        //first delete any that have not been found within the users selected list--prior fields could have been removed
        quicksearchfields.forEach((f) => {
            let priorFieldFound = field.column.find((c) => {
                return f.field_id === c.value ? f : null;
            });
            if (!priorFieldFound) ormQuickSearchFieldDelete(f.id);
        });
        //now add the new fields
        field.column.forEach((c) => {
            let priorField = quicksearchfields.filter((f) => {
                return f.field_id === c.value ? f : null;
            });
            if (priorField.length === 0) {
                c.value === "All Columns"
                    ? ormQuickSearchFieldCreate({ page_id: page[0].id, field_name: "All Columns", contactfield: false })
                    : ormQuickSearchFieldCreate({ page_id: page[0].id, field_id: c.value, field_name: c.label, contactfield: c.contactfield });
            }
        });
        this.setState({ openFieldSettings: false });
    };

    defaultUserSelectedFields = () => {
        const { quicksearchfields } = this.props;
        let defaultValues = [];

        quicksearchfields.forEach((fi) => {
            defaultValues.push({ label: fi.field_name, value: fi.field_id, id: fi.id, contactfield: false, identification: fi.identification });
        });

        return defaultValues;
    };

    //close form dialog
    formDialogClose = () => {
        this.setState({ formDialogStatus: false });
    };
    defaultSearchValues = () => {
        const { /*pagefields,*/ quicksearchfields } = this.props;

        let obj = {};
        // pagefields.forEach((p) => {
        //     return p.form.forEach((f) => {
        //         return f.groups.forEach((gf) => {
        //             gf.fields.forEach((fi) => {
        //                 return fi.identification ? (obj["column"] = fi.name) : null;
        //             });
        //         });
        //     });
        // });
        quicksearchfields.forEach((fi, idx) => {
            if (idx === 0) obj["column"] = fi.field_name;
        });

        return obj;
    };

    render() {
        const { page, pagefields, history, classes, sync, sections, contact_fields, quicksearchfields, authState, permission } = this.props;
        const { fieldToSearch, formDialogStatus, openFieldSettings, noFormOpen } = this.state;

        //get all fields for the field settings
        var fields = [];

        pagefields.forEach((p) => {
            return p.form.forEach((f) => {
                let temparray = [];
                temparray.push({ label: "All Columns", value: -1, contactfield: false, identification: false });
                f.groups.forEach((gf) => {
                    gf.fields.forEach((fi) => {
                        //we only support non-repeatable fields & not dates as slashes crash
                        if (!gf.repeatable && fi.type !== "Date")
                            temparray.push({ label: fi.name, value: fi.id, id: fi.id, contactfield: false, identification: fi.identification });
                    });
                });
                fields.push({ label: f.name + " (Section " + f.name + ")", options: temparray });
            });
        }); //get contact fields to tie into field settings
        if (contact_fields) {
            let temparray = [];
            contact_fields.forEach((cf) => {
                temparray.push({ label: cf.field_name, value: cf.id, id: cf.id, contactfield: true });
            });
            fields.push({ label: "Contact Fields", options: temparray });
        }

        var parentSection = sections.find((s) => (s.parent === true ? s : null));
        var parentName = parentSection ? parentSection.name : "";
        //used for create new records
        var forms;
        if (pagefields.length > 0) forms = pagefields.find((p) => p.parent === true).form;

        var userSelectedFields = [];
        let regFields = [];
        let contactFields = [];

        quicksearchfields.forEach((fi, idx) => {
            if (idx === 0 && !fieldToSearch) {
                //default fieldToSearch state--grabs first instance added to list from fieldToSearch
                this.setState({ fieldToSearch: fi.field_id });
            }
            if (!fi.contactfield) {
                regFields.push({ label: fi.field_name, value: fi.field_id, id: fi.id, contactfield: false, identification: fi.identification });
            } else {
                contactFields.push({ label: fi.field_name, value: fi.field_id, id: fi.id, contactfield: true, identification: fi.identification });
            }
        });

        userSelectedFields.push({ label: " (Section " + parentName + ")", options: regFields });
        if (contactFields.length > 0) userSelectedFields.push({ label: "Contact Fields", options: contactFields });

        //permissions
        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }

        return (
            //key allows fields to be updated
            <>
                {page.length > 0 && (
                    <Form
                        defaultValues={this.defaultSearchValues()}
                        key={page.length > 0 && page[0].id}
                        getApi={(el) => (this.formApi = el)}
                        validateOnSubmit={false}>
                        {(formApi) => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Grid item xs={5} md={3}>
                                        <ReactSelectAsync
                                            field={22}
                                            label={
                                                <HelpLabel
                                                    inputLabel="Search Records"
                                                    helpText={`Seaching of record section fields are only allowed on 'primary' form fields. 
                                            
                                            Instructions: 
                                            Type the requested search parameters into the window, after typing has stopped the quick search tool will return the database results that contain those characters.`}
                                                />
                                            }
                                            page={page[0].id}
                                            quick_search_field={fieldToSearch === -1 ? "All Columns" : fieldToSearch}
                                            eventHandle={(searchvalue) => {
                                                // this.setState({ loadingFields2: true });
                                                if (searchvalue !== "")
                                                    history.push(
                                                        "/page/" +
                                                            searchvalue.pageid +
                                                            "/parentrecord/" +
                                                            searchvalue.parentrecord +
                                                            "/section/" +
                                                            searchvalue.section +
                                                            "/recordsection/" +
                                                            searchvalue.recordsection +
                                                            "/form"
                                                    );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={5} md={3} style={{ marginLeft: 11, marginTop: -3 }}>
                                        <ReactSelect
                                            field={"column"}
                                            options={userSelectedFields}
                                            eventHandle={(value) => {
                                                this.setState({ fieldToSearch: value });
                                            }}
                                            dontClear={true}
                                            dashboardHeight={true}
                                            label={
                                                <HelpLabel
                                                    inputLabel="Field to Search"
                                                    helpText={`Select one of the 'primary fields' or 'master contact' fields to search. 'All Columns' refer to all primary fields that are set to show in table view. 
                                            
                                            Note: all repeatable field types and non-repeatable 'date' fields are not included in this list of searchable fields.`}
                                                />
                                            }
                                        />
                                    </Grid>
                                    {authState && authState.user.is_city_admin && (
                                        <Grid item xs={"auto"} style={{ marginTop: 11 }}>
                                            <Tooltip
                                                title="Field to Search Settings"
                                                style={{
                                                    marginLeft: 5,
                                                    marginTop: 10,
                                                    color: "#656565",
                                                }}>
                                                <SettingsIcon
                                                    onClick={(e) => {
                                                        this.setState({ openFieldSettings: true });
                                                    }}
                                                />
                                            </Tooltip>{" "}
                                        </Grid>
                                    )}

                                    <Grid item xs={5} md={2} style={{ marginTop: 17 }} classes={{ root: classes.root }}>
                                        <Button
                                            style={{
                                                fontSize: "1.0625rem",
                                                fontWeight: 400,
                                                height: 37,
                                            }}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={readOnly}
                                            onClick={() => {
                                                if (forms && forms.length === 0 /*|| forms[0].groups[0].fields.length === 0*/) {
                                                    this.setState({ noFormOpen: true });
                                                } else {
                                                    this.setState({ formDialogStatus: true });
                                                }
                                            }}>
                                            <AddIcon className={classes.iconAdd} /> &nbsp;&nbsp;&nbsp;Create New Record
                                            {sync && sync.ready === false && (
                                                <>
                                                    <CircularProgress
                                                        size={19}
                                                        className={classes.progress}
                                                        style={{
                                                            marginLeft: 105,
                                                            position: "fixed",
                                                            marginTop: 2,
                                                            color: "#fff",
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3} md={2} style={{ marginTop: 21, marginLeft: 10 }}>
                                        <Tooltip title={"Data Feed View"}>
                                            <Link style={{ textDecoration: "underline", fontSize: "large" }} to={`/datafeed/${page[0].id}`}>
                                                {"Go To Data Feed"}
                                            </Link>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                )}
                <Dialog open={openFieldSettings} classes={{ paper: classes.minWidth2 }} disableBackdropClick={true}>
                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                        <Typography variant="h5" className={classes.flex}>
                            Field To Search Settings
                        </Typography>
                        <IconButton onClick={() => this.setState({ openFieldSettings: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                getApi={(el) => (this.formApi = el)} //allows use of this.formApi ref external of form
                                validateError={this.errorValidator}
                                dontValidateOnMount="true"
                                onSubmit={(values) => this.saveFields(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container style={{ paddingTop: 20, paddingLeft: 36 }}>
                                            <Grid item xs={11}>
                                                <Typography variant="h5" className={classes.flex}>
                                                    Select Fields to Search
                                                </Typography>
                                                {/* default values are passed to the reactSelect component for rendering*/}
                                                <ReactSelect
                                                    key={this.formKey}
                                                    field={"column"}
                                                    eventHandle={(value) => {}}
                                                    defaultValue={this.defaultUserSelectedFields()}
                                                    quickSearchHandle={true}
                                                    options={fields}
                                                    isMulti
                                                    menuIsOpen={true} //keep menu always open
                                                />
                                                <div style={{ marginTop: 324 }}>
                                                    <Typography variant="h7" className={classes.flex}>
                                                        Note: selected fields will be removed from the list as they are selected.
                                                    </Typography>
                                                </div>

                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    style={{ marginTop: 29, width: "21%", float: "right" }}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {page && forms && parentSection && (
                    <CreateNewDialog
                        classes={classes}
                        open={formDialogStatus} //open is a keyword that knows dialog is open is based on flag value from ExpansionPanel
                        returnDialog={this.formDialogClose}
                        page={page[0].id}
                        forms={forms}
                        sectionGeometry={parentSection.has_geometry}
                        sectionid={parentSection.id}
                        has_confirmation={parentSection.has_confirmation}
                    />
                )}
                <WarningDialog
                    cancelAction={() => this.setState({ noFormOpen: false })}
                    open={noFormOpen}
                    noDeleteButtons
                    title="Form Not Created"
                    text="A new record can not be created until a 'form' has been configured for this section. Please go to the configuration module and configure a form first or talk to the software administrator."
                />
            </>
        );
    }
}
RecordSearch.displayName = "RecordSearch";

RecordSearch = connect(
    (state, ownProps) => ({
        pagefields: getPageFields(state, ownProps),
        sections: getAllSections(state, ownProps),
        contact_fields: getContactFields(state, ownProps),
        quicksearchfields: getQuickSearchFields(state, ownProps),
        authState: state.auth,
        permission: getPermission(state, ownProps),
    }),
    {
        ...PageAnalytics.actions,
        ...QuickSearchField.actions,
    }
)(RecordSearch);

export default withStyles(styles)(withRouter(RecordSearch));
