import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { loadModules } from "esri-loader";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { CircularProgress, withStyles } from "@material-ui/core";

import Close from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import Switch from "../common/Switch";
import Select from "../common/Select";
import HelpLabel from "../common/HelpLabel";
import { createSelector } from "../common/orm";
import Snackbar from "../common/Snackbar";
import WarningDialog from "../common/WarningDialog";
import { MapSetting, MapLayer } from "../page/models";
import { MAKE_OPTIONS } from "../../api/constants";

const settings = createSelector((schema) => {
    return schema.MapSetting.first();
});
const layers = createSelector((schema) => {
    return schema.MapLayer.all().orderBy("order").toModelArray();
});

const allPages = createSelector((schema) => {
    var p = schema.Page.all().orderBy("order").toModelArray()
    p.unshift({
        id: "All Modules",
        name: "All Modules"
    })
    return p;
});

const styles = (theme) => ({
    centerAlign: {
        textAlign: "center",
    },
    minWidth: {
        minWidth: 400,
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    flex: {
        flex: 1,
    },
    expandedMargin: {
        marginTop: "12px",
    },
    titlePanel: {
        minHeight: "auto !important",
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
});

const columnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "name", numeric: false, label: "Basemap Display Name", allowSort: true },
    { id: "layer_type", numeric: false, label: "Type", allowSort: true },
    { id: "order", numeric: false, label: "Layer Order", allowSort: true },
];

const columnData2 = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "name", numeric: false, label: "Layer Name", allowSort: true },
    { id: "layer_type", numeric: false, label: "Type", allowSort: true },
    { id: "order", numeric: false, label: "Layer Order", allowSort: true },
    { id: "on_by_default", numeric: false, label: "On By Default?", allowSort: true },
];

class MapSettings extends Component {
    state = {
        layerDialogOpen: false,
        layerDialogOpen2: false,
        snackbarOpen: false,
        showLayerType: null,
        activeLayer: { on_by_default: true },
        deleteDialogOpen: false,
        webMapLoading: false,
        webMapError: false,
        webMapLayers: [],
        webMapTitle: null,
        webMapOwner: null,
        order: "asc",
        orderBy: "name",
        order2: "asc",
        orderBy2: "name",
    };

    handleWebMap = (value) => {
        const _this = this;
        _this.setState({ webMapLoading: true });

        loadModules(["esri/WebMap"], { css: true }).then(([WebMap]) => {
            const webmap = new WebMap({
                portalItem: {
                    id: value,
                },
            });
            webmap
                .load()
                .then(function () {
                    // grab all the layers and load them
                    const allLayers = webmap.allLayers;
                    const promises = allLayers.map(function (layer) {
                        return layer.load();
                    });
                    return Promise.all(promises.toArray());
                })
                .then(function (temp_layers) {
                    const layers = temp_layers.reverse().map((x) => x.title + "(" + x.type + (x.popupEnabled ? ", Pop-up" : ", No Pop-up") + ")");
                    _this.setState({
                        webMapLayers: layers,
                        webMapLoading: false,
                        webMapError: false,
                        webMapTitle: webmap.portalItem.title,
                        webMapOwner: webmap.portalItem.owner,
                    });
                })
                .catch(function (error) {
                    if (webmap.portalItem && webmap.portalItem.title) {
                        const layers = webmap.allLayers.items
                            .filter((x) => (x.loadStatus = "loaded"))
                            .reverse()
                            .map((x) => x.title + "(" + x.type + (x.popupEnabled ? ", Pop-up" : ", No Pop-up") + ")");
                        _this.setState({
                            webMapLayers: layers,
                            webMapLoading: false,
                            webMapError: false,
                            webMapTitle: webmap.portalItem.title,
                            webMapOwner: webmap.portalItem.owner,
                        });
                    } else {
                        _this.setState({ webMapError: true });
                    }
                });
        });
    };

    openReferenceEdit = (l) => {
        this.handleWebMap(l.webmap_id);
        this.setState({
            webMapError: false,
            webMapLoading: true,
            webMapLayers: [],
            layerDialogOpen2: true,
            activeLayer: l,
            showLayerType: l.layer_type,
        });
    };

    errorValidator = (values) => {
        const validateLatitude = (num) => {
            if (!num) {
                return "Required";
            }

            const number = Number(num);
            if (isNaN(number)) return "Not a valid number";
            if (number < -90 || number > 90) {
                return "Must be between -90 and 90";
            }
            return null;
        };
        const validateLongitude = (num) => {
            if (!num) {
                return "Required";
            }
            const number = Number(num);
            if (isNaN(number)) return "Not a valid number";
            if (number < -180 || number > 180) {
                return "Must be between -180 and 180";
            }
            return null;
        };
        const validateInteger = (num) => {
            if (!num) {
                return "Required";
            }
            const number = Number(num);
            if (isNaN(number)) return "Not a valid number";
            if (!Number.isInteger(number)) {
                return "Not a valid number";
            }
            if (number < 4 || number > 18) {
                return "Must be between 4 and 18";
            }
            return null;
        };
        return {
            latitude: validateLatitude(values.latitude),
            longitude: validateLongitude(values.longitude),
            map_level: validateInteger(values.map_level),
        };
    };

    submitForm = (values) => {
        const { settings, ormMapSettingCreate, ormMapSettingUpdate, authState } = this.props;
        // When the value is cleared from the form the form returns an empty string, but the value must be null to be saved in django
        if (!values.geocoder_top || values.geocoder_top === " ") values.geocoder_top = null;
        if (!values.geocoder_bottom || values.geocoder_bottom === " ") values.geocoder_bottom = null;
        if (!values.geocoder_left || values.geocoder_left === " ") values.geocoder_left = null;
        if (!values.geocoder_right || values.geocoder_right === " ") values.geocoder_right = null;
        if (!values.label_scale || values.label_scale === " ") values.label_scale = null;
        if (!values.cluster_scale || values.cluster_scale === " ") values.cluster_scale = null;

        if (settings && settings.id) {
            ormMapSettingUpdate({
                id: settings.id,
                ...values,
            });
        } else {
            ormMapSettingCreate({
                city_id: authState.user.city_id,
                ...values,
            });
        }
        this.setState({ snackbarOpen: true });
    };

    MAKE_OPTIONS_CUST = (table) => {
        return table.map((row) => ({
            label: row,
            value: row,
        }));
    };

    showLayerType = (value) => {
        this.setState({ showLayerType: value });
    };

    errorValidatorLayer = (values) => {
        const { layers } = this.props;
        const validateRequired = (num) => {
            if (!num) {
                return "Required";
            }
            return null;
        };

        const validateOrdering = (order) => {
            var isError = null;
            layers
                .filter((l) => l.is_basemap)
                .forEach((o) => {
                    return o.order === parseInt(order) && values.id !== o.id ? (isError = "Ordering can not match another layer") : null;
                });
            return isError;
        };
        return {
            name: validateRequired(values.name),
            order: validateOrdering(values.order),
            layer_type: validateRequired(values.layer_type),
        };
    };

    errorValidatorLayer2 = (values) => {
        const { layers } = this.props;
        const validateRequired = (num) => {
            if (!num) {
                return "Required";
            }
            return null;
        };

        const validateOrdering = (order) => {
            var isError = null;
            layers
                .filter((l) => !l.is_basemap)
                .forEach((o) => {
                    return o.order === parseInt(order) && values.id !== o.id ? (isError = "Ordering can not match another layer") : null;
                });
            return isError;
        };
        return {
            name: validateRequired(values.name),
            order: validateOrdering(values.order),
            layer_type: validateRequired(values.layer_type),
        };
    };

    updateLayer = (values) => {
        const { ormMapLayerCreate, ormMapLayerUpdate, authState } = this.props;
        if (values.id) {
            ormMapLayerUpdate({
                id: values.id,
                is_basemap: true,
                ...values,
            });
        } else {
            ormMapLayerCreate({
                city_id: authState.user.city_id,
                is_basemap: true,
                ...values,
            });
        }
        this.setState({ layerDialogOpen: false });
    };

    updateLayer2 = (values) => {
        const { ormMapLayerCreate, ormMapLayerUpdate, authState } = this.props;
        if (values.id) {
            ormMapLayerUpdate({
                id: values.id,
                is_basemap: false,
                ...values,
            });
        } else {
            ormMapLayerCreate({
                city_id: authState.user.city_id,
                is_basemap: false,
                ...values,
            });
        }
        this.setState({ layerDialogOpen2: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.layers
                  .filter((l) => l.is_basemap)
                  .sort((a, b) => {
                      if (orderBy !== "order") {
                          return b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1;
                      } else {
                          return b[orderBy] < a[orderBy] ? -1 : 1;
                      }
                  })
            : this.props.layers
                  .filter((l) => l.is_basemap)
                  .sort((a, b) => {
                      if (orderBy !== "order") {
                          return a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1;
                      } else {
                          return a[orderBy] < b[orderBy] ? -1 : 1;
                      }
                  });

        this.setState({ order, orderBy });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.layers
                  .filter((l) => !l.is_basemap)
                  .sort((a, b) => {
                      if (orderBy !== "order") {
                          return b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1;
                      } else {
                          return b[orderBy] < a[orderBy] ? -1 : 1;
                      }
                  })
            : this.props.layers
                  .filter((l) => !l.is_basemap)
                  .sort((a, b) => {
                      if (orderBy !== "order") {
                          return a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1;
                      } else {
                          return a[orderBy] < b[orderBy] ? -1 : 1;
                      }
                  });

        this.setState({ order, orderBy });
    };

    render() {
        const { classes, settings, layers, ormMapLayerDelete, pages } = this.props;
        const {
            layerDialogOpen,
            layerDialogOpen2,
            snackbarOpen,
            showLayerType,
            activeLayer,
            deleteDialogOpen,
            order,
            orderBy,
            order2,
            orderBy2,
            webMapLoading,
            webMapError,
            webMapLayers,
            webMapTitle,
            webMapOwner,
        } = this.state;

        const layerBases = layers.filter((l) => l.is_basemap);

        layerBases.unshift({ id: -4, name: "Topographic" });
        layerBases.unshift({ id: -3, name: "Streets" });
        layerBases.unshift({ id: -2, name: "Imagery" });
        layerBases.unshift({ id: -1, name: "Imagery with Labels" });

        return (
            <AppContainer authenticated>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={10} classes={{ root: classes.root }}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                style={{ backgroundColor: "#cbcbcb" }}
                                classes={{ root: classes.expandedMargin }}
                                className={classes.titlePanel}>
                                <Typography variant="h6">Map Settings</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Form
                                    getApi={(el) => (this.formApi = el)}
                                    defaultValues={settings}
                                    dontValidateOnMount={true}
                                    validateOnSubmit={true}
                                    validateError={this.errorValidator}
                                    onSubmit={this.submitForm}>
                                    {(formApi) => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField field="latitude" fullWidth useNumberFormat>
                                                        <HelpLabel
                                                            inputLabel="Default Latitude"
                                                            helpText={
                                                                <div>
                                                                    The latitude you would like the maps to default to. Visit{" "}
                                                                    <a
                                                                        rel="noopener noreferrer"
                                                                        href="https://www.latlong.net/convert-address-to-lat-long.html"
                                                                        target="_blank">
                                                                        latlong.net
                                                                    </a>{" "}
                                                                    to easily determine the latitude from an address.
                                                                </div>
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField field="longitude" fullWidth useNumberFormat>
                                                        <HelpLabel
                                                            inputLabel="Default Longitude"
                                                            helpText={
                                                                <div>
                                                                    The longitude you would like the maps to default to. Visit{" "}
                                                                    <a
                                                                        rel="noopener noreferrer"
                                                                        href="https://www.latlong.net/convert-address-to-lat-long.html"
                                                                        target="_blank">
                                                                        latlong.net
                                                                    </a>{" "}
                                                                    to easily determine the longitude from an address.
                                                                </div>
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField field="map_level" fullWidth isInteger>
                                                        <HelpLabel
                                                            inputLabel="Default Map Level"
                                                            helpText={
                                                                <div>
                                                                    The default zoom level your city will appear at on the map. The recommended range
                                                                    is between 10-16 with 10 being zoomed out for larger cities and 16 being zoomed in
                                                                    for smaller cities.
                                                                </div>
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField field="max_zoom" fullWidth isInteger>
                                                        <HelpLabel
                                                            inputLabel="Maximum Zoom"
                                                            helpText={
                                                                <div>
                                                                    How far you are able to zoom into the map. By default this value is set at 18. If
                                                                    you have a custom tiled basemap you may want to increase this number.
                                                                </div>
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField field="label_scale" fullWidth isInteger>
                                                        <HelpLabel
                                                            inputLabel="Label Scale"
                                                            helpText={
                                                                <div>
                                                                    At what map scale the MS4Front Layer labels turn on at. If not defined the default
                                                                    is 50000. A higher number will show the labels starting at a further zoomed out
                                                                    distance and a smaller number will start displaying labels further zoomed in.
                                                                </div>
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <TextField field="cluster_scale" fullWidth isInteger>
                                                        <HelpLabel
                                                            inputLabel="Clustering Scale"
                                                            helpText={
                                                                <div>
                                                                    At what map scale the MS4Front Layer clustering turn off at. If not defined the
                                                                    default is 50000. A higher number will have the clustering ending at a further
                                                                    zoomed out distance and a smaller number will end clustering further zoomed in.
                                                                </div>
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Select
                                                        field="default_basemap_id"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Default Basemap"
                                                                helpText={
                                                                    <div>
                                                                        The default background map layer you see on a map. You may select from any
                                                                        layer you have defined as a basemap. If left blank the map will default to
                                                                        esri's Street View.
                                                                    </div>
                                                                }
                                                            />
                                                        }
                                                        fullWidth
                                                        options={MAKE_OPTIONS(layerBases)}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField field="geocoder_top" fullWidth useNumberFormat>
                                                        <HelpLabel
                                                            inputLabel="Geocoder Extent: Top"
                                                            helpText={
                                                                <div>
                                                                    Set a boundary in lat/lon. The geocoder will limit results to within this
                                                                    boundary.
                                                                </div>
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField field="geocoder_bottom" fullWidth useNumberFormat>
                                                        <HelpLabel
                                                            inputLabel="Geocoder Extent: Bottom"
                                                            helpText={
                                                                <div>
                                                                    Set a boundary in lat/lon. The geocoder will limit results to within this
                                                                    boundary.
                                                                </div>
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField field="geocoder_left" fullWidth useNumberFormat>
                                                        <HelpLabel
                                                            inputLabel="Geocoder Extent: Left"
                                                            helpText={
                                                                <div>
                                                                    Set a boundary in lat/lon. The geocoder will limit results to within this
                                                                    boundary.
                                                                </div>
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField field="geocoder_right" fullWidth useNumberFormat>
                                                        <HelpLabel
                                                            inputLabel="Geocoder Extent: Right"
                                                            helpText={
                                                                <div>
                                                                    Set a boundary in lat/lon. The geocoder will limit results to within this
                                                                    boundary.
                                                                </div>
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button fullWidth type="submit" variant="contained" color="primary">
                                                        Save Map Settings
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Form>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} md={10} classes={{ root: classes.root }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                style={{ backgroundColor: "#cbcbcb" }}
                                classes={{ root: classes.expandedMargin }}
                                className={classes.titlePanel}>
                                <Typography variant="h6">Base Layers</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    layerDialogOpen: true,
                                                    activeLayer: { order: layers.filter((l) => l.is_basemap).length },
                                                    showLayerType: null,
                                                })
                                            }
                                            fullWidth
                                            variant="contained"
                                            color="primary">
                                            <AddCircleOutlineIcon />
                                            &nbsp;&nbsp;&nbsp;Add New Base Layer
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.minWidth}>
                                            <EnhancedTableHead
                                                columnData={columnData}
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleRequestSort}
                                            />
                                            <TableBody>
                                                {layers
                                                    .filter((l) => l.is_basemap)
                                                    .map((l) => {
                                                        return (
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Edit">
                                                                        <Button
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    layerDialogOpen: true,
                                                                                    activeLayer: l,
                                                                                    showLayerType: l.layer_type,
                                                                                })
                                                                            }
                                                                            className={classes.deleteWidth}>
                                                                            <EditIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            onClick={() => this.setState({ deleteDialogOpen: true, activeLayer: l })}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{l.name}</CustomTableCell>
                                                                <CustomTableCell>{l.layer_type}</CustomTableCell>
                                                                <CustomTableCell>{l.order}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                {layers.filter((l) => l.is_basemap).length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                                            No User Added Base Layers Defined
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} md={10} classes={{ root: classes.root }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                style={{ backgroundColor: "#cbcbcb" }}
                                classes={{ root: classes.expandedMargin }}
                                className={classes.titlePanel}>
                                <Typography variant="h6">Reference Layers</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    layerDialogOpen2: true,
                                                    activeLayer: { on_by_default: true, page: "All Modules", order: layers.filter((l) => !l.is_basemap).length },
                                                    showLayerType: null,
                                                })
                                            }
                                            fullWidth
                                            variant="contained"
                                            color="primary">
                                            <AddCircleOutlineIcon />
                                            &nbsp;&nbsp;&nbsp;Add New Reference Layer
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.minWidth}>
                                            <EnhancedTableHead
                                                columnData={columnData2}
                                                order={order2}
                                                orderBy={orderBy2}
                                                onRequestSort={this.handleRequestSort2}
                                            />
                                            <TableBody>
                                                {layers
                                                    .filter((l) => !l.is_basemap)
                                                    .map((l) => {
                                                        return (
                                                            <TableRow key={l.name} hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Edit">
                                                                        <Button
                                                                            onClick={() => this.openReferenceEdit(l)}
                                                                            className={classes.deleteWidth}>
                                                                            <EditIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            onClick={() => this.setState({ deleteDialogOpen: true, activeLayer: l })}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{l.name}</CustomTableCell>
                                                                <CustomTableCell>{l.layer_type}</CustomTableCell>
                                                                <CustomTableCell>{l.order}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    {l.on_by_default} {l.on_by_default ? "Yes" : "No"}
                                                                </CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                {layers.filter((l) => !l.is_basemap).length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                                            No Reference Layers Defined
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Dialog open={layerDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Base Map Layer
                        </Typography>
                        <IconButton onClick={() => this.setState({ layerDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                validateError={this.errorValidatorLayer}
                                defaultValues={activeLayer}
                                onSubmit={(values) => this.updateLayer(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField field="name" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Basemap Display Name"
                                                        helpText={<div>The name for this layer that will appear in the map legend.</div>}
                                                    />
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="order" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Map Order"
                                                        helpText={` 
                                                                Ordering starts from 0 and needs to increment by 1. This setting determines the vertical ordering of the layers that will be seen on the map. 
                                                                
                                                                Note: layers need to be unique from one another (ex: layer1 = 0, layer2 = 1, etc.)
                                                          `}
                                                    />
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="layer_type"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Layer Type"
                                                            helpText={
                                                                <div>
                                                                    ArcGIS: You have access to an ESRI ArcGIS Server you want to display on the map.
                                                                    <br />
                                                                    Tiled: There is an external tiled web map you want displayed on the map.
                                                                    <br />
                                                                    WMS: There is an external WMS service you want displayed on the map.
                                                                </div>
                                                            }
                                                        />
                                                    }
                                                    options={this.MAKE_OPTIONS_CUST(["ArcGIS", "Tiled", "WMS"])}
                                                    eventHandle={(value) => this.showLayerType(value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="layerurl" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Layer URL"
                                                        helpText={` 
                                                                    Map Services must support requests in Web Mercator projection (WKID/ESPG 3857).
                                                                    Map Services may draw faster if the data source for the map service is stored in a
                                                                    Web Mercator projection because the server does not have to perform a
                                                                    re-projection on the fly. This is not a requirement and just a suggestion if you are
                                                                    experience slow drawing of the layer within the MS4Front map viewer.

                                                                    All map services must use SSL/HTTPS for it to be supported with MS4Front.
                                                                
                                                                    Examples of web services by format include:
                                                                    ArcGIS: https://[Your Server]/arcgis/rest/services/[Your Service]/MapServer
                                                                    Tiled: https://{s}.tile.cloudmade.com/{key}/256/{z}/{x}/{y}.png
                                                                    WMS: https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi 
                                                                        
                                                            `}
                                                    />
                                                </TextField>
                                            </Grid>
                                            {showLayerType === "ArcGIS" && (
                                                <Grid item xs={12}>
                                                    <Select
                                                        field="tileddynamic"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Tiled, dynamic, or feature service?"
                                                                helpText={
                                                                    <div>
                                                                        Is the layer tiled (cached), not (dynamic) or a feature service? Tiled
                                                                        services must be in Web Mercator projection (WKID/ESPG 3857).
                                                                    </div>
                                                                }
                                                            />
                                                        }
                                                        options={this.MAKE_OPTIONS_CUST(["Tiled", "Dynamic", "Feature Service"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                            {showLayerType === "WMS" && (
                                                <Grid item xs={12}>
                                                    <TextField field="wmslayers" fullWidth>
                                                        <HelpLabel
                                                            inputLabel="WMS Layers"
                                                            helpText={<div>Comma-separated list of WMS layers to show.</div>}
                                                        />
                                                    </TextField>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} className={classes.centerAlign}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={layerDialogOpen2} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Reference Layer
                        </Typography>
                        <IconButton onClick={() => this.setState({ layerDialogOpen2: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                validateError={this.errorValidatorLayer2}
                                defaultValues={activeLayer}
                                onSubmit={(values) => this.updateLayer2(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField field="name" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Layer Name"
                                                        helpText={<div>The name for this layer that will appear in the map legend.</div>}
                                                    />
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="order" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Map Order"
                                                        helpText={` 
                                                                Ordering starts from 0 and needs to increment by 1. This setting determines the vertical ordering of the layers that will be seen on the map. 
                                                                
                                                                Note: layers need to be unique from one another (ex: layer1 = 0, layer2 = 1, etc.)
                                                          `}
                                                    />
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="layer_type"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Layer Type"
                                                            helpText={
                                                                <div>
                                                                    ArcGIS: You have access to an ESRI ArcGIS Server you want to display on the map.
                                                                    <br />
                                                                    Tiled: There is an external tiled web map you want displayed on the map.
                                                                    <br />
                                                                    WMS: There is an external WMS service you want displayed on the map.
                                                                </div>
                                                            }
                                                        />
                                                    }
                                                    options={this.MAKE_OPTIONS_CUST(["Web Map", "ArcGIS", "Tiled", "WMS"])}
                                                    eventHandle={(value) => this.showLayerType(value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="page"
                                                    label="Display Layer Only in Specific Module"
                                                    options={MAKE_OPTIONS(pages)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {showLayerType === "Web Map" && (
                                                <Grid item xs={12}>
                                                    <Typography style={{ color: "red" }}>
                                                        * All of the feature layers in the web map and the web map must be shared with Public
                                                        otherwise an AGOL prompt will appear every time you access the map.
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {showLayerType === "Web Map" && (
                                                <Grid item xs={12}>
                                                    <TextField field="webmap_id" fullWidth eventHandle={(value) => this.handleWebMap(value)}>
                                                        <HelpLabel
                                                            inputLabel="Web Map ID"
                                                            helpText={<div>Web Map ID for layers to be pulled from.</div>}
                                                        />
                                                    </TextField>
                                                </Grid>
                                            )}
                                            {webMapError && (
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <ErrorOutlineIcon color="secondary" />
                                                    <Typography style={{ color: "#990033" }} variant="subtitle1">
                                                        Error Loading Layers From Web Map Id
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {!webMapError && showLayerType === "Web Map" && webMapLoading && (
                                                <div style={{ textAlign: "center" }}>
                                                    <CircularProgress color="primary" />
                                                </div>
                                            )}
                                            {!webMapError && showLayerType === "Web Map" && !webMapLoading && (
                                                <Typography variant="subtitle1">
                                                    <b>Web Map Title:</b> {webMapTitle}
                                                    <br />
                                                    <b>Web Map Owner:</b> {webMapOwner}
                                                    <br />
                                                    <b>Web Map Operation Layers:</b>
                                                    <ul>
                                                        {webMapLayers.map((x) => (
                                                            <li>{x}</li>
                                                        ))}
                                                    </ul>
                                                </Typography>
                                            )}
                                            {showLayerType !== "Web Map" &&
                                                (showLayerType === "ArcGIS" || showLayerType === "Tiled" || showLayerType === "WMS") && (
                                                    <Grid item xs={12}>
                                                        <TextField field="layerurl" fullWidth>
                                                            <HelpLabel
                                                                inputLabel="Layer URL"
                                                                helpText={` 
                                                                        Map Services must support requests in Web Mercator projection (WKID/ESPG 3857).
                                                                        Map Services may draw faster if the data source for the map service is stored in a
                                                                        Web Mercator projection because the server does not have to perform a
                                                                        re-projection on the fly. This is not a requirement and just a suggestion if you are
                                                                        experience slow drawing of the layer within the MS4Front map viewer.

                                                                        All map services must use SSL/HTTPS for it to be supported with MS4Front.
                                                                    
                                                                        Examples of web services by format include:
                                                                        ArcGIS: https://[Your Server]/arcgis/rest/services/[Your Service]/MapServer
                                                                        Tiled: https://{s}.tile.cloudmade.com/{key}/256/{z}/{x}/{y}.png
                                                                        WMS: https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi 
                                                                            
                                                                `}
                                                            />
                                                        </TextField>
                                                    </Grid>
                                                )}
                                            {showLayerType === "ArcGIS" && (
                                                <Grid item xs={12}>
                                                    <Select
                                                        field="tileddynamic"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Tiled, dynamic, or feature service?"
                                                                helpText={
                                                                    <div>
                                                                        Is the layer tiled (cached), not (dynamic) or a feature service? Tiled
                                                                        services must be in Web Mercator projection (WKID/ESPG 3857).
                                                                    </div>
                                                                }
                                                            />
                                                        }
                                                        options={this.MAKE_OPTIONS_CUST(["Tiled", "Dynamic", "Feature Service"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                            {showLayerType === "WMS" && (
                                                <Grid item xs={12}>
                                                    <TextField field="wmslayers" fullWidth>
                                                        <HelpLabel
                                                            inputLabel="WMS Layers"
                                                            helpText={<div>Comma-separated list of WMS layers to show.</div>}
                                                        />
                                                    </TextField>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <TextField field="opacity" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Opacity"
                                                        helpText={<div>(Optional) The opacity of the layer between 0-1. If blank default is 1.</div>}
                                                    />
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Switch
                                                    field="on_by_default"
                                                    name="on_by_default"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="On by Default?"
                                                            helpText={<div>Show this layer right away after the module loads?</div>}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.centerAlign}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Snackbar
                    snackbarOpen={snackbarOpen}
                    handleSnackbarClose={() => this.setState({ snackbarOpen: false })}
                    isSuccess
                    operationType="setting"
                />
                <WarningDialog
                    confirmAction={() => {
                        ormMapLayerDelete(activeLayer.id);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Layer"
                    text={"Are you sure you wish to permanently delete " + activeLayer.name + "?"}
                />
            </AppContainer>
        );
    }
}
MapSettings.displayName = "MapSettings";

MapSettings = connect(
    (state) => ({
        settings: settings(state),
        layers: layers(state),
        pages: allPages(state),
        authState: state.auth,
    }),
    {
        ...MapSetting.actions,
        ...MapLayer.actions,
    }
)(MapSettings);

export default withStyles(styles)(withRouter(MapSettings));
